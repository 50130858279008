import * as React from "react";
import {
  Typography,
  Button,
  Collapse,
  TextField,
  makeStyles,
  Theme,
  // Divider,
  Grid,
} from "@material-ui/core";
import parse from "html-react-parser";
import config from "customConfig";
import { wordCounter } from "utilities";

interface WritingContentProps {
  practice: any;
  setAnswers: (answer: any) => void;
  answers: any;
  submittedAnswer: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    overflow: "auto",
  },
  promptContainer: {
    backgroundColor: "#e8f4fd",
    padding: theme.spacing(1),
    borderRadius: "4px",
    overflowX: "hidden",
    [theme.breakpoints.up("sm")]: {
      overflowY: "auto",
      height: "calc(100vh - 8.5rem)",
      fontSize: "1.1rem",
    },
  },
  answerContainer: {
    padding: theme.spacing(1),
    overflowX: "hidden",
    [theme.breakpoints.up("sm")]: {
      overflowY: "auto",
      height: "calc(100vh - 8.5rem)",
      fontSize: "1.1rem",
    },
  },
  textField: {
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(2),
      width: "100%",
    },
  },
  prompt: {
    padding: "1rem",
    border: "solid 2px",
    marginTop: "1rem",
    marginBottom: "1rem",
    "& p": {
      fontWeight: 700,
      fontSize: "1rem",
    },
  },
  img: {
    width: "70%",
    height: "auto",
    margin: "auto",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  letter: {
    fontFamily: "Comic Sans MS, Comic Sans, cursive",
  },
  sampleAnswer: {
    padding: "1rem",
    border: "solid 2px",
    marginTop: "1rem",
    marginBottom: "1rem",
    "& p": {
      fontSize: "1rem",
    },
  },
}));

const WritingContent: React.FC<WritingContentProps> = ({
  practice,
  setAnswers,
  answers,
  submittedAnswer,
}) => {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    if (typeof answers === "string") {
      setAnswers(text);
    } else {
      if (practice.typeName === "Essay") {
        setAnswers({ ...answers, task2: text });
      } else {
        setAnswers({ ...answers, task1: text });
      }
    }
  };
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={6} className={classes.promptContainer}>
        {practice.typeName === "Essay" && (
          <EssayWrapper prompt={practice.prompt} />
        )}
        {practice.typeName === "Graph" && (
          <GraphWrapper prompt={practice.prompt} imgUrl={practice.img} />
        )}
        {practice.typeName === "Letter" && (
          <LetterWrapper prompt={practice.prompt} />
        )}
        {practice.sampleAnswer && submittedAnswer && (
          <SampleAnswerCollapse sampleAnswer={practice.sampleAnswer} />
        )}
      </Grid>

      {/* <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} /> */}
      <Grid item xs={12} sm={6} className={classes.answerContainer}>
        {submittedAnswer ? (
          <Typography style={{ whiteSpace: "pre-line" }}>
            {submittedAnswer}
          </Typography>
        ) : (
          <div className={classes.textField}>
            <TextField
              value={answers ?? ""}
              label="Write here"
              multiline
              rows={25}
              variant="outlined"
              margin="normal"
              onChange={handleChange}
            />
          </div>
        )}
        <Typography variant="h6">
          Words Count: {wordCounter(answers)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default WritingContent;

interface EssayWrapperProps {
  prompt: string;
}

const EssayWrapper: React.FC<EssayWrapperProps> = ({ prompt }) => {
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        <b>WRITING TASK 2</b>
      </Typography>
      <Typography gutterBottom>
        You should spend about 40 minutes on this task.
      </Typography>
      <Typography gutterBottom>Write abouit the following topic:</Typography>
      <WritingPrompt prompt={prompt} />
      <Typography gutterBottom>
        Give reasons for your answer and include any relevant examples from your
        won knowledge or experience.
      </Typography>
      <Typography gutterBottom>Write at least 250 words.</Typography>
    </div>
  );
};

interface GraphWrapperProps {
  prompt: string;
  imgUrl: string;
}

const GraphWrapper: React.FC<GraphWrapperProps> = ({ prompt, imgUrl }) => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        <b>WRITING TASK 1</b>
      </Typography>
      <Typography gutterBottom>
        You should spend about 20 minutes on this task.
      </Typography>
      <WritingPrompt prompt={prompt} />
      <Typography gutterBottom>Write at least 150 words.</Typography>
      <img
        src={`${config.awsUrl}resource/img/task2/${imgUrl}.jpg`}
        className={classes.img}
        alt=""
      />
    </div>
  );
};

interface LetterWrapperProps {
  prompt: string;
}

const LetterWrapper: React.FC<LetterWrapperProps> = ({ prompt }) => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        <b>WRITING TASK 1</b>
      </Typography>
      <Typography gutterBottom>
        You should spend about 20 minutes on this task.
      </Typography>
      <WritingPrompt prompt={prompt} />
      <Typography gutterBottom>Write at least 150 words.</Typography>
      <Typography gutterBottom>
        You do <b>NOT</b> need to write any addresses.
      </Typography>
      <Typography gutterBottom>Begin your letter as followings:</Typography>
      <Typography className={classes.letter}>Dear ________,</Typography>
    </div>
  );
};

interface WritingPromptProps {
  prompt: string;
}

const WritingPrompt: React.FC<WritingPromptProps> = ({ prompt }) => {
  const classes = useStyles();
  return <div className={classes.prompt}>{parse(prompt)}</div>;
};

interface SampleAnswerCollapseProps {
  sampleAnswer: string;
}

const SampleAnswerCollapse: React.FC<SampleAnswerCollapseProps> = ({
  sampleAnswer,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <Button onClick={handleExpandClick} variant="outlined" color="primary">
        {expanded ? "Hide" : "Show"} Sample Answer
      </Button>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.sampleAnswer}>{parse(sampleAnswer)}</div>
      </Collapse>
    </>
  );
};
