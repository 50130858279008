import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";

export interface ConfirmationDialogProps {
  message: string;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  message,
  onConfirm,
  onCancel,
  open,
}) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogContent>
        <Typography gutterBottom>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel} color="secondary">
          NO
        </Button>
        <Button autoFocus onClick={onConfirm} color="primary">
          YES
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
