import * as React from "react";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import {
  Grid,
  List,
  Typography,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Divider,
  LinearProgress,
} from "@material-ui/core";
import format from "date-fns/format";
import { millSecondsToMinutesAndSeconds } from "utilities";
import { useAuthValue } from "context/auth.context";
import * as mutations from "graphql/mutations";
import * as queries from "graphql/queries";
import CardLayout from "layout/CardLayout";
import { useImmer } from "use-immer";
import RenderInstruction from "layout/RenderInstruction";
import useAmplifyMutation from "hooks/useAmplifyMutation";
import useAmplifyQuery from "hooks/useAmplifyQuery";
import { useSnackbar } from "notistack";
import customConfig from "customConfig";

const { subjects } = customConfig;

export interface HomeworkDetailViewProps {
  preview?: boolean;
}

const HomeworkDetailView: React.FC<HomeworkDetailViewProps> = ({ preview }) => {
  const { state } = useLocation() as any;
  const hwData = state?.hwData;
  const { enqueueSnackbar } = useSnackbar();
  const { value, status, execute } = useAmplifyMutation();
  const { value: hwRaw, status: queryStatus } = useAmplifyQuery(
    queries.getHomework,
    { id: hwData.id }
  );
  const [homework, updateHomework] = useImmer(hwData);
  const history = useHistory();
  const mode = preview ? "preview" : "detail";
  const { isAdmin } = useAuthValue();
  React.useEffect(() => {
    if (hwRaw) {
      updateHomework(hwRaw.data.getHomework);
    }
    if (value) {
      updateHomework(value.data.updateHomework);
    }
    //eslint-disable-next-line
  }, [hwRaw, value]);
  if (!hwData) return <Redirect to="/homework" />;
  const { description, dueDate, practiceList } = homework;
  const onEdit = () => {
    history.push({ pathname: "edit", state: { hwData: homework } });
  };
  // const handleView = (practice: any) => {
  //   if (["Essay", "Graph", "Letter"].includes(practice.typeName)) {
  //     history.push({
  //       pathname: "/practice/writing",
  //       state: { hwData: practice },
  //     });
  //   } else if (
  //     [
  //       "PartOneTopic",
  //       "PartOne",
  //       "PartThree",
  //       "PartTwo",
  //       "SpeakingList",
  //     ].includes(practice.typeName)
  //   ) {
  //     history.push("/practice/speaking", { hwData: practice });
  //   } else {
  //     history.push({
  //       pathname: `/practice/${practice.typeName}`,
  //       state: {
  //         id: practice.id,
  //         submittedAnswers: practice.submittedAnswers,
  //         // timeLimit: practice.timeLimit,
  //       },
  //     });
  //   }
  // };

  const handleReset = async (practice: any) => {
    const newPracticeList = homework.practiceList.map((p: any) => {
      if (p.id !== practice.id) return p;
      const temp = { ...p };
      temp.submittedAnswers = null;
      temp.startTime = null;
      temp.endTime = null;
      return temp;
    });
    try {
      await execute({
        query: mutations.updateHomework,
        variables: {
          input: { id: homework.id, practiceList: newPracticeList },
        },
      });

      enqueueSnackbar("Successfully reset the question", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Failed to reset the question, please try again later", {
        variant: "error",
      });
      console.log("update homework error", error);
    }
  };
  const handleView = (practice: any) => {
    const { typeName, id } = practice;
    const subject = subjects[typeName];
    history.push(`/practice/${subject}?typeName=${typeName}&id=${id}`, {
      hw: homework,
      item: practice,
    });
  };
  // const handleStart = (practice: any) => {
  //   if (["Essay", "Graph", "Letter"].includes(practice.typeName)) {
  //     history.push("/practice/writing", {
  //       hwData: practice,
  //       submit: { subject: "hw", hw: homework },
  //     });
  //   } else if (
  //     [
  //       "PartOne",
  //       "PartOneTopic",
  //       "PartThree",
  //       "PartTwo",
  //       "SpeakingList",
  //     ].includes(practice.typeName)
  //   ) {
  //     history.push("/practice/speaking", {
  //       hwData: practice,
  //       submit: { subject: "hw", hw: homework },
  //     });
  //   } else {
  //     history.push({
  //       pathname: `/practice/${practice.typeName}`,
  //       state: {
  //         id: practice.id,
  //         timeLimit: practice.timeLimit,
  //         submittedAnswers: practice.submittedAnswers,
  //         submit: { subject: "hw", data: homework },
  //       },
  //     });
  //   }
  // };
  const handleStart = (practice: any) => {
    const { typeName, id } = practice;
    const subject = subjects[typeName];
    history.push(`/practice/${subject}?typeName=${typeName}&id=${id}`, {
      hw: homework,
      item: practice,
    });
  };
  const renderHomeworkItem = (p: any, i: number) => {
    let finishedTime;
    let usedTime;
    let timeLimit;
    if (p.startTime && p.endTime) {
      finishedTime = format(new Date(Number(p.endTime)), "yyyy-MM-dd hh:mm aa");
      usedTime = millSecondsToMinutesAndSeconds(
        Number(p.endTime - p.startTime)
      );
    }
    if (p.timeLimit) {
      timeLimit = `${p.timeLimit.substr(0, 2)} mins ${p.timeLimit.substr(
        2,
        4
      )} secs`;
    }
    return (
      <ListItem key={p.id + i}>
        <ListItemAvatar>
          <Avatar>{p.typeName.replace(/[^A-Z]+/g, "")}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`Question ${i + 1}`}
          secondary={
            finishedTime && usedTime
              ? `${
                  timeLimit ? `Time Limit: ${timeLimit}, ` : ""
                }finished at ${finishedTime}, used ${usedTime}`
              : p.timeLimit
              ? `Time Limit ${timeLimit}`
              : p.name || p.id
          }
        />
        <ListItemSecondaryAction>
          {isAdmin ? (
            <>
              <Button
                onClick={() => handleView(p)}
                variant="outlined"
                color="primary"
                style={{ marginRight: "1rem" }}
              >
                view
              </Button>
              <Button
                onClick={() => handleReset(p)}
                variant="outlined"
                color="primary"
              >
                reset
              </Button>
            </>
          ) : p.submittedAnswers ? (
            <Button
              onClick={() => handleStart(p)}
              variant="outlined"
              color="primary"
            >
              view
            </Button>
          ) : (
            <Button
              onClick={() => handleStart(p)}
              variant="outlined"
              color="primary"
            >
              start
            </Button>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  return (
    <CardLayout title="Homework Detail" mode={mode} onEdit={onEdit}>
      <Grid container direction="column" spacing={3}>
        {description && <RenderInstruction instruction={description} />}
        <Grid item>
          <Typography variant="h6">Due Date：</Typography>
          <Typography>
            <b>
              {dueDate &&
                format(new Date(homework.dueDate), "yyyy-MM-dd hh:mm aa")}
            </b>
          </Typography>
        </Grid>
        <Divider />
        {queryStatus === "pending" && <LinearProgress />}
        {status === "pending" && <LinearProgress />}
        <Grid item>
          <Typography variant="h6">Homework questions:</Typography>
          <List>{practiceList && practiceList.map(renderHomeworkItem)}</List>
        </Grid>
      </Grid>
    </CardLayout>
  );
};

HomeworkDetailView.defaultProps = {
  preview: false,
};

export default HomeworkDetailView;
