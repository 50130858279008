import { useAuthValue } from "context/auth.context";
import * as React from "react";
import UserList from "components/UserList";
import SpeakingQuestion from "./SpeakingQuestion";
import PartTwoQuestion from "./PartTwoQuestion";
import {
  Divider,
  Typography,
  makeStyles,
  Theme,
  Paper,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: "5px",
    overflow: "auto",
    margin: "auto",
    width: "100%",
    height: "100%",
    maxWidth: theme.breakpoints.values.lg,
    padding: theme.spacing(2),
  },
}));

interface SpeakingContentProps {
  practice: any;
}

const SpeakingContent: React.FC<SpeakingContentProps> = ({ practice }) => {
  const classes = useStyles();
  const { typeName } = practice;
  const { isAdmin, user } = useAuthValue();
  const [username, setUsername] = React.useState(user.username);
  return (
    <Paper className={classes.root}>
      {isAdmin && <UserList handleUserChange={setUsername} value={username} />}
      {["PartOneTopic", "SpeakingList"].includes(typeName) &&
        practice.questions.map((q: any, index: number) => (
          <SpeakingQuestion
            data={q}
            index={index + 1}
            key={index}
            username={username || user.username}
          />
        ))}
      {practice.typeName === "PartTwo" && (
        <>
          <PartTwoQuestion
            data={practice}
            username={username || user.username}
          />
          <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <Typography variant="h5">Part Three:</Typography>
          {practice.partThreeQuestions.map((q: any, index: number) => (
            <SpeakingQuestion
              data={q}
              index={index + 1}
              key={index}
              username={username || user.username}
            />
          ))}
        </>
      )}
      {["PartOne", "PartThree"].includes(practice.typeName) && (
        <SpeakingQuestion
          data={practice}
          index={1}
          key={1}
          username={username || user.username}
        />
      )}
    </Paper>
  );
};

export default SpeakingContent;
