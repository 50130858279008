import CreateExamButton from "components/Exam/CreateExamButton";
import MainWrapper from "layout/MainWrapper";
import MaterialTable from "material-table";
import * as React from "react";
import { format } from "date-fns";
import { API } from "aws-amplify";
import * as mutations from "graphql/mutations";
import { useAuthValue } from "context/auth.context";
import Spinner from "components/Spinner";
import * as queries from "graphql/queries";
import { useSnackbar } from "notistack";
import { tableIcons } from "utilities/materialTableIcon";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import InfoIcon from "@material-ui/icons/Info";
import RestoreIcon from "@material-ui/icons/Restore";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useHistory } from "react-router-dom";
import ConfirmationDialog from "components/ConfirmationDialog";

interface ExamListViewProps {}

const checkStatus = (data: any) => {
  const currentDate = new Date().getTime();
  const dueDate = new Date(data.dueDate).getTime();
  if (!data.status) {
    if (currentDate > dueDate) {
      API.graphql({
        query: mutations.updateExam,
        variables: { input: { id: data.id, status: "overdue" } },
      });
      return "overdue";
    }
    return "not start";
  }
  return data.status;
};

const adminColumn = [
  { field: "name", title: "Exam" },
  { field: "student", title: "Student" },
  {
    field: "dueDate",
    title: "Due Date",
    render: (rowData: any) =>
      format(new Date(rowData.dueDate), "yyyy-MM-dd hh:mm aa"),
  },
  {
    field: "submittedDate",
    title: "Submitted Date",
    render: (rowData: any) => {
      if (rowData.submittedDate) {
        return format(new Date(rowData.submittedDate), "yyyy-MM-dd hh:mm aa");
      }
      return "Not Submitted";
    },
  },
  {
    field: "status",
    title: "Status",
    render: (rowData: any) => checkStatus(rowData),
  },
];

const studentColumn = [
  { field: "name", title: "Exam" },
  {
    field: "dueDate",
    title: "Due Date",
    render: (rowData: any) =>
      format(new Date(rowData.dueDate), "yyyy-MM-dd hh:mm aa"),
  },
  {
    field: "status",
    title: "Status",
    render: (rowData: any) => checkStatus(rowData),
  },
];

const ExamListView: React.FC<ExamListViewProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedData, setSelectedData] = React.useState<any>(null);
  const history = useHistory();
  const { user } = useAuthValue();
  const isAdmin = user.groups?.includes("admin");
  const columns = isAdmin ? adminColumn : studentColumn;
  const [data, setData] = React.useState<any>(null);
  const handleResetExam = (_event: any, rowData: any) => {
    setOpen(true);
    setSelectedData(rowData);
  };
  const fetchExamList = async () => {
    try {
      const returnedData = (await API.graphql({
        query: queries.listExams,
        variables: { limit: 1000 },
      })) as any;
      setData(returnedData.data.listExams.items);
    } catch (error) {
      console.log("fetch exam list error", error);
      enqueueSnackbar(
        "Connection Error, please wait a moment and refresh the page.",
        { variant: "error" }
      );
    }
  };
  const resetExam = async () => {
    try {
      await API.graphql({
        query: mutations.updateExam,
        variables: {
          input: {
            id: selectedData.id,
            submittedAnswers: null,
            submittedDate: null,
            result: null,
            status: null,
          },
        },
      });
      onCancel();
    } catch (error) {
      console.log("reset exam error", error);
      enqueueSnackbar("failed to reset exam", { variant: "error" });
    }
    fetchExamList();
  };
  const onCancel = () => {
    setSelectedData(null);
    setOpen(false);
  };
  const cleanedData = data
    ? data.sort(
        (a: any, b: any) =>
          new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime()
      )
    : [];
  React.useEffect(() => {
    fetchExamList();
    //eslint-disable-next-line
  }, []);
  if (!data) return <Spinner />;
  return (
    <MainWrapper>
      <CreateExamButton />
      <MaterialTable
        title="Mock Exam List"
        data={cleanedData}
        columns={columns}
        icons={tableIcons}
        actions={[
          (rowData: any) => {
            if (rowData.submittedAnswers && isAdmin) {
              return {
                icon: () => <VisibilityIcon />,
                tooltip: "review exam",
                onClick: (_event, rowData: any) =>
                  history.push({
                    pathname: "/exam/review",
                    state: { examData: rowData },
                  }),
              };
            }
            if (!rowData.submittedAnswers) {
              return {
                icon: () => <ArrowForwardIcon />,
                tooltip: "start",
                onClick: (_event, rowData: any) =>
                  history.push({
                    pathname: "/exam/test",
                    state: { examData: rowData },
                  }),
              };
            }
            if (rowData.submittedAnswers && !isAdmin) {
              return {
                icon: () => <InfoIcon />,
                tooltip: "info",
                onClick: (_event, _rowData: any) =>
                  enqueueSnackbar(
                    "You have completed the exam, for result or reseting the exam, please contact your instructor.",
                    { variant: "info" }
                  ),
              };
            }
          },
          (rowData: any) => {
            if (rowData.submittedAnswers && isAdmin) {
              return {
                icon: () => <RestoreIcon />,
                tooltip: "reset exam",
                onClick: handleResetExam,
              };
            }
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          sorting: true,
          pageSize: 10,
        }}
      />
      <ConfirmationDialog
        open={open}
        message="Are you sure you want to reset the exam"
        onCancel={onCancel}
        onConfirm={resetExam}
      />
    </MainWrapper>
  );
};

export default ExamListView;
