import * as React from "react";
import WritingCard from "layout/WritingCard";
import { Typography, Divider } from "@material-ui/core";
import SpeakingQuestion from "components/Practice/speaking/SpeakingQuestion";
import { useAuthValue } from "context/auth.context";
import UserList from "components/UserList";
import PartTwoQuestion from "components/Practice/speaking/PartTwoQuestion";

interface RenderSpeakingPartProps {
  data: any;
}

const RenderSpeakingPart: React.FC<RenderSpeakingPartProps> = ({ data }) => {
  const { user } = useAuthValue();
  const isAdmin = user.groups?.includes("admin");
  const [username, setUsername] = React.useState(user.username);

  const title = data.typeName === "PartOneTopic" ? "Part 1" : "Part 2 and 3";
  return (
    <WritingCard title={title}>
      {isAdmin && <UserList handleUserChange={setUsername} value={username} />}
      {data &&
        data.typeName === "PartOneTopic" &&
        data.questions.map((q: any, index: number) => (
          <SpeakingQuestion
            data={q}
            index={index + 1}
            key={index}
            username={username || user.username}
          />
        ))}
      {data && data.typeName === "PartTwo" && (
        <>
          <PartTwoQuestion data={data} username={username || user.username} />
          <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <Typography variant="h5">Part Three:</Typography>
          {data.partThreeQuestions.map((q: any, index: number) => (
            <SpeakingQuestion
              data={q}
              index={index + 1}
              key={index}
              username={username || user.username}
            />
          ))}
        </>
      )}
    </WritingCard>
  );
};

export default RenderSpeakingPart;
