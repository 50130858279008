import * as React from "react";
import { fetchPractice } from "utilities/awsCRUD";
import BottomBanner from "../Practice/components/BottomBanner";
import RenderListeningQuestion from "./RenderListeningQuestion";
import TopBanner from "../Practice/components/TopBanner";
import config from "customConfig";
import AudioPlayer from "components/AudioPlayer";
import Spinner from "components/Spinner";
import { emptyObj } from "utilities";
import MainWrapper from "layout/MainWrapper";

interface RenderListeningProps {
  format: string;
  typeName: string;
  id: string;
  time?: string;
  handleListeningAnswer: any;
  submittedListeningAnswer: any;
  onSubmit: () => void;
  error: any;
  setAnswer: (answer: any) => void;
}

const RenderListening: React.FC<RenderListeningProps> = ({
  format,
  typeName,
  id,
  time,
  onSubmit,
  error,
  handleListeningAnswer,
  submittedListeningAnswer,
  setAnswer,
}) => {
  const [practice, setPractice] = React.useState<any>(null);
  const [index, setIndex] = React.useState<number>(0);
  const [scriptData, setScriptData] = React.useState<any>(null);
  const [scriptActiveIndex, setScriptActiveIndex] = React.useState<number>();
  const [showScript, setShowScript] = React.useState<boolean>(false);
  const player = React.createRef<HTMLAudioElement>();
  const sectionList = React.useMemo<any[]>(
    () => [practice?.s1, practice?.s2, practice?.s3, practice?.s4],
    [practice]
  );
  const handleNext = () => {
    setIndex((pre) => pre + 1);
  };
  const handlePrev = () => {
    setIndex((pre) => pre - 1);
  };
  const handleClick = (start: string) => {
    player.current.currentTime = Number(start);
    player.current.paused ? player.current.play() : player.current.pause();
  };
  const handleTimeUpdate = (e: any) => {
    const currentTime = e.currentTarget.currentTime;
    if (scriptData && scriptData.length > 0) {
      scriptData.forEach((scr: any, index: number) => {
        if (scr.startTime <= currentTime && scr.endTime >= currentTime) {
          setScriptActiveIndex(index);
        }
      });
    }
  };
  const toggleShowScript = () => {
    setShowScript(!showScript);
  };
  React.useEffect(() => {
    if (practice && showScript) {
      let sectionID;
      if (typeName === "ListeningTest") {
        sectionID = sectionList[index].id;
      } else {
        sectionID = practice.id;
      }
      const link = `${config.awsUrl}resource/scripts/${sectionID}.json`;
      fetch(link)
        .then((res) => res.json())
        .then((j_data) => setScriptData(j_data));
    }
  }, [typeName, index, showScript, practice, sectionList]);

  React.useEffect(() => {
    if (id) {
      fetchPractice(typeName, id, setPractice);
    }
    // eslint-disable-next-line
  }, [id, typeName]);
  React.useEffect(() => {
    if (!submittedListeningAnswer && practice) {
      handleListeningAnswer(emptyObj(practice.answers));
      setAnswer(practice.answers);
    }
    //eslint-disable-next-line
  }, [practice]);
  console.log("audio", player);
  if (!practice || !submittedListeningAnswer) return <Spinner />;
  return (
    <>
      <AudioPlayer
        sectionID={
          typeName === "ListeningTest" ? sectionList[index].id : practice.id
        }
        ref={player}
        showScript={showScript}
        hide={true}
        handleTimeUpdate={handleTimeUpdate}
      />
      <TopBanner
        format={format}
        title="Listening"
        time={time}
        toggleShowScript={toggleShowScript}
        audioPlayer={player}
        onSubmit={onSubmit}
        result={error}
      />
      <MainWrapper>
        <RenderListeningQuestion
          typeName={typeName}
          errors={error}
          setSubmittedAnswers={handleListeningAnswer}
          submittedAnswers={submittedListeningAnswer}
          practice={
            typeName === "ListeningTest" ? sectionList[index] : practice
          }
          scriptData={scriptData}
          showScript={showScript}
          handleClick={handleClick}
          scriptActiveIndex={scriptActiveIndex}
          index={index}
          handleNext={handleNext}
          handlePrev={handlePrev}
        />
      </MainWrapper>

      <BottomBanner
        title="Listening"
        onSubmit={onSubmit}
        submittedAnswers={submittedListeningAnswer}
      />
    </>
  );
};

export default RenderListening;
