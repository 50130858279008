import { getAnswersFromArray, getAnswersFromArrays } from "utilities";

const sortQuestion = (a, b) => {
  const aStart = Number(a.range.split("-")[0]);
  const bStart = Number(b.range.split("-")[0]);
  return aStart - bStart;
};

export const normalizeLQ = (LQ) => {
  if (!LQ) return null;
  const data = LQ.data ? LQ.data.getListeningQuestion : LQ;
  if (Object.keys(LQ).length === 0) return null;
  const {
    questionBody,
    type,
    sectionID,
    answers,
    name,
    range,
    id,
    start,
    end,
  } = data;
  return {
    questionBody: JSON.parse(questionBody),
    typeName: "ListeningQuestion",
    type,
    sectionID,
    answers: JSON.parse(answers),
    id,
    name,
    range,
    start,
    end,
  };
};

export const normalizeLS = (LS) => {
  if (!LS) return null;
  const data = LS.data ? LS.data.getListeningSection : LS;
  if (Object.keys(data).length === 0) return null;
  const { id, questions } = data;
  const normalizedQuestions = questions.items
    .map((question) => normalizeLQ(question))
    .sort(sortQuestion);
  const answers = getAnswersFromArray(normalizedQuestions);
  return {
    id,
    questions: normalizedQuestions,
    answers,
    typeName: "ListeningSection",
  };
};

export const normalizeLT = (LT) => {
  const data = LT.data.getListeningTest;
  if (!data) return null;
  if (Object.keys(data).length === 0) return null;
  const { id, s1, s2, s3, s4, book, test } = data;
  const normalizeSectionOne = normalizeLS(s1);
  const normalizeSectionTwo = normalizeLS(s2);
  const normalizeSectionThree = normalizeLS(s3);
  const normalizeSectionFour = normalizeLS(s4);
  const answers = getAnswersFromArrays(
    normalizeSectionOne.questions,
    normalizeSectionTwo.questions,
    normalizeSectionThree.questions,
    normalizeSectionFour.questions
  );
  return {
    id,
    book,
    test,
    s1: normalizeSectionOne,
    s2: normalizeSectionTwo,
    s3: normalizeSectionThree,
    s4: normalizeSectionFour,
    answers,
    typeName: "ListeningTest",
  };
};
export const normalizeRQ = (RQ) => {
  if (!RQ) return null;
  const data = RQ.data ? RQ.data.getReadingQuestion : RQ;
  if (Object.keys(data).length === 0) return null;
  const { questionBody, type, passage, answers, name, range, id } = data;
  return {
    questionBody: JSON.parse(questionBody),
    type,
    id,
    passage: passage.passage,
    answers: JSON.parse(answers),
    name,
    range,
    typeName: "ReadingQuestion",
  };
};

export const normalizeRP = (RP) => {
  if (!RP) return null;
  const data = RP.data ? RP.data.getReadingPassage : RP;
  if (Object.keys(data).length === 0) return null;
  const { id, questions, passage } = data;
  const normalizedQuestions = questions.items
    .map((question) => normalizeRQ(question))
    .sort(sortQuestion);
  const answers = getAnswersFromArray(normalizedQuestions);
  return {
    id,
    questions: normalizedQuestions,
    answers,
    passage,
    typeName: "ReadingPassage",
  };
};

export const normalizeRTA = (RTA) => {
  const data = RTA.data.getReadingTestA;
  if (!data) return null;
  if (Object.keys(data).length === 0) return null;
  const { id, book, test, genre, p1, p2, p3 } = data;
  const normalizePassageOne = normalizeRP(p1);
  const normalizePassageTwo = normalizeRP(p2);
  const normalizePassageThree = normalizeRP(p3);
  const answers = getAnswersFromArrays(
    normalizePassageOne.questions,
    normalizePassageTwo.questions,
    normalizePassageThree.questions
  );
  return {
    id,
    book,
    test,
    genre,
    p1: normalizePassageOne,
    p2: normalizePassageTwo,
    p3: normalizePassageThree,
    answers,
    typeName: "ReadingTestA",
  };
};

export const normalizeRTG = (RTG) => {
  const data = RTG.data.getReadingTestG;
  if (!data) return null;
  if (Object.keys(data).length === 0) return null;
  const { id, s1a, s1b, s2a, s2b, s3 } = data;
  const normalizeSectionOneA = normalizeRP(s1a);
  const normalizeSectionOneB = normalizeRP(s1b);
  const normalizeSectionTwoA = normalizeRP(s2a);
  const normalizeSectionTwoB = normalizeRP(s2b);
  const normalizeSectionThree = normalizeRP(s3);
  const answers = getAnswersFromArrays(
    normalizeSectionOneA.questions,
    normalizeSectionOneB.questions,
    normalizeSectionTwoA.questions,
    normalizeSectionTwoB.questions,
    normalizeSectionThree.questions
  );
  return {
    id,
    s1a: normalizeSectionOneA,
    s1b: normalizeSectionOneB,
    s2a: normalizeSectionTwoA,
    s2b: normalizeSectionTwoB,
    s3: normalizeSectionThree,
    answers,
    typeName: "ReadingTestG",
  };
};

export const normalizeCustomTest = (data) => {
  const questions = data.questions.map((q) => ({
    ...q,
    answers: JSON.parse(q.answers),
    questionBody: JSON.parse(q.questionBody),
  }));
  const answers = JSON.parse(data.answers);
  return { ...data, questions, answers };
};

export const normalizeWritingTask = (data, typeName) => {
  let writingTask;
  if (typeName === "Essay") {
    writingTask = data.data.getEssay;
  }
  if (typeName === "Graph") {
    writingTask = data.data.getGraph;
  }
  if (typeName === "Letter") {
    writingTask = data.data.getLetter;
  }
  const { book, genre, id } = writingTask;
  const header = `${book}-${genre && genre.toLowerCase()}-`;
  const name = id.replace(header, "");
  const normalized = { ...writingTask, name };
  delete normalized.createdAt;
  delete normalized.updatedAt;
  return normalized;
};
