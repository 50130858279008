import MainWrapper from "layout/MainWrapper";
import * as React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import NoteCreationView from "./NoteCreationView";
import NoteDetailView from "./NoteDetailView";
import NoteEditionView from "./NoteEditionView";
import NoteListView from "./NoteListView";
import ProtectedRoute from "routes/utilities/ProtectedRoute";

export interface NoteProps {}

const Note: React.FC<NoteProps> = () => {
  const { path } = useRouteMatch();
  return (
    <MainWrapper>
      <Switch>
        <Route exact path={path}>
          <NoteListView />
        </Route>
        <ProtectedRoute exact path={`${path}/create`}>
          <NoteCreationView />
        </ProtectedRoute>
        <ProtectedRoute exact path={`${path}/edit`}>
          <NoteEditionView />
        </ProtectedRoute>
        <Route exact path={`${path}/detail`}>
          <NoteDetailView />
        </Route>
        <ProtectedRoute exact path={`${path}/preview`}>
          <NoteDetailView preview={true} />
        </ProtectedRoute>
      </Switch>
    </MainWrapper>
  );
};

export default Note;
