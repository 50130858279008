import * as React from "react";
import Passage from "./Passage";

interface EditPassagesProps {
  readingTest: any;
  updateReadingTest: (readingTest: any) => void;
}

const EditPassages: React.FC<EditPassagesProps> = ({
  readingTest,
  updateReadingTest,
}) => {
  const { genre, book, test, p1, p2, p3, s1a, s1b, s2a, s2b, s3 } = readingTest;
  const testID = `${book}-${genre}-${test}`.toLowerCase();
  const updateQuestions = (passageNumber: string) => (questions: any) => {
    updateReadingTest((draft: any) => {
      draft[passageNumber].questions = questions;
    });
  };
  const updateArticle = (passageNumber: string) => (article: string) => {
    updateReadingTest((draft: any) => {
      draft[passageNumber].passage = article;
    });
  };
  if (book && genre && test)
    return (
      <>
        {genre === "A" ? (
          <>
            <Passage
              passageID={`${testID}-p1`}
              questions={p1.questions}
              setQuestions={updateQuestions("p1")}
              article={p1.passage}
              setArticle={updateArticle("p1")}
            />
            <Passage
              passageID={`${testID}-p2`}
              questions={p2.questions}
              setQuestions={updateQuestions("p2")}
              article={p2.passage}
              setArticle={updateArticle("p2")}
            />
            <Passage
              passageID={`${testID}-p3`}
              questions={p3.questions}
              setQuestions={updateQuestions("p3")}
              article={p3.passage}
              setArticle={updateArticle("p3")}
            />
          </>
        ) : (
          <>
            <Passage
              passageID={`${testID}-s1a`}
              questions={s1a.questions}
              setQuestions={updateQuestions("s1a")}
              article={s1a.passage}
              setArticle={updateArticle("s1a")}
            />
            <Passage
              passageID={`${testID}-s1b`}
              questions={s1b.questions}
              setQuestions={updateQuestions("s1b")}
              article={s1b.passage}
              setArticle={updateArticle("s1b")}
            />
            <Passage
              passageID={`${testID}-s2a`}
              questions={s2a.questions}
              setQuestions={updateQuestions("s2a")}
              article={s2a.passage}
              setArticle={updateArticle("s2a")}
            />
            <Passage
              passageID={`${testID}-s2b`}
              questions={s2b.questions}
              setQuestions={updateQuestions("s2b")}
              article={s2b.passage}
              setArticle={updateArticle("s2b")}
            />
            <Passage
              passageID={`${testID}-s3`}
              questions={s3.questions}
              setQuestions={updateQuestions("s3")}
              article={s3.passage}
              setArticle={updateArticle("s3")}
            />
          </>
        )}
      </>
    );
  return null;
};

export default EditPassages;
