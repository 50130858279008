import MainWrapper from "layout/MainWrapper";
import * as React from "react";
import Calendar from "components/Scheduler";

interface MyCalendarProps {}

const MyCalendar: React.FC<MyCalendarProps> = () => {
  return (
    <MainWrapper>
      <Calendar />
    </MainWrapper>
  );
};

export default MyCalendar;
