import * as React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Avatar,
} from "@material-ui/core";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import AddPracticeDialog from "./AddPracticeDialog";
import useLocalStorage from "hooks/useLocalStorage";
export interface AddNotePracticeListProps {
  practiceList: any[];
  append: (data: any) => void;
  remove: (index: number) => void;
}

const AddNotePracticeList: React.FC<AddNotePracticeListProps> = ({
  practiceList,
  append,
  remove,
}) => {
  const [open, setOpen] = useLocalStorage("noteDialog", false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <List>
      {practiceList.length > 0 &&
        practiceList.map((p: any, i: number) => (
          <ListItem key={p.id + i}>
            <ListItemAvatar>
              <Avatar>
                <QuestionAnswerIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={p.name || p.id}
              secondary={`${p.typeName} ${p.type || p.types || ""}`}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => remove(i)}
              >
                <DeleteIcon color="primary" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      <IconButton onClick={() => setOpen(true)}>
        <AddIcon />
      </IconButton>

      {open && (
        <AddPracticeDialog
          open={open}
          handleClose={handleClose}
          append={append}
        />
      )}
    </List>
  );
};

export default AddNotePracticeList;
