import * as React from "react";
import { Paper, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import parse from "html-react-parser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      // maxHeight: "95vh",
      height: "100%",
      // overflow: "scroll",
      [theme.breakpoints.down("xs")]: {
        maxHeight: "100%",
        flexDirection: "column",
        height: "100%",
      },
    },
    questions: {
      height: "85vh",
      fontSize: "0.75rem",
      overflow: "scroll",
      overflowX: "hidden",
      [theme.breakpoints.down("xs")]: {
        maxHeight: "100%",
      },
    },
    passage: {
      height: "85vh",
      fontSize: "1.1rem",
      padding: "5px",
      overflow: "scroll",
      overflowX: "hidden",
      [theme.breakpoints.down("xs")]: {
        maxHeight: "100%",
        fontSize: "1rem",
      },
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      // margin: "1rem",
      // paddingBottom: "1rem",
    },
    result: {
      margin: "1rem",
    },
  })
);

export interface ReadingWrapperProps {
  passage: string;
}

const ReadingWrapper: React.FC<ReadingWrapperProps> = ({
  passage,
  children,
}) => {
  const [button, ...questions] = React.Children.toArray(children).reverse();
  const classes = useStyles();
  return (
    <Paper>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={6} className={classes.passage}>
          {parse(passage)}
        </Grid>
        <Grid item xs={12} sm={6} className={classes.questions}>
          {questions.reverse()}
        </Grid>
        <Grid item className={classes.buttonContainer} xs={12}>
          {button}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ReadingWrapper;
