import * as React from "react";
import parse from "html-react-parser";
import { makeStyles, Theme, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#e8f4fd",
    padding: theme.spacing(1),
    borderRadius: "4px",
    margin: theme.spacing(1),
  },
}));

interface RenderInstructionProps {
  instruction: any;
}

const RenderInstruction: React.FC<RenderInstructionProps> = ({
  instruction,
}) => {
  const classes = useStyles();
  if (!instruction) return null;
  return (
    <Grid item className={classes.root}>
      {parse(instruction)}
    </Grid>
  );
};

export default RenderInstruction;
