import * as React from "react";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useAuthValue } from "context/auth.context";
import CreateExamDialog from "./CreateExamDialog";

interface CreateExamButtonProps {}

const CreateExamButton: React.FC<CreateExamButtonProps> = () => {
  const { user } = useAuthValue();
  const isAdmin = user.groups?.includes("admin");
  const [open, setOpen] = React.useState<boolean>(false);
  const handleClose = () => {
    setOpen(false);
  };
  if (!isAdmin) return null;
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setOpen(true)}
      >
        Create
      </Button>
      {open && <CreateExamDialog open={open} handleClose={handleClose} />}
    </>
  );
};

export default CreateExamButton;
