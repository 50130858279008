import * as React from "react";
import { Storage } from "aws-amplify";

interface DownloadFileProps {
  storageKey: string;
}

const DownloadFile: React.FC<DownloadFileProps> = ({ storageKey }) => {
  const [signedURL, setSignedURL] = React.useState<string>("");

  React.useEffect(() => {
    if (storageKey) {
      (async () => {
        const url = (await Storage.get(storageKey)) as string;
        setSignedURL(url);
      })();
    }
  }, [storageKey]);
  if (!signedURL || !storageKey) return null;
  return (
    <a href={signedURL} target="_blank" rel="noreferrer">
      {storageKey.split("/")[1]}
    </a>
  );
};

export default DownloadFile;
