import * as React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Slide,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { TransitionProps } from "@material-ui/core/transitions";
import AddQuestion from "./AddQuestion";
import { rangeToArray } from "utilities";
import { validateRange } from "utilities/validation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface QuestionDialogProps {
  handleSave: (question: any) => void;
  open: boolean;
  handleClose: () => void;
  listening: boolean;
  question: any;
}

const validateQuestionMeta = (
  range: string,
  type: string,
  start: any,
  end: any,
  listening: boolean
) => {
  if (listening) return validateRange(range) && start < end && type;
  return validateRange(range) && type;
};

const validateAnswers = (range: string, type: string, answers: any) => {
  if (!range || Object.keys(answers).length === 0) return false;
  const rangeArray = rangeToArray(range);
  if (type === "MC") {
    if (
      range === Object.keys(answers)[0] &&
      rangeArray.length === answers[range].length
    )
      return true;
  } else {
    const answersArray = Object.keys(answers);
    for (var i = 0; i < rangeArray.length; ++i) {
      if (rangeArray[i] !== parseInt(answersArray[i])) return false;
    }
    if (Object.values(answers).every((ans) => !!ans)) return true;
  }
  return false;
};

const listeningTypes = ["SC", "MC", "Matching", "Map", "FG", "FGO"];
const readingTypes = [
  "SC",
  "MC",
  "TFNG",
  "Matching",
  "Info",
  "LOH",
  "FG",
  "FGO",
];

const QuestionDialog: React.FC<QuestionDialogProps> = ({
  handleSave,
  open,
  handleClose,
  question,
  listening,
}) => {
  const classes = useStyles();
  const firstUpdate = React.useRef(true);
  const [range, setRange] = React.useState(question?.range);
  const [type, setType] = React.useState(question?.type);
  const questionTypes = listening ? listeningTypes : readingTypes;
  const [start, setStart] = React.useState(question?.start);
  const [end, setEnd] = React.useState(question?.end);

  const [questionBody, setQuestionBody] = React.useState<any>(
    question?.questionBody || null
  );

  const [answers, setAnswers] = React.useState<any>(question?.answers || {});
  const [step, setStep] = React.useState<number>(0);
  const onClose = () => {
    handleClose();
  };

  const onSave = () => {
    const data = listening
      ? {
          range,
          type,
          start,
          end,
          questionBody,
          answers,
          typeName: "ListeningQuestion",
        }
      : { range, type, questionBody, answers, typeName: "ReadingQuestion" };
    handleSave(data);
    handleClose();
  };
  const displaySave =
    validateQuestionMeta(range, type, start, end, listening) &&
    step &&
    validateAnswers(range, type, answers);

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setQuestionBody(null);
    setAnswers({});
  }, [range, type]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      disableEnforceFocus
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {question ? "Edit Question" : "Add Question"}
          </Typography>

          <Button
            autoFocus
            color="secondary"
            onClick={onSave}
            disabled={!displaySave}
            variant="outlined"
          >
            {question ? "Update" : "Save"}
          </Button>
        </Toolbar>
      </AppBar>
      <TextField
        error={!validateRange(range)}
        helperText={!validateRange(range) && "please enter correct format"}
        value={range ?? ""}
        required
        label="Question Range"
        variant="outlined"
        autoComplete="off"
        name="range"
        onChange={(e) => {
          setRange(e.target.value);
        }}
      />
      <FormControl variant="outlined">
        <InputLabel id="type-label">Question Type</InputLabel>
        <Select
          labelId="type-label"
          value={type ?? ""}
          onChange={(e) => {
            setType(e.target.value);
          }}
          label="Question Type"
        >
          {questionTypes.map((type: string) => (
            <MenuItem value={type} key={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {listening && (
        <div>
          <Typography>audio clip range</Typography>
          <TextField
            name="start"
            error={start >= end}
            label="audio start at"
            variant="outlined"
            type="number"
            autoComplete="off"
            value={start ?? ""}
            onChange={(e) => {
              setStart(e.target.value);
            }}
          />
          <TextField
            name="end"
            error={start >= end}
            label="audio end at"
            variant="outlined"
            autoComplete="off"
            type="number"
            value={end ?? ""}
            onChange={(e) => {
              setEnd(e.target.value);
            }}
          />
        </div>
      )}

      {validateQuestionMeta(range, type, start, end, listening) && (
        <AddQuestion
          listening={listening}
          type={type}
          range={range}
          question={questionBody}
          answers={answers}
          setAnswers={setAnswers}
          setQuestion={setQuestionBody}
          step={step}
          setStep={setStep}
        />
      )}
    </Dialog>
  );
};

export default QuestionDialog;
