import * as React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Exam from "./Exam";
import ExamListView from "./ExamListView";
import RenderExam from "./RenderExam";
import ProtectedRoute from "routes/utilities/ProtectedRoute";

export interface ExamIndexProps {}

const ExamIndex: React.FC<ExamIndexProps> = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <ExamListView />
      </Route>
      <ProtectedRoute exact path={`${path}/createModel`}>
        <Exam />
      </ProtectedRoute>
      <ProtectedRoute exact path={`${path}/editModel`}>
        <Exam />
      </ProtectedRoute>
      <Route exact path={`${path}/test`}>
        <RenderExam />
      </Route>
      <ProtectedRoute exact path={`${path}/review`}>
        <RenderExam />
      </ProtectedRoute>
      <ProtectedRoute exact path={`${path}/preview`}>
        <RenderExam />
      </ProtectedRoute>
    </Switch>
  );
};

export default ExamIndex;
