import * as React from "react";
import NoteTable from "components/Assignment/NoteTable";

export interface NoteListViewProps {}

const NoteListView: React.FC<NoteListViewProps> = () => {
  return <NoteTable />;
};

export default NoteListView;
