import * as React from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import AWSFileInput from "components/AWSFileInput";
import { useCourseValue } from "context/course.context";
import { format } from "date-fns";
import DeleteButton from "components/DeleteButton";
import { useAuthValue } from "context/auth.context";
import DownloadFile from "components/DownloadFile";
import RenderInstruction from "layout/RenderInstruction";

interface RenderContentAssignmentProps {
  onEdit: () => void;
  content: any;
}

const RenderContentAssignment: React.FC<RenderContentAssignmentProps> = ({
  onEdit,
  content,
}) => {
  const { user } = useAuthValue();
  const isAdmin = user.groups?.includes("admin");
  const { course, updateCourse } = useCourseValue();
  const handleSubmitChange = (storageKey: string) => {
    updateCourse((draft: any) => {
      const newContent = draft.content.map((data: any) => {
        if (data.id === content.id) return { ...data, submit: storageKey };
        return data;
      });
      draft.content = newContent;
    });
  };
  const handleDelete = () => {
    updateCourse((draft: any) => {
      draft.content = draft.content.filter(
        (data: any) => data.id !== content.id
      );
    });
  };
  return (
    <Grid container direction="column" spacing={2}>
      <RenderInstruction instruction={content.instruction} />
      {content.key && (
        <Grid item>
          <Typography display="inline">
            <b>Attachment: &nbsp;</b>
          </Typography>
          <DownloadFile storageKey={content.key} />
        </Grid>
      )}
      <Grid item>
        <Typography display="inline">
          <b>Due Time: &nbsp;</b>
          {format(new Date(content.dueDate), "yyyy-MM-dd hh:mm aa")}
        </Typography>
      </Grid>
      <Grid item>
        <AWSFileInput
          upload={handleSubmitChange}
          path={course.student}
          fileKey={content.submit}
          title="Submit"
        />
      </Grid>
      {isAdmin && (
        <Grid item container justify="flex-end">
          <Button color="secondary" onClick={() => onEdit()}>
            Edit
          </Button>
          <DeleteButton onDelete={handleDelete} />
        </Grid>
      )}
    </Grid>
  );
};

export default RenderContentAssignment;
