import * as React from "react";
import ReadingWrapper from "layout/ReadingWrapper";
import { Grid, Button, makeStyles, createStyles } from "@material-ui/core";
import RenderQuestion from "components/Practice/RenderQuestion";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

interface RenderReadingQuestionProps {
  errors: any;
  handleNext: () => void;
  handlePrev: () => void;
  practice: any;
  setSubmittedAnswers: (data: any) => void;
  submittedAnswers: any;
  typeName: string;
  index: number;
  totalPassage: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    buttonGutter: {
      flexGrow: 1,
    },
  })
);

const RenderReadingQuestion: React.FC<RenderReadingQuestionProps> = ({
  errors,
  handleNext,
  handlePrev,
  practice,
  setSubmittedAnswers,
  submittedAnswers,
  typeName,
  index,
  totalPassage,
}) => {
  const classes = useStyles();
  return (
    <ReadingWrapper passage={practice.passage} key={practice.id}>
      {(typeName.includes("ReadingTest") || typeName === "ReadingPassage") &&
        practice.questions.map((question: any) => (
          <Grid item key={question.range}>
            <RenderQuestion
              question={question.questionBody}
              type={question.type}
              setAnswers={setSubmittedAnswers}
              answers={submittedAnswers}
              errors={errors}
            />
          </Grid>
        ))}
      {typeName === "ReadingQuestion" && (
        <RenderQuestion
          question={practice.questionBody}
          type={practice.type}
          setAnswers={setSubmittedAnswers}
          answers={submittedAnswers}
          errors={errors}
        />
      )}
      {typeName.includes("ReadingTest") ? (
        <>
          {index !== 0 && (
            <Button
              startIcon={<NavigateBeforeIcon />}
              onClick={handlePrev}
              color="secondary"
              variant="contained"
              style={{ color: "#0d47a1" }}
            >
              previous
            </Button>
          )}
          <div className={classes.buttonGutter}></div>
          {index !== totalPassage - 1 && (
            <Button
              endIcon={<NavigateNextIcon />}
              onClick={handleNext}
              color="secondary"
              variant="contained"
              style={{ color: "#0d47a1" }}
            >
              next
            </Button>
          )}
        </>
      ) : (
        <></>
      )}
    </ReadingWrapper>
  );
};

export default RenderReadingQuestion;
