import * as React from "react";
import { emptyObj } from "utilities";
import BottomBanner from "../BottomBanner";
import TopBanner from "../TopBanner";
import { createPracticeResult } from "utilities";
import ListeningContent from "./ListeningContent";
import config from "customConfig";
import AudioPlayer from "components/AudioPlayer";

interface RenderListeningProps {
  practice: any;
  time?: string;
  onSubmit?: (data: any) => Promise<void>;
  onClose?: () => void;
  submittedAnswers?: any;
  title: string;
}

const RenderListening: React.FC<RenderListeningProps> = ({
  practice,
  time,
  onSubmit,
  onClose,
  submittedAnswers,
  title,
}) => {
  const { typeName } = practice;
  const [index, setIndex] = React.useState<number>(0);
  const [scriptData, setScriptData] = React.useState<any>([]);
  const [scriptActiveIndex, setScriptActiveIndex] = React.useState<number>();
  const [showScript, setShowScript] = React.useState<boolean>(false);
  const player = React.createRef<any>();
  const sectionList = React.useMemo<any[]>(
    () =>
      practice.s1 ? [practice.s1, practice.s2, practice.s3, practice.s4] : [],
    [practice]
  );
  const [answers, setAnswers] = React.useState<any>(
    submittedAnswers ? submittedAnswers : emptyObj(practice.answers)
  );
  const [errors, setErrors] = React.useState<any>(
    submittedAnswers
      ? createPracticeResult(submittedAnswers, practice.answers)
      : null
  );
  const handleSubmit = async () => {
    setErrors(createPracticeResult(answers, practice.answers));
    if (onSubmit) {
      await onSubmit(answers);
    }
  };
  const nextPage = () => {
    setIndex(index + 1);
  };
  const prevPage = () => {
    setIndex(index - 1);
  };
  const handleClick = (start: string) => {
    player.current.currentTime = Number(start);
    player.current.paused ? player.current.play() : player.current.pause();
  };
  const handleTimeUpdate = (e: any) => {
    const currentTime = e.currentTarget.currentTime;
    if (scriptData && scriptData.length > 0) {
      scriptData.forEach((scr: any, index: number) => {
        if (scr.startTime <= currentTime && scr.endTime >= currentTime) {
          setScriptActiveIndex(index);
        }
      });
    }
  };
  const toggleShowScript = () => {
    setShowScript(!showScript);
  };
  const sectionID =
    typeName === "ListeningTest"
      ? sectionList[index].id
      : typeName === "ListeningSection"
      ? practice.id
      : practice.sectionID;
  React.useEffect(() => {
    if (practice && showScript) {
      const link = `${config.awsUrl}resource/scripts/${sectionID}.json`;
      fetch(link)
        .then((res) => res.json())
        .then((j_data) => setScriptData(j_data));
    }
  }, [showScript, practice, sectionID]);
  const isExam = title.includes("Exam");
  const lastIndex = sectionList.length - 1;

  return (
    <div
      style={
        isExam
          ? null
          : {
              zIndex: 1200,
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(250,250,250,1)",
            }
      }
    >
      <AudioPlayer
        sectionID={sectionID}
        ref={player}
        showScript={showScript}
        hide={true}
        handleTimeUpdate={handleTimeUpdate}
      />
      <TopBanner
        onClose={onClose}
        onSubmit={handleSubmit}
        time={time}
        errors={errors}
        title={title}
        toggleShowScript={toggleShowScript}
        audioPlayer={player}
      />
      <ListeningContent
        practice={typeName === "ListeningTest" ? sectionList[index] : practice}
        setAnswers={setAnswers}
        answers={answers}
        errors={errors}
        scriptData={scriptData}
        showScript={showScript}
        handleClick={handleClick}
        scriptActiveIndex={scriptActiveIndex}
      />
      <BottomBanner
        onSubmit={handleSubmit}
        title={title}
        answers={answers}
        nextPage={nextPage}
        prevPage={prevPage}
        index={index}
        lastIndex={lastIndex}
        typeName={typeName}
        submitted={!!errors}
      />
    </div>
  );
};

export default RenderListening;
