import * as React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ProtectedRoute from "routes/utilities/ProtectedRoute";
import ListeningDetailView from "./ListeningDetailView";
import ListeningCreationView from "./ListeningCreationView";
import ListeningEditionView from "./ListeningEditionView";
import ListeningPreviewView from "./ListeningPreviewView";

export interface ListeningProps {}

const Listening: React.FC<ListeningProps> = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <ListeningDetailView />
      </Route>
      <ProtectedRoute exact path={`${path}/preview`}>
        <ListeningPreviewView />
      </ProtectedRoute>
      <ProtectedRoute exact path={`${path}/create`}>
        <ListeningCreationView />
      </ProtectedRoute>
      <ProtectedRoute exact path={`${path}/edit`}>
        <ListeningEditionView />
      </ProtectedRoute>
    </Switch>
  );
};

export default Listening;
