import * as React from "react";
import { rangeToArray } from "utilities";
import { RenderQuestionProps } from ".";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormHelperText,
} from "@material-ui/core";
import parse from "html-react-parser";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "10px",
      fontSize: "1.25rem",
      "& .MuiFormLabel-root": {
        fontSize: "inherit",
      },
      "& .MuiTypography-body1": {
        fontSize: "inherit",
      },
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down("xs")]: {
        padding: 0,
        fontSize: "0.75rem",
      },
    },
    formControl: {
      display: "block",
    },
    errorMessage: {
      color: "#f44336",
    },
  })
);

const valdiateMC = (range: string, selected: string[]) => {
  return rangeToArray(range).length === selected.length;
};

const RenderMC: React.FC<RenderQuestionProps> = ({
  question,
  setAnswers,
  answers,
  errors,
}) => {
  const classes = useStyles();
  const initalValue = answers[question.order] || [];
  const [selected, setSelected] = React.useState<string[]>(initalValue);
  const validateInput = valdiateMC(question.order, selected);
  const isChecked = (label: string) => {
    return selected.includes(label);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedSet = new Set<string>([...selected]);
    const label = e.target.name;
    if (isChecked(label)) {
      selectedSet.delete(label);
    } else {
      selectedSet.add(label);
    }
    setSelected(Array.from(selectedSet.values()));
  };
  const checkDisable = (label: string) => {
    return validateInput && !selected.includes(label);
  };
  React.useEffect(() => {
    if (validateInput) {
      setAnswers({
        ...answers,
        [question.order]: [...selected],
      });
    }
    //eslint-disable-next-line
  }, [selected, validateInput, question]);
  return (
    <div className={classes.root}>
      <div>{question && parse(question.ins)}</div>
      <FormControl
        required
        error={!validateInput || (errors && !errors[question.order].correct)}
        component="div"
        disabled={errors && true}
      >
        {errors && !errors[question.order].correct && (
          <FormLabel
            component="legend"
            className={classes.errorMessage}
          >{`correct answer is ${errors[question.order].ans}`}</FormLabel>
        )}

        <FormGroup>
          {question.options.map((option: any) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked(option.label)}
                  onChange={handleChange}
                  name={option.label}
                />
              }
              label={`${option.label}. ${option.text}`}
              disabled={checkDisable(option.label)}
              key={option.label}
            />
          ))}
        </FormGroup>
        <FormHelperText>
          Please select {rangeToArray(question.order).length} options
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export default RenderMC;
