import * as React from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import ContentAssignmentForm from "./ContentAssignmentForm";
import ContentHeaderForm from "./ContentHeaderForm";
import ContentFileForm from "./ContentFileForm";
import { useCourseValue } from "context/course.context";
import { v4 as uuidv4 } from "uuid";

interface AddContentDialogProps {
  open: boolean;
  handleClose: () => void;
  type: string;
}

const AddContentDialog: React.FC<AddContentDialogProps> = ({
  open,
  handleClose,
  type,
}) => {
  const { updateCourse } = useCourseValue();
  const onSubmit = (data: any) => {
    const content = { ...data, typeName: type, id: uuidv4() };
    updateCourse((draft: any) => {
      draft.content.push(content);
    });
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle>Add {type}</DialogTitle>
      <DialogContent>
        {type === "file" && (
          <ContentFileForm onCancel={handleClose} onSubmit={onSubmit} />
        )}
        {type === "header" && (
          <ContentHeaderForm onCancel={handleClose} onSubmit={onSubmit} />
        )}
        {type === "assignment" && (
          <ContentAssignmentForm onCancel={handleClose} onSubmit={onSubmit} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddContentDialog;
