import * as React from "react";
import { ReactSortable } from "react-sortablejs";
import ListTitle from "./ListTitle";

interface SortableContentListProps {
  sortContent: any;
  setSortContent: (data: any) => void;
}

const SortableContentList: React.FC<SortableContentListProps> = ({
  sortContent,
  setSortContent,
}) => {
  return (
    <ReactSortable list={sortContent} setList={setSortContent}>
      {sortContent.map((content: any) => (
        <ListTitle content={content} key={content.id} />
      ))}
    </ReactSortable>
  );
};

export default SortableContentList;
