import * as React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ProtectedRoute from "routes/utilities/ProtectedRoute";
import WritingDetailView from "./WritingDetailView";
import WritingCreationView from "./WritingCreationView";
import WritingEditionView from "./WritingEditionView";
import WritingPreviewView from "./WritingPreviewView";

export interface WritingProps {}

const Writing: React.FC<WritingProps> = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <WritingDetailView />
      </Route>
      <ProtectedRoute exact path={`${path}/preview`}>
        <WritingPreviewView />
      </ProtectedRoute>
      <ProtectedRoute exact path={`${path}/create`}>
        <WritingCreationView />
      </ProtectedRoute>
      <ProtectedRoute exact path={`${path}/edit`}>
        <WritingEditionView />
      </ProtectedRoute>
    </Switch>
  );
};

export default Writing;
