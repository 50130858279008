import * as React from "react";
import { fetchPractice } from "utilities/awsCRUD";
import BottomBanner from "../Practice/components/BottomBanner";
import TopBanner from "../Practice/components/TopBanner";
import { emptyObj } from "utilities";
import Spinner from "components/Spinner";
import RenderReadingQuestion from "./RenderReadingQuestion";

interface RenderReadingProps {
  format: string;
  typeName: string;
  id: string;
  time?: string;
  handleReadingAnswer: any;
  submittedReadingAnswer: any;
  onSubmit: () => void;
  error: any;
  setAnswer: (answer: any) => void;
}

const RenderReading: React.FC<RenderReadingProps> = ({
  format,
  typeName,
  id,
  time,
  onSubmit,
  error,
  handleReadingAnswer,
  submittedReadingAnswer,
  setAnswer,
}) => {
  const [practice, setPractice] = React.useState<any>(null);
  console.log("RenderReading, practice", practice);
  const [index, setIndex] = React.useState<number>(0);
  const passageList = React.useMemo<any[]>(() => {
    if (practice && practice.p1) return [practice.p1, practice.p2, practice.p3];
    if (practice && practice.s1a)
      return [
        practice.s1a,
        practice.s1b,
        practice.s2a,
        practice.s2b,
        practice.s3,
      ];
    return [];
  }, [practice]);
  const handleNext = () => {
    setIndex((pre) => pre + 1);
  };
  const handlePrev = () => {
    setIndex((pre) => pre - 1);
  };
  React.useEffect(() => {
    if (id) {
      fetchPractice(typeName, id, setPractice);
    }
    // eslint-disable-next-line
  }, [id, typeName]);
  React.useEffect(() => {
    if (!submittedReadingAnswer && practice) {
      handleReadingAnswer(emptyObj(practice.answers));
      setAnswer(practice.answers);
    }
    //eslint-disable-next-line
  }, [practice]);
  if (!practice || !submittedReadingAnswer) return <Spinner />;
  return (
    <>
      <TopBanner
        time={time}
        title="Reading Test"
        format={format}
        result={error}
        onSubmit={onSubmit}
      />
      <RenderReadingQuestion
        typeName={typeName}
        practice={
          typeName.includes("ReadingTest") ? passageList[index] : practice
        }
        setSubmittedAnswers={handleReadingAnswer}
        submittedAnswers={submittedReadingAnswer}
        errors={error}
        index={index}
        totalPassage={passageList.length}
        handleNext={handleNext}
        handlePrev={handlePrev}
      />

      <BottomBanner
        title="Reading"
        onSubmit={onSubmit}
        submittedAnswers={submittedReadingAnswer}
      />
    </>
  );
};

export default RenderReading;
