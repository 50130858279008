import RenderSpeaking from "components/Practice/speaking/RenderSpeaking";
import * as React from "react";
import { useLocation, Redirect } from "react-router-dom";
import { useAsync } from "react-async-hook";
import useQuery from "hooks/useQuery";
import { fetchSpeaking } from "utilities/awsCRUD";
import Spinner from "components/Spinner";
import useHomework from "hooks/useHomework";
import MainWrapper from "layout/MainWrapper";
import { Typography } from "@material-ui/core";

interface SpeakingDetailViewProps {}

const SpeakingDetailView: React.FC<SpeakingDetailViewProps> = () => {
  const { onSubmit, submittedAnswers, title, time } = useHomework();

  const location = useLocation() as any;
  let query = useQuery();
  const id = query.get("id");
  const typeName = query.get("typeName");
  const practice = useAsync(fetchSpeaking, [id, typeName]);
  const practiceData = location?.state?.practiceData;
  if (!practiceData && !id) {
    return <Redirect to="/practice" />;
  }
  return (
    <>
      {practice.loading && <Spinner />}
      {practice.error && (
        <MainWrapper>
          <Typography variant="h5" color="error">
            Error: {practice.error.message}
          </Typography>
        </MainWrapper>
      )}
      {practice.result && (
        <RenderSpeaking
          practice={practice.result}
          title={title ? `Speaking ${title}` : "Speaking Practice"}
          onSubmit={onSubmit}
          time={time}
          submittedAnswers={submittedAnswers}
        />
      )}
    </>
  );
};

export default SpeakingDetailView;
