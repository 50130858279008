import * as React from "react";
import Avatar from "@material-ui/core/Avatar";
import config from "customConfig";

export interface UserAvatarProps {
  user: any;
  className?: any;
  style?: any;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ user, className, style }) => {
  const { picture } = user;
  const picUrl = config.awsUrl + picture;
  return (
    <>
      {picture ? (
        <Avatar src={picUrl} className={className} style={style} />
      ) : (
        <Avatar className={className} style={style}>
          {user.username[0].toUpperCase()}
        </Avatar>
      )}
    </>
  );
};

export default UserAvatar;
