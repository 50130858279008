import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0d47a1",
    },
    secondary: {
      main: "#9eb5d9",
    },
    warning: {
      main: "#ffb518",
    },
    text: {
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    action: {
      disabled: "rgba(0, 0, 0, 0.5)",
      disabledOpacity: 0.5,
      disabledBackground: "rgba(0, 0, 0, 0.24)",
    },
  },
  typography: {
    fontFamily: `"Noto Sans","Arial", "-apple-system","BlinkMacSystemFont","Helvetica Neue","PingFang SC","Microsoft YaHei","Source Han Sans SC","Noto Sans CJK SC","WenQuanYi Micro Hei", "Helvetica", "sans-serif"`,
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: "rgba(0, 0, 0, 0.87)",
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: "#9eb5d9",
      },
    },
  },
});
theme = responsiveFontSizes(theme);
export default theme;
