import * as React from 'react';
import { SnackbarProvider } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export interface NotificationProviderProps {
    
}
 
const NotificationProvider: React.FC<NotificationProviderProps> = ({children}) => {
    const notistackRef: any = React.createRef();
    const onClickDismiss = (key:any) => () => { 
        notistackRef.current.closeSnackbar(key);
    }
    return ( 
        <SnackbarProvider 
            maxSnack={4} 
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            ref={notistackRef}
            action={(key) => (
                <IconButton onClick={onClickDismiss(key)} color="inherit">
                   <CloseIcon />
                </IconButton>
            )}>
            {children}
        </SnackbarProvider>
     );
}
 
export default NotificationProvider;