import * as React from "react";
import useLocalStorage from "hooks/useLocalStorage";
import { useIsMount } from "hooks/useIsMount";
import {
  Card,
  Tab,
  Theme,
  makeStyles,
  Button,
  CircularProgress,
} from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import ReadingControl from "./ReadingControl";
import ListeningControl from "./ListeningControl";
import WritingControl from "./WritingControl";
import SpeakingControl from "./SpeakingControl";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import CreateIcon from "@material-ui/icons/Create";
import MicIcon from "@material-ui/icons/Mic";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import { useAuthValue } from "context/auth.context";
import { fetchPracticeList } from "utilities/awsCRUD";

export interface PracticeControlTabProps {
  setData: (data: any) => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  tabList: {
    "& .Mui-selected": {
      backgroundColor: theme.palette.background.default,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  tab: {
    textTransform: "none",
    fontWeight: theme.typography.fontWeightMedium,
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
  },
  submit: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    margin: "8px",
  },
}));

const readingInitialInput = {
  typeName: "ReadingTest",
  genre: "A",
  book: "c16",
};
const listeningInitialInput = {
  typeName: "ListeningTest",
  book: "c15",
};

const writingInitialInput = {
  typeName: "Essay",
  book: "c16",
};

const speakingInitialInput = {
  typeName: "PartOneTopic",
  season: "2021-3",
};

const PracticeControlTab: React.FC<PracticeControlTabProps> = ({ setData }) => {
  const classes = useStyles();
  const { user } = useAuthValue();
  const isAdmin = user.groups?.includes("admin");
  const [category, setCategory] = useLocalStorage("category", "reading");
  const [input, setInput] = useLocalStorage("input", readingInitialInput);
  const [loading, setLoading] = React.useState<boolean>(false);
  const isMount = useIsMount();
  const handleChange = (_event: React.ChangeEvent<{}>, newValue: string) => {
    setCategory(newValue);
  };
  const handleInputChange = (name: string) => {
    const setValue = (value: string) => {
      setInput({ ...input, [name]: value });
    };
    return setValue;
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const practice = await fetchPracticeList(input);
      setData(practice);
    } catch (error) {
      console.log("fetch practice list error", error);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    if (!isMount) {
      if (category === "reading") {
        setInput(readingInitialInput);
      }
      if (category === "listening") {
        setInput(listeningInitialInput);
      }
      if (category === "writing") {
        setInput(writingInitialInput);
      }
      if (category === "speaking") {
        setInput(speakingInitialInput);
      }
    }
    // eslint-disable-next-line
  }, [category]);
  React.useEffect(() => {
    handleSearch();
    //eslint-disable-next-line
  }, []);
  return (
    <Card>
      <TabContext value={category}>
        <TabList
          onChange={handleChange}
          textColor="primary"
          variant="fullWidth"
          indicatorColor="primary"
          className={classes.tabList}
        >
          <Tab
            label="Reading"
            value="reading"
            icon={<MenuBookIcon />}
            className={classes.tab}
          />
          <Tab
            label="Listening"
            value="listening"
            icon={<VolumeUpIcon />}
            className={classes.tab}
          />
          <Tab
            label="Writing"
            value="writing"
            icon={<CreateIcon />}
            className={classes.tab}
          />
          <Tab
            label="Speaking"
            value="speaking"
            icon={<MicIcon />}
            className={classes.tab}
          />
        </TabList>
        <TabPanel value="reading">
          <ReadingControl input={input} handleInputChange={handleInputChange} />
          <div className={classes.submit}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              search
            </Button>
            {input.typeName === "ReadingTest" && isAdmin && (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                component={Link}
                to="/practice/reading/create"
              >
                Create
              </Button>
            )}
          </div>
        </TabPanel>
        <TabPanel value="listening">
          <ListeningControl
            input={input}
            handleInputChange={handleInputChange}
          />
          <div className={classes.submit}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              search
            </Button>
            {input.typeName === "ListeningTest" && isAdmin && (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                component={Link}
                to="/practice/listening/create"
              >
                Create
              </Button>
            )}
          </div>
        </TabPanel>
        <TabPanel value="writing">
          <WritingControl input={input} handleInputChange={handleInputChange} />
          <div className={classes.submit}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              search
            </Button>
            {isAdmin && (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                component={Link}
                to="/practice/createWriting"
              >
                Create
              </Button>
            )}
          </div>
        </TabPanel>
        <TabPanel value="speaking">
          <SpeakingControl
            input={input}
            handleInputChange={handleInputChange}
          />
          <div className={classes.submit}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              search
            </Button>
            {isAdmin && (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                component={Link}
                to="/practice/speaking/create"
              >
                Create
              </Button>
            )}
          </div>
        </TabPanel>
      </TabContext>
    </Card>
  );
};

export default PracticeControlTab;
