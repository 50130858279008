import * as React from "react";
import Collapse from "@material-ui/core/Collapse";
import { Button } from "@material-ui/core";
import UserListItemDetailForm from "./UserListItemDetailForm";
import { format } from "date-fns";

const getUserTargetScores = (user: any) => {
  return user.targetScores.map((score: any, idx: any) => {
    switch (idx) {
      case 0:
        return <span key={idx}>听力：{score}</span>;
      case 1:
        return <span key={idx}>阅读：{score}</span>;
      case 2:
        return <span key={idx}>写作：{score}</span>;
      case 3:
        return <span key={idx}>口语：{score}</span>;
      default:
        return null;
    }
  });
};

export interface UserListItemDetailProps {
  user: any;
  open: boolean;
}

const UserListItemDetail: React.FC<UserListItemDetailProps> = ({
  user,
  open,
}) => {
  const [edit, setEdit] = React.useState(false);
  const displayInfo = () => (
    <>
      <div>username: {user.username}</div>
      <div>email: {user.email}</div>
      <div>
        {" "}
        {user.targetScores && <p>目标分数：{getUserTargetScores(user)}</p>}
      </div>
      <div>
        {user.examDate && (
          <p>下次考试日期：{format(new Date(user.examDate), "MM/dd/yyyy")}</p>
        )}
      </div>
      <Button onClick={() => setEdit(true)}>edit</Button>
    </>
  );
  React.useEffect(() => {
    return () => {
      // Anything in here is fired on component unmount.
      setEdit(false);
    };
  }, []);
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      {!edit ? (
        displayInfo()
      ) : (
        <UserListItemDetailForm user={user} setEdit={setEdit} />
      )}
    </Collapse>
  );
};

export default UserListItemDetail;
