import * as React from "react";
import RenderExamIns from "./RenderExamIns";
import produce from "immer";
import RenderListening from "./RenderListening";
import RenderReading from "./RenderReading";
import RenderWriting from "./RenderWriting";
import RenderCustomTest from "components/Practice/CustomTest/RenderCustomTest";
import RenderExamSpeaking from "./RenderExamSpeaking";

interface RenderExamSectionProps {
  data: any;
  step: number;
  currentSectionIndex: number;
  submittedAnswers: any;
  setSubmittedAnswers: (data: any) => void;
  onSubmit: () => void;
  result: any;
  setAnswer: (answer: any) => void;
}

const RenderExamSection: React.FC<RenderExamSectionProps> = ({
  data,
  step,
  currentSectionIndex,
  submittedAnswers,
  setSubmittedAnswers,
  onSubmit,
  result,
  setAnswer,
}) => {
  if (step !== currentSectionIndex) return null;
  switch (data.name) {
    case "instructions":
      return <RenderExamIns ins={data.data} next={onSubmit} />;
    case "listening":
      const submittedListeningAnswer = submittedAnswers?.listening;
      const handleListeningAnswer = (listeningAnswers: any) => {
        setSubmittedAnswers(
          produce(submittedAnswers, (draft: any) => {
            draft.listening = listeningAnswers;
          })
        );
      };
      const setListeningAnswer = (answer: any) => {
        setAnswer(
          produce((draft: any) => {
            draft.listening = answer;
          })
        );
      };
      return (
        <RenderListening
          format="EL"
          submittedListeningAnswer={submittedListeningAnswer}
          handleListeningAnswer={handleListeningAnswer}
          typeName={data.data.typeName}
          id={data.data.id}
          time={data.data.time}
          onSubmit={onSubmit}
          error={result.listening}
          setAnswer={setListeningAnswer}
        />
      );
    case "reading":
      const submittedReadingAnswer = submittedAnswers?.reading;
      const handleReadingAnswer = (readingAnswers: any) => {
        setSubmittedAnswers(
          produce(submittedAnswers, (draft: any) => {
            draft.reading = readingAnswers;
          })
        );
      };
      const setReadingAnswer = (answer: any) => {
        setAnswer(
          produce((draft: any) => {
            draft.reading = answer;
          })
        );
      };
      if (data.data.typeName === "CustomTest") {
        return (
          <RenderCustomTest
            format="ER"
            submittedAnswer={submittedReadingAnswer}
            handleAnswer={handleReadingAnswer}
            id={data.data.id}
            time={data.data.time}
            onSubmit={onSubmit}
            error={result.reading}
            setAnswer={setReadingAnswer}
            typeName={data.data.typeName}
          />
        );
      }
      return (
        <RenderReading
          format="ER"
          submittedReadingAnswer={submittedReadingAnswer}
          handleReadingAnswer={handleReadingAnswer}
          typeName={data.data.typeName}
          id={data.data.id}
          time={data.data.time}
          onSubmit={onSubmit}
          error={result.reading}
          setAnswer={setReadingAnswer}
        />
      );
    case "writing":
      const { task1ID, task2ID, time } = data.data;
      const handleTask1 = (task1: string) => {
        setSubmittedAnswers(
          produce(submittedAnswers, (draft: any) => {
            draft.writing = { ...draft.writing, task1 };
          })
        );
      };
      const handleTask2 = (task2: string) => {
        setSubmittedAnswers(
          produce(submittedAnswers, (draft: any) => {
            draft.writing = { ...draft.writing, task2 };
          })
        );
      };
      const submittedTask1 = submittedAnswers.writing?.task1;
      const submittedTask2 = submittedAnswers.writing?.task2;
      const task1Result = result.writing?.task1;
      const task2Result = result.writing?.task2;
      return (
        <RenderWriting
          format="EW"
          time={time}
          onSubmit={onSubmit}
          task1ID={task1ID}
          task2ID={task2ID}
          handleTask1={handleTask1}
          handleTask2={handleTask2}
          submittedTask1={submittedTask1}
          submittedTask2={submittedTask2}
          task1Result={task1Result}
          task2Result={task2Result}
        />
      );
    case "speaking":
      return (
        <RenderExamSpeaking
          part1IDList={data.data.part1}
          part2ID={data.data.part2.id}
          onSubmit={onSubmit}
          time={data.data.time}
        />
      );
    default:
      return <div>{data.name}</div>;
  }
};

export default RenderExamSection;
