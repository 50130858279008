import * as React from "react";
import { Button, Menu, MenuItem, ListItemIcon } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AddContentDialog from "./AddContentDialog";

interface AddContentButtonProps {}

const AddContentButton: React.FC<AddContentButtonProps> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedContent, setSelectedContent] = React.useState<string>("");
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (type: string) => {
    setSelectedContent(type);
    handleClose();
  };
  React.useEffect(() => {
    if (selectedContent) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [selectedContent]);
  return (
    <>
      <Button
        aria-controls="add-content-button"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Add Content
      </Button>
      <Menu
        id="add-content-button"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleMenuClick("header")}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          Section
        </MenuItem>
        <MenuItem onClick={() => handleMenuClick("file")}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          File
        </MenuItem>
        <MenuItem onClick={() => handleMenuClick("assignment")}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          Assignment
        </MenuItem>
      </Menu>
      {open && (
        <AddContentDialog
          open={open}
          handleClose={() => setSelectedContent("")}
          type={selectedContent}
        />
      )}
    </>
  );
};

export default AddContentButton;
