import * as React from "react";
import RenderCustomTest from "components/Practice/CustomTest/RenderCustomTest";
import { useLocation } from "react-router-dom";
import { createPracticeResult } from "utilities";

interface CustomTestPreviewProps {}

const CustomTestPreview: React.FC<CustomTestPreviewProps> = () => {
  const location = useLocation() as any;
  const data = location.state.data;
  const [error, setError] = React.useState<any>(null);
  const [answer, setAnswer] = React.useState<any>(null);
  const [submittedAnswer, setSubmittedAnswer] = React.useState<any>(null);
  const onSubmit = () => {
    setError(createPracticeResult(submittedAnswer, answer));
  };
  return (
    <RenderCustomTest
      data={data}
      format="PR"
      submittedAnswer={submittedAnswer}
      handleAnswer={setSubmittedAnswer}
      onSubmit={onSubmit}
      error={error}
      setAnswer={setAnswer}
      typeName={data.typeName}
    />
  );
};

export default CustomTestPreview;
