import * as React from "react";
import { TextField } from "@material-ui/core";
import NumberFormatTime from "utilities/NumberFormatTime";

interface TimeInputProps {
  value: string;
  setValue: (time: string) => void;
}

const TimeInput: React.FC<TimeInputProps> = ({ value, setValue }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  return (
    <TextField
      label="time limit"
      value={value}
      onChange={handleChange}
      InputProps={{
        inputComponent: NumberFormatTime as any,
      }}
      autoComplete="off"
      variant="outlined"
    />
  );
};

export default TimeInput;
