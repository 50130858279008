import * as React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";

export interface DropDownProps {
  isEdit?: boolean;
  name: string;
  value: string;
  setValue: (book: string) => void;
  options: any;
  noneValue?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

const DropDown: React.FC<DropDownProps> = ({
  isEdit,
  setValue,
  value,
  name,
  options,
  noneValue,
}) => {
  const classes = useStyles();
  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setValue(e.target.value as string);
  };
  return (
    <FormControl
      className={classes.formControl}
      variant="outlined"
      disabled={isEdit}
    >
      <InputLabel id="book-label">{name}</InputLabel>
      <Select
        labelId={`${name}-label`}
        value={value ?? ""}
        name={name}
        onChange={handleChange}
        label={name}
        defaultValue=""
      >
        {noneValue && (
          <MenuItem value="" key="None">
            None
          </MenuItem>
        )}
        {options &&
          options.map((obj: any) => (
            <MenuItem value={obj.value} key={obj.value}>
              {obj.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

DropDown.defaultProps = {
  isEdit: false,
  noneValue: false,
};

export default DropDown;
