import * as React from "react";
import { useAuthValue } from "context/auth.context";
import { Redirect, Route } from "react-router-dom";

interface ProtectedRouteProps {
  exact?: boolean;
  path: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  exact,
  path,
  children,
}) => {
  const { user } = useAuthValue();
  const isAdmin = user.groups?.includes("admin");

  if (!isAdmin) return <Redirect to="/" />;
  return (
    <Route path={path} exact={exact}>
      {children}
    </Route>
  );
};

export default ProtectedRoute;

ProtectedRoute.defaultProps = {
  exact: false,
};
