import * as React from "react";
import useLocalImmer from "hooks/useLocalImmer";
import useAmplifyQuery from "hooks/useAmplifyQuery";
import * as queries from "graphql/queries";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import MainWrapper from "layout/MainWrapper";
import CardLayout from "layout/CardLayout";
import ReadingTestForm from "components/Practice/reading/ReadingTestForm";
import LinearProgress from "@material-ui/core/LinearProgress";
import { normalizeRTA, normalizeRTG } from "utilities/nomalizeData";
import { validateReadingTestNew } from "utilities/validation";
import { useSnackbar } from "notistack";
import {
  deleteReadingTest,
  updateReadingTest as updatingReadingTest,
} from "utilities/awsCRUD";

interface ReadingEditionViewProps {}

const ReadingEditionView: React.FC<ReadingEditionViewProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { state } = useLocation() as any;
  const id = state?.id;
  const typeName = state?.typeName;
  const query =
    typeName === "ReadingTestA"
      ? queries.getReadingTestA
      : typeName === "ReadingTestG"
      ? queries.getReadingTestG
      : null;
  const normalizeFn = typeName === "ReadingTestA" ? normalizeRTA : normalizeRTG;
  const { value, status } = useAmplifyQuery(query, { id }, normalizeFn);
  const [readingTest, updateReadingTest] = useLocalImmer(
    "editReadingTest",
    value
  );

  const onPreview = () => {
    try {
      validateReadingTestNew(readingTest);
      const typeName =
        readingTest.genre === "A" ? "ReadingTestA" : "ReadingTestG";
      const practiceData = { ...readingTest, typeName };
      history.push("/practice/reading/preview", { practiceData });
    } catch (error: any) {
      const message =
        error.message ||
        `${error.errors[0].path[0]} - ${error.errors[0].message}`;
      enqueueSnackbar(message, { variant: "error" });
    }
  };
  const onUpdate = async () => {
    try {
      validateReadingTestNew(readingTest);
      console.log("old", value);
      console.log("new", readingTest);

      await updatingReadingTest(value, readingTest);
      enqueueSnackbar(`You have successfully updated ${readingTest.id}`, {
        variant: "success",
      });
      // onCancel();
    } catch (error: any) {
      const message =
        error.message ||
        `${error.errors[0].path[0]} - ${error.errors[0].message}`;
      enqueueSnackbar(message, { variant: "error" });
    }
  };
  const onDelete = () => {
    try {
      validateReadingTestNew(value);
      deleteReadingTest(value);
      enqueueSnackbar(`You have successfully Deleted ${readingTest.id}`, {
        variant: "success",
      });
    } catch (error: any) {
      const message =
        error.message ||
        `${error.errors[0].path[0]} - ${error.errors[0].message}`;
      enqueueSnackbar(message, { variant: "error" });
    }
  };
  const onCancel = React.useCallback(() => {
    window.localStorage.removeItem("editReadingTest");
    history.push("/practice");
  }, [history]);
  React.useEffect(() => {
    if (value && !readingTest) {
      updateReadingTest(value);
    }
    //eslint-disable-next-line
  }, [value]);
  React.useEffect(() => {
    return () => {
      onCancel();
    };
  }, [onCancel]);
  if (!state) return <Redirect to="/practice" />;
  return (
    <MainWrapper>
      <CardLayout
        title="Reading Test Edition"
        mode="edit"
        loading={status === "pending"}
        onPreview={onPreview}
        onUpdate={onUpdate}
        onDelete={onDelete}
        onCancel={onCancel}
      >
        {status === "pending" && <LinearProgress />}
        {status === "error" && (
          <p>Reading Test fetch Error, please try again</p>
        )}
        {readingTest && (
          <ReadingTestForm
            readingTest={readingTest}
            updateReadingTest={updateReadingTest}
            isEdit={true}
          />
        )}
      </CardLayout>
    </MainWrapper>
  );
};

export default ReadingEditionView;
