import { Grid, Paper } from "@material-ui/core";
import MyCalendar from "components/Scheduler";
import * as React from "react";
import Activities from "./Activities";
import Profile from "./Profile";
import UserList from "./UserList";

export interface AdminDashboardProps {}

const AdminDashboard: React.FC<AdminDashboardProps> = () => {
  return (
    <>
      <Grid container item sm={6}>
        <Grid item xs={12}>
          <Paper>
            <Profile />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <UserList />
          </Paper>
        </Grid>
      </Grid>
      <Grid container item sm={6}>
        <Grid item xs={12}>
          <Paper>
            <Activities />
          </Paper>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <MyCalendar />
      </Grid>
    </>
  );
};

export default AdminDashboard;
