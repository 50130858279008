import * as React from 'react';
import RenderDropDownLetters from './RenderDropDownLetters';
import RenderFG from './RenderFG';
import RenderFGO from './RenderFGO';
import RenderMC from './RenderMC';
import RenderSC from './RenderSC';
import RenderTFNG from './RenderTFNG';

export interface RenderQuestionProps {
    question: any,
    type: string,
    setAnswers: (data: any)=>void,
    answers: any,
    errors?: any
}
 
const RenderQuestion: React.FC<RenderQuestionProps> = (props) => {
    const renderQuestion =()=>{
        switch (props.type){
            case 'SC':
                return <RenderSC {...props}/>
            case 'MC':
                return <RenderMC {...props}/>
            case 'FG':
                return <RenderFG {...props}/>
            case 'FGD':
                return <RenderFG {...props}/>
            case 'FGO':
                return <RenderFGO {...props}/>
            case 'TFNG':
                return <RenderTFNG {...props}/>
            default:
                return <RenderDropDownLetters {...props}/>

        }
    }
    return ( 
        <>{renderQuestion()}</>
     );
}
RenderQuestion.defaultProps={
    errors: null
} 
export default RenderQuestion;