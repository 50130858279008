import * as React from "react";
import { RenderQuestionProps } from ".";
import parse, {
  HTMLReactParserOptions,
  domToReact,
  attributesToProps,
  DOMNode,
} from "html-react-parser";
import { Element } from "domhandler/lib/node";
import { TextField, Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const isElement = (domNode: DOMNode): domNode is Element => {
  const isTag = domNode.type === "tag";
  const hasAttributes = (domNode as Element).attribs !== undefined;

  return isTag && hasAttributes;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: "1.25rem",
      borderCollapse: "collapse",
      width: "100%",
      padding: "10px",
      "& img": {
        width: "100%",
        height: "auto",
      },
      "& table": {
        borderCollapse: "collapse",
        width: "100%",
      },
      "& .MuiFormControl-root": {
        margin: "5px",
        verticalAlign: "middle",
      },
      "& .MuiInputBase-root": {
        fontSize: "1.25rem",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "1rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
        padding: 0,
        "& .MuiFormControl-root": {
          margin: "2px",
          width: "70px",
        },
        "& .MuiInputBase-root": {
          fontSize: "0.75rem",
        },
        "& .MuiInputLabel-root": {
          fontSize: "0.75rem",
        },
        "& .MuiFormHelperText-root": {
          fontSize: "0.75 rem",
        },
      },
    },
  })
);
const blankToInput = (text: string) => {
  const replacer = (_match: any, num: string) => {
    return `<input name="${num}">`;
  };
  return text.replaceAll(/\[(\d\d?)\]/gim, replacer);
};

const RenderFG: React.FC<RenderQuestionProps> = ({
  question,
  setAnswers,
  answers,
  errors,
}) => {
  const classes = useStyles();

  const handleChange = (e: any) => {
    setAnswers({ ...answers, [e.target.name]: e.target.value });
  };
  const options: HTMLReactParserOptions = {
    replace: (domNode: DOMNode) => {
      if (isElement(domNode) && domNode.name === "input") {
        const name = domNode.attribs.name;
        return (
          <Box component="span">
            <TextField
              name={name}
              label={name}
              onChange={handleChange}
              value={answers[name] || ""}
              variant="outlined"
              size="small"
              margin="dense"
              error={errors && !errors[name].correct}
              helperText={errors && !errors[name].correct && errors[name].ans}
              autoComplete="off"
              disabled={errors && true}
            />
          </Box>
        );
      }
      if (isElement(domNode) && domNode.name === "p") {
        const props = attributesToProps(domNode.attribs);
        return <div {...props}>{domToReact(domNode.children, options)}</div>;
      }
      if (isElement(domNode) && domNode.name === "figure") {
        return <div>{domToReact(domNode.children, options)}</div>;
      }
    },
  };
  return (
    <div className={classes.root}>
      {question?.ins && parse(blankToInput(question.ins), options)}
    </div>
  );
};

export default RenderFG;
