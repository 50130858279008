import * as React from "react";
import DropDown from "components/DropDown";
import { makeStyles, createStyles, TextField, Theme } from "@material-ui/core";
import config from "customConfig";

export interface ListeningControlProps {
  input: any;
  handleInputChange: any;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);
const ListeningControl: React.FC<ListeningControlProps> = ({
  input,
  handleInputChange,
}) => {
  const classes = useStyles();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    handleInputChange("search")(value);
  };
  return (
    <div>
      <div className={classes.inputContainer}>
        <DropDown
          name="Format"
          options={config.listeningTypeName}
          value={input.typeName}
          setValue={handleInputChange("typeName")}
        />
        <TextField
          label="Search by ID"
          type="search"
          variant="outlined"
          name="search"
          autoComplete="off"
          value={input.search ?? ""}
          onChange={handleChange}
          className={classes.formControl}
        />
      </div>
      <div className={classes.inputContainer}>
        <DropDown
          name="Book"
          options={config.books}
          value={input.book}
          setValue={handleInputChange("book")}
          noneValue
        />
        {input.typeName === "ListeningSection" && (
          <DropDown
            name="Type"
            options={config.listeningTypeOptions}
            value={input.types}
            setValue={handleInputChange("types")}
            noneValue
          />
        )}
        {input.typeName === "ListeningQuestion" && (
          <DropDown
            name="Type"
            options={config.listeningTypeOptions}
            value={input.type}
            setValue={handleInputChange("type")}
            noneValue
          />
        )}
        {input.typeName !== "ListeningTest" && (
          <DropDown
            name="Section"
            options={config.listeningSectionNumber}
            value={input.sectionNumber}
            setValue={handleInputChange("sectionNumber")}
            noneValue
          />
        )}
      </div>
    </div>
  );
};

export default ListeningControl;
