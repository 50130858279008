import * as React from "react";
import LoginForm from "./LoginForm";
import ResetPasswordForm from "./ResetPasswordForm";
import { makeStyles } from "@material-ui/core/styles";
import ForgotPasswordForm from "./ForgotPasswordForm";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  const classes = useStyles();
  const [formType, setFormType] = React.useState("login");
  const [initialUser, setInitialUser] = React.useState();

  return (
    <>
      {formType === "login" && (
        <LoginForm
          setInitialUser={setInitialUser}
          setFormType={setFormType}
          classes={classes}
        />
      )}
      {formType === "changePassword" && (
        <ResetPasswordForm initialUser={initialUser} classes={classes} />
      )}
      {formType === "forgotPassword" && (
        <ForgotPasswordForm setFormType={setFormType} classes={classes} />
      )}
    </>
  );
};

export default Login;
