import * as React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ProtectedRoute from "routes/utilities/ProtectedRoute";
import ReadingDetailView from "./ReadingDetailView";
import ReadingCreationView from "./ReadingCreationView";
import ReadingEditionView from "./ReadingEditionView";
import ReadingPreviewView from "./ReadingPreviewView";

export interface ReadingProps {}

const Reading: React.FC<ReadingProps> = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <ReadingDetailView />
      </Route>
      <ProtectedRoute exact path={`${path}/preview`}>
        <ReadingPreviewView />
      </ProtectedRoute>
      <ProtectedRoute exact path={`${path}/create`}>
        <ReadingCreationView />
      </ProtectedRoute>
      <ProtectedRoute exact path={`${path}/edit`}>
        <ReadingEditionView />
      </ProtectedRoute>
    </Switch>
  );
};

export default Reading;
