import { TextField, Button } from "@material-ui/core";
import CKeditor from "components/CKeditor";
import * as React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { QuestionProps } from ".";

const generateMCTemplate = (range: string) => ({
  ins: "",
  order: range,
  options: [
    { label: "A", text: "" },
    { label: "B", text: "" },
    { label: "C", text: "" },
    { label: "D", text: "" },
    { label: "E", text: "" },
  ],
});

const AddMC: React.FC<QuestionProps> = ({ question, onSubmit, range }) => {
  const defaultValues = question || generateMCTemplate(range);
  const { register, control, handleSubmit, setValue, watch } = useForm({
    defaultValues,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
  });
  const handleEditorChange = (_e: any, editor: any) => {
    setValue("ins", editor.getData());
    setValue("order", range);
  };
  const handleAddOption = () => {
    const [lastItem] = fields.slice(-1);
    const label = String.fromCharCode(lastItem.label.charCodeAt() + 1);
    append({ label, text: "" });
  };
  const handleRemoveOption = () => {
    remove(fields.length - 1);
  };
  React.useEffect(() => {
    register("ins", { required: true });
    setValue("ins", question?.ins);

    register("order", { required: true });
  }, [register, setValue, question]);
  const { ins } = watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CKeditor data={ins ?? ""} handleOnChange={handleEditorChange} />
      {fields.map((option, index) => {
        const { label, text } = option;
        return (
          <div key={option?.id || label}>
            <input
              hidden={true}
              name={`options[${index}].label`}
              defaultValue={label}
              ref={register()}
            />
            <TextField
              name={`options[${index}].text`}
              label={label}
              variant="outlined"
              autoComplete="off"
              inputRef={register({ required: true })}
              defaultValue={text}
              fullWidth
            />
          </div>
        );
      })}
      <Button onClick={handleAddOption} color="primary" variant="outlined">
        add option
      </Button>
      <Button onClick={handleRemoveOption} color="secondary" variant="outlined">
        remove option
      </Button>
      <div>
        <Button type="submit" variant="outlined">
          {question ? "Edit" : "Add"} Answers
        </Button>
      </div>
    </form>
  );
};

export default AddMC;
