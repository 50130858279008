import RenderSpeaking from "components/Practice/speaking/RenderSpeaking";
import * as React from "react";
import { useLocation } from "react-router-dom";

interface SpeakingPreviewViewProps {}

const SpeakingPreviewView: React.FC<SpeakingPreviewViewProps> = () => {
  const location = useLocation() as any;
  const practice = location.state.practiceData;
  return (
    <RenderSpeaking practice={practice} title="Speaking Practice Preview" />
  );
};

export default SpeakingPreviewView;
