import * as React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import DashboardIcon from "@material-ui/icons/Dashboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import NotesIcon from "@material-ui/icons/Notes";
import WorkIcon from "@material-ui/icons/Work";
import PeopleIcon from "@material-ui/icons/People";
import EventIcon from "@material-ui/icons/Event";
import ComputerIcon from "@material-ui/icons/Computer";
import { Auth } from "aws-amplify";

import logo from "assets/logo.png";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "context/auth.context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    logo: {
      marginRight: theme.spacing(2),
    },
    loginButton: {
      marginLeft: "auto",
    },
  })
);

const NavBar: React.FC = () => {
  let history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { setUser } = React.useContext(AuthContext);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleMenuItemClick = (link: string) => {
    toggleDrawer();
    history.push(link);
  };
  const handleLogout = () => {
    Auth.signOut();
    setUser && setUser(undefined);
  };
  let menuItems = [
    { link: "/", title: "Dashboard", icon: <DashboardIcon color="primary" /> },
    {
      link: "/calendar",
      title: "My Calendar",
      icon: <EventIcon color="primary" />,
    },
    {
      link: "/practice",
      title: "Practices",
      icon: <FileCopyIcon color="primary" />,
    },
    { link: "/note", title: "Notes", icon: <NotesIcon color="primary" /> },
    {
      link: "/homework",
      title: "Homework",
      icon: <WorkIcon color="primary" />,
    },
    {
      link: "/exam",
      title: "Mock Exams",
      icon: <ComputerIcon color="primary" />,
    },
    {
      link: "/course-list",
      title: "Courses List",
      icon: <PeopleIcon color="primary" />,
    },
  ];
  const list = () => (
    <List>
      {menuItems.map((item) => {
        const { link, title, icon } = item;
        return (
          <ListItem
            button
            onClick={() => handleMenuItemClick(link)}
            key={title}
          >
            <ListItemIcon> {icon}</ListItemIcon>
            <ListItemText primary={title} />
          </ListItem>
        );
      })}
    </List>
  );

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/">
            <Avatar alt="boxwhy's logo" src={logo} className={classes.logo} />
          </Link>
          <Typography color="initial">Boxwhy IELTS</Typography>
          <Button
            className={classes.loginButton}
            color="inherit"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Drawer anchor="left" open={open} onClose={toggleDrawer}>
        {list()}
      </Drawer>
    </>
  );
};

export default NavBar;
