import * as React from "react";
import NumberFormat from "react-number-format";

function limit(val: string, max: string) {
  if (val.length === 1 && val[0] > max[0]) {
    val = "0" + val;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = "00";

      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
}

function timeLimit(val: string) {
  let mins = limit(val.substring(0, 2), "99");
  let seconds = limit(val.substring(2, 4), "60");

  return mins + (seconds.length ? ":" + seconds : "");
}

function NumberFormatTime(props: any) {
  const { onChange, inputRef, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values: any) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={timeLimit}
      placeholder="mm:ss"
      // mask="mm:ss"
    />
  );
}

export default NumberFormatTime;
