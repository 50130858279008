import * as React from "react";
import { Grid, Button, Typography } from "@material-ui/core";
// import parse from "html-react-parser";
import DownloadFile from "components/DownloadFile";
import { useCourseValue } from "context/course.context";
import DeleteButton from "components/DeleteButton";
import { useAuthValue } from "context/auth.context";
import RenderInstruction from "layout/RenderInstruction";

interface RenderContentFileProps {
  onEdit: () => void;
  content: any;
}

const RenderContentFile: React.FC<RenderContentFileProps> = ({
  onEdit,
  content,
}) => {
  const { user } = useAuthValue();
  const isAdmin = user.groups?.includes("admin");
  const { updateCourse } = useCourseValue();
  const handleDelete = () => {
    updateCourse((draft: any) => {
      draft.content = draft.content.filter(
        (data: any) => data.id !== content.id
      );
    });
  };
  return (
    <Grid container direction="column" spacing={2}>
      <RenderInstruction instruction={content.instruction} />
      <Grid item>
        <Typography display="inline">
          <b>File: &nbsp;</b>
        </Typography>
        <DownloadFile storageKey={content.key} />
      </Grid>
      {isAdmin && (
        <Grid item container justify="flex-end">
          <Button color="secondary" onClick={() => onEdit()}>
            Edit
          </Button>
          <DeleteButton onDelete={handleDelete} />
        </Grid>
      )}
    </Grid>
  );
};

export default RenderContentFile;
