export const listLt = /* GraphQL */ `
  query ListLt(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelListeningTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLT(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        book
        test
        typeName
      }
      nextToken
    }
  }
`;
export const listLs = /* GraphQL */ `
  query ListLs(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelListeningSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLS(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        book
        sectionNumber
        types
        typeName
      }
      nextToken
    }
  }
`;
export const listLq = /* GraphQL */ `
  query ListLq(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelListeningQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLQ(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        book
        sectionNumber
        type
        typeName
      }
      nextToken
    }
  }
`;
export const listRta = /* GraphQL */ `
  query ListRta(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelReadingTestAFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRTA(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        book
        test
        typeName
      }
      nextToken
    }
  }
`;
export const listRtg = /* GraphQL */ `
  query ListRtg(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelReadingTestGFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRTG(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        book
        test
        typeName
      }
      nextToken
    }
  }
`;
export const listRp = /* GraphQL */ `
  query ListRp(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelReadingPassageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRP(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        book
        genre
        passageNumber
        types
        typeName
      }
      nextToken
    }
  }
`;
export const listRq = /* GraphQL */ `
  query ListRq(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelReadingQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRQ(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        book
        passageNumber
        type
        typeName
      }
      nextToken
    }
  }
`;

export const listP1topics = /* GraphQL */ `
  query ListP1topics(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelPartOneTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listP1topics(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const listP2 = /* GraphQL */ `
  query ListP2(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelPartTwoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listP2(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        prompt
      }
      nextToken
    }
  }
`;
export const listCoursesGist = /* GraphQL */ `
  query ListCoursesGist(
    $typeName: String
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoursesGist(
      typeName: $typeName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        typeName
        student
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
