import * as React from "react";
import {
  Typography,
  Button,
  Collapse,
  TextField,
  makeStyles,
  Theme,
  // Divider,
  Grid,
} from "@material-ui/core";
import parse from "html-react-parser";
import Spinner from "components/Spinner";
import config from "customConfig";
import { wordCounter } from "utilities";

export interface RenderWritingQuestionProps {
  writingData: any;
  value: string;
  onChange: (text: string) => void;
  submittedAnswers?: string;
  showSample?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // height: "90vh",
    // overflow: "scroll",
    // overflowX: "hidden",
  },
  promptContainer: {
    height: "80vh",
    overflow: "scroll",
    overflowX: "hidden",
  },
  answerContainer: {
    height: "80vh",
    overflow: "scroll",
    overflowX: "hidden",
  },
  textField: {
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(2),
      width: "100%",
    },
  },
  prompt: {
    padding: "1rem",
    border: "solid 2px",
    marginTop: "1rem",
    marginBottom: "1rem",
    "& p": {
      fontWeight: 700,
      fontSize: "1rem",
    },
  },
  img: {
    width: "70%",
    height: "auto",
    margin: "auto",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  letter: {
    fontFamily: "Comic Sans MS, Comic Sans, cursive",
  },
  sampleAnswer: {
    padding: "1rem",
    border: "solid 2px",
    marginTop: "1rem",
    marginBottom: "1rem",
    "& p": {
      fontSize: "1rem",
    },
  },
}));

const RenderWritingQuestion: React.FC<RenderWritingQuestionProps> = ({
  writingData,
  onChange,
  submittedAnswers,
  value,
  showSample,
}) => {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    onChange(text);
  };
  if (!writingData) {
    return <Spinner />;
  }
  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12} sm={6} className={classes.promptContainer}>
        {writingData.typeName === "Essay" && (
          <EssayWrapper prompt={writingData.prompt} />
        )}
        {writingData.typeName === "Graph" && (
          <GraphWrapper prompt={writingData.prompt} imgUrl={writingData.img} />
        )}
        {writingData.typeName === "Letter" && (
          <LetterWrapper prompt={writingData.prompt} />
        )}
        {writingData.sampleAnswer && showSample && (
          <SampleAnswerCollapse sampleAnswer={writingData.sampleAnswer} />
        )}
      </Grid>

      {/* <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} /> */}
      <Grid item xs={12} sm={6} className={classes.answerContainer}>
        {submittedAnswers ? (
          <Typography style={{ whiteSpace: "pre-line" }}>
            {submittedAnswers}
          </Typography>
        ) : (
          <div className={classes.textField}>
            <TextField
              value={value ?? ""}
              label="Write here"
              multiline
              rows={25}
              variant="outlined"
              margin="normal"
              onChange={handleChange}
            />
          </div>
        )}
        <Typography variant="h6">Words Count: {wordCounter(value)}</Typography>
      </Grid>
    </Grid>
  );
};

export default RenderWritingQuestion;

interface EssayWrapperProps {
  prompt: string;
}

const EssayWrapper: React.FC<EssayWrapperProps> = ({ prompt }) => {
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        <b>WRITING TASK 2</b>
      </Typography>
      <Typography gutterBottom>
        You should spend about 40 minutes on this task.
      </Typography>
      <Typography gutterBottom>Write abouit the following topic:</Typography>
      <WritingPrompt prompt={prompt} />
      <Typography gutterBottom>
        Give reasons for your answer and include any relevant examples from your
        won knowledge or experience.
      </Typography>
      <Typography gutterBottom>Write at least 250 words.</Typography>
    </div>
  );
};

interface GraphWrapperProps {
  prompt: string;
  imgUrl: string;
}

const GraphWrapper: React.FC<GraphWrapperProps> = ({ prompt, imgUrl }) => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        <b>WRITING TASK 1</b>
      </Typography>
      <Typography gutterBottom>
        You should spend about 20 minutes on this task.
      </Typography>
      <WritingPrompt prompt={prompt} />
      <Typography gutterBottom>Write at least 150 words.</Typography>
      <img
        src={`${config.awsUrl}resource/img/task2/${imgUrl}.jpg`}
        className={classes.img}
        alt=""
      />
    </div>
  );
};

interface LetterWrapperProps {
  prompt: string;
}

const LetterWrapper: React.FC<LetterWrapperProps> = ({ prompt }) => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        <b>WRITING TASK 1</b>
      </Typography>
      <Typography gutterBottom>
        You should spend about 20 minutes on this task.
      </Typography>
      <WritingPrompt prompt={prompt} />
      <Typography gutterBottom>Write at least 150 words.</Typography>
      <Typography gutterBottom>
        You do <b>NOT</b> need to write any addresses.
      </Typography>
      <Typography gutterBottom>Begin your letter as followings:</Typography>
      <Typography className={classes.letter}>Dear ________,</Typography>
    </div>
  );
};

interface WritingPromptProps {
  prompt: string;
}

const WritingPrompt: React.FC<WritingPromptProps> = ({ prompt }) => {
  const classes = useStyles();
  return <div className={classes.prompt}>{parse(prompt)}</div>;
};

interface SampleAnswerCollapseProps {
  sampleAnswer: string;
}

const SampleAnswerCollapse: React.FC<SampleAnswerCollapseProps> = ({
  sampleAnswer,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <Button onClick={handleExpandClick} variant="outlined" color="primary">
        {expanded ? "Hide" : "Show"} Sample Answer
      </Button>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.sampleAnswer}>{parse(sampleAnswer)}</div>
      </Collapse>
    </>
  );
};
