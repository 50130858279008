import * as React from "react";
import useLocalImmer from "hooks/useLocalImmer";
import CardLayout from "layout/CardLayout";
import ListeningTestForm from "components/Practice/listening/ListeningTestForm";
import { useHistory } from "react-router-dom";
import MainWrapper from "layout/MainWrapper";
import { useSnackbar } from "notistack";
import { useAsyncCallback } from "react-async-hook";
import { createListeningTestNew } from "utilities/awsCRUD";
import { isObjFieldFull } from "utilities/validation";

interface ListeningCreationViewProps {}

const initialData = {
  book: "",
  test: "",
  typeName: "ListeningTest",
  s1: { questions: [] } as any,
  s2: { questions: [] } as any,
  s3: { questions: [] } as any,
  s4: { questions: [] } as any,
};

const ListeningCreationView: React.FC<ListeningCreationViewProps> = () => {
  const asyncCreation = useAsyncCallback(
    async () => await createListeningTestNew(listeningTest)
  );
  const { enqueueSnackbar } = useSnackbar();
  const [listeningTest, updateListeningTest] = useLocalImmer(
    "createListeningTest",
    initialData
  );
  const history = useHistory();
  const onCancel = React.useCallback(() => {
    window.localStorage.removeItem("createListeningTest");
    history.push("/practice");
  }, [history]);

  const onSubmit = () => {
    if (isObjFieldFull(listeningTest)) {
      asyncCreation.execute();
    } else {
      enqueueSnackbar("You are missing fields", { variant: "error" });
    }
  };
  const onPreview = () => {
    if (isObjFieldFull(listeningTest)) {
      history.push("/practice/listening/preview", {
        practiceData: listeningTest,
      });
    } else {
      enqueueSnackbar("You are missing fields", { variant: "error" });
    }
  };
  React.useEffect(() => {
    if (asyncCreation.result) {
      enqueueSnackbar("successfully created listening test", {
        variant: "success",
      });
      onCancel();
    }
    if (asyncCreation.error) {
      enqueueSnackbar(asyncCreation.error.message, { variant: "error" });
    }
  }, [asyncCreation.error, asyncCreation.result, enqueueSnackbar, onCancel]);

  return (
    <MainWrapper>
      <CardLayout
        mode="create"
        title="Create Listening Test"
        onPreview={onPreview}
        onCancel={onCancel}
        onSubmit={onSubmit}
        loading={asyncCreation.loading}
      >
        <ListeningTestForm
          listeningTest={listeningTest}
          updateListeningTest={updateListeningTest}
        />
      </CardLayout>
    </MainWrapper>
  );
};

export default ListeningCreationView;
