import * as React from "react";
import { Button } from "@material-ui/core";
import ConfirmationDialog from "./ConfirmationDialog";
import CircularProgress from "@material-ui/core/CircularProgress";

interface DeleteButtonProps {
  loading?: boolean;
  message?: string;
  onDelete: () => void;
  size?: "large" | "medium" | "small";
  color?: "default" | "inherit" | "primary" | "secondary";
}

const DeleteButton: React.FC<DeleteButtonProps> = ({
  message,
  onDelete,
  size,
  color,
  loading,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const onConfirm = async () => {
    onDelete();
    setOpen(false);
  };
  return (
    <>
      <Button
        size={size}
        color={color}
        onClick={() => setOpen(true)}
        startIcon={loading && <CircularProgress size={18} />}
        disabled={loading}
      >
        Delete
      </Button>
      {open && (
        <ConfirmationDialog
          message={message}
          open={open}
          onCancel={() => setOpen(false)}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

DeleteButton.defaultProps = {
  size: "medium",
  message: "Are you sure you want to delete this?",
  color: "default",
};

export default DeleteButton;
