import * as React from "react";
import CourseContext from "context/course.context";
import CourseIntro from "components/Course/CourseIntro";
import ContentList from "components/Course/ContentList";
import MainWrapper from "layout/MainWrapper";
import { useImmer } from "use-immer";
import { useLocation, Redirect } from "react-router-dom";
import { API } from "aws-amplify";
import * as queries from "graphql/queries";
import * as mutations from "graphql/mutations";
import Spinner from "components/Spinner";
import { useSnackbar } from "notistack";

interface MyCourseProps {}

const MyCourse: React.FC<MyCourseProps> = () => {
  const count = React.useRef(0);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation() as any;
  const id = location.state?.id;
  const [course, updateCourse] = useImmer(null);
  React.useEffect(() => {
    if (id) {
      (async () => {
        const result = (await API.graphql({
          query: queries.getCourse,
          variables: { id },
        })) as any;
        const getCourse = result.data.getCourse;
        let content;
        if (getCourse.content) {
          content = JSON.parse(getCourse.content);
        } else {
          content = [];
        }
        updateCourse({ ...getCourse, content });
      })();
    }
  }, [id, updateCourse]);
  React.useEffect(() => {
    count.current += 1;
    if (course && count.current > 2) {
      (async () => {
        const input = { ...course };
        delete input.createdAt;
        delete input.updatedAt;
        if (input.content.length === 0) {
          delete input.content;
        } else {
          input.content = JSON.stringify(input.content);
        }

        try {
          await API.graphql({
            query: mutations.updateCourse,
            variables: { input },
          });
        } catch (error) {
          console.log("update course failed", error);
          enqueueSnackbar("course update failed", { variant: "error" });
        }
      })();
    }
  }, [course, enqueueSnackbar]);
  if (!id) return <Redirect to="/course-list" />;
  if (!course) return <Spinner />;
  return (
    <CourseContext.Provider value={{ course, updateCourse }}>
      <MainWrapper>
        <CourseIntro />
        <ContentList />
      </MainWrapper>
    </CourseContext.Provider>
  );
};

export default MyCourse;
