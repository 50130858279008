import * as React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

export interface GenreSelectorProps {
  genre: string;
  setGenre: (genre: string) => void;
  isEdit?: boolean;
}

const GenreSelector: React.FC<GenreSelectorProps> = ({
  genre,
  setGenre,
  isEdit,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGenre((event.target as HTMLInputElement).value);
  };
  return (
    <FormControl component="fieldset" disabled={isEdit}>
      <FormLabel component="legend">Genre</FormLabel>
      <RadioGroup
        aria-label="genre"
        row
        name="genre"
        value={genre}
        onChange={handleChange}
      >
        <FormControlLabel value="A" control={<Radio />} label="A" />
        <FormControlLabel value="G" control={<Radio />} label="G" />
      </RadioGroup>
    </FormControl>
  );
};

GenreSelector.defaultProps = {
  isEdit: false,
};

export default GenreSelector;
