import * as React from "react";
import { useHistory } from "react-router-dom";
import RenderExamSection from "components/Exam/RenderExamSection";
import StepBar from "components/Exam/StepBar";
import { API } from "aws-amplify";
import * as queries from "graphql/queries";
import * as mutations from "graphql/mutations";
import { useSnackbar } from "notistack";
import { createPracticeResult } from "utilities";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 1200,
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    backgroundColor: "rgba(250,250,250,1)",
  },
}));

interface RenderExamProps {}

const createExamResult = (submittedAnswers: any, answers: any) => {
  const result = {} as any;
  Object.keys(answers).forEach((key: string) => {
    result[key] = createPracticeResult(submittedAnswers[key], answers[key]);
  });
  if (submittedAnswers.writing) {
    result.writing = { ...submittedAnswers.writing };
  }
  return result;
};

const RenderExam: React.FC<RenderExamProps> = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory() as any;
  const pathname = history.location.pathname;
  const mode = pathname.includes("preview")
    ? "preview"
    : pathname.includes("review")
    ? "review"
    : "test";
  const [examModel, setExamModel] = React.useState<any>(
    mode === "preview" ? history.location.state.examModel : null
  );
  const examData = React.useMemo(
    () => (mode !== "preview" ? history.location.state.examData : null),
    [mode, history]
  );
  const [answer, setAnswer] = React.useState<any>({});
  const result = React.useMemo(() => {
    if (examData && mode === "review") {
      return JSON.parse(examData.result);
    }
    return {};
  }, [examData, mode]);
  const [currentSectionIndex, setCurrentSectionIndex] =
    React.useState<number>(0);
  const [submittedAnswers, setSubmittedAnswers] = React.useState<any>(() =>
    mode === "review" ? JSON.parse(examData.submittedAnswers) : {}
  );
  const onSubmit = async () => {
    if (currentSectionIndex < examSectionList.length - 1) {
      setCurrentSectionIndex(currentSectionIndex + 1);
    } else {
      if (mode === "test") {
        const examResult = createExamResult(submittedAnswers, answer);
        const input = {
          id: examData.id,
          submittedAnswers: JSON.stringify(submittedAnswers),
          result: JSON.stringify(examResult),
          submittedDate: new Date().toISOString(),
          status: "completed",
        };
        try {
          await API.graphql({
            query: mutations.updateExam,
            variables: { input },
          });
          enqueueSnackbar("Mock exam was submitted successfully", {
            variant: "success",
          });
          history.goBack();
        } catch (error) {
          console.log("submit exam error", error);
          enqueueSnackbar(
            "Connection error, please wait a moment and submit again.",
            { variant: "error" }
          );
        }
      }
    }
  };
  const examSectionList = React.useMemo(() => {
    if (!examModel) return [];
    const list = [];
    const nameList = [
      "instructions",
      "listening",
      "reading",
      "writing",
      "speaking",
    ];
    for (const [key, value] of Object.entries(examModel)) {
      if (nameList.includes(key) && examModel[key]) {
        list.push({ name: key, data: value });
      }
    }
    return list.sort(
      (a: any, b: any) => nameList.indexOf(a.name) - nameList.indexOf(b.name)
    );
  }, [examModel]);
  React.useEffect(() => {
    if (mode !== "preview" && examData) {
      (async () => {
        try {
          const data = (await API.graphql({
            query: queries.getExamModel,
            variables: { id: examData.examModelID },
          })) as any;
          setExamModel(data.data.getExamModel);
          if (mode === "review") {
            setSubmittedAnswers(JSON.parse(examData.submittedAnswers));
          }
        } catch (error) {
          console.log("fetch exam model error", error);
          enqueueSnackbar("Connection error, please try again later.", {
            variant: "warning",
          });
        }
      })();
    }
  }, [mode, examData, enqueueSnackbar]);

  return (
    <div className={classes.root}>
      <StepBar
        examSectionList={examSectionList}
        mode={mode}
        currentSectionIndex={currentSectionIndex}
        setCurrentSectionIndex={setCurrentSectionIndex}
      />
      {examSectionList.map((examSection: any, index: number) => (
        <RenderExamSection
          result={result}
          onSubmit={onSubmit}
          step={index}
          data={examSection}
          key={examSection.name}
          currentSectionIndex={currentSectionIndex}
          submittedAnswers={submittedAnswers}
          setSubmittedAnswers={setSubmittedAnswers}
          setAnswer={setAnswer}
        />
      ))}
    </div>
  );
};

export default RenderExam;
