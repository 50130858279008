import * as React from "react";
import MainWrapper from "./MainWrapper";
import {
  Card,
  CardHeader,
  CardContent,
  makeStyles,
  CardActions,
  Button,
  Theme,
} from "@material-ui/core";
import ConfirmationDialog from "components/ConfirmationDialog";

export interface CreationCardProps {
  type: string;
  isEdit: boolean;
  onCancel: () => void;
  onPreview: () => void;
  onSubmit: () => void;
  onDelete?: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "5px",
    minHeight: "80vh",
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
  },
  cardContent: {
    flexGrow: 1,
  },
  deleteButton: {
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
}));
const CreationCard: React.FC<CreationCardProps> = ({
  children,
  type,
  isEdit,
  onCancel,
  onPreview,
  onSubmit,
  onDelete,
}) => {
  const classes = useStyles();
  const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);
  const handleDelete = () => {
    setOpenConfirm(true);
  };
  const deleteMessage = `Are you sure you want to delete ${type}`;
  return (
    <MainWrapper>
      <Card className={classes.root}>
        <CardHeader title={isEdit ? `Edit ${type}` : `Create ${type}`} />
        <CardContent className={classes.cardContent}>{children}</CardContent>
        <CardActions>
          <Button variant="contained" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onPreview}>
            Preview
          </Button>
          <Button variant="contained" color="secondary" onClick={onSubmit}>
            {isEdit ? "Update" : "Submit"}
          </Button>
          {isEdit && (
            <Button
              variant="contained"
              className={classes.deleteButton}
              onClick={handleDelete}
            >
              Delete
            </Button>
          )}
        </CardActions>
      </Card>
      <ConfirmationDialog
        open={openConfirm}
        onCancel={() => setOpenConfirm(false)}
        onConfirm={() => {
          onDelete();
          setOpenConfirm(false);
        }}
        message={deleteMessage}
      />
    </MainWrapper>
  );
};

export default CreationCard;
