import * as React from "react";
import Section from "./Section";

interface EditSectionsProps {
  listeningTest: any;
  updateListeningTest: (listeningTest: any) => void;
}

const EditSections: React.FC<EditSectionsProps> = ({
  listeningTest,
  updateListeningTest,
}) => {
  const { book, test, s1, s2, s3, s4 } = listeningTest;
  const testID = `${book}-${test}`.toLowerCase();
  const updateQuestions = (sectionNumber: string) => (questions: any) => {
    updateListeningTest((draft: any) => {
      draft[sectionNumber].questions = questions;
    });
  };
  if (book && test)
    return (
      <>
        <Section
          sectionID={`${testID}-s1`}
          questions={s1.questions}
          setQuestions={updateQuestions("s1")}
        />
        <Section
          sectionID={`${testID}-s2`}
          questions={s2.questions}
          setQuestions={updateQuestions("s2")}
        />
        <Section
          sectionID={`${testID}-s3`}
          questions={s3.questions}
          setQuestions={updateQuestions("s3")}
        />
        <Section
          sectionID={`${testID}-s4`}
          questions={s4.questions}
          setQuestions={updateQuestions("s4")}
        />
      </>
    );

  return null;
};

export default EditSections;
