import { useState, useEffect } from "react";
import { API } from "aws-amplify";

const useAmplifyMutation = () => {
  const [status, setStatus] = useState("idle");
  const [value, setValue] = useState(null);
  let promise;
  const execute = async ({ query, variables, normalize = null }) => {
    setStatus("pending");
    setValue(null);
    promise = API.graphql({ query, variables });
    try {
      const data = await promise;
      if (normalize) {
        setValue(normalize(data));
      } else {
        setValue(data);
      }
      setStatus("success");
    } catch (error) {
      setStatus("error");
      throw error;
    }
  };

  useEffect(() => {
    return () => {
      API.cancel(promise, "amplify fetch cancelled");
    };
    //eslint-disable-next-line
  }, []);

  return { value, status, execute };
};
export default useAmplifyMutation;
