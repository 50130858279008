import * as React from "react";
import useLocalImmer from "hooks/useLocalImmer";
import CardLayout from "layout/CardLayout";
import ReadingTestForm from "components/Practice/reading/ReadingTestForm";
import { useHistory } from "react-router-dom";
import MainWrapper from "layout/MainWrapper";
import { useSnackbar } from "notistack";
import { validateReadingTestNew } from "utilities/validation";
import { createReadingTestNew } from "utilities/awsCRUD";

interface ReadingCreationViewProps {}

const initialData = {
  genre: "A",
  book: "",
  test: "",
  p1: { passage: "", questions: [] } as any,
  p2: { passage: "", questions: [] } as any,
  p3: { passage: "", questions: [] } as any,
  s1a: { passage: "", questions: [] } as any,
  s1b: { passage: "", questions: [] } as any,
  s2a: { passage: "", questions: [] } as any,
  s2b: { passage: "", questions: [] } as any,
  s3: { passage: "", questions: [] } as any,
};

const ReadingCreationView: React.FC<ReadingCreationViewProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [readingTest, updateReadingTest] = useLocalImmer(
    "createReadingTest",
    initialData
  );
  const history = useHistory();
  const onCancel = () => {
    window.localStorage.removeItem("createReadingTest");
    history.push("/practice");
  };

  const onPreview = () => {
    try {
      validateReadingTestNew(readingTest);
      const typeName =
        readingTest.genre === "A" ? "ReadingTestA" : "ReadingTestG";
      const practiceData = { ...readingTest, typeName };
      history.push("/practice/reading/preview", { practiceData });
    } catch (error: any) {
      const message =
        error.message ||
        `${error.errors[0].path[0]} - ${error.errors[0].message}`;
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const onSubmit = async () => {
    try {
      validateReadingTestNew(readingTest);
      await createReadingTestNew(readingTest);
      enqueueSnackbar(
        `You have successfully created ${
          readingTest.book
        }-${readingTest.genre.toLowerCase()}-${readingTest.test}`,
        {
          variant: "success",
        }
      );
      onCancel();
    } catch (error: any) {
      const message =
        error.message ||
        `${error.errors[0].path[0]} - ${error.errors[0].message}`;
      enqueueSnackbar(message, { variant: "error" });
    }
  };
  return (
    <MainWrapper>
      <CardLayout
        mode="create"
        title="Create Reading Test"
        onPreview={onPreview}
        onCancel={onCancel}
        onSubmit={onSubmit}
      >
        <ReadingTestForm
          readingTest={readingTest}
          updateReadingTest={updateReadingTest}
        />
      </CardLayout>
    </MainWrapper>
  );
};

export default ReadingCreationView;
