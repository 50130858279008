import * as React from "react";
import { Auth } from "aws-amplify";
import { trackEvent } from "utilities";
import { useSnackbar } from "notistack";
import { updateUser } from "utilities/awsCRUD";

export interface AuthProviderProps {}

interface AuthContextInterface {
  user: any;
  setUser: (user: any) => void;
  getUser: () => void;
  isAdmin: boolean;
}

export const AuthContext = React.createContext<AuthContextInterface>({
  user: null,
  getUser: undefined,
  setUser: undefined,
  isAdmin: false,
});

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = React.useState<any>(null);
  const { enqueueSnackbar } = useSnackbar();
  const isAdmin = user?.groups?.includes("admin");
  const getUser = async () => {
    try {
      const authUser = await Auth.currentAuthenticatedUser();

      const updatedUser = await updateUser(authUser, enqueueSnackbar);
      if (updatedUser) {
        setUser && setUser(updatedUser);
        trackEvent("登录", updatedUser);
      } else {
        setUser && setUser(undefined);
      }
    } catch (error) {
      setUser(undefined);
      enqueueSnackbar("请登录", { variant: "info" });
    }
  };

  React.useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <AuthContext.Provider value={{ user, getUser, setUser, isAdmin }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthValue = () => React.useContext(AuthContext);

export default AuthProvider;
