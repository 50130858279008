import * as React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import SettingsIcon from "@material-ui/icons/Settings";
import { AuthContext } from "context/auth.context";
import ChangeProfilePictureDialog from "./ChangeProfilePictureDialog";
import ChangePasswordDialog from "./ChangePasswordDialog";
import UserAvatar from "components/UserAvatar";
import { format } from "date-fns";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // width: "100%",
      maxWidth: "600px",
      minHeight: "250px",
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: red[500],
      width: 100,
      height: 100,
    },
  })
);

export interface ProfileProps {}

const Profile: React.FC<ProfileProps> = () => {
  const { user } = React.useContext(AuthContext);
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openCPPD, setOpenCPPD] = React.useState(false);
  const toggleCPPD = () => {
    setOpenCPPD(!openCPPD);
  };

  const [openCPD, setOpenCPD] = React.useState(false);
  const toggleCPD = () => {
    setOpenCPD(!openCPD);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleCCPD = () => {
    handleCloseMenu();
    toggleCPPD();
  };
  const handleCPD = () => {
    handleCloseMenu();
    toggleCPD();
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <IconButton aria-label="settings" onClick={handleClick}>
            <SettingsIcon />
          </IconButton>
        }
        title="Profile"
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleCCPD}>Change Avatar</MenuItem>
        <MenuItem onClick={handleCPD}>Change Password</MenuItem>
      </Menu>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item>
            <UserAvatar user={user} className={classes.avatar} />
          </Grid>
          <Grid item>
            <Typography variant="body1" component="p">
              Username：<b>{user.username}</b>
            </Typography>
            <Typography variant="body1" component="p">
              Email：<b>{user.email}</b>
            </Typography>
            {user.targetScores && (
              <Typography variant="body1" component="p">
                Targeted Scores：<b>{getUserTargetScores(user)}</b>
              </Typography>
            )}
            {user.examDate && (
              <Typography variant="body1" component="p">
                Next Exam Date：
                <b>{format(new Date(user.examDate), "MM/dd/yyyy")}</b>
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <ChangeProfilePictureDialog openCPPD={openCPPD} toggleCPPD={toggleCPPD} />
      <ChangePasswordDialog openCPD={openCPD} toggleCPD={toggleCPD} />
    </Card>
  );
};

export default Profile;

const getUserTargetScores = (user: any) => {
  return user.targetScores.map((score: any, idx: any) => {
    switch (idx) {
      case 0:
        return <span key={idx}>L:{score} &nbsp;&nbsp;&nbsp;</span>;
      case 1:
        return <span key={idx}>R:{score} &nbsp;&nbsp;&nbsp;</span>;
      case 2:
        return <span key={idx}>W:{score} &nbsp;&nbsp;&nbsp;</span>;
      case 3:
        return <span key={idx}>S:{score} &nbsp;&nbsp;&nbsp;</span>;
      default:
        return null;
    }
  });
};
