import * as React from "react";
import { makeStyles, Theme, Grid } from "@material-ui/core";
import RenderQuestion from "../RenderQuestion";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexGrow: 1,
    overflow: "auto",
    backgroundColor: "white",
  },
  singleContainer: {
    display: "flex",
    flexGrow: 1,
    overflow: "auto",
    backgroundColor: "white",
    maxWidth: theme.breakpoints.values.lg,
    borderRadius: "4px",
    boxShadow:
      " 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    margin: "auto",
  },
  script: {
    backgroundColor: "#e8f4fd",
    padding: theme.spacing(1),
    borderRadius: "4px",
    // overflowX: "hidden",
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      overflowY: "scroll",
      height: "calc(100vh - 8.5rem)",
      fontSize: "1.1rem",
    },
  },
  question: {
    width: "100%",
    // overflowX: "hidden",
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      overflowY: "scroll",
      height: "calc(100vh - 8.5rem)",
      // fontSize: "1.1rem",
    },
  },
  active: {
    color: "red",
  },
}));

interface ListeningContentProps {
  practice: any;
  answers: any;
  setAnswers: (data: any) => void;
  errors: any;
  scriptData: any;
  showScript: boolean;
  handleClick: (start: string) => void;
  scriptActiveIndex: number;
}

const ListeningContent: React.FC<ListeningContentProps> = ({
  practice,
  answers,
  setAnswers,
  errors,
  scriptData,
  showScript,
  handleClick,
  scriptActiveIndex,
}) => {
  const { questions } = practice;
  const classes = useStyles();
  const renderQuestion = () => {
    if (questions) {
      if (Array.isArray(questions)) {
        return (
          <>
            {practice.questions.map((question: any) => (
              <Grid item key={question.range}>
                <RenderQuestion
                  question={question.questionBody}
                  type={question.type}
                  setAnswers={setAnswers}
                  answers={answers}
                  errors={errors}
                />
              </Grid>
            ))}
          </>
        );
      } else {
        return (
          <RenderQuestion
            question={questions.questionBody}
            type={questions.type}
            setAnswers={setAnswers}
            answers={answers}
            errors={errors}
          />
        );
      }
    } else {
      return (
        <RenderQuestion
          question={practice.questionBody}
          type={practice.type}
          setAnswers={setAnswers}
          answers={answers}
          errors={errors}
        />
      );
    }
  };
  return (
    <Grid
      container
      className={showScript ? classes.container : classes.singleContainer}
    >
      {showScript && (
        <Grid item xs={12} sm={6} className={classes.script}>
          <ul>
            {scriptData.length > 0 ? (
              scriptData.map((script: any, index: number) => (
                <li
                  key={script.startTime}
                  className={
                    index === scriptActiveIndex ? classes.active : null
                  }
                  onClick={() => handleClick(script.startTime)}
                >
                  {script.text}
                </li>
              ))
            ) : (
              <li>no content yet</li>
            )}
          </ul>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        sm={showScript && scriptData ? 6 : 12}
        className={classes.question}
      >
        {renderQuestion()}
      </Grid>
    </Grid>
  );
};

export default ListeningContent;
