import * as React from "react";
import config from "customConfig";

export interface AudioPlayerProps {
  sectionID: string;
  startTime?: string;
  hide?: boolean;
  showScript: boolean;
  handleTimeUpdate: (e: any) => void;
}

const AudioPlayer = React.forwardRef<HTMLAudioElement, AudioPlayerProps>(
  ({ sectionID, hide, handleTimeUpdate, showScript }, ref) => {
    const audioUrl = `${config.awsUrl}resource/audio/${sectionID}.mp3`;
    // React.useEffect(()=>{
    //     if (startTime !== null){
    //         audioRef.current.currentTime = startTime
    //     }
    // }, [startTime])
    if (sectionID) {
      return (
        <>
          <audio
            ref={ref}
            src={audioUrl}
            autoPlay={true}
            controls={!hide}
            controlsList="nodownload"
            onTimeUpdate={showScript ? handleTimeUpdate : undefined}
          />
        </>
      );
    } else {
      return null;
    }
  }
);

AudioPlayer.defaultProps = {
  startTime: null,
  hide: false,
};

export default AudioPlayer;
