import { Grid } from "@material-ui/core";
import { useAuthValue } from "context/auth.context";
import MainWrapper from "layout/MainWrapper";
import * as React from "react";
import AdminDashboard from "./components/AdminDashboard";
import UserDashboard from "./components/UserDashboard";

export interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  const { isAdmin } = useAuthValue();
  return (
    <MainWrapper>
      <Grid container spacing={2}>
        {isAdmin ? <AdminDashboard /> : <UserDashboard />}
      </Grid>
    </MainWrapper>
  );
};

export default Dashboard;
