import * as React from "react";
import { useSnackbar } from "notistack";
import { Grid, TextField, Button } from "@material-ui/core";

interface ContentAssignmentFormProps {
  content?: any;
  onCancel: () => void;
  onSubmit: (content: any) => void;
}

const initialData = {
  title: "",
} as any;

const ContentAssignmentForm: React.FC<ContentAssignmentFormProps> = ({
  content,
  onCancel,
  onSubmit,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = React.useState<any>(content ? content : initialData);
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, title: event.target.value });
  };

  const handleCancel = () => {
    setData(initialData);
    onCancel();
  };
  const handleSubmit = () => {
    if (data.title) {
      onSubmit(data);
      onCancel();
    } else {
      enqueueSnackbar("You are missing title field.", { variant: "warning" });
    }
  };
  return (
    <Grid container direction="column">
      <Grid item>
        <TextField
          label="Title"
          value={data.title}
          onChange={handleTitleChange}
          variant="outlined"
        />
      </Grid>
      <Grid item>
        <Button color="default" onClick={handleCancel}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default ContentAssignmentForm;
