import * as React from "react";
import CardLayout from "layout/CardLayout";
import MainWrapper from "layout/MainWrapper";
import WritingForm from "components/Practice/writing/WritingForm";
import useLocalImmer from "hooks/useLocalImmer";
import { isObjFieldFull } from "utilities/validation";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useAsyncCallback } from "react-async-hook";
import { createWritingTask } from "utilities/awsCRUD";

interface WritingCreationViewProps {}

const initialData = {
  typeName: "Essay",
  book: "",
  name: "",
  type: "",
  prompt: "",
  sampleAnswer: "",
};

const WritingCreationView: React.FC<WritingCreationViewProps> = () => {
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const [writing, updateWriting] = useLocalImmer("createWriting", initialData);
  const asyncCreation = useAsyncCallback(
    async () => await createWritingTask(writing)
  );
  const onCancel = React.useCallback(() => {
    window.localStorage.removeItem("createWriting");
    history.push("/practice");
  }, [history]);
  const onPreview = () => {
    console.log("onPreview");
  };

  const onSubmit = () => {
    if (isObjFieldFull(writing, "sampleAnswer")) {
      console.log("validate", writing);
      asyncCreation.execute();
    } else {
      enqueueSnackbar("Fields are missing", { variant: "error" });
    }
  };
  React.useEffect(() => {
    if (asyncCreation.result) {
      enqueueSnackbar("successfully created Writing Task", {
        variant: "success",
      });
      onCancel();
    }
    if (asyncCreation.error) {
      enqueueSnackbar(asyncCreation.error.message, { variant: "error" });
    }
  }, [asyncCreation.error, asyncCreation.result, enqueueSnackbar, onCancel]);
  return (
    <MainWrapper>
      <CardLayout
        mode="create"
        title="Create Writing"
        onPreview={onPreview}
        onCancel={onCancel}
        onSubmit={onSubmit}
        loading={asyncCreation.loading}
      >
        <WritingForm writing={writing} updateWriting={updateWriting} />
      </CardLayout>
    </MainWrapper>
  );
};

export default WritingCreationView;
