import * as React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import DeleteButton from "components/DeleteButton";
import { useAuthValue } from "context/auth.context";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: "85vh",
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flexGrow: 1,
    },
    actions: {
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

export interface CardLayoutProps {
  title: string;
  mode: "edit" | "create" | "detail" | "preview";
  loading?: boolean;
  onPreview?: () => void;
  onSubmit?: () => void;
  onDelete?: () => void;
  onUpdate?: () => void;
  onEdit?: () => void;
  onCancel?: () => void;
}

const CardLayout: React.FC<CardLayoutProps> = ({
  title,
  mode,
  onPreview,
  onSubmit,
  onDelete,
  onUpdate,
  children,
  onEdit,
  onCancel,
  loading,
}) => {
  const { isAdmin } = useAuthValue();
  const history = useHistory();
  const classes = useStyles();
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      history.goBack();
    }
  };
  const renderButtons = () => {
    if (mode === "create") {
      return (
        <>
          <Button onClick={onPreview} color="secondary" variant="contained">
            preview
          </Button>
          <Button
            onClick={onSubmit}
            color="primary"
            variant="contained"
            startIcon={loading && <CircularProgress size={18} />}
            disabled={loading}
          >
            create
          </Button>
        </>
      );
    }
    if (mode === "edit") {
      return (
        <>
          <DeleteButton onDelete={onDelete} loading={loading} />
          <Button onClick={onPreview} color="secondary" variant="contained">
            preview
          </Button>

          <Button
            onClick={onUpdate}
            color="primary"
            variant="contained"
            startIcon={loading && <CircularProgress size={18} />}
            disabled={loading}
          >
            update
          </Button>
        </>
      );
    }
    if (mode === "detail" && isAdmin) {
      return (
        <>
          <Button variant="contained" color="primary" onClick={onEdit}>
            edit
          </Button>
        </>
      );
    }
  };
  return (
    <Card className={classes.root}>
      <CardHeader title={title} />
      <CardContent className={classes.content}>{children}</CardContent>
      <CardActions className={classes.actions}>
        <Button onClick={handleCancel} variant="contained">
          {mode === "preview" ? "go back" : "cancel"}
        </Button>
        {renderButtons()}
      </CardActions>
    </Card>
  );
};

export default CardLayout;
