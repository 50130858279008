import * as React from "react";
import { API } from "aws-amplify";
import * as queries from "graphql/queries";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import UserListItem from "./UserListItem";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "100%",
    },
    actionButton: {
      marginLeft: "auto",
    },
    list: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

export interface UserListProps {}
const LIMIT = 5;

const UserList: React.FC<UserListProps> = () => {
  const [usersList, setUsersList] = React.useState([]);
  const [nextToken, setNextToken] = React.useState(undefined);
  const [nextNextToken, setNextNextToken] = React.useState();
  const [previousTokens, setPreviousTokens] = React.useState([]);

  React.useEffect(() => {
    const getTrackingData = async () => {
      try {
        const result: any = await API.graphql({
          query: queries.listUsers,
          variables: { limit: LIMIT, nextToken: nextToken },
        });
        setNextNextToken(result.data.listUsers.nextToken);
        setUsersList(result.data.listUsers.items);
      } catch (error) {
        console.log("listUser error", error);
      }
    };
    getTrackingData();
  }, [nextToken]);
  const handleNextPage = () => {
    setPreviousTokens((prev) => [...prev, nextToken]);
    setNextToken(nextNextToken);
    setNextNextToken(null);
  };
  const handlePreviousPage = () => {
    setNextToken(previousTokens.pop());
    setPreviousTokens([...previousTokens]);
    setNextNextToken(null);
  };
  const hasNext = !!nextNextToken;
  const hasPrev = previousTokens.length;

  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader title="用户列表" />
      <CardContent>
        <List component="nav" className={classes.list}>
          {usersList &&
            usersList.map((user) => (
              <UserListItem user={user} key={user.username} />
            ))}
        </List>
      </CardContent>
      <CardActions disableSpacing>
        {!!hasPrev && (
          <IconButton
            aria-label="add to favorites"
            className={classes.actionButton}
            onClick={handlePreviousPage}
          >
            <ChevronLeftIcon />
          </IconButton>
        )}

        {hasNext && (
          <IconButton aria-label="share" onClick={handleNextPage}>
            <ChevronRightIcon />
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
};

export default UserList;
