import * as React from "react";
import { Paper, Grid, Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import RenderQuestion from "components/Practice/RenderQuestion";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "nowrap",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    containerSingle: {
      maxWidth: theme.breakpoints.values.lg,
      margin: "auto",
    },
    question: {
      height: "91vh",
      overflow: "scroll",
      overflowX: "hidden",
      flexGrow: 1,
      flexBasis: 0,
      [theme.breakpoints.down("sm")]: {
        height: "100%",
        flexBasis: "auto",
      },
    },
    questionSingle: {
      minHeight: "91vh",
    },
    script: {
      height: "91vh",
      flexGrow: 1,
      flexBasis: 0,
      overflow: "scroll",
      overflowX: "hidden",
      fontSize: "1rem",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
        flexBasis: "auto",
      },
    },
    audioContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "1rem",
    },
    active: {
      color: "red",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      margin: "1rem",
      paddingBottom: "1rem",
    },
    buttonGutter: {
      flexGrow: 1,
    },
  })
);

interface RenderListeningQuestionProps {
  scriptData: any;
  showScript: boolean;
  handleClick: (start: string) => void;
  errors: any;
  handleNext: () => void;
  handlePrev: () => void;
  practice: any;
  setSubmittedAnswers: (data: any) => void;
  submittedAnswers: any;
  typeName: string;
  scriptActiveIndex: number;
  index: number;
}

const RenderListeningQuestion: React.FC<RenderListeningQuestionProps> = ({
  scriptData,
  showScript,
  handleClick,
  errors,
  handleNext,
  handlePrev,
  practice,
  setSubmittedAnswers,
  submittedAnswers,
  typeName,
  scriptActiveIndex,
  index,
}) => {
  const classes = useStyles();
  return (
    <Paper className={showScript ? classes.container : classes.containerSingle}>
      {showScript && scriptData && (
        <Grid item className={classes.script}>
          <ul>
            {scriptData.length > 0 ? (
              scriptData.map((script: any, index: number) => (
                <li
                  key={script.startTime}
                  className={
                    index === scriptActiveIndex ? classes.active : null
                  }
                  onClick={() => handleClick(script.startTime)}
                >
                  {script.text}
                </li>
              ))
            ) : (
              <li>no content yet</li>
            )}
          </ul>
        </Grid>
      )}

      <Grid
        item
        className={showScript ? classes.question : classes.questionSingle}
      >
        {typeName === "ListeningTest" && practice && (
          <>
            {practice.questions.map((question: any) => (
              <Grid item key={question.range}>
                <RenderQuestion
                  question={question.questionBody}
                  type={question.type}
                  setAnswers={setSubmittedAnswers}
                  answers={submittedAnswers}
                  errors={errors}
                />
              </Grid>
            ))}
            <Grid item className={classes.buttonContainer}>
              {index === 0 ? null : (
                <Button
                  startIcon={<NavigateBeforeIcon />}
                  onClick={handlePrev}
                  color="secondary"
                  variant="contained"
                  style={{ color: "#0d47a1" }}
                >
                  previous
                </Button>
              )}
              <div className={classes.buttonGutter}></div>
              {index < 3 && (
                <Button
                  endIcon={<NavigateNextIcon />}
                  onClick={handleNext}
                  color="secondary"
                  variant="contained"
                  style={{ color: "#0d47a1" }}
                >
                  next
                </Button>
              )}
            </Grid>
          </>
        )}
        {typeName === "ListeningSection" && practice && (
          <>
            {practice.questions.map((question: any) => (
              <Grid item key={question.range}>
                <RenderQuestion
                  question={question.questionBody}
                  type={question.type}
                  setAnswers={setSubmittedAnswers}
                  answers={submittedAnswers}
                  errors={errors}
                />
              </Grid>
            ))}
          </>
        )}
        {typeName === "ListeningQuestion" && practice && (
          <RenderQuestion
            question={practice.questionBody}
            type={practice.type}
            setAnswers={setSubmittedAnswers}
            answers={submittedAnswers}
            errors={errors}
          />
        )}
      </Grid>
    </Paper>
  );
};

export default RenderListeningQuestion;
