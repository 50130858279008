import CKeditor from "components/CKeditor";
import * as React from "react";
import { QuestionProps } from ".";
import { Button } from "@material-ui/core";
import { blankMatchRange } from "utilities/validation";

const validateFG = (data: string, range: string) => {
  return blankMatchRange(data, range);
};

const AddFG: React.FC<QuestionProps> = ({ question, onSubmit, range }) => {
  const [data, setData] = React.useState<any>(question?.ins);
  const handleChange = (_e: any, editor: any) => {
    setData(editor.getData());
  };
  const handlePreview = () => {
    onSubmit({ ins: data });
  };
  const showButton = validateFG(data, range);
  return (
    <>
      <p>Add Filling Gaps</p>
      <CKeditor
        handleOnChange={handleChange}
        data={question?.ins ?? "type here...."}
      />
      <Button
        variant="outlined"
        onClick={handlePreview}
        color="primary"
        disabled={!showButton}
      >
        {question ? "Edit" : "Add"} Answers
      </Button>
    </>
  );
};

export default AddFG;
