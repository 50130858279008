import * as React from "react";
import DropDown from "components/DropDown";
import config from "customConfig";
import RadioSelector from "components/RadioSelector";
import { TextField, Typography, Grid, useTheme } from "@material-ui/core";
import CKeditor from "components/CKeditor";

interface WritingFormProps {
  writing: any;
  updateWriting: (writing: any) => void;
  isEdit?: boolean;
}

const WritingForm: React.FC<WritingFormProps> = ({
  writing,
  updateWriting,
  isEdit,
}) => {
  const theme = useTheme();
  const {
    typeName,
    book,
    name,
    genre,
    type,
    topic,
    img,
    prompt,
    sampleAnswer,
    aim,
  } = writing;
  const setBook = (book: string) => {
    updateWriting((draft: any) => {
      draft.book = book;
    });
  };
  const setTask = (typeName: string) => {
    updateWriting((draft: any) => {
      draft.typeName = typeName;
      if (typeName === "Essay") {
        delete draft.type;
        delete draft.img;
        delete draft.aim;
      }
      if (typeName === "Graph") {
        delete draft.type;
        delete draft.topic;
        delete draft.genre;
        delete draft.aim;
      }
      if (typeName === "Letter") {
        delete draft.type;
        delete draft.topic;
        delete draft.genre;
        delete draft.img;
      }
    });
  };
  const handleTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const property = e.target.name;
    const value = e.target.value;
    updateWriting((draft: any) => {
      draft[property] = value;
    });
  };
  const setPrompt = (_e: any, editor: any) => {
    updateWriting((draft: any) => {
      draft.prompt = editor.getData();
    });
  };
  const setSampleAnswer = (_e: any, editor: any) => {
    updateWriting((draft: any) => {
      draft.sampleAnswer = editor.getData();
    });
  };
  const setGenre = (genre: string) => {
    updateWriting((draft: any) => {
      draft.genre = genre;
    });
  };
  const setType = (type: string) => {
    updateWriting((draft: any) => {
      draft.type = type;
    });
  };
  const setAim = (aim: string) => {
    updateWriting((draft: any) => {
      draft.aim = aim;
    });
  };
  const setEssayTopic = (topic: string) => {
    updateWriting((draft: any) => {
      draft.topic = topic;
    });
  };
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <RadioSelector
          name="Task"
          options={config.writingTasks}
          isEdit={isEdit}
          value={typeName}
          setValue={setTask}
        />
        <DropDown
          name="Book"
          options={config.books}
          isEdit={isEdit}
          value={book}
          setValue={setBook}
        />
        <TextField
          label="Name"
          disabled={isEdit}
          name="name"
          variant="outlined"
          value={name}
          onChange={handleTextField}
          autoComplete="off"
          style={{ margin: theme.spacing(1) }}
        />
      </Grid>

      {typeName === "Essay" && (
        <Grid item>
          <RadioSelector
            name="Genre"
            options={config.genre}
            value={genre}
            setValue={setGenre}
          />
          <DropDown
            name="Type"
            options={config.essayType}
            value={type}
            setValue={setType}
          />
          <DropDown
            name="Topic"
            options={config.essayTopic}
            value={topic}
            setValue={setEssayTopic}
          />
        </Grid>
      )}
      {typeName === "Graph" && (
        <Grid item>
          <DropDown
            name="Type"
            options={config.graphType}
            value={type}
            setValue={setType}
          />
          <TextField
            label="Graph Image"
            variant="outlined"
            name="img"
            type="url"
            value={img ?? ""}
            onChange={handleTextField}
            autoComplete="off"
            style={{ margin: "8px" }}
          />
        </Grid>
      )}
      {typeName === "Letter" && (
        <Grid item>
          <DropDown
            name="Type"
            options={config.letterType}
            value={type}
            setValue={setType}
          />
          <DropDown
            name="Aim"
            options={config.letterAim}
            value={aim}
            setValue={setAim}
          />
        </Grid>
      )}
      <Grid item>
        <Typography variant="h6">Prompt:</Typography>
        <CKeditor data={prompt} handleOnChange={setPrompt} />
      </Grid>
      <Grid item>
        <Typography variant="h6">Sample Answer:</Typography>
        <CKeditor data={sampleAnswer} handleOnChange={setSampleAnswer} />
      </Grid>
    </Grid>
  );
};

WritingForm.defaultProps = {
  isEdit: false,
};

export default WritingForm;
