import * as React from "react";
import useLocalStorage from "hooks/useLocalStorage";
import CreationCard from "layout/CreationCard";
import { useHistory } from "react-router-dom";
import {
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import QuestionDialog from "components/Practice/QuestionDialog";
import produce from "immer";
import { useSnackbar } from "notistack";
import { getAnswersFromArray } from "utilities";
import { API } from "aws-amplify";
import * as mutations from "graphql/mutations";

interface CustomTestProps {}

const initialData = {
  id: "",
  typeName: "CustomTest",
  questions: [],
} as any;

const validateCustomTest = (data: any) =>
  data.id && data.questions.length > 0 ? true : false;

const stringifyCustomTestData = (data: any) => {
  const questions = data.questions.map((q: any) => ({
    ...q,
    answers: JSON.stringify(q.answers),
    questionBody: JSON.stringify(q.questionBody),
  }));
  const answers = JSON.stringify(data.answers);
  return { ...data, questions, answers };
};

const CustomTest: React.FC<CustomTestProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const isEdit = history.location.pathname.includes("edit");
  const [customTestData, setCustomTestData] = useLocalStorage(
    "customTestData",
    initialData
  );
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const handleTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const property = e.target.name;
    const value = e.target.value;
    setCustomTestData({ ...customTestData, [property]: value });
  };
  const questions = [...customTestData.questions];
  const setQuestions = (questions: any) => {
    setCustomTestData(
      produce((draft: any) => {
        draft.questions = questions;
      })
    );
  };
  const [selectedQuestionIndex, setSelectedQuestionIndex] = React.useState<
    number | null
  >(null);
  const [selectedQuestion, setSelectedQuestion] = React.useState<any>(null);

  const addQuestion = (data: any) => {
    setQuestions([...questions, data]);
  };
  const removeQuestion = (question: any) => {
    setQuestions([...questions.filter((q: any) => q.range !== question.range)]);
  };
  const updateQuestion = (data: any) => {
    questions[selectedQuestionIndex] = data;
    setQuestions([...questions]);
  };
  const handleSave = (data: any) => {
    if (selectedQuestion) {
      updateQuestion(data);
    } else {
      addQuestion(data);
    }
  };
  const handleClose = () => {
    setOpenDialog(false);
    setSelectedQuestionIndex(null);
    setSelectedQuestion(null);
  };
  const editQuestion = (question: any, index: number) => {
    setSelectedQuestionIndex(index);
    setSelectedQuestion(question);
    setOpenDialog(true);
  };

  const onSubmit = async () => {
    if (validateCustomTest(customTestData)) {
      const data = {
        ...customTestData,
        answers: getAnswersFromArray(customTestData.questions),
      };
      try {
        await API.graphql({
          query: mutations.createCustomTest,
          variables: { input: stringifyCustomTestData(data) },
        });
        enqueueSnackbar("successfully created customTest", {
          variant: "success",
        });
      } catch (error) {
        console.log("create customTest error", error);
        enqueueSnackbar("connection error", { variant: "error" });
      }
    } else {
      enqueueSnackbar("missing ID or Questions", { variant: "error" });
    }
  };
  const onPreview = () => {
    if (validateCustomTest(customTestData)) {
      const data = {
        ...customTestData,
        answers: getAnswersFromArray(customTestData.questions),
      };
      history.push({
        pathname: "/practice/customTest/preview",
        state: { data },
      });
    } else {
      enqueueSnackbar("missing ID or Questions", { variant: "error" });
    }
  };
  const onDelete = () => {
    console.log("onDelete");
  };
  const onCancel = () => {
    setCustomTestData(initialData);
    history.goBack();
  };
  return (
    <CreationCard
      type="Custom Test"
      isEdit={isEdit}
      onSubmit={onSubmit}
      onPreview={onPreview}
      onDelete={onDelete}
      onCancel={onCancel}
    >
      <TextField
        label="ID"
        name="id"
        variant="outlined"
        value={customTestData.id}
        onChange={handleTextField}
        autoComplete="off"
        style={{ margin: "8px" }}
      />
      <List>
        {questions.length > 0 &&
          questions.map((question: any, index: number) => (
            <ListItem key={question.range}>
              <ListItemAvatar>
                <Avatar>
                  <QuestionAnswerIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={question.range}
                secondary={question.type}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => removeQuestion(question)}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => editQuestion(question, index)}
                >
                  <EditIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        <IconButton
          aria-label="add question"
          onClick={() => setOpenDialog(true)}
        >
          <AddIcon />
        </IconButton>
        {openDialog && (
          <QuestionDialog
            handleSave={handleSave}
            open={openDialog}
            handleClose={handleClose}
            question={selectedQuestion}
            listening={false}
          />
        )}
      </List>
    </CreationCard>
  );
};

export default CustomTest;
