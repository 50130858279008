import * as React from "react";
import ContentFile from "./ContentFile";
import ContentAssignment from "./ContentAssignment";
import ContentHeader from "./ContentHeader";

interface RenderCourseContentProps {
  content: any;
}

const RenderCourseContent: React.FC<RenderCourseContentProps> = ({
  content,
}) => {
  switch (content.typeName) {
    case "file":
      return <ContentFile content={content} key={content.id} />;
    case "assignment":
      return <ContentAssignment content={content} key={content.id} />;
    case "header":
      return <ContentHeader content={content} key={content.id} />;
    default:
      return null;
  }
};

export default RenderCourseContent;
