import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import { isObjFieldFull } from "utilities/validation";
import CKeditor from "components/CKeditor";
import UserList from "components/UserList";
import { useSnackbar } from "notistack";
import { createCourse } from "utilities/awsCRUD";

interface AddCourseDialogProps {
  open: boolean;
  handleClose: () => void;
}

const initialData = {
  typeName: "Course",
  student: "",
  title: "",
  description: "",
};

const AddCourseDialog: React.FC<AddCourseDialogProps> = ({
  open,
  handleClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = React.useState<any>(initialData);
  const handleUserChange = (user: string) => {
    setData({ ...data, student: user });
  };
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, title: event.target.value });
  };
  const handleDesChange = (_e: any, editor: any) => {
    setData({ ...data, description: editor.getData() });
  };
  const onSubmit = async () => {
    if (isObjFieldFull(data)) {
      try {
        await createCourse(data);
        enqueueSnackbar(`${data.title} created successfully.`, {
          variant: "success",
        });
        handleClose();
      } catch (error) {
        console.log("course creation error", error);
        enqueueSnackbar("connection error, please try again later", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("You are missing fields.", { variant: "warning" });
    }
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle>Create New Course</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid item>
            <UserList
              handleUserChange={handleUserChange}
              value={data.student}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Title"
              value={data.title}
              onChange={handleTitleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <CKeditor
              handleOnChange={handleDesChange}
              data={data.description ?? "type here...."}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary" variant="outlined">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCourseDialog;
