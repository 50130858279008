import RenderReading from "components/Practice/reading/RenderReading";
import * as React from "react";
import useQuery from "hooks/useQuery";
import { useHistory } from "react-router-dom";
import { useAsync } from "react-async-hook";
import Spinner from "components/Spinner";
import { Typography } from "@material-ui/core";
import MainWrapper from "layout/MainWrapper";
import { fetchReading } from "utilities/awsCRUD";
import useHomework from "hooks/useHomework";

interface ReadingDetailViewProps {}

const ReadingDetailView: React.FC<ReadingDetailViewProps> = () => {
  const { onSubmit, submittedAnswers, title, time } = useHomework();
  const history = useHistory();
  let query = useQuery();
  const id = query.get("id");
  const typeName = query.get("typeName");
  React.useEffect(() => {
    if (!id || !typeName) {
      history.push("/practice");
    }
  }, [id, typeName, history]);
  const readingPractice = useAsync(fetchReading, [id, typeName]);
  return (
    <>
      {readingPractice.loading && <Spinner />}
      {readingPractice.error && (
        <MainWrapper>
          <Typography variant="h5" color="error">
            Error: {readingPractice.error.message}
          </Typography>
        </MainWrapper>
      )}
      {readingPractice.result && (
        <RenderReading
          practice={readingPractice.result}
          title={title ? `Reading ${title}` : "Reading Practice"}
          onSubmit={onSubmit}
          time={time}
          submittedAnswers={submittedAnswers}
        />
      )}
    </>
  );
};

export default ReadingDetailView;
