/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      title
      typeName
      student
      description
      content
      createdAt
      updatedAt
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        typeName
        student
        description
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      typeName
      student
      start
      end
      createdAt
      updatedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        typeName
        student
        start
        end
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExamModel = /* GraphQL */ `
  query GetExamModel($id: ID!) {
    getExamModel(id: $id) {
      id
      name
      typeName
      instructions
      listening {
        id
        typeName
        time
      }
      reading {
        id
        typeName
        time
      }
      writing {
        task1ID
        task2ID
        time
      }
      speaking {
        part1
        part2 {
          id
          prompt
        }
        time
      }
      createdAt
      updatedAt
    }
  }
`;
export const listExamModels = /* GraphQL */ `
  query ListExamModels(
    $filter: ModelExamModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExamModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        typeName
        instructions
        listening {
          id
          typeName
          time
        }
        reading {
          id
          typeName
          time
        }
        writing {
          task1ID
          task2ID
          time
        }
        speaking {
          part1
          part2 {
            id
            prompt
          }
          time
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExam = /* GraphQL */ `
  query GetExam($id: ID!) {
    getExam(id: $id) {
      id
      name
      examModelID
      student
      dueDate
      submittedDate
      submittedAnswers
      result
      status
      createdAt
      updatedAt
    }
  }
`;
export const listExams = /* GraphQL */ `
  query ListExams(
    $filter: ModelExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        examModelID
        student
        dueDate
        submittedDate
        submittedAnswers
        result
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNote = /* GraphQL */ `
  query GetNote($id: ID!) {
    getNote(id: $id) {
      id
      title
      description
      student
      practiceList {
        id
        typeName
        name
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        student
        practiceList {
          id
          typeName
          name
          type
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHomework = /* GraphQL */ `
  query GetHomework($id: ID!) {
    getHomework(id: $id) {
      id
      title
      description
      student
      dueDate
      practiceList {
        id
        typeName
        type
        name
        timeLimit
        startTime
        endTime
        submittedAnswers
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const listHomeworks = /* GraphQL */ `
  query ListHomeworks(
    $filter: ModelHomeworkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHomeworks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        student
        dueDate
        practiceList {
          id
          typeName
          type
          name
          timeLimit
          startTime
          endTime
          submittedAnswers
        }
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSpeakingList = /* GraphQL */ `
  query GetSpeakingList($id: ID!) {
    getSpeakingList(id: $id) {
      id
      typeName
      name
      type
      questions {
        id
        typeName
        question
        partOneTopicID
        partTwoID
        type
        season
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSpeakingLists = /* GraphQL */ `
  query ListSpeakingLists(
    $filter: ModelSpeakingListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpeakingLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeName
        name
        type
        questions {
          id
          typeName
          question
          partOneTopicID
          partTwoID
          type
          season
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBook = /* GraphQL */ `
  query GetBook($id: ID!) {
    getBook(id: $id) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const listBooks = /* GraphQL */ `
  query ListBooks(
    $filter: ModelBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getListeningTest = /* GraphQL */ `
  query GetListeningTest($id: ID!) {
    getListeningTest(id: $id) {
      id
      typeName
      book
      test
      s1ID
      s1 {
        id
        typeName
        book
        sectionNumber
        types
        questions {
          items {
            id
            typeName
            name
            book
            sectionNumber
            sectionID
            type
            start
            end
            range
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s2ID
      s2 {
        id
        typeName
        book
        sectionNumber
        types
        questions {
          items {
            id
            typeName
            name
            book
            sectionNumber
            sectionID
            type
            start
            end
            range
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s3ID
      s3 {
        id
        typeName
        book
        sectionNumber
        types
        questions {
          items {
            id
            typeName
            name
            book
            sectionNumber
            sectionID
            type
            start
            end
            range
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s4ID
      s4 {
        id
        typeName
        book
        sectionNumber
        types
        questions {
          items {
            id
            typeName
            name
            book
            sectionNumber
            sectionID
            type
            start
            end
            range
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listListeningTests = /* GraphQL */ `
  query ListListeningTests(
    $filter: ModelListeningTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listListeningTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeName
        book
        test
        s1ID
        s1 {
          id
          typeName
          book
          sectionNumber
          types
          questions {
            items {
              id
              typeName
              name
              book
              sectionNumber
              sectionID
              type
              start
              end
              range
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        s2ID
        s2 {
          id
          typeName
          book
          sectionNumber
          types
          questions {
            items {
              id
              typeName
              name
              book
              sectionNumber
              sectionID
              type
              start
              end
              range
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        s3ID
        s3 {
          id
          typeName
          book
          sectionNumber
          types
          questions {
            items {
              id
              typeName
              name
              book
              sectionNumber
              sectionID
              type
              start
              end
              range
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        s4ID
        s4 {
          id
          typeName
          book
          sectionNumber
          types
          questions {
            items {
              id
              typeName
              name
              book
              sectionNumber
              sectionID
              type
              start
              end
              range
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getListeningSection = /* GraphQL */ `
  query GetListeningSection($id: ID!) {
    getListeningSection(id: $id) {
      id
      typeName
      book
      sectionNumber
      types
      questions {
        items {
          id
          typeName
          name
          book
          sectionNumber
          sectionID
          type
          start
          end
          range
          questionBody
          answers
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listListeningSections = /* GraphQL */ `
  query ListListeningSections(
    $filter: ModelListeningSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listListeningSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeName
        book
        sectionNumber
        types
        questions {
          items {
            id
            typeName
            name
            book
            sectionNumber
            sectionID
            type
            start
            end
            range
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getListeningQuestion = /* GraphQL */ `
  query GetListeningQuestion($id: ID!) {
    getListeningQuestion(id: $id) {
      id
      typeName
      name
      book
      sectionNumber
      sectionID
      type
      start
      end
      range
      questionBody
      answers
      createdAt
      updatedAt
    }
  }
`;
export const listListeningQuestions = /* GraphQL */ `
  query ListListeningQuestions(
    $filter: ModelListeningQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listListeningQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeName
        name
        book
        sectionNumber
        sectionID
        type
        start
        end
        range
        questionBody
        answers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReadingTestA = /* GraphQL */ `
  query GetReadingTestA($id: ID!) {
    getReadingTestA(id: $id) {
      id
      typeName
      book
      test
      genre
      p1ID
      p1 {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      p2ID
      p2 {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      p3ID
      p3 {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listReadingTestAs = /* GraphQL */ `
  query ListReadingTestAs(
    $filter: ModelReadingTestAFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReadingTestAs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeName
        book
        test
        genre
        p1ID
        p1 {
          id
          typeName
          book
          testID
          genre
          types
          passageNumber
          passage
          questions {
            items {
              id
              typeName
              name
              book
              genre
              type
              range
              passageNumber
              passageID
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        p2ID
        p2 {
          id
          typeName
          book
          testID
          genre
          types
          passageNumber
          passage
          questions {
            items {
              id
              typeName
              name
              book
              genre
              type
              range
              passageNumber
              passageID
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        p3ID
        p3 {
          id
          typeName
          book
          testID
          genre
          types
          passageNumber
          passage
          questions {
            items {
              id
              typeName
              name
              book
              genre
              type
              range
              passageNumber
              passageID
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReadingTestG = /* GraphQL */ `
  query GetReadingTestG($id: ID!) {
    getReadingTestG(id: $id) {
      id
      typeName
      genre
      book
      test
      s1aID
      s1a {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s1bID
      s1b {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s2aID
      s2a {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s2bID
      s2b {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s3ID
      s3 {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listReadingTestGs = /* GraphQL */ `
  query ListReadingTestGs(
    $filter: ModelReadingTestGFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReadingTestGs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeName
        genre
        book
        test
        s1aID
        s1a {
          id
          typeName
          book
          testID
          genre
          types
          passageNumber
          passage
          questions {
            items {
              id
              typeName
              name
              book
              genre
              type
              range
              passageNumber
              passageID
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        s1bID
        s1b {
          id
          typeName
          book
          testID
          genre
          types
          passageNumber
          passage
          questions {
            items {
              id
              typeName
              name
              book
              genre
              type
              range
              passageNumber
              passageID
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        s2aID
        s2a {
          id
          typeName
          book
          testID
          genre
          types
          passageNumber
          passage
          questions {
            items {
              id
              typeName
              name
              book
              genre
              type
              range
              passageNumber
              passageID
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        s2bID
        s2b {
          id
          typeName
          book
          testID
          genre
          types
          passageNumber
          passage
          questions {
            items {
              id
              typeName
              name
              book
              genre
              type
              range
              passageNumber
              passageID
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        s3ID
        s3 {
          id
          typeName
          book
          testID
          genre
          types
          passageNumber
          passage
          questions {
            items {
              id
              typeName
              name
              book
              genre
              type
              range
              passageNumber
              passageID
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReadingPassage = /* GraphQL */ `
  query GetReadingPassage($id: ID!) {
    getReadingPassage(id: $id) {
      id
      typeName
      book
      testID
      genre
      types
      passageNumber
      passage
      questions {
        items {
          id
          typeName
          name
          book
          genre
          type
          range
          passageNumber
          passageID
          passage {
            id
            typeName
            book
            testID
            genre
            types
            passageNumber
            passage
            questions {
              nextToken
            }
            createdAt
            updatedAt
          }
          questionBody
          answers
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listReadingPassages = /* GraphQL */ `
  query ListReadingPassages(
    $filter: ModelReadingPassageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReadingPassages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReadingQuestion = /* GraphQL */ `
  query GetReadingQuestion($id: ID!) {
    getReadingQuestion(id: $id) {
      id
      typeName
      name
      book
      genre
      type
      range
      passageNumber
      passageID
      passage {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      questionBody
      answers
      createdAt
      updatedAt
    }
  }
`;
export const listReadingQuestions = /* GraphQL */ `
  query ListReadingQuestions(
    $filter: ModelReadingQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReadingQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeName
        name
        book
        genre
        type
        range
        passageNumber
        passageID
        passage {
          id
          typeName
          book
          testID
          genre
          types
          passageNumber
          passage
          questions {
            items {
              id
              typeName
              name
              book
              genre
              type
              range
              passageNumber
              passageID
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        questionBody
        answers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEssay = /* GraphQL */ `
  query GetEssay($id: String!) {
    getEssay(id: $id) {
      id
      typeName
      book
      genre
      type
      topic
      prompt
      sampleAnswer
      createdAt
      updatedAt
    }
  }
`;
export const listEssays = /* GraphQL */ `
  query ListEssays(
    $id: String
    $filter: ModelEssayFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEssays(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        typeName
        book
        genre
        type
        topic
        prompt
        sampleAnswer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGraph = /* GraphQL */ `
  query GetGraph($id: String!) {
    getGraph(id: $id) {
      id
      typeName
      book
      type
      prompt
      img
      sampleAnswer
      createdAt
      updatedAt
    }
  }
`;
export const listGraphs = /* GraphQL */ `
  query ListGraphs(
    $id: String
    $filter: ModelGraphFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGraphs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        typeName
        book
        type
        prompt
        img
        sampleAnswer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLetter = /* GraphQL */ `
  query GetLetter($id: String!) {
    getLetter(id: $id) {
      id
      typeName
      book
      type
      aim
      prompt
      sampleAnswer
      createdAt
      updatedAt
    }
  }
`;
export const listLetters = /* GraphQL */ `
  query ListLetters(
    $id: String
    $filter: ModelLetterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLetters(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        typeName
        book
        type
        aim
        prompt
        sampleAnswer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPartOneTopic = /* GraphQL */ `
  query GetPartOneTopic($id: ID!) {
    getPartOneTopic(id: $id) {
      id
      typeName
      season
      questions {
        items {
          id
          typeName
          question
          partOneTopicID
          type
          season
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPartOneTopics = /* GraphQL */ `
  query ListPartOneTopics(
    $filter: ModelPartOneTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartOneTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeName
        season
        questions {
          items {
            id
            typeName
            question
            partOneTopicID
            type
            season
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPartOne = /* GraphQL */ `
  query GetPartOne($id: ID!) {
    getPartOne(id: $id) {
      id
      typeName
      question
      partOneTopicID
      type
      season
      createdAt
      updatedAt
    }
  }
`;
export const listPartOnes = /* GraphQL */ `
  query ListPartOnes(
    $filter: ModelPartOneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartOnes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeName
        question
        partOneTopicID
        type
        season
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPartTwo = /* GraphQL */ `
  query GetPartTwo($id: ID!) {
    getPartTwo(id: $id) {
      id
      typeName
      prompt
      subQuestions
      season
      type
      partThreeQuestions {
        items {
          id
          typeName
          question
          partTwoID
          type
          season
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPartTwos = /* GraphQL */ `
  query ListPartTwos(
    $filter: ModelPartTwoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartTwos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeName
        prompt
        subQuestions
        season
        type
        partThreeQuestions {
          items {
            id
            typeName
            question
            partTwoID
            type
            season
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPartThree = /* GraphQL */ `
  query GetPartThree($id: ID!) {
    getPartThree(id: $id) {
      id
      typeName
      question
      partTwoID
      type
      season
      createdAt
      updatedAt
    }
  }
`;
export const listPartThrees = /* GraphQL */ `
  query ListPartThrees(
    $filter: ModelPartThreeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartThrees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeName
        question
        partTwoID
        type
        season
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSpeakingRecord = /* GraphQL */ `
  query GetSpeakingRecord($id: ID!) {
    getSpeakingRecord(id: $id) {
      id
      user
      typeName
      type
      typeID
      url
      createdAt
      updatedAt
    }
  }
`;
export const listSpeakingRecords = /* GraphQL */ `
  query ListSpeakingRecords(
    $filter: ModelSpeakingRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpeakingRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        typeName
        type
        typeID
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomTest = /* GraphQL */ `
  query GetCustomTest($id: ID!) {
    getCustomTest(id: $id) {
      id
      typeName
      questions {
        typeName
        type
        range
        questionBody
        answers
      }
      answers
      createdAt
      updatedAt
    }
  }
`;
export const listCustomTests = /* GraphQL */ `
  query ListCustomTests(
    $filter: ModelCustomTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeName
        questions {
          typeName
          type
          range
          questionBody
          answers
        }
        answers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      groups
      picture
      targetScores
      note
      examDate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        email
        groups
        picture
        targetScores
        note
        examDate
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTracking = /* GraphQL */ `
  query GetTracking($id: ID!) {
    getTracking(id: $id) {
      id
      userID
      type
      user {
        id
        username
        email
        groups
        picture
        targetScores
        note
        examDate
        createdAt
        updatedAt
        owner
      }
      action
      createdAt
      updatedAt
    }
  }
`;
export const listTrackings = /* GraphQL */ `
  query ListTrackings(
    $filter: ModelTrackingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrackings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        type
        user {
          id
          username
          email
          groups
          picture
          targetScores
          note
          examDate
          createdAt
          updatedAt
          owner
        }
        action
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCoursesGist = /* GraphQL */ `
  query ListCoursesGist(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoursesGist(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        typeName
        student
        description
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listLt = /* GraphQL */ `
  query ListLt(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelListeningTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLT(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeName
        book
        test
        s1ID
        s1 {
          id
          typeName
          book
          sectionNumber
          types
          questions {
            items {
              id
              typeName
              name
              book
              sectionNumber
              sectionID
              type
              start
              end
              range
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        s2ID
        s2 {
          id
          typeName
          book
          sectionNumber
          types
          questions {
            items {
              id
              typeName
              name
              book
              sectionNumber
              sectionID
              type
              start
              end
              range
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        s3ID
        s3 {
          id
          typeName
          book
          sectionNumber
          types
          questions {
            items {
              id
              typeName
              name
              book
              sectionNumber
              sectionID
              type
              start
              end
              range
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        s4ID
        s4 {
          id
          typeName
          book
          sectionNumber
          types
          questions {
            items {
              id
              typeName
              name
              book
              sectionNumber
              sectionID
              type
              start
              end
              range
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listLs = /* GraphQL */ `
  query ListLs(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelListeningSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLS(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeName
        book
        sectionNumber
        types
        questions {
          items {
            id
            typeName
            name
            book
            sectionNumber
            sectionID
            type
            start
            end
            range
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listLq = /* GraphQL */ `
  query ListLq(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelListeningQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLQ(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeName
        name
        book
        sectionNumber
        sectionID
        type
        start
        end
        range
        questionBody
        answers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bySectionId = /* GraphQL */ `
  query BySectionId(
    $sectionID: String
    $sortDirection: ModelSortDirection
    $filter: ModelListeningQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bySectionID(
      sectionID: $sectionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeName
        name
        book
        sectionNumber
        sectionID
        type
        start
        end
        range
        questionBody
        answers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listRta = /* GraphQL */ `
  query ListRta(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelReadingTestAFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRTA(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeName
        book
        test
        genre
        p1ID
        p1 {
          id
          typeName
          book
          testID
          genre
          types
          passageNumber
          passage
          questions {
            items {
              id
              typeName
              name
              book
              genre
              type
              range
              passageNumber
              passageID
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        p2ID
        p2 {
          id
          typeName
          book
          testID
          genre
          types
          passageNumber
          passage
          questions {
            items {
              id
              typeName
              name
              book
              genre
              type
              range
              passageNumber
              passageID
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        p3ID
        p3 {
          id
          typeName
          book
          testID
          genre
          types
          passageNumber
          passage
          questions {
            items {
              id
              typeName
              name
              book
              genre
              type
              range
              passageNumber
              passageID
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listRtg = /* GraphQL */ `
  query ListRtg(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelReadingTestGFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRTG(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeName
        genre
        book
        test
        s1aID
        s1a {
          id
          typeName
          book
          testID
          genre
          types
          passageNumber
          passage
          questions {
            items {
              id
              typeName
              name
              book
              genre
              type
              range
              passageNumber
              passageID
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        s1bID
        s1b {
          id
          typeName
          book
          testID
          genre
          types
          passageNumber
          passage
          questions {
            items {
              id
              typeName
              name
              book
              genre
              type
              range
              passageNumber
              passageID
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        s2aID
        s2a {
          id
          typeName
          book
          testID
          genre
          types
          passageNumber
          passage
          questions {
            items {
              id
              typeName
              name
              book
              genre
              type
              range
              passageNumber
              passageID
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        s2bID
        s2b {
          id
          typeName
          book
          testID
          genre
          types
          passageNumber
          passage
          questions {
            items {
              id
              typeName
              name
              book
              genre
              type
              range
              passageNumber
              passageID
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        s3ID
        s3 {
          id
          typeName
          book
          testID
          genre
          types
          passageNumber
          passage
          questions {
            items {
              id
              typeName
              name
              book
              genre
              type
              range
              passageNumber
              passageID
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listRp = /* GraphQL */ `
  query ListRp(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelReadingPassageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRP(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listRq = /* GraphQL */ `
  query ListRq(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelReadingQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRQ(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeName
        name
        book
        genre
        type
        range
        passageNumber
        passageID
        passage {
          id
          typeName
          book
          testID
          genre
          types
          passageNumber
          passage
          questions {
            items {
              id
              typeName
              name
              book
              genre
              type
              range
              passageNumber
              passageID
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        questionBody
        answers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byPassageId = /* GraphQL */ `
  query ByPassageId(
    $passageID: String
    $sortDirection: ModelSortDirection
    $filter: ModelReadingQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byPassageID(
      passageID: $passageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeName
        name
        book
        genre
        type
        range
        passageNumber
        passageID
        passage {
          id
          typeName
          book
          testID
          genre
          types
          passageNumber
          passage
          questions {
            items {
              id
              typeName
              name
              book
              genre
              type
              range
              passageNumber
              passageID
              questionBody
              answers
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        questionBody
        answers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listP1topics = /* GraphQL */ `
  query ListP1topics(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelPartOneTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listP1topics(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeName
        season
        questions {
          items {
            id
            typeName
            question
            partOneTopicID
            type
            season
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byPartOneTopicId = /* GraphQL */ `
  query ByPartOneTopicId(
    $partOneTopicID: String
    $sortDirection: ModelSortDirection
    $filter: ModelPartOneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byPartOneTopicID(
      partOneTopicID: $partOneTopicID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeName
        question
        partOneTopicID
        type
        season
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listP2 = /* GraphQL */ `
  query ListP2(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelPartTwoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listP2(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeName
        prompt
        subQuestions
        season
        type
        partThreeQuestions {
          items {
            id
            typeName
            question
            partTwoID
            type
            season
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byPartTwoId = /* GraphQL */ `
  query ByPartTwoId(
    $partTwoID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPartThreeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byPartTwoID(
      partTwoID: $partTwoID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeName
        question
        partTwoID
        type
        season
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byUserTypeTypeId = /* GraphQL */ `
  query ByUserTypeTypeId(
    $user: String
    $typeTypeID: ModelSpeakingRecordByUserTypeTypeIDCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpeakingRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byUserTypeTypeID(
      user: $user
      typeTypeID: $typeTypeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        typeName
        type
        typeID
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTrackingsByCreatedAt = /* GraphQL */ `
  query GetTrackingsByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTrackingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTrackingsByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        type
        user {
          id
          username
          email
          groups
          picture
          targetScores
          note
          examDate
          createdAt
          updatedAt
          owner
        }
        action
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
