import * as React from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import useLocalImmer from "hooks/useLocalImmer";
import CardLayout from "layout/CardLayout";
import HomeworkForm from "components/Assignment/HomeworkForm";
import useAmplifyMutation from "hooks/useAmplifyMutation";
import { isObjFieldFull } from "utilities/validation";
import * as mutations from "graphql/mutations";

const initialHwData = {
  title: "",
  description: "",
  student: "",
  dueDate: new Date().toISOString(),
  practiceList: [],
} as any;

export interface HomeworkCreationViewProps {}

const HomeworkCreationView: React.FC<HomeworkCreationViewProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [hwData, updateHwData] = useLocalImmer("hwData", initialHwData);
  const { status, execute } = useAmplifyMutation();
  const history = useHistory();

  const onCancel = () => {
    window.localStorage.removeItem("hwData");
    history.goBack();
  };

  const onPreview = () => {
    if (isObjFieldFull(hwData)) {
      history.push({ pathname: "/homework/preview", state: { hwData } });
    } else {
      enqueueSnackbar("you are missing fields", { variant: "warning" });
    }
  };

  const onSubmit = async () => {
    if (isObjFieldFull(hwData)) {
      try {
        await execute({
          query: mutations.createHomework,
          variables: { input: hwData },
        });
        enqueueSnackbar("Successfully created Homework", {
          variant: "success",
        });
        onCancel();
      } catch (error) {
        enqueueSnackbar("Connection error, please try again later", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("you are missing fields", { variant: "warning" });
    }
  };
  return (
    <CardLayout
      loading={status === "pending"}
      title="Create Homework"
      mode="create"
      onSubmit={onSubmit}
      onPreview={onPreview}
      onCancel={onCancel}
    >
      <HomeworkForm hwData={hwData} updateHwData={updateHwData} />
    </CardLayout>
  );
};

export default HomeworkCreationView;
