import * as React from "react";
import { Grid } from "@material-ui/core";
import MultiSelect from "components/MultiSelect";
import SpeakingQuestionList from "./SpeakingQuestionList";
import config from "customConfig";
import TopicDropDown from "./PartOneTopicDropDown";

interface PartOneTopicFormProps {
  speaking: any;
  updateSpeaking: (data: any) => void;
}

const PartOneTopicForm: React.FC<PartOneTopicFormProps> = ({
  speaking,
  updateSpeaking,
}) => {
  const part1Topic = speaking.id ? speaking : null;
  const setPart1TopicSeason = (season: string[]) => {
    updateSpeaking((draft: any) => {
      draft.season = season;
    });
  };
  const setQuestions = (questions: any) => {
    updateSpeaking((draft: any) => {
      draft.questions = questions;
    });
  };
  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item container justify="space-between" alignItems="center">
        <Grid item>
          <TopicDropDown speaking={speaking} updateSpeaking={updateSpeaking} />
        </Grid>
        <Grid item>
          {part1Topic && (
            <MultiSelect
              options={config.seasonOptions}
              label="Season"
              value={part1Topic.season}
              setValue={setPart1TopicSeason}
            />
          )}
        </Grid>
      </Grid>
      {part1Topic && (
        <Grid item>
          <SpeakingQuestionList
            part="1"
            setQuestions={setQuestions}
            questions={part1Topic.questions}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default PartOneTopicForm;
