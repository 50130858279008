import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { API } from "aws-amplify";
import * as queries from "graphql/queries";
import { formatRelative } from "date-fns";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: "100%",
    },
    actionButton: {
      marginLeft: "auto",
    },
  })
);

export interface ActivitiesProps {}

const LIMIT = 10;

const Activities: React.FC<ActivitiesProps> = () => {
  const classes = useStyles();
  const [events, setEvents] = React.useState([]);
  const [nextToken, setNextToken] = React.useState(undefined);
  const [nextNextToken, setNextNextToken] = React.useState();
  const [previousTokens, setPreviousTokens] = React.useState([]);

  React.useEffect(() => {
    const getTrackingData = async () => {
      try {
        const result: any = await API.graphql({
          query: queries.getTrackingsByCreatedAt,
          variables: {
            type: "Trackings",
            sortDirection: "DESC",
            limit: LIMIT,
            nextToken: nextToken,
          },
        });
        setNextNextToken(result.data.getTrackingsByCreatedAt.nextToken);
        setEvents(result.data.getTrackingsByCreatedAt.items);
      } catch (error) {
        console.log("list trackings error", error);
      }
    };
    getTrackingData();
  }, [nextToken]);
  const handleNextPage = () => {
    setPreviousTokens((prev) => [...prev, nextToken]);
    setNextToken(nextNextToken);
    setNextNextToken(null);
  };
  const handlePreviousPage = () => {
    setNextToken(previousTokens.pop());
    setPreviousTokens([...previousTokens]);
    setNextNextToken(null);
  };
  const hasNext = !!nextNextToken;
  const hasPrev = previousTokens.length;

  return (
    <Card className={classes.root}>
      <CardHeader title="用户活动记录" />
      <CardContent>
        {events &&
          events.map((item: any) => (
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              key={item.id}
            >
              {item.user.username} {item.action}{" "}
              {formatRelative(new Date(item.createdAt), new Date())}
            </Typography>
          ))}
      </CardContent>
      <CardActions disableSpacing>
        {!!hasPrev && (
          <IconButton
            aria-label="add to favorites"
            className={classes.actionButton}
            onClick={handlePreviousPage}
          >
            <ChevronLeftIcon />
          </IconButton>
        )}

        {hasNext && (
          <IconButton aria-label="share" onClick={handleNextPage}>
            <ChevronRightIcon />
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
};

export default Activities;
