import * as React from "react";
import HomeworkTable from "components/Assignment/HomeworkTable";

export interface HomeworkListViewProps {}

const HomeworkListView: React.FC<HomeworkListViewProps> = () => {
  return <HomeworkTable />;
};

export default HomeworkListView;
