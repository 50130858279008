import * as React from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  makeStyles,
  Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(0),
  },
}));

export interface RadioSelectorProps {
  value: string;
  options: { value: string; label: string }[];
  name: string;
  setValue: (genre: string) => void;
  isEdit?: boolean;
}

const RadioSelector: React.FC<RadioSelectorProps> = ({
  value,
  setValue,
  isEdit,
  name,
  options,
}) => {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  return (
    <FormControl
      component="fieldset"
      disabled={isEdit}
      className={classes.root}
    >
      <FormLabel component="legend">{name}</FormLabel>
      <RadioGroup
        aria-label={name}
        row
        name={name}
        value={value ?? ""}
        onChange={handleChange}
      >
        {options.map(({ value, label }) => (
          <FormControlLabel
            value={value}
            control={<Radio />}
            label={label}
            key={label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

RadioSelector.defaultProps = {
  isEdit: false,
};

export default RadioSelector;
