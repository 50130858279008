import * as React from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import MainWrapper from "layout/MainWrapper";
import CardLayout from "layout/CardLayout";
import WritingForm from "components/Practice/writing/WritingForm";
import { useSnackbar } from "notistack";
import { useAsync, useAsyncCallback } from "react-async-hook";
import {
  deleteWritingTask,
  fetchWritingTask,
  updateWritingTask,
} from "utilities/awsCRUD";
import LinearProgress from "@material-ui/core/LinearProgress";
import useLocalImmer from "hooks/useLocalImmer";

interface WritingEditionViewProps {}

const WritingEditionView: React.FC<WritingEditionViewProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { state } = useLocation() as any;
  const id = state?.id;
  const typeName = state?.typeName;
  const asyncEdition = useAsync(fetchWritingTask, [id, typeName]);
  const asyncDeletion = useAsyncCallback(
    async () => await deleteWritingTask(asyncEdition.result)
  );

  const asyncUpdate = useAsyncCallback(
    async () => await updateWritingTask(writing)
  );
  const [writing, updateWriting] = useLocalImmer(
    "editWriting",
    asyncEdition.result
  );
  React.useEffect(() => {
    if (asyncEdition.result && !writing) {
      updateWriting(asyncEdition.result);
    }
    if (asyncDeletion.result) {
      enqueueSnackbar("successfully deleted writing test", {
        variant: "success",
      });
      onCancel();
    }
    if (asyncUpdate.result) {
      enqueueSnackbar("succesfully updated writing test", {
        variant: "success",
      });
    }
    //eslint-disable-next-line
  }, [asyncEdition.result, asyncDeletion.result, asyncUpdate.result]);
  const onPreview = () => {
    console.log("onPreview");
    enqueueSnackbar("onPreview", { variant: "success" });
  };
  const onUpdate = () => {
    asyncUpdate.execute();
  };
  const onDelete = () => {
    asyncDeletion.execute();
  };
  const onCancel = () => {
    window.localStorage.removeItem("editWriting");
    history.goBack();
  };
  const loading =
    asyncEdition.loading || asyncDeletion.loading || asyncUpdate.loading;
  const errorMessage =
    asyncEdition?.error?.message ||
    asyncDeletion?.error?.message ||
    asyncUpdate?.error?.message;
  console.log("writing", writing);
  if (!state) return <Redirect to="/practice" />;
  return (
    <MainWrapper>
      <CardLayout
        title="Writing Edition"
        mode="edit"
        loading={asyncEdition.loading}
        onPreview={onPreview}
        onUpdate={onUpdate}
        onDelete={onDelete}
        onCancel={onCancel}
      >
        {loading && <LinearProgress />}
        {errorMessage && enqueueSnackbar(errorMessage, { variant: "error" })}
        {writing && (
          <WritingForm
            writing={writing}
            updateWriting={updateWriting}
            isEdit={true}
          />
        )}
      </CardLayout>
    </MainWrapper>
  );
};

export default WritingEditionView;
