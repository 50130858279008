import * as React from "react";
import config from "customConfig";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Button,
  makeStyles,
  createStyles,
  Typography,
  Theme,
  Avatar,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useCourseValue } from "context/course.context";
import { useAuthValue } from "context/auth.context";
import parse from "html-react-parser";
import CourseIntroForm from "./CourseIntroForm";
import UserAvatar from "components/UserAvatar";
import EventIcon from "@material-ui/icons/Event";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    avatar: {
      backgroundColor: theme.palette.secondary.main,
    },
    main: {
      display: "flex",
    },
    img: {
      width: 200,
    },
    left: { display: "flex", alignItems: "flex-start", padding: "16px" },
    right: {
      flexGrow: 1,
    },
    actions: {
      flexDirection: "row-reverse",
    },
    button: {
      margin: theme.spacing(1),
    },
  })
);

interface CourseIntroProps {}

const CourseIntro: React.FC<CourseIntroProps> = () => {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const { course, updateCourse } = useCourseValue();
  const { user } = useAuthValue();
  const isAdmin = user.groups?.includes("admin");
  const onCancel = () => {
    setOpen(false);
  };
  const onSubmit = (intro: any) => {
    updateCourse((draft: any) => {
      draft.title = intro.title;
      draft.description = intro.description;
    });
  };
  if (open)
    return (
      <CourseIntroForm
        intro={{ title: course.title, description: course.description }}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    );
  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          user.username === course.student ? (
            <UserAvatar user={user} />
          ) : (
            <Avatar className={classes.avatar}>
              {course.student[0].toUpperCase()}
            </Avatar>
          )
        }
        title={
          <Typography variant="h5">
            <b>{course.title}</b>
          </Typography>
        }
        action={
          isAdmin && (
            <IconButton aria-label="edit" onClick={() => setOpen(true)}>
              <EditIcon />
            </IconButton>
          )
        }
      />
      <div className={classes.main}>
        <div className={classes.left}>
          <img
            src={`${config.awsUrl}resource/img/1on1course.png`}
            alt="Boxwhy IELTS 1on1 course"
            className={classes.img}
          />
        </div>
        <div className={classes.right}>
          <CardContent>
            <Typography component="div" variant="h6">
              {parse(course.description)}
            </Typography>
          </CardContent>
        </div>
      </div>
      <CardActions className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<EventIcon />}
          onClick={() => history.push("/calendar")}
        >
          My Calendar
        </Button>
      </CardActions>
    </Card>
  );
};

export default CourseIntro;
