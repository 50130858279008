import * as React from "react";
import { Card } from "@material-ui/core";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import "react-big-calendar/lib/css/react-big-calendar.css";
import EventDialog from "./EventDialog";
import CreateEventDialog from "./CreateEventDialog";
import { useAuthValue } from "context/auth.context";
import { fetchEvents } from "utilities/awsCRUD";

interface MyCalendarProps {
  height?: string | number;
}

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const MyCalendar: React.FC<MyCalendarProps> = ({ height }) => {
  const { user } = useAuthValue();
  const isAdmin = user.groups.includes("admin");
  const [eventList, setEventList] = React.useState<any[]>([]);
  const [selectedEvent, setSelectedEvent] = React.useState<any>(null);
  const [openEvent, setOpenEvent] = React.useState<boolean>(false);
  const [openCreateEvent, setOpenCreateEvent] = React.useState<boolean>(false);
  const [createEventData, setCreateEventData] = React.useState<any>(null);
  const handleSelectEvent = (event: any) => {
    setSelectedEvent(event);
    setOpenEvent(true);
  };

  const handleSelect = (data: any) => {
    setCreateEventData(data);
    setOpenCreateEvent(true);
  };
  const addEventToList = (event: any) => {
    setEventList([...eventList, event]);
  };
  const deleteEvent = (id: string) => {
    const newList = eventList.filter((event: any) => event.id !== id);
    setEventList(newList);
  };
  React.useEffect(() => {
    (async () => {
      try {
        const events = await fetchEvents();
        if (events.length > 0) {
          setEventList(events);
        }
      } catch (error) {
        console.log("fetch events error", error);
      }
    })();
  }, []);
  return (
    <Card>
      <Calendar
        selectable={isAdmin}
        localizer={localizer}
        events={eventList}
        titleAccessor={(event: any) => `${event.student}'s ${event.title}`}
        tooltipAccessor={(event: any) => `${event.student}'s ${event.title}`}
        startAccessor="start"
        endAccessor="end"
        style={{ height }}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelect}
        views={isAdmin ? ["month", "day"] : ["month"]}
      />
      {openEvent && (
        <EventDialog
          open={openEvent}
          handleClose={() => setOpenEvent(false)}
          event={selectedEvent}
          handleDeleteEvent={deleteEvent}
        />
      )}
      {openCreateEvent && (
        <CreateEventDialog
          addEventToList={addEventToList}
          open={openCreateEvent}
          handleClose={() => setOpenCreateEvent(false)}
          data={createEventData}
        />
      )}
    </Card>
  );
};

export default MyCalendar;

MyCalendar.defaultProps = {
  height: "80vh",
};
