import * as React from "react";
import { useCourseValue } from "context/course.context";
import RenderCourseContent from "./RenderCourseContent";
import {
  Button,
  List,
  ListItem,
  makeStyles,
  createStyles,
  Theme,
  Card,
} from "@material-ui/core";
import { useAuthValue } from "context/auth.context";
import SortableContentList from "./SortableContentList";
import AddContentButton from "./AddContentButton";

interface ContentListProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

const ContentList: React.FC<ContentListProps> = () => {
  const { user } = useAuthValue();
  const classes = useStyles();
  const isAdmin = user.groups?.includes("admin");
  const { course, updateCourse } = useCourseValue();
  const [sortContent, setSortContent] = React.useState<any>(course.content);
  const [isSortable, setIsSortable] = React.useState<boolean>(false);
  const handleCompleteSort = () => {
    const normalizedSortContent = sortContent.map((content: any) => {
      delete content.chosen;
      delete content.selected;
      return content;
    });
    updateCourse({ ...course, content: normalizedSortContent });
    setIsSortable(false);
  };
  const sortButton = !isSortable ? (
    <Button onClick={() => setIsSortable(true)}>sort</Button>
  ) : (
    <Button onClick={handleCompleteSort}>complete sort</Button>
  );
  React.useEffect(() => {
    setSortContent(course.content);
  }, [course.content]);
  return (
    <Card>
      <List className={classes.root}>
        {isSortable ? (
          <SortableContentList
            sortContent={sortContent}
            setSortContent={setSortContent}
          />
        ) : (
          course.content.map((content: any) => (
            <RenderCourseContent content={content} key={content.id} />
          ))
        )}
        {isAdmin && (
          <ListItem>
            {sortButton}
            <AddContentButton />
          </ListItem>
        )}
      </List>
    </Card>
  );
};

export default ContentList;
