import { API } from "aws-amplify";
import * as mutations from "graphql/mutations";
import * as customQueries from "customQueries";
import * as queries from "graphql/queries";
import {
  normalizeLQ,
  normalizeLS,
  normalizeLT,
  normalizeRTA,
  normalizeRQ,
  normalizeRTG,
  normalizeRP,
  normalizeCustomTest,
  normalizeWritingTask,
} from "./nomalizeData";

export const createListeningTest = async (book: string, test: string) => {
  const id = `${book}-${test}`.toLowerCase();
  const s1ID = `${id}-s1`;
  const s2ID = `${id}-s2`;
  const s3ID = `${id}-s3`;
  const s4ID = `${id}-s4`;
  const input = {
    id,
    book,
    test,
    s1ID,
    s2ID,
    s3ID,
    s4ID,
    typeName: "ListeningTest",
  };
  await API.graphql({
    query: mutations.createListeningTest,
    variables: { input },
  });
};

export const createListeningTestNew = async (listeningTest: any) => {
  const { book, test, s1, s2, s3, s4 } = listeningTest;
  const sectionArray = [s1, s2, s3, s4];
  const testID = `${book}-${test}`;
  await API.graphql({
    query: mutations.createListeningTest,
    variables: {
      input: {
        id: testID,
        typeName: "ListeningTest",
        book,
        test,
        s1ID: `${testID}-s1`,
        s2ID: `${testID}-s2`,
        s3ID: `${testID}-s3`,
        s4ID: `${testID}-s4`,
      },
    },
  });
  sectionArray.forEach(async (section: any, index: number) => {
    const sectionNumber = (index + 1).toString();
    const sectionID = `${testID}-s${sectionNumber}`;
    const types = section.questions
      .map((question: any) => question.type)
      .join("&");
    await API.graphql({
      query: mutations.createListeningSection,
      variables: {
        input: {
          id: sectionID,
          book,
          sectionNumber,
          types,
          typeName: "ListeningSection",
        },
      },
    });
    section.questions.forEach(async (question: any) => {
      const { type, start, end, range, questionBody, answers } = question;
      const name = `${sectionID}-q${range}`;
      const input = {
        name,
        book,
        sectionNumber,
        type,
        sectionID,
        start,
        end,
        range,
        answers: JSON.stringify(answers),
        questionBody: JSON.stringify(questionBody),
        typeName: "ListeningQuestion",
      };
      await API.graphql({
        query: mutations.createListeningQuestion,
        variables: { input },
      });
    });
  });
  return "completed";
};

export const createListeningSection = async (
  book: string,
  test: string,
  sectionNumber: string,
  questions: any[]
) => {
  const id = `${book}-${test}-s${sectionNumber}`.toLowerCase();
  const types = questions.map((question) => question.type).join("&");
  const input = {
    id,
    book,
    sectionNumber,
    types,
    typeName: "ListeningSection",
  };
  await API.graphql({
    query: mutations.createListeningSection,
    variables: { input },
  });
};

export const createListeningQuestion = async (
  book: string,
  test: string,
  sectionNumber: string,
  question: any,
  enqueueSnackbar: any
) => {
  const sectionID = `${book}-${test}-s${sectionNumber}`.toLowerCase();
  const { type, start, end, range, questionBody, answers } = question;
  const name = `${sectionID}-q${range}`;
  const input = {
    name,
    book,
    sectionNumber,
    type,
    sectionID,
    start,
    end,
    range,
    answers: JSON.stringify(answers),
    questionBody: JSON.stringify(questionBody),
    typeName: "ListeningQuestion",
  };
  try {
    await API.graphql({
      query: mutations.createListeningQuestion,
      variables: { input },
    });
  } catch (error: any) {
    enqueueSnackbar(
      `fail to create listening question ${name}\n reason ${error.errors[0].message}`,
      { variant: "error" }
    );
    console.log(`${name} Error`, error);
    return;
  }
};

export const createWholeListeningTest = async (
  testInfo: any,
  sectionOne: any,
  sectionTwo: any,
  sectionThree: any,
  sectionFour: any,
  enqueueSnackbar: any
) => {
  const { book, testName } = testInfo;
  await createListeningTest(book, testName);
  await createListeningSection(book, testName, "1", sectionOne);
  await createListeningSection(book, testName, "2", sectionTwo);
  await createListeningSection(book, testName, "3", sectionThree);
  await createListeningSection(book, testName, "4", sectionFour);
  sectionOne.forEach(
    async (question: any) =>
      await createListeningQuestion(
        book,
        testName,
        "1",
        question,
        enqueueSnackbar
      )
  );
  sectionTwo.forEach(
    async (question: any) =>
      await createListeningQuestion(
        book,
        testName,
        "2",
        question,
        enqueueSnackbar
      )
  );
  sectionThree.forEach(
    async (question: any) =>
      await createListeningQuestion(
        book,
        testName,
        "3",
        question,
        enqueueSnackbar
      )
  );
  sectionFour.forEach(
    async (question: any) =>
      await createListeningQuestion(
        book,
        testName,
        "4",
        question,
        enqueueSnackbar
      )
  );
};

export const createReadingTest = async (
  book: string,
  testName: string,
  genre: string,
  enqueueSnackbar: any
) => {
  const id = `${book}-${genre}-${testName}`.toLowerCase();
  try {
    if (genre === "A") {
      const typeName = "ReadingTestA";
      const input = {
        id,
        typeName,
        book,
        test: testName,
        genre,
        p1ID: `${id}-p1`,
        p2ID: `${id}-p2`,
        p3ID: `${id}-p3`,
      };
      await API.graphql({
        query: mutations.createReadingTestA,
        variables: { input },
      });
    }
    if (genre === "G") {
      const typeName = "ReadingTestG";
      const input = {
        id,
        typeName,
        book,
        test: testName,
        genre,
        s1aID: `${id}-s1a`,
        s1bID: `${id}-s1b`,
        s2aID: `${id}-s2a`,
        s2bID: `${id}-s2b`,
        s3ID: `${id}-s3`,
      };
      await API.graphql({
        query: mutations.createReadingTestG,
        variables: { input },
      });
    }
  } catch (error: any) {
    enqueueSnackbar(
      `fail to create test \n reason ${error.errors[0].message}`,
      { variant: "error" }
    );
    console.log("CreateReadingTest Error", error);
    return;
  }
};

export const createWholeReadingTest = async (
  book: string,
  testName: string,
  genre: string,
  passages: any,
  enqueueSnackbar: any
) => {
  createReadingTest(book, testName, genre, enqueueSnackbar);
  const testID = `${book}-${genre}-${testName}`.toLowerCase();
  const passageNumberMapA: any = { 0: "p1", 1: "p2", 2: "p3" };
  const passageNumberMapG: any = {
    0: "s1a",
    1: "s1b",
    2: "s2a",
    3: "s2b",
    4: "s3",
  };
  const typeName = "ReadingPassage";
  try {
    if (genre === "A") {
      passages.forEach(async (passage: any, index: number) => {
        const passageNumber = passageNumberMapA[index];
        const types = passage.questions.map((q: any) => q.type).join("&");
        const id = `${testID}-${passageNumber}`;
        const input = {
          id,
          typeName,
          book,
          testID,
          genre,
          passageNumber,
          passage: passage.passage,
          types,
        };
        await API.graphql({
          query: mutations.createReadingPassage,
          variables: { input },
        });

        passage.questions.forEach(async (question: any) => {
          const typeName = "ReadingQuestion";
          const name = `${id}-q${question.range}`;
          const { type, range } = question;
          const questionBody = JSON.stringify(question.questionBody);
          const answers = JSON.stringify(question.answers);
          const input = {
            typeName,
            name,
            book,
            genre,
            type,
            range,
            passageNumber,
            passageID: id,
            questionBody,
            answers,
          };
          try {
            await API.graphql({
              query: mutations.createReadingQuestion,
              variables: { input },
            });
          } catch (error: any) {
            enqueueSnackbar(
              `fail to create reading passages \n reason ${error.errors[0].message}`,
              { variant: "error" }
            );
            console.log("CreateReadingQuestion Error", error);
            return;
          }
        });
      });
    }
    if (genre === "G") {
      passages.forEach(async (passage: any, index: number) => {
        const passageNumber = passageNumberMapG[index];
        const types = passage.questions.map((q: any) => q.type).join("&");
        const id = `${testID}-${passageNumber}`;
        const input = {
          id,
          typeName,
          book,
          testID,
          genre,
          passageNumber,
          passage: passage.passage,
          types,
        };
        await API.graphql({
          query: mutations.createReadingPassage,
          variables: { input },
        });

        passage.questions.forEach(async (question: any) => {
          const typeName = "ReadingQuestion";
          const name = `${id}-q${question.range}`;
          const { type, range } = question;
          const questionBody = JSON.stringify(question.questionBody);
          const answers = JSON.stringify(question.answers);
          const input = {
            typeName,
            name,
            book,
            genre,
            type,
            range,
            passageNumber,
            passageID: id,
            questionBody,
            answers,
          };
          try {
            await API.graphql({
              query: mutations.createReadingQuestion,
              variables: { input },
            });
          } catch (error: any) {
            enqueueSnackbar(
              `fail to create reading passages \n reason ${error.errors[0].message}`,
              { variant: "error" }
            );
            console.log("CreateReadingQuestion Error", error);
            return;
          }
        });
      });
    }
  } catch (error: any) {
    enqueueSnackbar(
      `fail to create reading passages \n reason ${error.errors[0].message}`,
      { variant: "error" }
    );
    console.log("CreateReadingPassages Error", error);
    return;
  }
};

export const createReadingTestNew = async (readingTest: any) => {
  const { book, test, genre, p1, p2, p3, s1a, s1b, s2a, s2b, s3 } = readingTest;
  const testID = `${book}-${genre}-${test}`.toLowerCase();
  const passageNumberMapA: any = { 0: "p1", 1: "p2", 2: "p3" };
  const passageNumberMapG: any = {
    0: "s1a",
    1: "s1b",
    2: "s2a",
    3: "s2b",
    4: "s3",
  };
  try {
    if (genre === "A") {
      await API.graphql({
        query: mutations.createReadingTestA,
        variables: {
          input: {
            id: testID,
            typeName: "ReadingTestA",
            book,
            test,
            genre,
            p1ID: `${testID}-p1`,
            p2ID: `${testID}-p2`,
            p3ID: `${testID}-p3`,
          },
        },
      });
      const passageArray = [p1, p2, p3];
      passageArray.forEach(async (passage: any, index: number) => {
        const passageNumber = passageNumberMapA[index];
        const types = passage.questions.map((q: any) => q.type).join("&");
        const id = `${testID}-${passageNumber}`;
        const input = {
          id,
          typeName: "ReadingPassage",
          book,
          testID,
          genre,
          passageNumber,
          passage: passage.passage,
          types,
        };
        await API.graphql({
          query: mutations.createReadingPassage,
          variables: { input },
        });
        passage.questions.forEach(async (question: any) => {
          const typeName = "ReadingQuestion";
          const name = `${id}-q${question.range}`;
          const { type, range } = question;
          const questionBody = JSON.stringify(question.questionBody);
          const answers = JSON.stringify(question.answers);
          const input = {
            typeName,
            name,
            book,
            genre,
            type,
            range,
            passageNumber,
            passageID: id,
            questionBody,
            answers,
          };
          await API.graphql({
            query: mutations.createReadingQuestion,
            variables: { input },
          });
        });
      });
    } else {
      await API.graphql({
        query: mutations.createReadingTestG,
        variables: {
          input: {
            id: testID,
            typeName: "ReadingTestG",
            book,
            test,
            genre,
            s1aID: `${testID}-s1a`,
            s1bID: `${testID}-s1b`,
            s2aID: `${testID}-s2a`,
            s2bID: `${testID}-s2b`,
            s3ID: `${testID}-s3`,
          },
        },
      });
      const passageArray = [s1a, s1b, s2a, s2b, s3];
      passageArray.forEach(async (passage: any, index: number) => {
        const passageNumber = passageNumberMapG[index];
        const types = passage.questions.map((q: any) => q.type).join("&");
        const id = `${testID}-${passageNumber}`;
        const input = {
          id,
          typeName: "ReadingPassage",
          book,
          testID,
          genre,
          passageNumber,
          passage: passage.passage,
          types,
        };
        await API.graphql({
          query: mutations.createReadingPassage,
          variables: { input },
        });

        passage.questions.forEach(async (question: any) => {
          const typeName = "ReadingQuestion";
          const name = `${id}-q${question.range}`;
          const { type, range } = question;
          const questionBody = JSON.stringify(question.questionBody);
          const answers = JSON.stringify(question.answers);
          const input = {
            typeName,
            name,
            book,
            genre,
            type,
            range,
            passageNumber,
            passageID: id,
            questionBody,
            answers,
          };
          await API.graphql({
            query: mutations.createReadingQuestion,
            variables: { input },
          });
        });
      });
    }
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (authUser: any, enqueueSnackbar: any) => {
  const {
    attributes: { sub, email },
  } = authUser;
  const username =
    authUser.signInUserSession.idToken.payload["cognito:username"];
  let groups = authUser.signInUserSession.idToken.payload["cognito:groups"];
  try {
    const data: any = await API.graphql({
      query: mutations.updateUser,
      variables: { input: { id: sub, email, username, groups } },
    });
    return data.data.updateUser;
  } catch (error: any) {
    if (error.errors[0].path[0] === "updateUser") {
      const data: any = await API.graphql({
        query: mutations.createUser,
        variables: { input: { id: sub, email, username, groups } },
      });
      return data.data.createUser;
    }
    console.log("update errror", error);
    enqueueSnackbar(
      "Update user error, please check internet connection and try again later",
      { variant: "error" }
    );
    return undefined;
  }
};

export const createUser = async (authUser: any, enqueueSnackbar: any) => {
  const { username } = authUser;
  const sub = authUser.signInUserSession.idToken.payload["sub"];
  const email = authUser.signInUserSession.idToken.payload["email"];
  let groups = authUser.signInUserSession.idToken.payload["cognito:groups"];
  try {
    const data: any = await API.graphql({
      query: mutations.createUser,
      variables: { input: { id: sub, email, username, groups } },
    });
    return data.data.createUser;
  } catch (error) {
    console.log("create user error", error);
    enqueueSnackbar(
      "Create user error, please check internet connection and try again later",
      { variant: "error" }
    );
    return undefined;
  }
};

export const listPractice = async (
  format: string,
  input: any,
  limit: number | null = 1000,
  nextToken: string | null = null
) => {
  const { book, type, sectionNumber, passageNumber, genre, search } = input;
  let filter: any = {};
  if (book) filter.book = { eq: book };
  if (sectionNumber) filter.sectionNumber = { eq: sectionNumber };
  if (passageNumber) filter.passageNumber = { eq: passageNumber };

  try {
    if (format === "ListeningTest") {
      if (search) filter.id = { contains: search };
      let inputFilter = Object.keys(filter).length === 0 ? null : filter;
      const data: any = await API.graphql({
        query: customQueries.listLt,
        variables: {
          typeName: "ListeningTest",
          filter: inputFilter,
          limit,
          nextToken,
        },
      });
      return data.data.listLT;
    }
    if (format === "ListeningSection") {
      if (search) filter.id = { contains: search };
      if (type) filter.types = { contains: type };
      let inputFilter = Object.keys(filter).length === 0 ? null : filter;
      const data: any = await API.graphql({
        query: customQueries.listLs,
        variables: {
          typeName: "ListeningSection",
          filter: inputFilter,
          limit,
          nextToken,
        },
      });
      return data.data.listLS;
    }
    if (format === "ListeningQuestion") {
      if (search) filter.name = { contains: search };
      if (type) filter.type = { eq: type };
      let inputFilter = Object.keys(filter).length === 0 ? null : filter;
      const data: any = await API.graphql({
        query: customQueries.listLq,
        variables: {
          typeName: "ListeningQuestion",
          filter: inputFilter,
          limit,
          nextToken,
        },
      });
      return data.data.listLQ;
    }
    if (format === "ReadingTest") {
      if (search) filter.id = { contains: search };
      let inputFilter = Object.keys(filter).length === 0 ? null : filter;
      if (genre === "A") {
        const data: any = await API.graphql({
          query: customQueries.listRta,
          variables: {
            typeName: "ReadingTestA",
            filter: inputFilter,
            limit,
            nextToken,
          },
        });
        return data.data.listRTA;
      }
      if (genre === "G") {
        const data: any = await API.graphql({
          query: customQueries.listRtg,
          variables: {
            typeName: "ReadingTestG",
            filter: inputFilter,
            limit,
            nextToken,
          },
        });
        return data.data.listRTG;
      }
    }
    if (format === "ReadingPassage") {
      if (genre) filter.genre = { eq: genre };
      if (search) filter.id = { contains: search };
      if (type) filter.types = { contains: type };
      let inputFilter = Object.keys(filter).length === 0 ? null : filter;
      const data: any = await API.graphql({
        query: customQueries.listRp,
        variables: {
          typeName: "ReadingPassage",
          filter: inputFilter,
          limit,
          nextToken,
        },
      });
      return data.data.listRP;
    }
    if (format === "ReadingQuestion") {
      if (genre) filter.genre = { eq: genre };
      if (search) filter.name = { contains: search };
      if (type) filter.type = { eq: type };
      let inputFilter = Object.keys(filter).length === 0 ? null : filter;
      const data: any = await API.graphql({
        query: customQueries.listRq,
        variables: {
          typeName: "ReadingQuestion",
          filter: inputFilter,
          limit,
          nextToken,
        },
      });
      return data.data.listRQ;
    }
  } catch (error: any) {
    console.log(`fail to fetch practice : reason ${error.errors[0].message}`);
  }
};

export const fetchPractice = async (
  pathname: string,
  id: string,
  setPractice: any
) => {
  try {
    if (pathname.toLowerCase().includes("listeningquestion")) {
      const data: any = await API.graphql({
        query: queries.getListeningQuestion,
        variables: { id },
      });
      const normalizedData = normalizeLQ(data);
      setPractice(normalizedData);
    }
    if (pathname.toLowerCase().includes("listeningsection")) {
      const data: any = await API.graphql({
        query: queries.getListeningSection,
        variables: { id },
      });
      const normalizedData = normalizeLS(data.data.getListeningSection);
      setPractice(normalizedData);
    }
    if (pathname.toLowerCase().includes("listeningtest")) {
      const data: any = await API.graphql({
        query: queries.getListeningTest,
        variables: { id },
      });

      const normalizedData = normalizeLT(data);
      setPractice(normalizedData);
    }
    if (pathname.toLowerCase().includes("readingquestion")) {
      const data: any = await API.graphql({
        query: queries.getReadingQuestion,
        variables: { id },
      });
      const normalizedData = normalizeRQ(data.data.getReadingQuestion);
      setPractice(normalizedData);
    }
    if (pathname.toLowerCase().includes("readingpassage")) {
      const data: any = await API.graphql({
        query: queries.getReadingPassage,
        variables: { id },
      });
      const normalizedData = normalizeRP(data.data.getReadingPassage);
      setPractice(normalizedData);
    }
    if (pathname.toLowerCase().includes("readingtesta")) {
      const data: any = await API.graphql({
        query: queries.getReadingTestA,
        variables: { id },
      });
      const normalizedData = normalizeRTA(data);
      setPractice(normalizedData);
    }
    if (pathname.toLowerCase().includes("readingtestg")) {
      const data: any = await API.graphql({
        query: queries.getReadingTestG,
        variables: { id },
      });
      const normalizedData = normalizeRTG(data);

      setPractice(normalizedData);
    }
    if (pathname.toLowerCase().includes("customtest")) {
      const data: any = await API.graphql({
        query: queries.getCustomTest,
        variables: { id },
      });
      const normalizedData = normalizeCustomTest(data.data.getCustomTest);
      setPractice(normalizedData);
    }
  } catch (error) {
    console.log("fetchPractice Errors", error);
  }
};

export const createWriting = async (writingData: any) => {
  const {
    typeName,
    book,
    name,
    genre,
    type,
    topic,
    img,
    prompt,
    sampleAnswer,
    aim,
  } = writingData;
  if (typeName === "Essay") {
    const id = `${book}-${genre.toLowerCase()}-${name}`;
    const input = {
      id,
      typeName,
      book,
      genre,
      type,
      topic,
      prompt,
      sampleAnswer,
    };
    await API.graphql({
      query: mutations.createEssay,
      variables: { input },
    });
  }
  if (typeName === "Graph") {
    const id = `${book}-${name}`;
    const input = {
      id,
      typeName,
      type,
      book,
      img,
      prompt,
      sampleAnswer,
    };
    await API.graphql({
      query: mutations.createGraph,
      variables: { input },
    });
  }
  if (typeName === "Letter") {
    const id = `${book}-${name}`;
    const input = {
      id,
      typeName,
      type,
      book,
      aim,
      prompt,
      sampleAnswer,
    };
    await API.graphql({
      query: mutations.createLetter,
      variables: { input },
    });
  }
};

export const createWritingTask = async (writing: any) => {
  const {
    typeName,
    book,
    name,
    genre,
    type,
    topic,
    img,
    prompt,
    sampleAnswer,
    aim,
  } = writing;
  if (typeName === "Essay") {
    const id = `${book}-${genre.toLowerCase()}-${name}`;
    const input = {
      id,
      typeName,
      book,
      genre,
      type,
      topic,
      prompt,
      sampleAnswer,
    };
    await API.graphql({
      query: mutations.createEssay,
      variables: { input },
    });
  }
  if (typeName === "Graph") {
    const id = `${book}-${name}`;
    const input = {
      id,
      typeName,
      type,
      book,
      img,
      prompt,
      sampleAnswer,
    };
    await API.graphql({
      query: mutations.createGraph,
      variables: { input },
    });
  }
  if (typeName === "Letter") {
    const id = `${book}-${name}-letter`;
    const input = {
      id,
      typeName,
      type,
      book,
      aim,
      prompt,
      sampleAnswer,
    };
    await API.graphql({
      query: mutations.createLetter,
      variables: { input },
    });
  }
  return "completed";
};

export const fetchWritingTask = async (id: string, typeName: string) => {
  let data;
  if (typeName === "Essay") {
    data = await API.graphql({ query: queries.getEssay, variables: { id } });
  }
  if (typeName === "Graph") {
    data = await API.graphql({ query: queries.getGraph, variables: { id } });
  }
  if (typeName === "Letter") {
    data = await API.graphql({ query: queries.getLetter, variables: { id } });
  }
  return normalizeWritingTask(data, typeName);
};

export const deleteWritingTask = async (writing: any) => {
  const { id, typeName } = writing;
  if (typeName === "Essay") {
    await API.graphql({
      query: mutations.deleteEssay,
      variables: { input: { id } },
    });
  }
  if (typeName === "Graph") {
    await API.graphql({
      query: mutations.deleteGraph,
      variables: { input: { id } },
    });
  }
  if (typeName === "Letter") {
    await API.graphql({
      query: mutations.deleteLetter,
      variables: { input: { id } },
    });
  }
  return "deleted";
};

export const updateWritingTask = async (updated: any) => {
  const { typeName } = updated;
  const input = { ...updated };
  delete input.name;
  if (typeName === "Essay") {
    await API.graphql({
      query: mutations.updateEssay,
      variables: { input },
    });
  }
  if (typeName === "Graph") {
    await API.graphql({
      query: mutations.updateGraph,
      variables: { input },
    });
  }
  if (typeName === "Letter") {
    await API.graphql({
      query: mutations.updateLetter,
      variables: { input },
    });
  }
  return "updated";
};

export const updatePartOneTopic = async (part1Topic: any) => {
  const { id, season, questions } = part1Topic;
  const createQuestions = questions.filter((q: any) => !q.id);
  createQuestions.forEach(async (q: any) => {
    await API.graphql({
      query: mutations.createPartOne,
      variables: {
        input: {
          typeName: "PartOne",
          question: q.question,
          partOneTopicID: id,
          type: q.type,
          season,
        },
      },
    });
  });
  await API.graphql({
    query: mutations.updatePartOneTopic,
    variables: { input: { id, season } },
  });
};

export const fetchPartOneTopic = async (p1TopicId: string) => {
  const data = (await API.graphql({
    query: queries.getPartOneTopic,
    variables: { id: p1TopicId, limit: 10000 },
  })) as any;
  const { id, typeName, season, questions } = data.data.getPartOneTopic;
  return { id, typeName, season, questions: questions.items };
};

export const deletePartOneTopic = async (part1Topic: any) => {
  const { id, questions } = part1Topic;
  await API.graphql({
    query: mutations.deletePartOneTopic,
    variables: { input: { id } },
  });
  const toDeleteQuestions = questions.filter((q: any) => q.id);
  toDeleteQuestions.forEach(async (q: any) => {
    await API.graphql({
      query: mutations.deletePartOne,
      variables: { input: { id: q.id } },
    });
  });
};

export const fetchPartTwo = async (p2Id: string) => {
  const data = (await API.graphql({
    query: queries.getPartTwo,
    variables: { id: p2Id, limit: 10000 },
  })) as any;
  const {
    id,
    typeName,
    prompt,
    season,
    subQuestions,
    type,
    partThreeQuestions,
  } = data.data.getPartTwo;
  return {
    id,
    typeName,
    prompt,
    subQuestions,
    type,
    season,
    partThreeQuestions: partThreeQuestions.items,
  };
};

export const updatePartTwo = async (part2: any) => {
  const { id, season, subQuestions, type, prompt, partThreeQuestions } = part2;
  await API.graphql({
    query: mutations.updatePartTwo,
    variables: { input: { id, season, subQuestions, type, prompt } },
  });
  const createQuestions = partThreeQuestions.filter((q: any) => !q.id);
  createQuestions.forEach(async (q: any) => {
    await API.graphql({
      query: mutations.createPartThree,
      variables: {
        input: {
          typeName: "PartThree",
          question: q.question,
          partTwoID: id,
          type: q.type,
          season,
        },
      },
    });
  });
};

export const deletePartTwo = async (part2: any) => {
  const { id, partThreeQuestions } = part2;
  await API.graphql({
    query: mutations.deletePartTwo,
    variables: { input: { id } },
  });
  const toDeleteQuestions = partThreeQuestions.filter((q: any) => q.id);
  toDeleteQuestions.forEach(async (q: any) => {
    await API.graphql({
      query: mutations.deletePartThree,
      variables: { input: { id: q.id } },
    });
  });
};

const createFilter = (input: any) => {
  let filter = {} as any;
  Object.entries(input).forEach(([key, value]: [string, any]) => {
    if (value && !["search", "typeName"].includes(key)) {
      if (["types", "season", "type"].includes(key)) {
        if (
          key === "type" &&
          !["PartOne", "PartThree"].includes(input.typeName)
        ) {
          filter[key] = { eq: value };
        } else {
          filter[key] = { contains: value };
        }
      } else {
        filter[key] = { eq: value };
      }
    }
  });
  return filter;
};

export const fetchPracticeList = async (
  input: any,
  limit: number | null = 10000,
  nextToken: string | null = null
) => {
  const { typeName, search } = input;
  const filter = createFilter(input);
  if (typeName === "ListeningTest") {
    if (search) {
      filter.id = { contains: search };
    }
    const inputFilter = Object.keys(filter).length === 0 ? null : filter;
    const data: any = await API.graphql({
      query: customQueries.listLt,
      variables: {
        typeName: "ListeningTest",
        filter: inputFilter,
        limit,
        nextToken,
      },
    });
    return data.data.listLT.items;
  }
  if (typeName === "ListeningSection") {
    if (search) {
      filter.id = { contains: search };
    }
    const inputFilter = Object.keys(filter).length === 0 ? null : filter;
    const data: any = await API.graphql({
      query: customQueries.listLs,
      variables: {
        typeName: "ListeningSection",
        filter: inputFilter,
        limit,
        nextToken,
      },
    });
    return data.data.listLS.items;
  }
  if (typeName === "ListeningQuestion") {
    if (search) {
      filter.name = { contains: search };
    }
    const inputFilter = Object.keys(filter).length === 0 ? null : filter;
    const data: any = await API.graphql({
      query: customQueries.listLq,
      variables: {
        typeName: "ListeningQuestion",
        filter: inputFilter,
        limit,
        nextToken,
      },
    });
    return data.data.listLQ.items;
  }
  if (typeName === "ReadingTest") {
    if (search) {
      filter.id = { contains: search };
    }
    delete filter.genre;
    if (input.genre === "A") {
      const data: any = await API.graphql({
        query: customQueries.listRta,
        variables: {
          typeName: "ReadingTestA",
          filter: Object.keys(filter).length === 0 ? null : filter,
          limit,
          nextToken,
        },
      });
      return data.data.listRTA.items;
    } else {
      const data: any = await API.graphql({
        query: customQueries.listRtg,
        variables: {
          typeName: "ReadingTestG",
          filter: Object.keys(filter).length === 0 ? null : filter,
          limit,
          nextToken,
        },
      });
      return data.data.listRTG.items;
    }
  }
  if (typeName === "ReadingPassage") {
    if (search) {
      filter.id = { contains: search };
    }
    const data: any = await API.graphql({
      query: customQueries.listRp,
      variables: {
        typeName: "ReadingPassage",
        filter: Object.keys(filter).length === 0 ? null : filter,
        limit,
        nextToken,
      },
    });
    return data.data.listRP.items;
  }
  if (typeName === "ReadingQuestion") {
    if (search) {
      filter.name = { contains: search };
    }
    const data: any = await API.graphql({
      query: customQueries.listRq,
      variables: {
        typeName: "ReadingQuestion",
        filter: Object.keys(filter).length === 0 ? null : filter,
        limit,
        nextToken,
      },
    });
    return data.data.listRQ.items;
  }
  if (typeName === "Essay") {
    if (search) {
      filter.id = { contains: search };
    }
    const inputFilter = Object.keys(filter).length === 0 ? null : filter;
    const data: any = await API.graphql({
      query: queries.listEssays,
      variables: {
        filter: inputFilter,
        limit,
        nextToken,
      },
    });
    return data.data.listEssays.items;
  }
  if (typeName === "Letter") {
    if (search) {
      filter.id = { contains: search };
    }
    delete filter.genre;
    const inputFilter = Object.keys(filter).length === 0 ? null : filter;
    const data: any = await API.graphql({
      query: queries.listLetters,
      variables: {
        filter: inputFilter,
        limit,
        nextToken,
      },
    });
    return data.data.listLetters.items;
  }
  if (typeName === "Graph") {
    if (search) {
      filter.id = { contains: search };
    }
    const inputFilter = Object.keys(filter).length === 0 ? null : filter;
    const data: any = await API.graphql({
      query: queries.listGraphs,
      variables: {
        filter: inputFilter,
        limit,
        nextToken,
      },
    });
    return data.data.listGraphs.items;
  }
  if (typeName === "PartOneTopic") {
    if (search) {
      filter.id = { contains: search };
    }
    const inputFilter = Object.keys(filter).length === 0 ? null : filter;
    const data: any = await API.graphql({
      query: queries.listPartOneTopics,
      variables: {
        filter: inputFilter,
        limit,
        nextToken,
      },
    });
    const result = data.data.listPartOneTopics.items.map((part1Topic: any) => ({
      ...part1Topic,
      questions: part1Topic.questions.items,
    }));
    return result;
  }
  if (typeName === "PartTwo") {
    if (search) {
      filter.prompt = { contains: search };
    }
    const inputFilter = Object.keys(filter).length === 0 ? null : filter;
    const data: any = await API.graphql({
      query: queries.listPartTwos,
      variables: {
        filter: inputFilter,
        limit,
        nextToken,
      },
    });
    return data.data.listPartTwos.items.map((part2: any) => ({
      ...part2,
      partThreeQuestions: part2.partThreeQuestions.items,
    }));
  }
  if (typeName === "PartOne") {
    if (search) {
      filter.question = { contains: search };
    }
    const inputFilter = Object.keys(filter).length === 0 ? null : filter;
    const data: any = await API.graphql({
      query: queries.listPartOnes,
      variables: {
        filter: inputFilter,
        limit,
        nextToken,
      },
    });
    return data.data.listPartOnes.items;
  }
  if (typeName === "PartThree") {
    if (search) {
      filter.question = { contains: search };
    }
    const inputFilter = Object.keys(filter).length === 0 ? null : filter;
    const data: any = await API.graphql({
      query: queries.listPartThrees,
      variables: {
        filter: inputFilter,
        limit,
        nextToken,
      },
    });
    return data.data.listPartThrees.items;
  }
};

export const createCourse = async (data: any) => {
  await API.graphql({
    query: mutations.createCourse,
    variables: { input: data },
  });
};

export const listCourses = async () => {
  const data = (await API.graphql({
    query: customQueries.listCoursesGist,
    variables: { typeName: "Course" },
  })) as any;
  return data.data.listCoursesGist.items;
};

export const deleteCourse = async (id: string) => {
  await API.graphql({
    query: mutations.deleteCourse,
    variables: { input: { id } },
  });
};

export const fetchEvents = async () => {
  const data = (await API.graphql({
    query: queries.listEvents,
  })) as any;
  const events = data.data.listEvents.items;
  return events.map((event: any) => ({
    ...event,
    start: new Date(event.start),
    end: new Date(event.end),
  }));
};

export const createEvent = async (event: any) => {
  const input = {
    ...event,
    typeName: "Event",
    start: event.start.toISOString(),
    end: event.end.toISOString(),
  };
  const data = (await API.graphql({
    query: mutations.createEvent,
    variables: { input },
  })) as any;
  const created = data.data.createEvent;
  return {
    ...created,
    start: new Date(created.start),
    end: new Date(created.end),
  };
};
export const deleteEvent = async (id: string) => {
  await API.graphql({
    query: mutations.deleteEvent,
    variables: { input: { id } },
  });
};

export const fetchReading = async (id: string, typeName: string) => {
  let query: any;
  let normalizer: any;
  switch (typeName) {
    case "ReadingTestA":
      query = queries.getReadingTestA;
      normalizer = normalizeRTA;
      break;
    case "ReadingTestG":
      query = queries.getReadingTestG;
      normalizer = normalizeRTG;
      break;
    case "ReadingPassage":
      query = queries.getReadingPassage;
      normalizer = normalizeRP;
      break;
    case "ReadingQuestion":
      query = queries.getReadingQuestion;
      normalizer = normalizeRQ;
      break;
    default:
      break;
  }
  const data = await API.graphql({ query, variables: { id } });
  return normalizer(data);
};

export const fetchListening = async (id: string, typeName: string) => {
  let query: any;
  let normalizer: any;
  switch (typeName) {
    case "ListeningTest":
      query = queries.getListeningTest;
      normalizer = normalizeLT;
      break;
    case "ListeningSection":
      query = queries.getListeningSection;
      normalizer = normalizeLS;
      break;
    case "ListeningQuestion":
      query = queries.getListeningQuestion;
      normalizer = normalizeLQ;
      break;
    default:
      break;
  }
  const data = await API.graphql({ query, variables: { id } });
  return normalizer(data);
};

export const updateReadingTest = async (original: any, updated: any) => {
  const { genre, book } = original;
  let originalPassagesList: any[];
  let updatedPassagesList: any[];
  if (genre === "A") {
    originalPassagesList = [original.p1, original.p2, original.p3];
    updatedPassagesList = [updated.p1, updated.p2, updated.p3];
  } else {
    originalPassagesList = [
      original.s1a,
      original.s1b,
      original.s2a,
      original.s2b,
      original.s3,
    ];
    updatedPassagesList = [
      updated.s1a,
      updated.s1b,
      updated.s2a,
      updated.s2b,
      updated.s3,
    ];
  }
  try {
    originalPassagesList.forEach(async (p: any, index) => {
      const updatedPassage = updatedPassagesList[index];
      const originalQuestionsList = [...p.questions];
      const updatedQuestionsList = [...updatedPassage.questions];
      if (JSON.stringify(p) !== JSON.stringify(updatedPassage)) {
        //update passage article
        if (p.passage !== updatedPassage.passage) {
          const types = updatedPassage.questions
            .map((q: any) => q.type)
            .join("&");
          const input = { id: p.id, passage: updatedPassage.passage, types };
          await API.graphql({
            query: mutations.updateReadingPassage,
            variables: { input },
          });
        }

        //get passageNumber
        const splittedIDArray = p.id.split("-");
        const passageNumber = splittedIDArray[splittedIDArray.length - 1];

        originalQuestionsList.forEach(async (q: any) => {
          const updatedQuestion = updatedQuestionsList.find(
            (uq: any) => uq.id === q.id
          );

          if (updatedQuestion) {
            //update question
            if (JSON.stringify(updatedQuestion) !== JSON.stringify(q)) {
              const input = {
                ...updatedQuestion,
                answers: JSON.stringify(updatedQuestion.answers),
                questionBody: JSON.stringify(updatedQuestion.questionBody),
              };
              await API.graphql({
                query: mutations.updateReadingQuestion,
                variables: { input },
              });
            }
          } else {
            //delete question
            await API.graphql({
              query: mutations.deleteReadingQuestion,
              variables: { input: { id: q.id } },
            });
          }
        });
        //add question
        updatedQuestionsList.forEach(async (uq: any) => {
          const foundQuestion = originalQuestionsList.find(
            (q: any) => q.id === uq.id
          );
          if (!foundQuestion) {
            const input = {
              book,
              genre,
              name: `${p.id}-q${uq.range}`,
              passageNumber,
              passageID: p.id,
              range: uq.range,
              type: uq.type,
              typeName: "ReadingQuestion",
              answers: JSON.stringify(uq.answers),
              questionBody: JSON.stringify(uq.questionBody),
            };
            await API.graphql({
              query: mutations.createReadingQuestion,
              variables: { input },
            });
          }
        });
      }
    });
  } catch (error) {
    throw error;
  }
};

export const deleteReadingTest = async (readingTest: any) => {
  let passagesList: any[];
  const { id, genre, p1, p2, p3, s1a, s1b, s2a, s2b, s3 } = readingTest;
  try {
    if (genre === "A") {
      passagesList = [p1, p2, p3];
      await API.graphql({
        query: mutations.deleteReadingTestA,
        variables: { input: { id } },
      });
    } else {
      passagesList = [s1a, s1b, s2a, s2b, s3];
      await API.graphql({
        query: mutations.deleteReadingTestG,
        variables: { input: { id } },
      });
    }
    passagesList.forEach(async (p: any) => {
      await API.graphql({
        query: mutations.deleteReadingPassage,
        variables: { input: { id: p.id } },
      });
      p.questions.forEach(async (q: any) => {
        await API.graphql({
          query: mutations.deleteReadingQuestion,
          variables: { input: { id: q.id } },
        });
      });
    });
  } catch (error) {
    throw error;
  }
};

export const deleteListeningTest = async (listeningTest: any) => {
  const { id, s1, s2, s3, s4 } = listeningTest;
  let sectionList = [s1, s2, s3, s4];
  try {
    await API.graphql({
      query: mutations.deleteListeningTest,
      variables: { input: { id } },
    });
    sectionList.forEach(async (section: any) => {
      await API.graphql({
        query: mutations.deleteListeningSection,
        variables: { input: { id: section.id } },
      });
      section.questions.forEach(async (question: any) => {
        await API.graphql({
          query: mutations.deleteListeningQuestion,
          variables: { input: { id: question.id } },
        });
      });
    });
  } catch (error) {
    throw error;
  }
  return "deleted";
};

export const updateListeningTest = async (original: any, updated: any) => {
  const { id, book } = original;
  const originalSectionList = [
    original.s1,
    original.s2,
    original.s3,
    original.s4,
  ];
  const updatedSectionList = [updated.s1, updated.s2, updated.s3, updated.s4];
  try {
    originalSectionList.forEach(async (section: any, index: number) => {
      const sectionNumber = (index + 1).toString();
      const sectionID = `${id}-s${sectionNumber}`;
      const updatedSection = updatedSectionList[index];
      const originalQuestionsList = section.questions;
      const updatedQuestionsList = updatedSection.questions;
      if (JSON.stringify(section) !== JSON.stringify(updatedSection)) {
        const types = updatedSection.questions
          .map((q: any) => q.type)
          .join("&");
        const input = { id: section.id, types };
        await API.graphql({
          query: mutations.updateListeningSection,
          variables: { input },
        });
        originalQuestionsList.forEach(async (q: any) => {
          const updatedQuestion = updatedQuestionsList.find(
            (uq: any) => uq.id === q.id
          );
          if (updatedQuestion) {
            if (JSON.stringify(updatedQuestion) !== JSON.stringify(q)) {
              const input = {
                ...updatedQuestion,
                answers: JSON.stringify(updatedQuestion.answers),
                questionBody: JSON.stringify(updatedQuestion.questionBody),
              };
              await API.graphql({
                query: mutations.updateListeningQuestion,
                variables: { input },
              });
            }
          } else {
            await API.graphql({
              query: mutations.deleteListeningQuestion,
              variables: { input: { id: q.id } },
            });
          }
        });
        updatedQuestionsList.forEach(async (uq: any) => {
          const foundQuestion = originalQuestionsList.find(
            (q: any) => q.id === uq.id
          );
          if (!foundQuestion) {
            const input = {
              typeName: "ListeningQuestion",
              name: `${sectionID}-q${uq.range}`,
              book,
              sectionNumber,
              sectionID,
              range: uq.range,
              type: uq.type,
              start: uq.start,
              end: uq.end,
              answers: JSON.stringify(uq.answers),
              questionBody: JSON.stringify(uq.questionBody),
            };
            await API.graphql({
              query: mutations.createListeningQuestion,
              variables: { input },
            });
          }
        });
      }
    });
  } catch (error) {
    throw error;
  }
  return "updated";
};

export const listPartOneTopic = async () => {
  const data = (await API.graphql({
    query: customQueries.listP1topics,
    variables: {
      typeName: "PartOneTopic",
      limit: 10000,
    },
  })) as any;
  return data.data.listP1topics.items.map((data: any) => data);
};

export const createPartOneTopic = async (data: any) => {
  const input = {
    id: data.id,
    typeName: "PartOneTopic",
    season: [...data.season],
  };
  const returnedData = (await API.graphql({
    query: mutations.createPartOneTopic,
    variables: { input },
  })) as any;
  const { id, typeName, season, questions } =
    returnedData.data.createPartOneTopic;
  return { id, typeName, season, questions: questions.items };
};

export const listPartTwoPrompt = async () => {
  const data = (await API.graphql({
    query: customQueries.listP2,
    variables: {
      typeName: "PartTwo",
      limit: 10000,
    },
  })) as any;
  return data.data.listP2.items.map((data: any) => data);
};

export const createPartTwo = async (data: any) => {
  const input = { ...data, typeName: "PartTwo" };
  const newData = (await API.graphql({
    query: mutations.createPartTwo,
    variables: { input },
  })) as any;
  const part2 = newData.data.createPartTwo;
  return { ...part2, partThreeQuestions: part2.partThreeQuestions.items };
};

export const deleteSpeaking = async (speaking: any) => {
  const { typeName, id } = speaking;
  if (typeName === "PartOneTopic") {
    await API.graphql({
      query: mutations.deletePartOneTopic,
      variables: { input: { id } },
    });
    speaking.questions.forEach(async (q: any) => {
      await API.graphql({
        query: mutations.deletePartOne,
        variables: { input: { id: q.id } },
      });
    });
  }
  if (typeName === "PartTwo") {
    await API.graphql({
      query: mutations.deletePartTwo,
      variables: { input: { id } },
    });
    speaking.partThreeQuestions.forEach(async (q: any) => {
      await API.graphql({
        query: mutations.deletePartThree,
        variables: { input: { id: q.id } },
      });
    });
  }
  return "deleted";
};
export const fetchSpeaking = async (id: string, typeName: string) => {
  if (typeName === "PartOneTopic") {
    return await fetchPartOneTopic(id);
  }
  if (typeName === "PartOne") {
    const returnedData = (await API.graphql({
      query: queries.getPartOne,
      variables: { id, limit: 10000 },
    })) as any;
    return returnedData.data.getPartOne;
  }
  if (typeName === "PartThree") {
    const returnedData = (await API.graphql({
      query: queries.getPartThree,
      variables: { id, limit: 10000 },
    })) as any;
    return returnedData.data.getPartThree;
  }
  if (typeName === "PartTwo") {
    return await fetchPartTwo(id);
  }
  if (typeName === "SpeakingList") {
    const returnedData = (await API.graphql({
      query: queries.getSpeakingList,
      variables: { id, limit: 10000 },
    })) as any;
    return returnedData.data.getSpeakingList;
  }
};
export const updateSpeaking = async (original: any, edited: any) => {
  const { typeName } = original;
  if (typeName === "PartOneTopic") {
    const { id, season } = edited;
    if (JSON.stringify(original.season) !== JSON.stringify(edited.season)) {
      const input = { id, season };
      await API.graphql({
        query: mutations.updatePartOneTopic,
        variables: { input },
      });
    }
    edited.questions.forEach(async (q: any) => {
      if (!q.id) {
        const input = {
          typeName: "PartOne",
          question: q.question,
          type: q.type,
          season,
          partOneTopicID: id,
        };
        await API.graphql({
          query: mutations.createPartOne,
          variables: { input },
        });
      }
    });
    original.questions.forEach(async (oq: any) => {
      const eq = edited.questions.find((item: any) => item.id === oq.id);
      if (eq) {
        if (JSON.stringify(oq) !== JSON.stringify(eq)) {
          const input = {
            id: eq.id,
            question: eq.question,
            type: eq.type,
            season,
          };
          await API.graphql({
            query: mutations.updatePartOne,
            variables: { input },
          });
        }
      } else {
        await API.graphql({
          query: mutations.deletePartOne,
          variables: { input: { id: oq.id } },
        });
      }
    });
  }
  if (typeName === "PartTwo") {
    const { id, season } = edited;
    if (
      JSON.stringify({
        prompt: original.prompt,
        season: original.season,
        subQuestions: original.subQuestions,
        type: original.type,
      }) !==
      JSON.stringify({
        prompt: edited.prompt,
        season: edited.season,
        subQuestions: edited.subQuestions,
        type: edited.type,
      })
    ) {
      const input = {
        id,
        season,
        prompt: edited.prompt,
        subQuestions: edited.subQuestions,
        type: edited.type,
      };
      await API.graphql({
        query: mutations.updatePartTwo,
        variables: { input },
      });
    }
    edited.partThreeQuestions.forEach(async (q: any) => {
      if (!q.id) {
        const input = {
          typeName: "PartThree",
          question: q.question,
          type: q.type,
          season,
          partTwoID: id,
        };
        await API.graphql({
          query: mutations.createPartThree,
          variables: { input },
        });
      }
    });
    original.partThreeQuestions.forEach(async (oq: any) => {
      const eq = edited.partThreequestions.find(
        (item: any) => item.id === oq.id
      );
      if (eq) {
        if (JSON.stringify(oq) !== JSON.stringify(eq)) {
          const input = {
            id: eq.id,
            question: eq.question,
            type: eq.type,
            season,
          };
          await API.graphql({
            query: mutations.updatePartThree,
            variables: { input },
          });
        }
      } else {
        await API.graphql({
          query: mutations.deletePartThree,
          variables: { input: { id: oq.id } },
        });
      }
    });
  }
  return { id: edited.id, typeName };
};
