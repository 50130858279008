import * as React from "react";
import { emptyObj } from "utilities";
import BottomBanner from "../BottomBanner";
import TopBanner from "../TopBanner";
import { createPracticeResult } from "utilities";
import ReadingContent from "./ReadingContent";
import Spinner from "components/Spinner";

interface RenderReadingProps {
  practice: any;
  time?: string;
  onSubmit?: (data: any) => Promise<void>;
  onClose?: () => void;
  submittedAnswers?: any;
  title: string;
}

const RenderReading: React.FC<RenderReadingProps> = ({
  practice,
  time,
  onSubmit,
  onClose,
  submittedAnswers,
  title,
}) => {
  console.log("renderReading.tsx", practice);
  const { typeName } = practice;
  const [index, setIndex] = React.useState<number>(0);
  const practiceList = React.useMemo<any[]>(() => {
    if (practice && practice.p1) return [practice.p1, practice.p2, practice.p3];
    if (practice && practice.s1a)
      return [
        practice.s1a,
        practice.s1b,
        practice.s2a,
        practice.s2b,
        practice.s3,
      ];
    return [];
  }, [practice]);
  const [answers, setAnswers] = React.useState<any>(
    submittedAnswers ? submittedAnswers : emptyObj(practice.answers)
  );
  const [errors, setErrors] = React.useState<any>(
    submittedAnswers
      ? createPracticeResult(submittedAnswers, practice.answers)
      : null
  );
  const handleSubmit = async () => {
    setErrors(createPracticeResult(answers, practice.answers));
    if (onSubmit) {
      await onSubmit(answers);
    }
  };
  const nextPage = () => {
    setIndex(index + 1);
  };
  const prevPage = () => {
    setIndex(index - 1);
  };
  const isExam = title.includes("Exam");
  const lastIndex = practiceList.length - 1;
  if (!answers) return <Spinner />;
  return (
    <div
      style={
        isExam
          ? null
          : {
              zIndex: 1200,
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100vh",
              top: 0,
              left: 0,
              backgroundColor: "rgba(250,250,250,1)",
            }
      }
    >
      <TopBanner
        onClose={onClose}
        onSubmit={handleSubmit}
        time={time}
        errors={errors}
        title={title}
      />
      <ReadingContent
        practice={typeName.includes("Test") ? practiceList[index] : practice}
        setAnswers={setAnswers}
        answers={answers}
        errors={errors}
      />
      <BottomBanner
        onSubmit={handleSubmit}
        title={title}
        answers={answers}
        nextPage={nextPage}
        prevPage={prevPage}
        index={index}
        lastIndex={lastIndex}
        typeName={typeName}
        submitted={!!errors}
      />
    </div>
  );
};

export default RenderReading;
