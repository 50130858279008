import * as React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import PracticeListView from "./PracticeListView";
import ProtectedRoute from "routes/utilities/ProtectedRoute";
import CustomTest from "./customTest/CustomTest";
import CustomTestPreview from "./customTest/CustomTestPreview";
import Reading from "./reading";
import Listening from "./listening";
import Writing from "./writing";
import Speaking from "./speaking";

interface PracticeProps {}

const Practice: React.FC<PracticeProps> = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <PracticeListView />
      </Route>
      <Route path={`${path}/reading`}>
        <Reading />
      </Route>
      <Route path={`${path}/listening`}>
        <Listening />
      </Route>
      <Route path={`${path}/writing`}>
        <Writing />
      </Route>
      <Route path={`${path}/speaking`}>
        <Speaking />
      </Route>
      {/* deprecated */}

      <ProtectedRoute exact path={`${path}/createCustomTest`}>
        <CustomTest />
      </ProtectedRoute>
      <ProtectedRoute exact path={`${path}/customTest/preview`}>
        <CustomTestPreview />
      </ProtectedRoute>
    </Switch>
  );
};

export default Practice;
