import * as React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import UserList from "components/UserList";
import CKeditor from "components/CKeditor";
import AddHwPracticeList from "components/Assignment/AddHWPracticeList";
import MUIDateTimePicker from "components/MUIDateTimePicker";

interface HomeworkFormProps {
  hwData: any;
  updateHwData: (data: any) => void;
}

const HomeworkForm: React.FC<HomeworkFormProps> = ({
  hwData,
  updateHwData,
}) => {
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateHwData((draft: any) => {
      draft.title = e.target.value;
    });
  };
  const handleStudentChange = (student: string) => {
    updateHwData((draft: any) => {
      draft.student = student;
    });
  };
  const handleDesChange = (_e: any, editor: any) => {
    updateHwData((draft: any) => {
      draft.description = editor.getData();
    });
  };
  const handleDateTimeChange = (dueDate: Date) => {
    updateHwData((draft: any) => {
      draft.dueDate = dueDate.toISOString();
    });
  };
  if (!hwData) return null;
  return (
    <Grid container spacing={3} direction="column">
      <Grid item container spacing={3} justify="space-between">
        <Grid item>
          <TextField
            label="Title"
            variant="outlined"
            name="title"
            autoComplete="off"
            value={hwData.title}
            onChange={handleTitleChange}
          />
        </Grid>
        <Grid item>
          <UserList
            handleUserChange={handleStudentChange}
            value={hwData.student}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Typography>Descriptions: </Typography>
        <CKeditor
          handleOnChange={handleDesChange}
          data={hwData.description ?? "type here...."}
        />
      </Grid>
      <Grid item>
        <MUIDateTimePicker
          label="Due Time"
          value={hwData.dueDate}
          onChange={handleDateTimeChange}
        />
      </Grid>
      <Grid item>
        <Typography>Questions:</Typography>
        <AddHwPracticeList
          practiceList={hwData.practiceList}
          updateHwData={updateHwData}
        />
      </Grid>
    </Grid>
  );
};

export default HomeworkForm;
