import * as React from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import CKeditor from "components/CKeditor";
import AWSFileInput from "components/AWSFileInput";
import { useCourseValue } from "context/course.context";
import { isObjFieldFull } from "utilities/validation";
import { useSnackbar } from "notistack";

interface ContentFileFormProps {
  content?: any;
  onCancel: () => void;
  onSubmit: (content: any) => void;
}

const initialData = {
  title: "",
  instruction: "",
  key: "",
};

const ContentFileForm: React.FC<ContentFileFormProps> = ({
  content,
  onCancel,
  onSubmit,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { course } = useCourseValue();
  const [data, setData] = React.useState<any>(content ? content : initialData);
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, title: event.target.value });
  };
  const handleInsChange = (_e: any, editor: any) => {
    setData({ ...data, instruction: editor.getData() });
  };
  const handleKeyChange = (key: string) => {
    setData({ ...data, key });
  };
  const handleCancel = () => {
    setData(initialData);
    onCancel();
  };
  const handleSubmit = () => {
    if (isObjFieldFull(data)) {
      onSubmit(data);
      onCancel();
    } else {
      enqueueSnackbar("You are missing fields.", { variant: "warning" });
    }
  };
  return (
    <Grid container direction="column">
      <Grid item>
        <TextField
          label="Title"
          value={data.title}
          onChange={handleTitleChange}
          variant="outlined"
        />
      </Grid>
      <Grid item>
        <CKeditor
          handleOnChange={handleInsChange}
          data={data.instruction ?? "type here...."}
        />
      </Grid>
      <Grid item>
        <AWSFileInput
          upload={handleKeyChange}
          path={course.student}
          fileKey={data.key}
        />
      </Grid>
      <Grid item>
        <Button color="default" onClick={handleCancel}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default ContentFileForm;
