import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import MainWrapper from "layout/MainWrapper";
import CardLayout from "layout/CardLayout";
import SpeakingForm from "components/Practice/speaking/SpeakingForm";
import { useSnackbar } from "notistack";
import { useAsyncCallback } from "react-async-hook";
import {
  deleteSpeaking,
  fetchSpeaking,
  updateSpeaking as updatingSpeaking,
} from "utilities/awsCRUD";
import LinearProgress from "@material-ui/core/LinearProgress";
import useLocalImmer from "hooks/useLocalImmer";
import { isObjFieldFull } from "utilities/validation";

export const setOriginalContext = React.createContext<any>(null);

const initialData = { typeName: "PartOneTopic" };

interface SpeakingEditionViewProps {}

const SpeakingEditionView: React.FC<SpeakingEditionViewProps> = () => {
  const location = useLocation() as any;
  const practiceData = location?.state?.practiceData;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [speaking, updateSpeaking] = useLocalImmer(
    "editSpeaking",
    practiceData ? practiceData : initialData
  );
  const asyncDeletion = useAsyncCallback(
    async (speaking: any) => await deleteSpeaking(speaking)
  );
  const asyncFetchSpeaking = useAsyncCallback(
    async (id: string, typeName: string) => await fetchSpeaking(id, typeName)
  );
  const asyncUpdateSpeaking = useAsyncCallback(
    async (orignial: any, edited: any) =>
      await updatingSpeaking(orignial, edited)
  );
  const onPreview = () => {
    if (speaking.id && isObjFieldFull(speaking)) {
      history.push("/practice/speaking/preview", { practiceData: speaking });
    } else {
      enqueueSnackbar("You are missing fields", { variant: "error" });
    }
  };
  const onUpdate = () => {
    const original = asyncFetchSpeaking.result;
    const edited = { ...speaking };
    delete edited.chosen;
    delete edited.selected;
    if (original && isObjFieldFull(edited)) {
      asyncUpdateSpeaking.execute(original, edited);
    } else {
      console.log("original", original);
      console.log("edited", edited);
      enqueueSnackbar("You are missing fields", { variant: "error" });
    }
  };
  const onDelete = () => {
    const result = asyncFetchSpeaking.result;
    if (result) {
      asyncDeletion.execute(result);
    } else {
      enqueueSnackbar(`please choose ${speaking.typeName}`, {
        variant: "error",
      });
    }
  };
  const onCancel = React.useCallback(() => {
    window.localStorage.removeItem("editSpeaking");
    history.goBack();
  }, [history]);
  //after fetch speaking, update speaking state
  React.useEffect(() => {
    if (asyncFetchSpeaking.result) {
      updateSpeaking(asyncFetchSpeaking.result);
    }
  }, [asyncFetchSpeaking.result, updateSpeaking]);
  const refetchSpeaking = asyncFetchSpeaking.execute;
  //after update speaking, renew speaking state
  React.useEffect(() => {
    const result = asyncUpdateSpeaking.result;
    if (result) {
      console.log("asyncUpdateSpeaking", result);
      refetchSpeaking(result.id, result.typeName);
      enqueueSnackbar("successfully updated speaking", { variant: "success" });
    }
  }, [
    asyncUpdateSpeaking.result,
    enqueueSnackbar,
    updateSpeaking,
    refetchSpeaking,
  ]);
  //after delete speaking, reset speaking state
  React.useEffect(() => {
    const result = asyncDeletion.result;
    if (result) {
      updateSpeaking((pre: any) => ({ typeName: pre.typeName }));
      enqueueSnackbar("successfully deleted speaking", { variant: "success" });
    }
  }, [enqueueSnackbar, asyncDeletion.result, updateSpeaking]);
  //clean persisted editSpeaking data
  React.useEffect(() => {
    window.addEventListener("beforeunload", () => {
      window.localStorage.removeItem("editSpeaking");
    });
    return () => {
      window.removeEventListener("beforeunload", () => {
        window.localStorage.removeItem("editSpeaking");
      });
    };
  }, []);
  const loading =
    asyncFetchSpeaking.loading ||
    asyncUpdateSpeaking.loading ||
    asyncDeletion.loading;
  const errorMessage =
    asyncFetchSpeaking?.error?.message ||
    asyncUpdateSpeaking?.error?.message ||
    asyncDeletion?.error?.message;
  return (
    <MainWrapper>
      <CardLayout
        title="Speaking Creation"
        mode="edit"
        loading={loading}
        onPreview={onPreview}
        onUpdate={onUpdate}
        onDelete={onDelete}
        onCancel={onCancel}
      >
        <setOriginalContext.Provider value={{ fetchSpeaking: refetchSpeaking }}>
          {loading && <LinearProgress />}
          {errorMessage && enqueueSnackbar(errorMessage, { variant: "error" })}
          {speaking && (
            <SpeakingForm
              speaking={speaking}
              updateSpeaking={updateSpeaking}
              // isEdit={true}
            />
          )}
        </setOriginalContext.Provider>
      </CardLayout>
    </MainWrapper>
  );
};

export default SpeakingEditionView;
