import * as React from "react";
import {
  Link,
  Avatar,
  Button,
  TextField,
  Grid,
  Typography,
  Container,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useSnackbar } from "notistack";
import Spinner from "components/Spinner";

export interface ForgotPasswordFormProps {
  setFormType: any;
  classes: any;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  setFormType,
  classes,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit, errors } = useForm({ mode: "onBlur" });
  const [gotCode, setGotCode] = React.useState(false);
  const onSubmit = async ({
    email,
    code,
    newPassword,
  }: {
    email: any;
    code: any;
    newPassword: any;
  }) => {
    setLoading(true);
    if (gotCode) {
      try {
        await Auth.forgotPasswordSubmit(email, code, newPassword);
        setFormType("login");
        enqueueSnackbar(
          "New password setup successfully, please login with your new credential",
          { variant: "success" }
        );
      } catch (error) {
        console.log("set new password error", error);
        enqueueSnackbar("Please check your reset code, and try again", {
          variant: "error",
        });
      }
    } else {
      try {
        await Auth.forgotPassword(email);
        setGotCode(true);
        enqueueSnackbar("Reset code was sent to your email", {
          variant: "success",
        });
      } catch (error) {
        console.log("reset code error", error);
        enqueueSnackbar("Connection error, please try again", {
          variant: "error",
        });
      }
    }
    setLoading(false);
  };
  return (
    <Container component="main" maxWidth="xs">
      {loading && <Spinner />}
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            error={!!errors.email}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            inputRef={register({
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: "please enter your email",
              },
            })}
            helperText={errors?.email?.message}
          />
          {gotCode && (
            <>
              <TextField
                error={!!errors.code}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="code"
                label="Reset Code"
                type="text"
                autoComplete="off"
                inputRef={register({
                  pattern: {
                    value: /\d{6}/,
                    message: "reset is 6 digits number",
                  },
                })}
                helperText={errors?.code?.message}
              />
              <TextField
                error={!!errors.newPassword}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="New Password"
                type="password"
                id="newPassword"
                autoComplete="off"
                inputRef={register({
                  minLength: {
                    value: 6,
                    message: "password has to be at least 6 characters",
                  },
                })}
                helperText={errors?.newPassword?.message}
              />
            </>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {gotCode ? "Submit" : "Get reset code"}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                onClick={() => setFormType("login")}
                variant="body2"
                style={{ cursor: "pointer" }}
              >
                Back to Login
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default ForgotPasswordForm;
