import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import UserList from "components/UserList";
import ExamModelList from "./ExamModelList";
import MUIDateTimePicker from "components/MUIDateTimePicker";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import * as mutations from "graphql/mutations";
import { useSnackbar } from "notistack";

interface CreateExamDialogProps {
  open: boolean;
  handleClose: () => void;
}

const validateExamData = (exam: any) => {
  return Object.values(exam).every((value: any) => value);
};

const initialData = { name: "", examModelID: "", student: "", dueDate: "" };

const CreateExamDialog: React.FC<CreateExamDialogProps> = ({
  open,
  handleClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [data, setData] = React.useState<any>(initialData);
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, name: e.target.value });
  };
  const handleUserChange = (username: string) => {
    setData({ ...data, student: username });
  };
  const handleExamModelChange = (examModelID: string) => {
    setData({ ...data, examModelID });
  };
  const handleDueDateChange = (dueDate: Date) => {
    setData({ ...data, dueDate });
  };
  const handleAddExamModel = () => {
    history.push("/exam/createModel");
  };
  const handleCancel = () => {
    setData(initialData);
    handleClose();
  };
  const handleSubmit = async () => {
    if (validateExamData(data)) {
      try {
        await API.graphql({
          query: mutations.createExam,
          variables: { input: data },
        });
        enqueueSnackbar("successfully created Exam", { variant: "success" });
        handleCancel();
      } catch (error) {
        console.log("create exam error", error);
        enqueueSnackbar("connection error please try again later", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar(
        "You are missing input, please check user field and press enter after entering value.",
        { variant: "warning" }
      );
    }
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Create Exam</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          name="name"
          label="Name"
          value={data.name}
          onChange={handleTextChange}
          variant="outlined"
          autoComplete="off"
        />
        <UserList handleUserChange={handleUserChange} value={data.student} />
        <MUIDateTimePicker
          label="DueDate"
          value={data.dueDate}
          onChange={handleDueDateChange}
        />
        <ExamModelList handleChange={handleExamModelChange} />
        <IconButton onClick={handleAddExamModel}>
          <AddCircleOutlineIcon />
        </IconButton>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateExamDialog;
