import * as React from "react";
import BottomBanner from "../components/BottomBanner";
import TopBanner from "../components/TopBanner";
import { emptyObj } from "utilities";
import Spinner from "components/Spinner";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import RenderQuestion from "../RenderQuestion";
import MainWrapper from "layout/MainWrapper";
import { fetchPractice } from "utilities/awsCRUD";

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    "& table": {
      borderSpacing: "1rem",
      borderCollapse: "separate",
    },
  },
}));

interface RenderCustomTestProps {
  format: string;
  id?: string;
  data?: any;
  time?: string;
  onSubmit: () => void;
  error: any;
  setAnswer: (answer: any) => void;
  handleAnswer: any;
  submittedAnswer: any;
  typeName: string;
}

const RenderCustomTest: React.FC<RenderCustomTestProps> = ({
  format,
  id,
  data,
  time,
  onSubmit,
  error,
  handleAnswer,
  submittedAnswer,
  setAnswer,
  typeName,
}) => {
  const classes = useStyles();
  const [practice, setPractice] = React.useState<any>(data);
  React.useEffect(() => {
    if (id) {
      fetchPractice(typeName, id, setPractice);
    }
  }, [id, typeName]);
  React.useEffect(() => {
    if (!submittedAnswer && practice) {
      handleAnswer(emptyObj(practice.answers));
      setAnswer(practice.answers);
    }
    //eslint-disable-next-line
  }, [practice]);

  if (!practice || !submittedAnswer) return <Spinner />;
  return (
    <>
      <TopBanner
        time={time}
        title="Reading Test"
        format={format}
        result={error}
        onSubmit={onSubmit}
      />
      <MainWrapper>
        <Paper className={classes.root}>
          {practice.questions.map((question: any) => (
            <Grid item key={question.range}>
              <RenderQuestion
                question={question.questionBody}
                type={question.type}
                setAnswers={handleAnswer}
                answers={submittedAnswer}
                errors={error}
              />
            </Grid>
          ))}
        </Paper>
      </MainWrapper>

      <BottomBanner
        title="Reading"
        onSubmit={onSubmit}
        submittedAnswers={submittedAnswer}
      />
    </>
  );
};

export default RenderCustomTest;
