import * as React from "react";
import { TextField } from "@material-ui/core";

interface TextListInputProps {
  value: string[];
  setValue: (textList: string[]) => void;
  name: string;
}

const TextListInput: React.FC<TextListInputProps> = ({
  value,
  setValue,
  name,
}) => {
  const handleTextChange = (text: string, index: number) => {
    value[index] = text;
    setValue([...value]);
  };
  return (
    <div>
      <p>{name}</p>
      {value.map((text: string, index: number) => (
        <div key={index}>
          <TextField
            autoFocus
            margin="normal"
            value={text}
            onChange={(event) => handleTextChange(event.target.value, index)}
            label={`Q-${index + 1}`}
            type="text"
            variant="outlined"
            style={{ minWidth: 500 }}
          />
        </div>
      ))}
    </div>
  );
};

export default TextListInput;
