import * as React from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import MainWrapper from "layout/MainWrapper";
import CardLayout from "layout/CardLayout";
import ListeningTestForm from "components/Practice/listening/ListeningTestForm";
import { useSnackbar } from "notistack";
import { useAsync, useAsyncCallback } from "react-async-hook";
import {
  deleteListeningTest,
  fetchListening,
  updateListeningTest as updatingListeningTest,
} from "utilities/awsCRUD";
import LinearProgress from "@material-ui/core/LinearProgress";
import useLocalImmer from "hooks/useLocalImmer";

interface ListeningEditionViewProps {}

const ListeningEditionView: React.FC<ListeningEditionViewProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { state } = useLocation() as any;
  const id = state?.id;
  const asyncEdition = useAsync(fetchListening, [id, "ListeningTest"]);
  const asyncDeletion = useAsyncCallback(
    async () => await deleteListeningTest(asyncEdition.result)
  );
  const asyncUpdate = useAsyncCallback(
    async () => await updatingListeningTest(asyncEdition.result, listeningTest)
  );
  const [listeningTest, updateListeningTest] = useLocalImmer(
    "editListeningTest",
    asyncEdition.result
  );

  const onPreview = () => {
    console.log("onPreview");
    enqueueSnackbar("onPreview", { variant: "success" });
  };
  const onUpdate = () => {
    asyncUpdate.execute();
  };
  const onDelete = () => {
    asyncDeletion.execute();
  };
  const onCancel = React.useCallback(() => {
    window.localStorage.removeItem("editListeningTest");
    history.goBack();
  }, [history]);
  React.useEffect(() => {
    if (asyncEdition.result && !listeningTest) {
      updateListeningTest(asyncEdition.result);
    }
    if (asyncDeletion.result) {
      enqueueSnackbar("successfully deleted listening test", {
        variant: "success",
      });
      onCancel();
    }
    if (asyncUpdate.result) {
      enqueueSnackbar("succesfully updated listening test", {
        variant: "success",
      });
      onCancel();
    }
  }, [
    asyncEdition.result,
    asyncDeletion.result,
    asyncUpdate.result,
    onCancel,
    updateListeningTest,
    enqueueSnackbar,
    listeningTest,
  ]);
  React.useEffect(() => {
    return () => {
      onCancel();
    };
  }, [onCancel]);
  const loading =
    asyncEdition.loading || asyncDeletion.loading || asyncUpdate.loading;
  const errorMessage =
    asyncEdition?.error?.message ||
    asyncDeletion?.error?.message ||
    asyncUpdate?.error?.message;
  if (!state) return <Redirect to="/practice" />;
  return (
    <MainWrapper>
      <CardLayout
        title="Listening Test Edition"
        mode="edit"
        loading={asyncEdition.loading}
        onPreview={onPreview}
        onUpdate={onUpdate}
        onDelete={onDelete}
        onCancel={onCancel}
      >
        {loading && <LinearProgress />}
        {errorMessage && enqueueSnackbar(errorMessage, { variant: "error" })}
        {listeningTest && (
          <ListeningTestForm
            listeningTest={listeningTest}
            updateListeningTest={updateListeningTest}
            isEdit={true}
          />
        )}
      </CardLayout>
    </MainWrapper>
  );
};

export default ListeningEditionView;
