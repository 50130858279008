import React from "react";
import type { RefObject } from "react";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import VolumeUp from "@material-ui/icons/VolumeUp";
import { IconButton, makeStyles, Theme } from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 300,
    [theme.breakpoints.down("sm")]: {
      width: 150,
    },
  },
}));

interface VolumeSliderProps {
  audioPlayer: RefObject<HTMLAudioElement | null>;
}

const VolumeSlider: React.FC<VolumeSliderProps> = ({ audioPlayer }) => {
  const [paused, setPaused] = React.useState<boolean>(true);
  const classes = useStyles();
  const [value, setValue] = React.useState<number>(100);
  const handleChange = (_event: any, newValue: any) => {
    setValue(newValue);
    audioPlayer.current.volume = newValue / 100;
  };
  const onPlay = () => {
    audioPlayer.current.play();
    setPaused(false);
  };
  const onPause = () => {
    audioPlayer.current.pause();
    setPaused(true);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <VolumeUp />
        </Grid>
        <Grid item xs>
          <Slider value={value} onChange={handleChange} />
        </Grid>
        <Grid item>
          {paused ? (
            <IconButton onClick={onPlay} color="primary">
              <PlayCircleOutlineIcon />
            </IconButton>
          ) : (
            <IconButton onClick={onPause} color="primary">
              <PauseCircleOutlineIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default VolumeSlider;
