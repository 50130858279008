const config = {
  awsUrl:
    "https://boxwhylmsd5a83bf5767d4a3db3fa3957bd02927c130601-dev.s3.ap-northeast-2.amazonaws.com/public/",
  books: [
    { label: "Actual Test", value: "actual-test" },
    { label: "c17", value: "c17" },
    { label: "c16", value: "c16" },
    { label: "c15", value: "c15" },
    { label: "c14", value: "c14" },
    { label: "c13", value: "c13" },
    { label: "c12", value: "c12" },
    { label: "c11", value: "c11" },
    { label: "c10", value: "c10" },
    { label: "c9", value: "c9" },
    { label: "c8", value: "c8" },
    { label: "c7", value: "c7" },
    { label: "c6", value: "c6" },
    { label: "c5", value: "c5" },
    { label: "c4", value: "c4" },
  ],
  seasons: [
    { label: "2021 9-12", value: "2021-3" },
    { label: "2022 1-4", value: "2022-1" },
    { label: "2022 5-8", value: "2022-2" },
    { label: "2022 9-12", value: "2022-3" },
    { label: "2023 1-4", value: "2023-1" },
  ],
  seasonOptions: ["2021-3", "2022-1", "2022-2", "2022-3", "2023-1"],
  subjects: {
    ReadingTestA: "reading",
    ReadingPassage: "reading",
    ReadingQuestion: "reading",
    ReadingTestG: "reading",
    ListeningTest: "listening",
    ListeningSection: "listening",
    ListeningQuestion: "listening",
    Essay: "writing",
    Graph: "writing",
    Letter: "writing",
    PartOneTopic: "speaking",
    PartTwo: "speaking",
    PartOne: "speaking",
    PartThree: "speaking",
    SpeakingList: "speaking",
  },
  editables: [
    "ReadingTestA",
    "ReadingTestG",
    "ListeningTest",
    "Essay",
    "Graph",
    "Letter",
    "PartOneTopic",
    "PartTwo",
  ],
  genre: [
    { label: "A", value: "A" },
    { label: "G", value: "G" },
  ],
  writingTasks: [
    { label: "Essay", value: "Essay" },
    { label: "Letter", value: "Letter" },
    { label: "Graph", value: "Graph" },
  ],
  essayType: [
    { label: "Discuss both views", value: "DiscussBothViews" },
    { label: "Agree or disagree", value: "AgreeOrDisagree" },
    { label: "Positive or negative", value: "PositiveOrNegative" },
    {
      label: "Advantage outweigh disadvantage",
      value: "AdvantageOutweighDisadvantage",
    },
    { label: "Advantage&Disadvantage", value: "AdvantageAndDisadvantage" },
    { label: "Causes&Solutions", value: "CausesAndSolutions" },
    { label: "Causes&Effects", value: "CausesAndEffects" },
    { label: "Other double questions", value: "OtherDoubleQuestions" },
  ],
  essayTopic: [
    { label: "Education", value: "Education" },
    { label: "Technology&Science", value: "TechnologyAndScience" },
    { label: "Consumerism&Advertising", value: "ConsumerismAndAdvertising" },
    { label: "Government", value: "Government" },
    { label: "Arts", value: "Arts" },
    { label: "Health", value: "Health" },
    { label: "Career", value: "Career" },
    { label: "PersonalLife", value: "PersonalLife" },
    { label: "Travel&Transportation", value: "TravelAndTransportation" },
    { label: "Tradition", value: "Tradition" },
    { label: "CrimeAndSocialProblem", value: "CrimeAndSocialProblem" },
    { label: "Globalization", value: "Globalization" },
    {
      label: "Environment&Animal protection",
      value: "EnvironmentAndAnimalProtection",
    },
  ],
  graphType: [
    { label: "Line", value: "Line" },
    { label: "Bar", value: "Bar" },
    { label: "Pie", value: "Pie" },
    { label: "Table", value: "Table" },
    { label: "Map", value: "Map" },
    { label: "Flow", value: "Flow" },
    { label: "Double", value: "Double" },
  ],
  letterType: [
    { label: "Formal", value: "Formal" },
    { label: "SemiFormal", value: "SemiFormal" },
    { label: "Personal", value: "Personal" },
  ],
  letterAim: [
    { label: "Complaint", value: "Complaint" },
    { label: "Invitation", value: "Invitation" },
    { label: "Application&Resignation", value: "ApplicationAndResignation" },
    { label: "Request", value: "Request" },
    { label: "Arrangement", value: "Arrangement" },
    { label: "Explanation", value: "Explanation" },
    { label: "Informative&News", value: "InformativeAndNews" },
    { label: "Apology", value: "Apology" },
  ],
  readingTypeName: [
    { label: "Reading Test", value: "ReadingTest" },
    { label: "Reading Passage", value: "ReadingPassage" },
    { label: "Reading Question", value: "ReadingQuestion" },
  ],
  readingTypeOptions: [
    { label: "Single Choice", value: "SC" },
    { label: "Multiple Choices", value: "MC" },
    { label: "True False or Not Given", value: "TFNG" },
    { label: "Matching", value: "Matching" },
    { label: "List of Headings", value: "LOH" },
    { label: "Info", value: "Info" },
    { label: "Filling Gaps", value: "FG" },
    { label: "Filling Gaps with Options", value: "FGO" },
  ],
  readingPassageNumberA: [
    { label: "Passage 1", value: "p1" },
    { label: "Passage 2", value: "p2" },
    { label: "Passage 3", value: "p3" },
  ],
  readingPassageNumberG: [
    { label: "Section 1 A", value: "s1a" },
    { label: "Section 1 B", value: "s1b" },
    { label: "Section 2 A", value: "s2a" },
    { label: "Section 2 B", value: "s2b" },
    { label: "Section 3", value: "s3" },
  ],
  listeningSectionNumber: [
    { label: "Section 1", value: "1" },
    { label: "Section 2", value: "2" },
    { label: "Section 3", value: "3" },
    { label: "Section 4", value: "4" },
  ],
  listeningTypeName: [
    { label: "Listening Test", value: "ListeningTest" },
    { label: "Listening Section", value: "ListeningSection" },
    { label: "Listening Question", value: "ListeningQuestion" },
  ],
  listeningTypeOptions: [
    { label: "Single Choice", value: "SC" },
    { label: "Multiple Choices", value: "MC" },
    { label: "Matching", value: "Matching" },
    { label: "Filling Gaps", value: "FG" },
    { label: "Filling Gaps with Options", value: "FGO" },
    { label: "Map", value: "Map" },
  ],
  partOneType: [
    "PresentSimple",
    "PastSimple",
    "PastExp",
    "Listing",
    "Generalization",
    "Future",
    "Comparison",
    "Superlative",
    "Change",
    "Description",
  ],
  partThreeType: ["Opinion", "Hypothetical", "Reason", "Listing", "Comparison"],
  speakingPartTwoType: [
    { label: "person", value: "Person" },
    { label: "event", value: "Event" },
    { label: "object", value: "Object" },
    { label: "abstract", value: "Abstract" },
    { label: "place", value: "Place" },
  ],
  speakingTypeName: [
    { label: "Part 1 Topic", value: "PartOneTopic" },
    { label: "Part 2", value: "PartTwo" },
    { label: "Part 1", value: "PartOne" },
    { label: "Part 3", value: "PartThree" },
  ],
} as any;
export default config;
