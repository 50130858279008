import * as React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import UserList from "components/UserList";
import CKeditor from "components/CKeditor";
import AddNotePracticeList from "components/Assignment/AddNotePracticeList";

interface NoteFormProps {
  noteData: any;
  updateNoteData: (data: any) => void;
}

const NoteForm: React.FC<NoteFormProps> = ({ noteData, updateNoteData }) => {
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateNoteData((draft: any) => {
      draft.title = e.target.value;
    });
  };
  const handleStudentChange = (student: string) => {
    updateNoteData((draft: any) => {
      draft.student = student;
    });
  };
  const handleDesChange = (_e: any, editor: any) => {
    updateNoteData((draft: any) => {
      draft.description = editor.getData();
    });
  };
  const handleAddPractice = (practice: any) => {
    updateNoteData((draft: any) => {
      draft.practiceList.push(practice);
    });
  };
  const handleDeletePractice = (index: number) => {
    updateNoteData((draft: any) => {
      draft.practiceList.splice(index, 1);
    });
  };
  if (!noteData) return null;
  return (
    <Grid container spacing={3} direction="column">
      <Grid item container spacing={3} justify="space-between">
        <Grid item>
          <TextField
            label="Title"
            variant="outlined"
            name="title"
            autoComplete="off"
            value={noteData.title}
            onChange={handleTitleChange}
          />
        </Grid>
        <Grid item>
          <UserList
            handleUserChange={handleStudentChange}
            value={noteData.student}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Typography>Descriptions: </Typography>
        <CKeditor
          handleOnChange={handleDesChange}
          data={noteData.description ?? "type here...."}
        />
      </Grid>
      <Grid item>
        <AddNotePracticeList
          practiceList={noteData.practiceList}
          append={handleAddPractice}
          remove={handleDeletePractice}
        />
      </Grid>
    </Grid>
  );
};

export default NoteForm;
