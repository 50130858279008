// import { Fab } from "@material-ui/core";
import * as React from "react";
import { useTimer } from "react-timer-hook";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "fixed",
      top: "1rem",
      left: "50%",
      margin: "auto",
      width: "100px",
      border: "3px solid white",
      borderRadius: "50px",
      color: "white",
      zIndex: 1100,
      cursor: "pointer",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        border: "3px solid ",
        borderColor: "#0d47a1",
        color: theme.palette.primary.main,
        top: "4rem",
        left: "1%",
        backgroundColor: "white",
      },
    },
    static: {
      // position: "fixed",
      margin: "auto",
      width: "100px",
      border: "3px solid #0d47a1",
      borderRadius: "50px",
      color: theme.palette.primary.main,
      cursor: "default",
      textAlign: "center",
    },
  })
);

export interface PracticeTimerProps {
  timeLimit?: number;
  handleSubmit?: () => void;
  position?: "fixed" | "static";
}

const PracticeTimer: React.FC<PracticeTimerProps> = ({
  timeLimit,
  handleSubmit,
  position,
}) => {
  const time = new Date();
  const classes = useStyles();
  const { seconds, minutes, start } = useTimer({
    expiryTimestamp: time.setSeconds(time.getSeconds() + timeLimit),
    onExpire: () => handleSubmit(),
  });
  React.useEffect(() => {
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={position === "fixed" ? classes.root : classes.static}>
      {minutes}: {seconds}
    </div>
  );
};

export default PracticeTimer;

PracticeTimer.defaultProps = {
  position: "fixed",
};
