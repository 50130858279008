import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { compareAnswerLabel } from "utilities";

export interface ResultDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  resultList: any;
}

const countGrade = (resultList: any) => {
  let grade = 0;
  for (const [num, meta] of Object.entries(resultList) as any) {
    if (num.includes("-")) {
      for (const a of meta.input) {
        if (meta.ans.includes(a)) {
          grade = grade + 1;
        }
      }
    } else {
      if (meta.correct) {
        grade = grade + 1;
      }
    }
  }
  return grade;
};

const ResultDialog: React.FC<ResultDialogProps> = ({
  open,
  resultList,
  setOpen,
}) => {
  const totalQuestions = Object.keys(resultList)
    .map((label: string) => {
      if (label.includes("-")) return label.split("-");
      return label;
    })
    .flat().length;
  const grade = countGrade(resultList);
  console.log("resultList", resultList);
  const sortedKeys = Object.keys(resultList).sort(compareAnswerLabel);
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">Result</DialogTitle>
      <DialogContent>
        <p>Total Questions：{totalQuestions}</p>
        <p>Grade: {grade}</p>
        <hr />
        <table style={{ width: "100%", textAlign: "left" }}>
          <thead>
            <tr>
              <th>Question Number</th>
              <th>Your answer</th>
              <th>correct answer</th>
            </tr>
          </thead>
          <tbody>
            {sortedKeys.map((num: string) => {
              const meta = resultList[num];
              const { ans, input, correct } = meta;
              return (
                <tr key={num}>
                  <td>{num}</td>
                  {correct ? (
                    <>
                      <td style={{ color: "green" }}>
                        <b>{input}</b>
                      </td>
                      <td>
                        <CheckIcon style={{ color: "green" }} />
                      </td>
                    </>
                  ) : (
                    <>
                      <td style={{ color: "red" }}>
                        <b>{input}</b>
                      </td>
                      <td style={{ color: "green" }}>
                        <b>{ans}</b>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
            {/* {Object.entries(resultList).map((data: any) => {
              const [num, meta] = data;
              const { ans, input, correct } = meta;
              return (
                <tr key={num}>
                  <td>{num}</td>
                  {correct ? (
                    <>
                      <td style={{ color: "green" }}>
                        <b>{input}</b>
                      </td>
                      <td>
                        <CheckIcon style={{ color: "green" }} />
                      </td>
                    </>
                  ) : (
                    <>
                      <td style={{ color: "red" }}>
                        <b>{input}</b>
                      </td>
                      <td style={{ color: "green" }}>
                        <b>{ans}</b>
                      </td>
                    </>
                  )}
                </tr>
              );
            })} */}
          </tbody>
        </table>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResultDialog;
