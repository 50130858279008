import * as React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  makeStyles,
  CardActions,
} from "@material-ui/core";
// import MainWrapper from "./MainWrapper";

export interface WritingCardProps {
  title: string;
  buttons?: any;
}

const useStyles = makeStyles(() => ({
  root: {
    padding: "5px",
    minHeight: "70vh",
    display: "flex",
    flexDirection: "column",
    marginTop: "5px",
  },
  cardContent: {
    flexGrow: 1,
  },
  cardActions: {
    justifyContent: "space-between",
  },
}));

const WritingCard: React.FC<WritingCardProps> = ({
  title,
  children,
  buttons,
}) => {
  const classes = useStyles();
  return (
    // <MainWrapper>
    <Card className={classes.root}>
      <CardHeader title={title} />
      <CardContent className={classes.cardContent}>{children}</CardContent>
      {buttons && (
        <CardActions className={classes.cardActions}>{buttons}</CardActions>
      )}
    </Card>
    // </MainWrapper>
  );
};

export default WritingCard;
