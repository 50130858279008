import * as React from "react";
import QuestionList from "../QuestionList";

export interface SectionProps {
  sectionID: string;
  questions: any[];
  setQuestions: (question: any) => void;
}

const Section: React.FC<SectionProps> = ({
  sectionID,
  questions,
  setQuestions,
}) => {
  const sectionNumber = sectionID.split("-").slice(-1)[0];
  return (
    <>
      <p>section {sectionNumber} </p>
      <QuestionList
        sectionID={sectionID}
        questions={[...questions]}
        setQuestions={setQuestions}
      />
    </>
  );
};

export default Section;
