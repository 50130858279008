import * as React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  Chip,
  MenuItem,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";

interface MultiSelectProps {
  options: string[];
  label: string;
  value: string[];
  setValue: (value: string[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
  })
);

const MultiSelect: React.FC<MultiSelectProps> = ({
  label,
  value,
  setValue,
  options,
}) => {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as string[]);
  };
  return (
    <FormControl className={classes.formControl} variant="outlined">
      <InputLabel id="mutiple-chip-label">{label}</InputLabel>
      <Select
        labelId="mutiple-chip-label"
        multiple
        value={value}
        label={label}
        onChange={handleChange}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {(selected as string[]).map((value) => (
              <Chip key={value} label={value} className={classes.chip} />
            ))}
          </div>
        )}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;
