import { Grid } from "@material-ui/core";
import RadioSelector from "components/RadioSelector";
import PartTwoForm from "./PartTwoForm";
import PartOneTopicForm from "./PartOneTopicForm";
import * as React from "react";

interface SpeakingFormProps {
  speaking: any;
  updateSpeaking: (data: any) => void;
  isEdit?: boolean;
}

const SpeakingForm: React.FC<SpeakingFormProps> = ({
  speaking,
  updateSpeaking,
  isEdit,
}) => {
  const { typeName } = speaking;
  const onSpeakingTypeChange = (type: string) => {
    updateSpeaking((_draft: any) => ({ typeName: type }));
  };
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <RadioSelector
          value={typeName}
          name="Speaking types"
          options={[
            { value: "PartOneTopic", label: "Part One Topic" },
            { value: "PartTwo", label: "Part Two" },
          ]}
          setValue={onSpeakingTypeChange}
          isEdit={isEdit}
        />
      </Grid>
      {typeName === "PartOneTopic" && (
        <PartOneTopicForm speaking={speaking} updateSpeaking={updateSpeaking} />
      )}
      {typeName === "PartTwo" && (
        <PartTwoForm speaking={speaking} updateSpeaking={updateSpeaking} />
      )}
    </Grid>
  );
};

SpeakingForm.defaultProps = {
  isEdit: false,
};

export default SpeakingForm;
