import { Button } from "@material-ui/core";
import * as React from "react";
import RenderQuestion from "../RenderQuestion";
import AddDropDown from "./AddDropDown";
import AddFG from "./AddFG";
import AddFGO from "./AddFGO";
import AddMC from "./AddMC";
import AddSC from "./AddSC";
import AddTFNG from "./AddTFNG";

export interface QuestionProps {
  listening?: boolean;
  type: string;
  range: string;
  question: any;
  setQuestion: (data: any) => void;
  answers: any;
  setAnswers: (data: any) => void;
  onSubmit?: (data: any) => void;
  step: number;
  setStep: (step: number) => void;
}

const AddQuestion: React.FC<QuestionProps> = (props) => {
  const { step, setStep, setQuestion } = props;
  const handleFormSubmit = (data: any) => {
    setQuestion(data);
    setStep(1);
  };

  const editQuestion = () => {
    switch (props.type) {
      case "SC":
        return <AddSC {...props} onSubmit={handleFormSubmit} />;
      case "MC":
        return <AddMC {...props} onSubmit={handleFormSubmit} />;
      case "Matching":
        return <AddDropDown {...props} onSubmit={handleFormSubmit} />;
      case "Map":
        return <AddDropDown {...props} onSubmit={handleFormSubmit} />;
      case "FG":
        return <AddFG {...props} onSubmit={handleFormSubmit} />;
      case "FGO":
        return <AddFGO {...props} onSubmit={handleFormSubmit} />;
      case "FGD":
        return <AddFG {...props} onSubmit={handleFormSubmit} />;
      case "TFNG":
        return <AddTFNG {...props} onSubmit={handleFormSubmit} />;
      case "Info":
        return <AddDropDown {...props} onSubmit={handleFormSubmit} />;
      case "LOH":
        return <AddDropDown {...props} onSubmit={handleFormSubmit} />;
      default:
        return <p>Please select a question type</p>;
    }
  };

  return (
    <>
      {step === 1 ? (
        <>
          <RenderQuestion
            question={props.question}
            setAnswers={props.setAnswers}
            answers={props.answers}
            type={props.type}
          />
          <Button
            onClick={() => {
              setStep(0);
            }}
          >
            edit question
          </Button>
        </>
      ) : (
        editQuestion()
      )}
    </>
  );
};

export default AddQuestion;
