import * as React from "react";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { TextField, CircularProgress } from "@material-ui/core";
import { useAsyncCallback } from "react-async-hook";
import {
  listPartOneTopic,
  // fetchPartOneTopic,
  createPartOneTopic,
} from "utilities/awsCRUD";
import PartOneTopicCreationDialog from "./PartOneTopicCreationDialog";
import { useSnackbar } from "notistack";
import { isObjFieldFull } from "utilities/validation";
import { setOriginalContext } from "routes/Practice/speaking/SpeakingCreationView";

interface PartOneTopicDropDownProps {
  speaking: any;
  updateSpeaking: (data: any) => void;
}

const initialDialogValue = { id: "", season: [] } as any;

const filter = createFilterOptions<any>();
const handleOptionLabel = (option: any) =>
  typeof option === "string" ? option : option.id;
const handleFilterOptions = (options: any, params: any) => {
  const filtered = filter(options, params) as any;
  if (params.inputValue !== "") {
    filtered.push({
      inputValue: params.inputValue,
      id: `Add "${params.inputValue}"`,
    });
  }
  return filtered;
};

const PartOneTopicDropDown: React.FC<PartOneTopicDropDownProps> = ({
  speaking,
  updateSpeaking,
}) => {
  const { fetchSpeaking } = React.useContext(setOriginalContext);
  const { typeName } = speaking;
  const { enqueueSnackbar } = useSnackbar();
  const asyncOptions = useAsyncCallback(listPartOneTopic);
  const asyncCreatePartOneTopic = useAsyncCallback(createPartOneTopic);
  const value = speaking.id ? speaking : null;
  const [options, setOptions] = React.useState<any[]>([]);
  const [dialogValue, setDialogValue] = React.useState<any>(initialDialogValue);
  const [open, setOpen] = React.useState<boolean>(false);
  const onValueChange = async (_event: any, newValue: any) => {
    if (typeof newValue === "string" || newValue?.inputValue) {
      const id = typeof newValue === "string" ? newValue : newValue.inputValue;
      setDialogValue({ ...dialogValue, id });
      setOpen(true);
    } else if (!newValue) {
      updateSpeaking({ typeName });
    } else {
      fetchSpeaking(newValue.id, "PartOneTopic");
      // const newPart1Topic = await fetchPartOneTopic(newValue.id);
      // updateSpeaking(newPart1Topic);
    }
  };
  const onOpen = async (_event: any) => {
    asyncOptions.execute();
  };
  const onClose = () => {
    setOpen(false);
    setDialogValue(initialDialogValue);
  };
  const onSubmit = () => {
    if (isObjFieldFull(dialogValue)) {
      asyncCreatePartOneTopic.execute(dialogValue);
    } else {
      enqueueSnackbar("You are missing fields", { variant: "error" });
    }
  };
  React.useEffect(() => {
    if (asyncOptions.result) {
      setOptions(asyncOptions.result);
    }
  }, [asyncOptions.result, setOptions]);

  React.useEffect(() => {
    const result = asyncCreatePartOneTopic.result;
    if (result) {
      setOptions((options) => [...options, result]);
      fetchSpeaking(result.id, "PartOneTopic");
      onClose();
    }
    //eslint-disable-next-line
  }, [asyncCreatePartOneTopic.result, setOptions]);
  return (
    <>
      <Autocomplete
        onOpen={onOpen}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        options={options}
        getOptionLabel={handleOptionLabel}
        filterOptions={handleFilterOptions}
        value={value}
        onChange={onValueChange}
        style={{ minWidth: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Part One Topics"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {asyncOptions.loading ? (
                    <CircularProgress color="primary" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      {open && (
        <PartOneTopicCreationDialog
          dialogValue={dialogValue}
          setDialogValue={setDialogValue}
          open={open}
          onClose={onClose}
          onSubmit={onSubmit}
          loading={asyncCreatePartOneTopic.loading}
        />
      )}
    </>
  );
};

export default PartOneTopicDropDown;
