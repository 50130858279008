import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Highlighter from "web-highlighter";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import Draggable from "react-draggable";
import StopIcon from "@material-ui/icons/Stop";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(2),
  },
  yellow: {
    backgroundColor: "rgba(255,255,0, 0.5)",
    "&:hover": {
      backgroundColor: "rgba(255,255,0, 0.8)",
    },
  },
  red: {
    backgroundColor: "rgba(255,0,0,0.5)",
    "&:hover": {
      backgroundColor: "rgba(255,0,0, 0.8)",
    },
  },
  blue: {
    backgroundColor: "rgba(0,0,255, 0.5)",
    "&:hover": {
      backgroundColor: "rgba(0,0,255, 0.8)",
    },
  },
  green: {
    backgroundColor: "rgba(0,128,0, 0.5)",
    "&:hover": {
      backgroundColor: "rgba(0,128,0, 0.8)",
    },
  },
}));

const useHighlight = () => {
  const [color, setColor] = React.useState("yellow");
  const [enable, setEnable] = React.useState(true);
  const [highlighter] = React.useState(() => new Highlighter());
  const handleEnable = () => {
    if (enable) {
      highlighter.stop();
      setEnable(false);
    } else {
      highlighter.run();
      setEnable(true);
    }
  };
  React.useEffect(() => {
    highlighter.setOption({
      wrapTag: "span",
      style: {
        className: "highlight-yellow",
      },
    });

    highlighter.on(Highlighter.event.CLICK, ({ id }) => {
      highlighter.remove(id);
    });
    highlighter.run();
    return () => {
      highlighter.dispose();
      highlighter.stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    const highlightColor = `highlight-${color}`;
    highlighter.setOption({
      style: {
        className: highlightColor,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const highlightFabs = (
    <Draggable>
      <SpeedDial
        ariaLabel="highlighter"
        className={classes.speedDial}
        icon={
          <SpeedDialIcon
            openIcon={<ColorLensIcon />}
            icon={<BorderColorIcon />}
          />
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        <SpeedDialAction
          key="yellow"
          tooltipTitle="yellow"
          onClick={() => setColor("yellow")}
          classes={{ fab: classes.yellow }}
          icon={<FormatColorFillIcon />}
        />
        <SpeedDialAction
          key="red"
          tooltipTitle="red"
          onClick={() => setColor("red")}
          classes={{ fab: classes.red }}
          icon={<FormatColorFillIcon />}
        />
        <SpeedDialAction
          key="blue"
          tooltipTitle="blue"
          onClick={() => setColor("blue")}
          classes={{ fab: classes.blue }}
          icon={<FormatColorFillIcon />}
        />
        <SpeedDialAction
          key="green"
          tooltipTitle="green"
          onClick={() => setColor("green")}
          classes={{ fab: classes.green }}
          icon={<FormatColorFillIcon />}
        />
        <SpeedDialAction
          key="clean"
          tooltipTitle="clear all"
          onClick={() => highlighter.removeAll()}
          icon={<DeleteForeverIcon />}
        />
        <SpeedDialAction
          key={enable ? "stop" : "run"}
          tooltipTitle={enable ? "stop" : "run"}
          onClick={handleEnable}
          icon={enable ? <StopIcon /> : <PlayArrowIcon />}
        />
      </SpeedDial>
    </Draggable>
  );
  return { highlighter, highlightFabs };
};

export default useHighlight;
