import * as React from "react";
import { tableIcons } from "utilities/materialTableIcon";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useAuthValue } from "context/auth.context";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import * as queries from "graphql/queries";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import useAmplifyQuery from "hooks/useAmplifyQuery";
import LinearProgress from "@material-ui/core/LinearProgress";

export interface NoteTableProps {}

const sortByCreatedAt = (a: any, b: any) =>
  new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();

const options = {
  actionsColumnIndex: -1,
  sorting: true,
  pageSize: 10,
};
const adminTableColumn = [
  { title: "Title", field: "title" },
  {
    title: "Student",
    field: "student",
  },
];

const NoteTable: React.FC<NoteTableProps> = () => {
  const { isAdmin } = useAuthValue();
  const history = useHistory();
  const { value, status } = useAmplifyQuery(queries.listNotes);
  const columns = isAdmin
    ? adminTableColumn
    : adminTableColumn.filter((col: any) => col.title !== "Student");
  const adminActions = [
    {
      icon: () => <ArrowForwardIcon />,
      tooltip: "go to note detail page",
      onClick: (_event: any, rowData: any) =>
        history.push({
          pathname: "/note/detail",
          state: { noteData: rowData },
        }),
    },
    {
      icon: () => <AddCircleIcon />,
      tooltip: "Add Note",
      isFreeAction: true,
      onClick: () => history.push("/note/create"),
    },
  ];
  const data = value ? value.data.listNotes.items.sort(sortByCreatedAt) : [];
  const studentActions = [
    {
      icon: () => <ArrowForwardIcon />,
      tooltip: "go to note detail page",
      onClick: (_event: any, rowData: any) =>
        history.push({
          pathname: "/note/detail",
          state: { noteData: rowData },
        }),
    },
  ];
  const actions = isAdmin ? adminActions : studentActions;
  return (
    <>
      {status === "pending" && <LinearProgress />}
      <MaterialTable
        title="Note List"
        icons={tableIcons}
        columns={columns}
        data={data}
        actions={actions}
        options={options}
      />
    </>
  );
};

export default NoteTable;
