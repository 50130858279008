import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import MultiSelect from "components/MultiSelect";
import DropDown from "components/DropDown";
import TextListInput from "components/TextListInput";
import config from "customConfig";

interface PartTwoCreationDialogProps {
  dialogValue: any;
  setDialogValue: (data: any) => void;
  open: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  loading: boolean;
}

const PartTwoCreationDialog: React.FC<PartTwoCreationDialogProps> = ({
  open,
  onClose,
  setDialogValue,
  dialogValue,
  onSubmit,
  loading,
}) => {
  const setDialogValueSeason = (season: string[]) => {
    setDialogValue({ ...dialogValue, season: [...season] });
  };
  const setDialogValueType = (type: string) => {
    setDialogValue({ ...dialogValue, type });
  };
  const setDialogValueSubQuestions = (subQuestions: string[]) => {
    setDialogValue({ ...dialogValue, subQuestions });
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Add Part Two</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid item>
            <TextField
              autoFocus
              variant="outlined"
              //   style={{ minWidth: 500 }}
              fullWidth={true}
              value={dialogValue.prompt}
              onChange={(event) =>
                setDialogValue({ ...dialogValue, prompt: event.target.value })
              }
              label="prompt"
              type="text"
            />
          </Grid>
          <Grid item container>
            <Grid item>
              <MultiSelect
                options={config.seasonOptions}
                label="Season"
                value={dialogValue.season}
                setValue={setDialogValueSeason}
              />
            </Grid>
            <Grid item>
              <DropDown
                name="type"
                value={dialogValue.type}
                setValue={setDialogValueType}
                options={config.speakingPartTwoType}
              />
            </Grid>
          </Grid>

          <Grid item>
            <TextListInput
              name="Part 2 Sub Questions"
              value={dialogValue.subQuestions}
              setValue={setDialogValueSubQuestions}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          color="primary"
          variant="outlined"
          startIcon={loading ? <CircularProgress size={18} /> : null}
          disabled={loading}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PartTwoCreationDialog;
