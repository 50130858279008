import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { useSnackbar } from "notistack";

const useAmplifyQuery = (query, variables = null, normalize = null) => {
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState("idle");
  const [value, setValue] = useState(null);
  useEffect(() => {
    let promise;
    if (query) {
      (async () => {
        setStatus("pending");
        setValue(null);
        if (variables) {
          promise = API.graphql({ query, variables });
        } else {
          promise = API.graphql({ query });
        }

        try {
          const data = await promise;
          if (normalize) {
            setValue(normalize(data));
          } else {
            setValue(data);
          }

          setStatus("success");
        } catch (error) {
          console.log("useAmplifyAPI fetch error", error);
          enqueueSnackbar("Connection error, please try agian later", {
            variant: "error",
          });
          setStatus("error");
        }
      })();
    }

    return () => {
      API.cancel(promise, "amplify fetch cancelled");
    };
    //eslint-disable-next-line
  }, []);

  return { value, status };
};
export default useAmplifyQuery;
