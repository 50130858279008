import * as React from "react";
import { API } from "aws-amplify";
import * as queries from "graphql/queries";
import TopBanner from "components/Practice/components/TopBanner";
import BottomBanner from "components/Practice/components/BottomBanner";
import Spinner from "components/Spinner";
import RenderWritingQuestion from "components/Practice/writing/RenderWritingQuestion";
import { Button, Paper, makeStyles, Theme } from "@material-ui/core";

interface RenderWritingProps {
  format: string;
  time?: string;
  onSubmit: () => void;
  task1ID?: string;
  task1Data?: any;
  task1Result?: string;
  handleTask1?: (task1: string) => void;
  submittedTask1?: string;
  task2ID?: string;
  task2Data?: any;
  task2Result?: string;
  handleTask2?: (task2: string) => void;
  submittedTask2?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: "1rem",
  },
  button: {
    color: theme.palette.primary.main,
  },
  buttonContainer: {
    paddingTop: "2rem",
  },
}));

const RenderWriting: React.FC<RenderWritingProps> = ({
  format,
  time,
  onSubmit,
  task1ID,
  task1Data,
  task1Result,
  handleTask1,
  submittedTask1,
  task2ID,
  task2Data,
  task2Result,
  handleTask2,
  submittedTask2,
}) => {
  const classes = useStyles();
  const [task1, setTask1] = React.useState<any>(task1Data);
  const [task2, setTask2] = React.useState<any>(task2Data);
  const [taskIndex, setTaskIndex] = React.useState<1 | 2>(1);
  const handleTaskChange = () => {
    if (taskIndex === 1) {
      setTaskIndex(2);
    }
    if (taskIndex === 2) {
      setTaskIndex(1);
    }
  };
  const renderTask1 = task1 && (
    <RenderWritingQuestion
      value={submittedTask1}
      writingData={task1}
      submittedAnswers={task1Result}
      onChange={handleTask1}
      showSample={!!task1Result}
    />
  );
  const renderTask2 = task2 && (
    <RenderWritingQuestion
      value={submittedTask2}
      writingData={task2}
      submittedAnswers={task2Result}
      onChange={handleTask2}
      showSample={!!task2Result}
    />
  );
  React.useEffect(() => {
    if (task1ID || task2ID) {
      (async () => {
        try {
          if (task1ID && !task1) {
            if (task1ID.includes("letter")) {
              const returnedData = (await API.graphql({
                query: queries.getLetter,
                variables: { id: task1ID },
              })) as any;
              setTask1(returnedData.data.getLetter);
            } else {
              const returnedData = (await API.graphql({
                query: queries.getGraph,
                variables: { id: task1ID },
              })) as any;
              setTask1(returnedData.data.getGraph);
            }
          }
          if (task2ID && !task2) {
            const returnedData = (await API.graphql({
              query: queries.getEssay,
              variables: { id: task2ID },
            })) as any;
            setTask2(returnedData.data.getEssay);
          }
        } catch (error) {
          console.log("fetch writing task error", error);
        }
      })();
    }
    //eslint-disable-next-line
  }, [task1ID, task2ID]);
  if (!task1 && !task2) return <Spinner />;
  return (
    <>
      <TopBanner
        time={time}
        title="Writing Task"
        format={format}
        result={task1Result || task2Result}
        onSubmit={onSubmit}
      />
      <Paper className={classes.paper}>
        {task1 && !task2 && renderTask1}
        {task2 && !task1 && renderTask2}
        {task1 && task2 && (
          <>
            {taskIndex === 1 ? renderTask1 : renderTask2}
            <div className={classes.buttonContainer}>
              <Button
                onClick={handleTaskChange}
                style={{ display: "block", margin: "auto", color: "#0d47a1" }}
                color="secondary"
                variant="contained"
              >
                {taskIndex === 1 ? "Go to Task2" : "Back to Task1"}
              </Button>
            </div>
          </>
        )}
      </Paper>

      <BottomBanner
        title="Writing"
        onSubmit={onSubmit}
        submittedAnswers={
          task1 && task2
            ? { task1: submittedTask1, task2: submittedTask2 }
            : task1
            ? { task1: submittedTask1 }
            : { task2: submittedTask2 }
        }
      />
    </>
  );
};

export default RenderWriting;
