import API from "@aws-amplify/api";
import * as React from "react";
import AudioRecorder from "./AudioRecorder";
import * as queries from "graphql/queries";
import SpeakingRecordList from "./SpeakingRecordList";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: "1rem",
      border: "solid 2px",
      marginTop: "1rem",
      marginBottom: "1rem",
      "& p": {
        fontWeight: 700,
        fontSize: "1rem",
      },
    },
  })
);

interface PartTwoQuestionProps {
  data: any;
  username: string;
}

const PartTwoQuestion: React.FC<PartTwoQuestionProps> = ({
  data,
  username,
}) => {
  const classes = useStyles();
  const [recordList, setRecordList] = React.useState<any>([]);
  const { id: questionID, typeName: questionTypeName } = data;

  React.useEffect(() => {
    (async () => {
      try {
        const {
          data: {
            byUserTypeTypeID: { items },
          },
        } = (await API.graphql({
          query: queries.byUserTypeTypeId,
          variables: {
            user: username,
            typeTypeID: { eq: { type: questionTypeName, typeID: questionID } },
            limit: 100,
          },
        })) as any;
        setRecordList(items);
      } catch (error) {
        console.log("list recordlist error", error);
      }
    })();
    return () => {
      setRecordList([]);
    };
  }, [questionID, questionTypeName, username]);
  return (
    <>
      <div className={classes.root}>
        <p>Describe {data.prompt}</p>
        <p>You should say:</p>
        <ul>
          <li>{data.subQuestions[0]}</li>
          <li>{data.subQuestions[1]}</li>
          <li>{data.subQuestions[2]}</li>
        </ul>
        <p>{data.subQuestions[3]}</p>
      </div>
      <p>
        You will have to talk about the topic for one to two minutes. You have
        one minute to think about what you are going to say. You can make some
        notes to help you if you wish
      </p>
      <SpeakingRecordList
        recordList={recordList}
        setRecordList={setRecordList}
      />
      <AudioRecorder
        username={username}
        questionTypeName={questionTypeName}
        questionID={questionID}
        setRecordList={setRecordList}
      />
    </>
  );
};

export default PartTwoQuestion;
