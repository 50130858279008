import * as React from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Avatar,
  Typography,
} from "@material-ui/core";
import LibraryBooksTwoToneIcon from "@material-ui/icons/LibraryBooksTwoTone";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useLocation, useHistory, Redirect, Link } from "react-router-dom";
import CardLayout from "layout/CardLayout";
import RenderInstruction from "layout/RenderInstruction";
import customConfig from "customConfig";

const { subjects } = customConfig;

export interface NoteDetailViewProps {
  preview?: boolean;
}

const NoteDetailView: React.FC<NoteDetailViewProps> = ({ preview }) => {
  const { state } = useLocation() as any;
  const history = useHistory();
  const noteData = state?.noteData;
  if (!noteData) return <Redirect to="/note" />;

  const linkTo = (p: any) => {
    const { typeName, id } = p;
    const subject = subjects[typeName];

    return `/practice/${subject}?typeName=${typeName}&id=${id}`;
  };
  const onEdit = () => {
    history.push({ pathname: "edit", state: { noteData } });
  };

  const mode = preview ? "preview" : "detail";
  const { description, practiceList } = noteData;
  console.log("practiceList", practiceList);

  return (
    <CardLayout title="Note Detail" mode={mode} onEdit={onEdit}>
      <Grid container direction="column" spacing={3}>
        {description && <RenderInstruction instruction={description} />}
        <Grid item>
          <Typography variant="h6">Question List</Typography>
          <List>
            {practiceList.length > 0 &&
              practiceList.map((p: any, i: number) => (
                <ListItem key={p.id + i}>
                  <ListItemAvatar>
                    <Avatar>
                      <LibraryBooksTwoToneIcon color="primary" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={p.name || p.id}
                    secondary={`${p.typeName} ${p.type || p.types || ""}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="view"
                      color="primary"
                      component={Link}
                      to={(_location: any) => linkTo(p)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </Grid>
      </Grid>
    </CardLayout>
  );
};

NoteDetailView.defaultProps = {
  preview: false,
};

export default NoteDetailView;
