import * as React from "react";
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  Theme,
  useTheme,
  useMediaQuery,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import AnswerIndicator from "./components/AnswerIndicator";
import ConfirmationDialog from "components/ConfirmationDialog";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

interface BottomBannerProps {
  submitted: boolean;
  typeName: string;
  onSubmit: () => Promise<void>;
  title: string;
  answers?: any;
  nextPage?: () => void;
  prevPage?: () => void;
  lastIndex?: number;
  index?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: "4.5rem",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    flexShrink: 0,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  midItem: {
    flexGrow: 1,
  },
  leftSide: {
    display: "flex",
    alignItems: "center",
  },
  rightSide: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  arrows: {
    display: "flex",
  },
  submit: {
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6rem",
      margin: 0,
    },
  },
}));

const BottomBanner: React.FC<BottomBannerProps> = ({
  submitted,
  title,
  typeName,
  onSubmit,
  answers,
  nextPage,
  prevPage,
  lastIndex,
  index,
}) => {
  const isTest = typeName.toLowerCase().includes("test");
  const classes = useStyles({ isTest });
  const theme = useTheme();
  const matchXsDown = useMediaQuery(theme.breakpoints.down("xs"));

  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const handleSubmit = async () => {
    if (answers && typeof answers !== "string") {
      let count = 0;
      Object.values(answers).forEach((ans: any) => {
        if (!ans) {
          count++;
        }
      });
      if (count > 0) {
        setMessage(
          `You have ${count} unanswered questions, are you sure you want to submit? `
        );
        setOpen(true);
      } else {
        await onSubmit();
      }
    } else {
      await onSubmit();
    }
  };
  const showSubmit = lastIndex <= 0 || lastIndex === index;
  return (
    <Grid container className={classes.root} wrap="nowrap" alignItems="center">
      <Grid item className={classes.leftSide}>
        {index > 0 && (
          <Tooltip title="Previous">
            <IconButton
              onClick={() => prevPage()}
              aria-label="previous"
              color="primary"
              size={matchXsDown ? "small" : "medium"}
              edge="end"
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
      <Grid
        item
        className={classes.midItem}
        container
        direction="column"
        alignItems="center"
        justify="center"
      >
        {!isTest && (
          <Grid item>
            <Typography>{title}</Typography>
          </Grid>
        )}

        <Grid
          item
          className={classes.midItem}
          container
          justify="center"
          alignItems="center"
        >
          {answers && typeof answers !== "string" && (
            <AnswerIndicator submittedAnswers={answers} />
          )}
        </Grid>
      </Grid>
      <Grid item className={classes.rightSide}>
        {showSubmit ? (
          <Grid item>
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              size="small"
              className={classes.submit}
              disabled={submitted}
            >
              Submit
            </Button>
          </Grid>
        ) : (
          <Tooltip title="Next">
            <IconButton
              onClick={() => nextPage()}
              aria-label="next"
              size={matchXsDown ? "small" : "medium"}
              color="primary"
              edge="start"
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Tooltip>
        )}
      </Grid>

      {open && (
        <ConfirmationDialog
          open={open}
          onConfirm={() => {
            setOpen(false);
            onSubmit();
          }}
          onCancel={() => setOpen(false)}
          message={message}
        />
      )}
    </Grid>
  );
};

export default BottomBanner;
