import * as React from "react";
import RenderListening from "components/Practice/listening/RenderListening";
import { useLocation } from "react-router-dom";

interface ListeningPreviewViewProps {}

const ListeningPreviewView: React.FC<ListeningPreviewViewProps> = () => {
  const location = useLocation() as any;
  const practice = location.state.practiceData;
  return (
    <RenderListening practice={practice} title="Listening Practice Preview" />
  );
};

export default ListeningPreviewView;
