import * as React from "react";
import { RenderQuestionProps } from ".";
import parse, {
  DOMNode,
  domToReact,
  HTMLReactParserOptions,
} from "html-react-parser";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Element } from "domhandler/lib/node";

const isElement = (domNode: DOMNode): domNode is Element => {
  const isTag = domNode.type === "tag";
  const hasAttributes = (domNode as Element).attribs !== undefined;

  return isTag && hasAttributes;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "10px",
      fontSize: "1.25rem",
      "& img": {
        width: "75%",
        height: "auto",
      },
      "& table": {
        margin: "auto",
        padding: "10px",
      },
      "& .MuiFormLabel-root": {
        fontSize: "inherit",
      },
      "& .MuiTypography-body1": {
        fontSize: "inherit",
      },
      "& .MuiFormControl-root": {
        margin: "5px",
        verticalAlign: "middle",
      },
      [theme.breakpoints.down("xs")]: {
        "& img": {
          width: "100%",
          height: "auto",
        },
        padding: 0,
        fontSize: "0.75rem",
        "& table": {
          padding: "5px",
        },
      },
    },
    questionStem: {
      display: "flex",
      justifyContent: "space-between",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      [theme.breakpoints.down("xs")]: {
        minWidth: 70,
      },
    },
  })
);

const RenderDropDownLetters: React.FC<RenderQuestionProps> = ({
  question,
  setAnswers,
  answers,
  errors,
}) => {
  const classes = useStyles();
  const options = question.options;
  const handleChange = (e: any) => {
    setAnswers({ ...answers, [e.target.name]: e.target.value });
  };
  const parseOptions: HTMLReactParserOptions = {
    replace: (domNode: DOMNode) => {
      if (isElement(domNode) && domNode.name === "figure") {
        return <div>{domToReact(domNode.children, parseOptions)}</div>;
      }
    },
  };
  return (
    <div className={classes.root}>
      <div>{question && parse(question.ins, parseOptions)}</div>
      {question.stems.map((stem: any) => {
        const { order, text } = stem;
        return (
          <div key={order} className={classes.questionStem}>
            <p>
              {order}. {text}
            </p>
            <FormControl
              className={classes.formControl}
              size="small"
              margin="dense"
              variant="outlined"
              error={errors && !errors[order].correct}
              disabled={errors && true}
            >
              <InputLabel id={`question-${order}`}>{order}</InputLabel>
              <Select
                labelId={`question-${order}`}
                name={order}
                value={answers[order] || ""}
                variant="outlined"
                label={order}
                onChange={handleChange}
              >
                {options.map((option: string) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              {errors && !errors[order].correct && (
                <FormHelperText>{errors[order].ans}</FormHelperText>
              )}
            </FormControl>
          </div>
        );
      })}
    </div>
  );
};

export default RenderDropDownLetters;
