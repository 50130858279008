import * as React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import MUIDatePicker from "components/MUIDatePickers";
import { API } from "aws-amplify";
import * as mutations from "graphql/mutations";
import { useSnackbar } from "notistack";

const bandScores = [
  "4.0",
  "4.5",
  "5.0",
  "5.5",
  "6.0",
  "6.5",
  "7.0",
  "7.5",
  "8.0",
  "8.5",
  "9.0",
];
const subjects = ["Listening", "Reading", "Writing", "Speaking"];

export interface UserListItemDetailFormProps {
  setEdit: any;
  user: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

const UserListItemDetailForm: React.FC<UserListItemDetailFormProps> = ({
  setEdit,
  user,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, watch, control } = useForm<any>({
    defaultValues: {
      note: user.note,
      examDate: user.examDate ? new Date(user.examDate) : new Date(),
      targetScores: user.targetScores
        ? user.targetScores
        : ["7.0", "7.0", "7.0", "7.0"],
    },
  });

  const onSubmit = async (data: any) => {
    const { note, examDate, targetScores } = data;

    try {
      await API.graphql({
        query: mutations.updateUser,
        variables: { input: { id: user.id, note, examDate, targetScores } },
      });

      enqueueSnackbar("User info changed successfully", { variant: "success" });
      setTimeout(() => window.location.reload(), 4000);
    } catch (error) {
      // enqueueSnackbar(error,  { variant: 'error'});
      console.log("errr", error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* register your input into the hook by invoking the "register" function */}
      <div>
        <TextField
          label="Note"
          multiline
          rows={4}
          // defaultValue={user.note}
          autoComplete="off"
          variant="outlined"
          name="note"
          inputRef={register}
        />
      </div>
      <Controller
        name="examDate"
        control={control}
        defaultValue={watch("examDate")}
        render={({ onChange, value }) => (
          <MUIDatePicker
            onChange={onChange}
            value={value}
            label="下次考试日期"
          />
        )}
      />

      <div>
        {subjects.map((subject: string, index: number) => {
          const name = `targetScores[${index}]`;
          return (
            <Controller
              control={control}
              name={name}
              key={index}
              render={({ onChange, value }) => (
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id={`${subject}-score`}>{subject}</InputLabel>
                  <Select
                    labelId={`${subject}-score`}
                    name={name}
                    value={value}
                    onChange={onChange}
                    label={subject}
                  >
                    {bandScores.map((band: string) => (
                      <MenuItem value={band} key={band}>
                        {band}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          );
        })}
      </div>
      <Button type="submit">submit</Button>
      <Button
        onClick={() => {
          setEdit(false);
        }}
      >
        Cancel
      </Button>
    </form>
  );
};

export default UserListItemDetailForm;
