import CreationCard from "layout/CreationCard";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { TextField, Typography, IconButton } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import PopEditor from "components/PopEditor";
import useLocalStorage from "hooks/useLocalStorage";
import produce from "immer";
import DropDown from "components/DropDown";
import TimeInput from "components/TimeInput";
import P1ListAutoComplete from "components/Practice/P1ListAutoComplete";
import P2ListAutoComplete from "components/Practice/P2ListAutoComplete";
import { useSnackbar } from "notistack";
import { API } from "aws-amplify";
import * as mutations from "graphql/mutations";

const initialExamData = {
  name: "",
  instructions: "",
};
const initialListeningData = { typeName: "", id: "", time: "" };
const initialReadingData = { typeName: "", id: "", time: "" };
const initialWritingData = {
  task1ID: "",
  task2ID: "",
  time: "",
};
const initialSpeakingData = {
  part1: [],
  part2: null,
  time: "",
} as any;

interface ExamProps {}

const validateExamModel = (examModel: any) => {
  const { name, instructions, listening, reading, writing, speaking } =
    examModel;
  if (!name || !instructions) return false;
  if ([listening, reading, writing, speaking].filter((s: any) => s).length < 1)
    return false;
  return true;
};

const Exam: React.FC<ExamProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [exam, setExam] = useLocalStorage("examModel", initialExamData);
  const history = useHistory();
  const isEdit = history.location.pathname.includes("edit");
  const handleExamNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExam({ ...exam, name: e.target.value });
  };
  const handleExamInstruction = (ins: string) => {
    setExam({ ...exam, instructions: ins });
  };
  const addSubject = (subject: string, initialData: any) => {
    setExam(
      produce(exam, (draft: any) => {
        draft[subject] = initialData;
      })
    );
  };
  const deleteSubject = (subject: string) => {
    setExam(
      produce(exam, (draft: any) => {
        delete draft[subject];
      })
    );
  };
  const handleListeningTypeName = (typeName: string) => {
    setExam(
      produce(exam, (draft: any) => {
        draft.listening.typeName = typeName;
      })
    );
  };
  const handleListeningID = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExam(
      produce(exam, (draft: any) => {
        draft.listening.id = e.target.value;
      })
    );
  };
  const handleSubjectTime = (subject: string) => {
    const handleTime = (time: string) => {
      setExam(
        produce(exam, (draft: any) => {
          draft[subject].time = time;
        })
      );
    };
    return handleTime;
  };

  const handleReadingTypeName = (typeName: string) => {
    setExam(
      produce(exam, (draft: any) => {
        draft.reading.typeName = typeName;
      })
    );
  };
  const handleReadingID = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExam(
      produce(exam, (draft: any) => {
        draft.reading.id = e.target.value;
      })
    );
  };
  const handleWritingID =
    (task: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setExam(
        produce(exam, (draft: any) => {
          draft.writing[task] = e.target.value;
        })
      );
    };
  const handleSpeakingPartOne = (topics: string[]) => {
    setExam(
      produce(exam, (draft: any) => {
        draft.speaking.part1 = topics;
      })
    );
  };
  const handleSpeakingPartTwo = (p2: { id: string; prompt: string }) => {
    setExam(
      produce(exam, (draft: any) => {
        draft.speaking.part2 = p2;
      })
    );
  };
  const onSubmit = async () => {
    if (validateExamModel(exam)) {
      try {
        await API.graphql({
          query: mutations.createExamModel,
          variables: { input: { ...exam, typeName: "ExamModel" } },
        });
        enqueueSnackbar("Successfully created Exam Model", {
          variant: "success",
        });
      } catch (error) {
        console.log("create Exam Model Error", error);
        enqueueSnackbar("create Exam Model Error", { variant: "error" });
      }
    } else {
      enqueueSnackbar("Input Error please check again", { variant: "error" });
    }
  };
  const onDelete = async () => {
    try {
      await API.graphql({
        query: mutations.deleteExam,
        variables: { input: { id: exam.id } },
      });
    } catch (error) {
      console.log("exam deletion error", error);
      enqueueSnackbar("connection error, please try again later", {
        variant: "error",
      });
    }
  };
  const onPreview = () => {
    if (validateExamModel(exam)) {
      history.push({ pathname: "/exam/preview", state: { examModel: exam } });
    } else {
      enqueueSnackbar("Input Error please check again", { variant: "error" });
    }
  };
  const onCancel = () => {
    setExam(initialExamData);
  };

  return (
    <CreationCard
      type="Exam Model"
      isEdit={isEdit}
      onCancel={onCancel}
      onDelete={onDelete}
      onSubmit={onSubmit}
      onPreview={onPreview}
    >
      <TextField
        label="Name"
        name="name"
        variant="outlined"
        value={exam.name}
        onChange={handleExamNameChange}
        autoComplete="off"
      />
      <PopEditor
        title="Exam Instruction"
        value={exam.instructions}
        setValue={handleExamInstruction}
      />
      <div>
        <Typography>Listening:</Typography>
        {!exam.listening ? (
          <IconButton
            onClick={() => {
              addSubject("listening", initialListeningData);
            }}
            color="primary"
          >
            <AddCircleOutlineIcon />
          </IconButton>
        ) : (
          <div>
            <DropDown
              value={exam.listening.typeName}
              name="Type"
              setValue={handleListeningTypeName}
              options={[
                { label: "test", value: "ListeningTest" },
                { label: "section", value: "ListeningSection" },
              ]}
            />
            <TextField
              label="ID"
              name="ListeningID"
              variant="outlined"
              value={exam.listening.id}
              onChange={handleListeningID}
              autoComplete="off"
            />
            <TimeInput
              value={exam.listening.time}
              setValue={handleSubjectTime("listening")}
            />
            <IconButton
              onClick={() => deleteSubject("listening")}
              color="secondary"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </div>
      <div>
        <Typography>Reading</Typography>
        {!exam.reading ? (
          <IconButton
            onClick={() => {
              addSubject("reading", initialReadingData);
            }}
            color="primary"
          >
            <AddCircleOutlineIcon />
          </IconButton>
        ) : (
          <div>
            <DropDown
              value={exam.reading.typeName}
              name="Type"
              setValue={handleReadingTypeName}
              options={[
                { label: "test A", value: "ReadingTestA" },
                { label: "test G", value: "ReadingTestG" },
                { label: "passage", value: "ReadingPassage" },
                { label: "custom test", value: "CustomTest" },
              ]}
            />
            <TextField
              label="ID"
              name="ReadingID"
              variant="outlined"
              value={exam.reading.id}
              onChange={handleReadingID}
              autoComplete="off"
            />
            <TimeInput
              value={exam.reading.time}
              setValue={handleSubjectTime("reading")}
            />
            <IconButton
              onClick={() => deleteSubject("reading")}
              color="secondary"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </div>
      <div>
        <Typography>Writing</Typography>
        {!exam.writing ? (
          <IconButton
            onClick={() => {
              addSubject("writing", initialWritingData);
            }}
            color="primary"
          >
            <AddCircleOutlineIcon />
          </IconButton>
        ) : (
          <div>
            <TextField
              label="Task1 ID"
              name="Task1"
              variant="outlined"
              value={exam.writing.task1ID}
              onChange={handleWritingID("task1ID")}
              autoComplete="off"
            />
            <TextField
              label="Task2 ID"
              name="Task2"
              variant="outlined"
              value={exam.writing.task2ID}
              onChange={handleWritingID("task2ID")}
              autoComplete="off"
            />
            <TimeInput
              value={exam.writing.time}
              setValue={handleSubjectTime("writing")}
            />
            <IconButton
              onClick={() => deleteSubject("writing")}
              color="secondary"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </div>
      <div>
        <Typography>Speaking</Typography>
        {!exam.speaking ? (
          <IconButton
            onClick={() => {
              addSubject("speaking", initialSpeakingData);
            }}
            color="primary"
          >
            <AddCircleOutlineIcon />
          </IconButton>
        ) : (
          <div>
            <P1ListAutoComplete
              multiple={true}
              value={exam.speaking.part1}
              setValue={handleSpeakingPartOne}
            />
            <P2ListAutoComplete
              value={exam.speaking.part2}
              setValue={handleSpeakingPartTwo}
            />
            <TimeInput
              value={exam.speaking.time}
              setValue={handleSubjectTime("speaking")}
            />
            <IconButton
              onClick={() => deleteSubject("speaking")}
              color="secondary"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </div>
    </CreationCard>
  );
};

export default Exam;
