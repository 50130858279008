import * as React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import API from "@aws-amplify/api";
import * as queries from "graphql/queries";
import { useSnackbar } from "notistack";

interface UserListProps {
  value: string;
  handleUserChange: (user: any) => void;
}

const UserList: React.FC<UserListProps> = ({ handleUserChange, value }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [options, setOptions] = React.useState<any[]>([]);
  React.useEffect(() => {
    (async () => {
      try {
        const data = (await API.graphql({
          query: queries.listUsers,
          variables: { limit: 1000 },
        })) as any;
        setOptions(data.data.listUsers.items);
      } catch (error) {
        console.log("list users error", error);
        enqueueSnackbar("connection error, please try again later", {
          variant: "error",
        });
      }
    })();
  }, [enqueueSnackbar]);
  return (
    <Autocomplete
      freeSolo
      onChange={(_event: any, newValue: any) => {
        if (typeof newValue === "object" && newValue !== null) {
          handleUserChange(newValue.username);
        } else {
          handleUserChange(newValue);
        }
      }}
      options={options.map((option) => option.username)}
      style={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} label="Choose user" variant="outlined" />
      )}
      value={value}
    />
  );
};

export default UserList;
