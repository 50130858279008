import * as React from "react";
import AddSpeakingQuestionDialog from "./AddSpeakingQuestionDialog";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { ReactSortable } from "react-sortablejs";

interface SpeakingQuestionListProps {
  part: "1" | "3";
  questions: any[];
  setQuestions: (data: any) => void;
}

const initialData = { question: "", type: [] } as {
  question: string;
  type: string[];
};

const SpeakingQuestionList: React.FC<SpeakingQuestionListProps> = ({
  part,
  questions,
  setQuestions,
}) => {
  const [selectedQuestion, setSelectedQuestion] =
    React.useState<any>(initialData);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const addQuestion = (question: any) => {
    setQuestions([...questions, question]);
  };
  const updateQuestion = (question: any) => {
    const newQuestions = questions.map((q: any) =>
      q.question === selectedQuestion.question
        ? { ...q, question: question.question, type: question.type }
        : q
    );
    setQuestions(newQuestions);
  };
  const handleDelete = (sq: any) => {
    setQuestions(questions.filter((p: any) => p.question !== sq.question));
  };
  const onEdit = (question: any) => {
    setSelectedQuestion(question);
    setIsEdit(true);
    setOpen(true);
  };
  const onClose = () => {
    setSelectedQuestion(initialData);
    setIsEdit(false);
    setOpen(false);
  };
  return (
    <>
      <Typography variant="h6">Part {part}</Typography>
      <List>
        <ReactSortable list={questions} setList={setQuestions}>
          {questions.map((sq: any, index: number) => (
            <ListItem key={sq.id || index}>
              <ListItemText primary={`${index + 1}. ${sq.question}`} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="start"
                  aria-label="delete"
                  onClick={() => onEdit(sq)}
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDelete(sq)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </ReactSortable>
      </List>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        style={{ margin: "auto", display: "block" }}
        color="primary"
      >
        add
      </Button>
      {open && (
        <AddSpeakingQuestionDialog
          updateQuestion={updateQuestion}
          isEdit={isEdit}
          selectedQuestion={selectedQuestion}
          part={part}
          open={open}
          onClose={onClose}
          addQuestion={addQuestion}
        />
      )}
    </>
  );
};

export default SpeakingQuestionList;
