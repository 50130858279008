import { TextField, Button } from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import CKeditor from "components/CKeditor";
import * as React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { QuestionProps } from ".";
import { rangeToArray } from "utilities";
// import { optionsGreaterThanRange } from "utilities/validation";

const generateDDtemplate = (range: string) => {
  const template: { ins: string; options: string[]; stems: any[] } = {
    ins: "",
    options: [],
    stems: [],
  };
  rangeToArray(range).forEach((order) => {
    template.stems.push({ order, text: "" });
  });
  return template;
};

const AddDropDown: React.FC<QuestionProps> = ({
  question,
  range,
  onSubmit,
}) => {
  const defaultValues = question ? question : generateDDtemplate(range);
  const { register, setValue, handleSubmit, control } = useForm({
    defaultValues,
  });
  const { fields } = useFieldArray({
    control,
    name: "stems",
  });
  const handleEditorChange = (_e: any, editor: any) => {
    setValue("ins", editor.getData());
  };
  const handleChipChange = (chips: string[]) => {
    setValue("options", chips);
  };

  React.useEffect(() => {
    register("ins", { required: true });
    setValue("ins", question?.ins);
    register("options");
  }, [register, range, setValue, question]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CKeditor
        data={question?.ins || ""}
        handleOnChange={handleEditorChange}
      />
      <p>options</p>
      <ChipInput
        defaultValue={question?.options || []}
        onChange={(chips: string[]) => handleChipChange(chips)}
      />
      {fields &&
        fields.map((stem: any, index: number) => (
          <div key={stem.order}>
            <input
              hidden={true}
              name={`stems[${index}].order`}
              defaultValue={stem.order}
              ref={register()}
            />
            <TextField
              name={`stems[${index}].text`}
              label={`Question ${stem.order}`}
              variant="outlined"
              inputRef={register({ required: true })}
              defaultValue={stem.text}
              autoComplete="off"
              fullWidth
            />
          </div>
        ))}
      <Button type="submit">{question ? "Edit" : "Add"} Answers</Button>
    </form>
  );
};

export default AddDropDown;
