import * as React from "react";
import { Button, TextField, Grid } from "@material-ui/core";
import { QuestionProps } from ".";
import { useFieldArray, useForm } from "react-hook-form";
import { rangeToArray } from "utilities";
import CKeditor from "components/CKeditor";

const generateSCTemplate = (range: string, listening: boolean) => {
  const template: { ins: string; questions: any[] } = {
    ins: "",
    questions: [],
  };
  const rangeArray = rangeToArray(range);
  for (let i = 0; i < rangeArray.length; i++) {
    if (listening) {
      template.questions.push({
        order: rangeArray[i],
        ins: "",
        options: { a: "", b: "", c: "" },
      });
    } else {
      template.questions.push({
        order: rangeArray[i],
        ins: "",
        options: { a: "", b: "", c: "", d: "" },
      });
    }
  }
  return template;
};

const AddSC: React.FC<QuestionProps> = ({
  listening,
  range,
  onSubmit,
  question,
}) => {
  const defaultValues = question || generateSCTemplate(range, listening);
  const { register, control, handleSubmit, setValue, reset } = useForm({
    defaultValues,
  });
  const handleEditorChange = (_e: any, editor: any) => {
    setValue("ins", editor.getData());
  };
  const { fields } = useFieldArray({
    control,
    name: "questions",
  });
  React.useEffect(() => {
    reset(generateSCTemplate(range, listening));
  }, [range, reset, listening]);

  React.useEffect(() => {
    register("ins", { required: true });
    setValue("ins", question?.ins);
  }, [register, setValue, question]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p>
        {listening ? "Listening " : "Reading "}Single Choices, question {range}
      </p>
      <CKeditor
        data={question?.ins || ""}
        handleOnChange={handleEditorChange}
      />
      {fields &&
        fields.map((q, index) => (
          <Grid key={q.order} container direction="column">
            <input
              hidden
              name={`questions[${index}].order`}
              ref={register}
              defaultValue={q.order}
            />
            <Grid item>
              <TextField
                name={`questions[${index}].ins`}
                label={`Question ${q.order}`}
                variant="outlined"
                autoComplete="off"
                inputRef={register({ required: true })}
                defaultValue={q.ins}
                fullWidth
              />
            </Grid>
            {Object.entries(q.options).map(([label, opt]) => (
              <TextField
                name={`questions[${index}].options[${label}]`}
                label={label}
                variant="outlined"
                inputRef={register({ required: true })}
                key={label}
                autoComplete="off"
                defaultValue={opt}
              />
            ))}
          </Grid>
        ))}

      <Button type="submit">{question ? "Edit" : "Add"} Answers</Button>
    </form>
  );
};

export default AddSC;
