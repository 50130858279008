import MainWrapper from "layout/MainWrapper";
import * as React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import HomeworkCreationView from "./HomeworkCreationView";
import HomeworkDetailView from "./HomeworkDetailView";
import HomeworkEditionView from "./HomeworkEditionView";
import HomeworkListView from "./HomeworkListView";
import ProtectedRoute from "routes/utilities/ProtectedRoute";

export interface HomeworkProps {}

const Homework: React.FC<HomeworkProps> = () => {
  const { path } = useRouteMatch();
  return (
    <MainWrapper>
      <Switch>
        <Route exact path={path}>
          <HomeworkListView />
        </Route>
        <ProtectedRoute exact path={`${path}/create`}>
          <HomeworkCreationView />
        </ProtectedRoute>
        <ProtectedRoute exact path={`${path}/edit`}>
          <HomeworkEditionView />
        </ProtectedRoute>
        <Route exact path={`${path}/detail`}>
          <HomeworkDetailView />
        </Route>
        <Route exact path={`${path}/preview`}>
          <HomeworkDetailView preview={true} />
        </Route>
      </Switch>
    </MainWrapper>
  );
};

export default Homework;
