import * as React from "react";
import { Grid, TextField } from "@material-ui/core";
// import PartTwoPromptDialog from "./PartTwoPromptDialog";
import PartTwoPromptDropDown from "./PartTwoPromptDropDown";
import SpeakingQuestionList from "./SpeakingQuestionList";
import DropDown from "components/DropDown";
import MultiSelect from "components/MultiSelect";
import TextListInput from "components/TextListInput";

import config from "customConfig";

interface PartTwoFormProps {
  speaking: any;
  updateSpeaking: (data: any) => void;
}

const PartTwoForm: React.FC<PartTwoFormProps> = ({
  speaking,
  updateSpeaking,
}) => {
  const part2 = speaking.id ? speaking : null;
  const onPromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateSpeaking((draft: any) => {
      draft.prompt = event.target.value;
    });
  };
  const onTypeChange = (type: string) => {
    updateSpeaking((draft: any) => {
      draft.type = type;
    });
  };
  const onSubQuestionChange = (subQuestions: string[]) => {
    updateSpeaking((draft: any) => {
      draft.subQuestions = subQuestions;
    });
  };
  const onSeasonChange = (season: string[]) => {
    updateSpeaking((draft: any) => {
      draft.season = season;
    });
  };
  const onPartThreeQuestionsChange = (partThreeQuestions: string[]) => {
    updateSpeaking((draft: any) => {
      draft.partThreeQuestions = partThreeQuestions;
    });
  };
  return (
    <Grid item container direction="column">
      <Grid item>
        <PartTwoPromptDropDown
          speaking={speaking}
          updateSpeaking={updateSpeaking}
        />
      </Grid>

      {part2 && (
        <>
          <Grid item>
            <TextField
              autoFocus
              margin="normal"
              value={part2.prompt}
              onChange={onPromptChange}
              label="prompt"
              type="text"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item container justify="space-between" alignItems="center">
            <Grid item>
              <MultiSelect
                options={config.seasonOptions}
                label="Season"
                value={part2.season}
                setValue={onSeasonChange}
              />
            </Grid>
            <Grid item>
              <DropDown
                name="type"
                value={part2.type}
                setValue={onTypeChange}
                options={config.speakingPartTwoType}
              />
            </Grid>
          </Grid>
          <Grid item>
            <TextListInput
              name="Part 2 prompts"
              value={part2.subQuestions}
              setValue={onSubQuestionChange}
            />
          </Grid>
          <Grid item>
            <SpeakingQuestionList
              part="3"
              setQuestions={onPartThreeQuestionsChange}
              questions={part2.partThreeQuestions}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PartTwoForm;
