import * as React from "react";
import { Button, Grid, makeStyles, Typography, Theme } from "@material-ui/core";
import AnswerIndicator from "./AnswerIndicator";
import ConfirmationDialog from "components/ConfirmationDialog";

interface BottomBannerProps {
  onSubmit: () => void;
  title: string;
  submittedAnswers?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    paddingLeft: "2rem",
    paddingRight: "2rem",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  midItem: {
    flexGrow: 1,
    minHeight: "4rem",
  },
}));

const BottomBanner: React.FC<BottomBannerProps> = ({
  title,
  onSubmit,
  submittedAnswers,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const handleSubmit = () => {
    if (submittedAnswers) {
      let count = 0;
      Object.values(submittedAnswers).forEach((ans: any) => {
        if (!ans) {
          count++;
        }
      });
      if (count > 0) {
        setMessage(
          `You have ${count} unanswered questions, are you sure you want to submit? `
        );
        setOpen(true);
      } else {
        onSubmit();
      }
    } else {
      onSubmit();
    }
  };

  return (
    <Grid container className={classes.root} wrap="nowrap">
      <Grid item className={classes.title}>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid
        item
        className={classes.midItem}
        container
        alignItems="center"
        justify="center"
      >
        {submittedAnswers && (
          <AnswerIndicator submittedAnswers={submittedAnswers} />
        )}
      </Grid>
      <Grid item className={classes.title}>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </Grid>
      {open && (
        <ConfirmationDialog
          open={open}
          onConfirm={onSubmit}
          onCancel={() => setOpen(false)}
          message={message}
        />
      )}
    </Grid>
  );
};

export default BottomBanner;
