import CKeditor from "components/CKeditor";
import * as React from "react";
import QuestionList from "../QuestionList";
import {
  Button,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import parse from "html-react-parser";

export interface PassageProps {
  passageID: string;
  article: string;
  setArticle: (article: string) => void;
  questions: any;
  setQuestions: (question: any) => void;
}

const getPassageLabel = (passageID: string) => {
  if (passageID.includes("p1")) return "Passage 1";
  if (passageID.includes("p2")) return "Passage 2";
  if (passageID.includes("p3")) return "Passage 3";
  if (passageID.includes("s1a")) return "Section 1 a";
  if (passageID.includes("s1b")) return "Section 1 b";
  if (passageID.includes("s2a")) return "Section 2 a";
  if (passageID.includes("s2b")) return "Section 2 b";
  if (passageID.includes("s3")) return "Section 3";
};

const Passage: React.FC<PassageProps> = ({
  passageID,
  article,
  setArticle,
  questions,
  setQuestions,
}) => {
  const [isEditing, setIsEditing] = React.useState<boolean>(() =>
    article ? false : true
  );
  const [data, setData] = React.useState<any>(article);
  const handleChange = (_e: any, editor: any) => {
    setData(editor.getData());
  };
  const handleSubmit = () => {
    setArticle(data);
    setIsEditing(false);
  };
  return (
    <>
      <p>Passage {passageID} </p>
      {isEditing ? (
        <>
          <CKeditor data={data} handleOnChange={handleChange} />
          <Button onClick={handleSubmit}>submit article</Button>
        </>
      ) : (
        <>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{getPassageLabel(passageID)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>{parse(article)}</div>
            </AccordionDetails>
          </Accordion>
          <Button onClick={() => setIsEditing(true)}>edit article</Button>
        </>
      )}
      <QuestionList
        sectionID={passageID}
        questions={[...questions]}
        setQuestions={setQuestions}
        listening={false}
        passage={article}
      />
    </>
  );
};

export default Passage;
