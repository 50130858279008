import {
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  Grid,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import * as React from "react";
import { isObjFieldFull } from "utilities/validation";
import CKeditor from "components/CKeditor";

interface CourseIntroFormProps {
  intro?: any;
  onCancel: () => void;
  onSubmit: (content: any) => void;
}

const initialData = {
  title: "",
  description: "",
};

const CourseIntroForm: React.FC<CourseIntroFormProps> = ({
  intro,
  onCancel,
  onSubmit,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = React.useState<any>(intro ? intro : initialData);
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, title: event.target.value });
  };
  const handleInsChange = (_e: any, editor: any) => {
    setData({ ...data, description: editor.getData() });
  };
  const handleCancel = () => {
    setData(initialData);
    onCancel();
  };
  const handleSubmit = () => {
    if (isObjFieldFull(data)) {
      onSubmit(data);
      onCancel();
    } else {
      enqueueSnackbar("You are missing title field.", { variant: "warning" });
    }
  };
  return (
    <Card style={{ marginBottom: "20px" }}>
      <CardContent>
        <Grid container direction="column">
          <Grid item>
            <TextField
              label="Title"
              value={data.title}
              onChange={handleTitleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <CKeditor
              handleOnChange={handleInsChange}
              data={data.description ?? "type here...."}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button onClick={handleCancel}>Cancel</Button>{" "}
        <Button onClick={handleSubmit}>Submit</Button>
      </CardActions>
    </Card>
  );
};

export default CourseIntroForm;
