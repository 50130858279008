import React from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

export interface CKeditorProps {
  data: any;
  handleOnChange: (e: any, editor: any) => void;
}

const editorConfig = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "underline",
    "fontSize",
    "fontFamily",
    "fontColor",
    "fontBackgroundColor",
    "undo",
    "redo",
    "|",
    "insertTable",
    "alignment",
    "horizontalLine",
    "outdent",
    "indent",
    "bulletedList",
    "numberedList",
    "|",
    "imageInsert",
  ],
  image: {
    toolbar: [
      "imageTextAlternative",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "imageStyle:alignLeft",
      "imageStyle:alignCenter",
      "imageStyle:alignRight",
    ],
    styles: ["full", "side", "alignLeft", "alignCenter", "alignRight"],
  },
  heading: {
    options: [
      { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableProperties",
      "tableCellProperties",
    ],

    // Configuration of the TableProperties plugin.
    tableProperties: {
      defaults: {
        borderColor: "red",
        borderWidth: "1",
        borderStyle: "solid",
      },
    },

    // Configuration of the TableCellProperties plugin.
    tableCellProperties: {
      // ...
    },
  },
};

const CKeditor: React.FC<CKeditorProps> = ({ data, handleOnChange }) => {
  return (
    <CKEditor
      editor={Editor}
      data={data}
      config={editorConfig}
      onChange={(event: any, editor: any) => {
        handleOnChange(event, editor);
      }}
      onFocus={(event: any, editor: any) => {
        handleOnChange(event, editor);
      }}
    />
  );
};

export default CKeditor;
