import RenderReading from "components/Practice/reading/RenderReading";
import * as React from "react";
import { useLocation } from "react-router-dom";

interface ReadingPreviewViewProps {}

const ReadingPreviewView: React.FC<ReadingPreviewViewProps> = () => {
  const location = useLocation() as any;
  const practice = location.state.practiceData;
  return <RenderReading practice={practice} title="Reading Practice Preview" />;
};

export default ReadingPreviewView;
