import * as React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { compareAnswerLabel } from "utilities";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "2rem",
    height: "2rem",
    margin: "1px",
    border: "solid",
    borderWidth: "2px",
    borderColor: theme.palette.primary.main,
    borderRadius: "20%",
    cursor: "default",
    lineHeight: 0,
    fontSize: "0.6rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      width: "1.6rem",
      height: "1.6rem",
      margin: "0.5px",
      fontSize: "0.5rem",
      borderWidth: "1px",
    },
  },
  answered: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  unanswered: {
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
}));

interface AnswerIndicatorProps {
  submittedAnswers: any;
}

const AnswerIndicator: React.FC<AnswerIndicatorProps> = ({
  submittedAnswers,
}) => {
  const classes = useStyles();
  const sortedKeys = Object.keys(submittedAnswers).sort(compareAnswerLabel);
  return (
    <>
      {sortedKeys.map((label: any) => {
        const value = submittedAnswers[label];
        return (
          <div
            key={label}
            className={`${classes.box} ${
              value ? classes.answered : classes.unanswered
            }`}
          >
            {label}
          </div>
        );
      })}
    </>
  );
};

export default AnswerIndicator;
