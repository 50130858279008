import * as React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Avatar,
  TextField,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import AddPracticeDialog from "./AddPracticeDialog";
import useLocalStorage from "hooks/useLocalStorage";
import NumberFormatTime from "utilities/NumberFormatTime";

export interface AddHWPracticeListProps {
  practiceList: any[];
  updateHwData: any;
}

const AddHWPracticeList: React.FC<AddHWPracticeListProps> = ({
  practiceList,
  updateHwData,
}) => {
  const [open, setOpen] = useLocalStorage("noteDialog", false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = (i: number) => {
    updateHwData((draft: any) => {
      draft.practiceList.splice(i, 1);
    });
  };
  const addTimeLimit = (i: number, event: any) => {
    updateHwData((draft: any) => {
      draft.practiceList[i].timeLimit = event.target.value;
    });
  };
  const addPractice = (practice: any) => {
    updateHwData((draft: any) => {
      draft.practiceList.push(practice);
    });
  };
  return (
    <List>
      {practiceList.length > 0 &&
        practiceList.map((p: any, i: number) => (
          <ListItem key={p.id + i}>
            <ListItemAvatar>
              <Avatar>{p.typeName.replace(/[^A-Z]+/g, "")}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={p.name || p.id}
              secondary={`${p.type || p.types || ""}`}
            />
            <TextField
              label="time limit"
              value={p.timeLimit}
              onChange={(time: any) => addTimeLimit(i, time)}
              InputProps={{
                inputComponent: NumberFormatTime as any,
              }}
              autoComplete="off"
              variant="outlined"
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDelete(i)}
                color="primary"
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      <IconButton onClick={() => setOpen(true)} color="primary">
        <AddIcon />
      </IconButton>

      {open && (
        <AddPracticeDialog
          open={open}
          handleClose={handleClose}
          append={addPractice}
        />
      )}
    </List>
  );
};

export default AddHWPracticeList;
