import * as React from "react";
import ListTitle from "./ListTitle";
import { Collapse, makeStyles, Theme, Divider } from "@material-ui/core";
import ContentFileForm from "./ContentFileForm";
import RenderContentFile from "./RenderContentFile";
import { useCourseValue } from "context/course.context";

const useStyles = makeStyles((theme: Theme) => ({
  collapse: {
    paddingLeft: theme.spacing(11),
    paddingRight: theme.spacing(5),
    marginTop: theme.spacing(2),
  },
}));

interface ContentFileProps {
  content: any;
}

const ContentFile: React.FC<ContentFileProps> = ({ content }) => {
  const classes = useStyles();
  const { updateCourse } = useCourseValue();
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const handleFormCancel = () => {
    setIsEdit(false);
  };
  const handleFormSubmit = (data: any) => {
    updateCourse((draft: any) => {
      const newContent = draft.content.map((content: any) => {
        if (content.id === data.id) return data;
        return content;
      });
      draft.content = newContent;
    });
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListTitle
        content={content}
        key={content.id}
        open={open}
        toggleOpen={toggleOpen}
      />
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        className={classes.collapse}
      >
        {isEdit ? (
          <ContentFileForm
            content={content}
            onCancel={handleFormCancel}
            onSubmit={handleFormSubmit}
          />
        ) : (
          <RenderContentFile content={content} onEdit={() => setIsEdit(true)} />
        )}
        <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} />
      </Collapse>
    </>
  );
};

export default ContentFile;
