import * as React from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";

export interface MUITimePickerProps {
  label: string;
  value: any;
  onChange: any;
}

const MUITimePicker: React.FC<MUITimePickerProps> = ({
  label,
  onChange,
  value,
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        autoOk
        ampm={false}
        disableToolbar
        inputVariant="outlined"
        margin="normal"
        label={label}
        value={value}
        onChange={onChange}
      />
    </MuiPickersUtilsProvider>
  );
};

export default MUITimePicker;
