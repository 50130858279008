import * as React from "react";
import MaterialTable from "material-table";
import { tableIcons } from "utilities/materialTableIcon";
import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import PracticeControlTab from "./ListView/PracticeControlTab";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    item: {
      width: "100%",
      padding: "20px",
    },
  })
);
export interface PracticeListProps {
  action: any;
  handleSelection?: (data: any) => void | null;
}

const createTableColumn = (practiceList: any) => {
  if (!practiceList) return [];
  if (practiceList.length === 0) return [];
  const typeName = practiceList[0].typeName;
  if (typeName === "ListeningTest") {
    return [
      { field: "id", title: "ID" },
      { field: "book", title: "Book" },
      { field: "test", title: "Test" },
    ];
  }
  if (typeName === "ListeningSection") {
    return [
      { field: "id", title: "ID" },
      { field: "book", title: "Book" },
      { field: "sectionNumber", title: "Section" },
      { field: "types", title: "Types" },
    ];
  }
  if (typeName === "ListeningQuestion") {
    return [
      { field: "name", title: "Name" },
      { field: "book", title: "Book" },
      { field: "sectionNumber", title: "Section" },
      { field: "type", title: "Type" },
    ];
  }
  if (typeName.includes("ReadingTest")) {
    return [
      { field: "id", title: "ID" },
      { field: "book", title: "Book" },
      { field: "test", title: "Test" },
    ];
  }
  if (typeName === "ReadingPassage") {
    return [
      { field: "id", title: "ID" },
      { field: "book", title: "Book" },
      { field: "genre", title: "Genre" },
      { field: "test", title: "Test" },
      { field: "passageNumber", title: "Passage" },
      { field: "types", title: "Types" },
    ];
  }
  if (typeName === "ReadingQuestion") {
    return [
      { field: "name", title: "Name" },
      { field: "book", title: "Book" },
      { field: "passageNumber", title: "Passage" },
      { field: "type", title: "Type" },
    ];
  }
  if (typeName === "Essay") {
    return [
      { field: "id", title: "ID" },
      { field: "book", title: "Book" },
      { field: "topic", title: "Topic" },
      { field: "type", title: "Type" },
    ];
  }
  if (typeName === "Graph") {
    return [
      { field: "id", title: "ID" },
      { field: "book", title: "Book" },
      { field: "type", title: "Type" },
    ];
  }
  if (typeName === "Letter") {
    return [
      { field: "id", title: "ID" },
      { field: "book", title: "Book" },
      { field: "type", title: "Type" },
      { field: "aim", title: "Aim" },
    ];
  }
  if (typeName === "PartOneTopic") {
    return [
      { field: "id", title: "ID" },
      {
        field: "season",
        title: "Season",
        render: (rowData: any) => <p>{rowData.season.join(" | ")}</p>,
      },
    ];
  }
  if (typeName === "PartOne") {
    return [
      {
        field: "question",
        title: "Prompt",
        cellStyle: {
          minWidth: "calc(228.25px*2)",
          fontWeight: 600,
        },
      },
      { field: "partOneTopicID", title: "Topic" },
      {
        field: "type",
        title: "Type",
        render: (rowData: any) => <p>{rowData.type.join(" | ")}</p>,
      },
      {
        field: "season",
        title: "Season",
        render: (rowData: any) => <p>{rowData.season.join(" | ")}</p>,
      },
    ];
  }
  if (typeName === "PartTwo") {
    return [
      {
        field: "prompt",
        title: "Prompt",
        cellStyle: {
          minWidth: "calc(228.25px*2)",
          fontWeight: 600,
        },
      },
      { field: "type", title: "Type" },
      {
        field: "season",
        title: "Season",
        render: (rowData: any) => <p>{rowData.season.join(" | ")}</p>,
      },
    ];
  }
  if (typeName === "PartThree") {
    return [
      {
        field: "question",
        title: "Prompt",
        cellStyle: {
          minWidth: "calc(228.25px*2)",
          fontWeight: 600,
        },
      },
      {
        field: "type",
        title: "Type",
        render: (rowData: any) => <p>{rowData.type.join(" | ")}</p>,
      },
      {
        field: "season",
        title: "Season",
        render: (rowData: any) => <p>{rowData.season.join(" | ")}</p>,
      },
    ];
  }
};

const PracticeList: React.FC<PracticeListProps> = ({
  action,
  handleSelection,
}) => {
  const classes = useStyles();
  const [practiceList, setPracticeList] = React.useState<any[]>([]);
  const setData = (data: any) => {
    if (data) {
      setPracticeList([...data]);
    }
  };

  const typeName = practiceList ? practiceList[0]?.typeName : null;
  const onSelect = (list: any[]) => {
    if (list.length !== 0) {
      handleSelection({ list, type: typeName });
    } else {
      handleSelection(null);
    }
  };
  const showSelect =
    ["PartOne", "PartThree"].includes(typeName) && !!handleSelection;
  const selectionAction = [
    {
      tooltip: "add questions",
      icon: () => <AddCircleOutlineIcon />,
      onClick: (_evt: any, data: any) => onSelect(data),
    },
  ];
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item className={classes.item}>
        <PracticeControlTab setData={setData} />
      </Grid>
      <Grid item className={classes.item}>
        <MaterialTable
          title={practiceList[0]?.typeName}
          icons={tableIcons}
          columns={createTableColumn(practiceList)}
          data={JSON.parse(JSON.stringify(practiceList))}
          actions={showSelect ? selectionAction : action}
          options={
            showSelect
              ? { actionsColumnIndex: -1, pageSize: 10, selection: true }
              : {
                  actionsColumnIndex: -1,
                  pageSize: 10,
                }
          }
          detailPanel={
            typeName === "PartOneTopic"
              ? (rowData: any) => {
                  if (rowData.typeName === "PartOneTopic") {
                    return (
                      <ul>
                        {rowData.questions.map((q: any) => (
                          <li key={q.id}>{q.question}</li>
                        ))}
                      </ul>
                    );
                  }
                }
              : null
          }
        />
      </Grid>
    </Grid>
  );
};

export default PracticeList;

PracticeList.defaultProps = {
  handleSelection: null,
};
