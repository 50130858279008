import * as React from "react";
import { RenderQuestionProps } from ".";
import {
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import parse from "html-react-parser";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "10px",
      fontSize: "1.25rem",
      "& .MuiFormLabel-root": {
        fontSize: "inherit",
      },
      "& .MuiTypography-body1": {
        fontSize: "inherit",
      },
      "& .MuiRadio-colorSecondary.Mui-checked": {
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down("xs")]: {
        padding: 0,
        fontSize: "0.75rem",
      },
    },
    formControl: {
      display: "block",
    },
    options: {
      margin: "1rem 2rem 2rem 2rem",
    },
    option: {
      display: "flex",
      alignItems: "center",
      flexWrap: "nowrap",
    },
  })
);

const RenderSC: React.FC<RenderQuestionProps> = ({
  question,
  setAnswers,
  answers,
  errors,
}) => {
  const classes = useStyles();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAnswers({ ...answers, [e.target.name]: e.target.value });
  };
  return (
    <div className={classes.root}>
      <div>{question && parse(question.ins)}</div>
      {question &&
        question.questions.map((q: any, _index: number) => (
          <FormControl
            component="div"
            key={q.order}
            className={classes.formControl}
            error={errors && !errors[q.order].correct}
            disabled={errors && true}
          >
            <FormLabel component="legend">
              <b>{q.order}</b>&nbsp;&nbsp;&nbsp; {q.ins}
            </FormLabel>
            <RadioGroup
              name={q.order}
              value={answers[q.order] || ""}
              onChange={handleChange}
              className={classes.options}
            >
              {Object.entries(q.options).map(([label, opt]) => (
                <div key={label} className={classes.option}>
                  <span>{label.toUpperCase()}&nbsp;&nbsp;&nbsp;</span>
                  <FormControlLabel
                    value={label}
                    control={<Radio />}
                    label={opt}
                  />
                </div>
              ))}
              <div style={{ color: "red" }}>
                {errors &&
                  !errors[q.order].correct &&
                  `correct answer is ${errors[q.order].ans.toUpperCase()}`}
              </div>
            </RadioGroup>
          </FormControl>
        ))}
    </div>
  );
};

export default RenderSC;
