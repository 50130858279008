import * as React from "react";
import ListTitle from "./ListTitle";
import { useCourseValue } from "context/course.context";
import { makeStyles, Theme, Collapse } from "@material-ui/core";
import ContentHeaderForm from "./ContentHeaderForm";

const useStyles = makeStyles((theme: Theme) => ({
  collapse: {
    paddingLeft: theme.spacing(11),
    paddingRight: theme.spacing(5),
    marginTop: theme.spacing(2),
  },
}));

interface ContentHeaderProps {
  content: any;
}

const ContentHeader: React.FC<ContentHeaderProps> = ({ content }) => {
  const classes = useStyles();
  const { updateCourse } = useCourseValue();
  const [open, setOpen] = React.useState<boolean>(false);
  const toggleOpen = () => {
    setOpen(!open);
  };
  const handleFormSubmit = (data: any) => {
    updateCourse((draft: any) => {
      const newContent = draft.content.map((content: any) => {
        if (content.id === data.id) return data;
        return content;
      });
      draft.content = newContent;
    });
  };
  return (
    <>
      <ListTitle
        content={content}
        key={content.id}
        open={open}
        toggleOpen={toggleOpen}
      />
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        className={classes.collapse}
      >
        <ContentHeaderForm
          content={content}
          onCancel={toggleOpen}
          onSubmit={handleFormSubmit}
        />
      </Collapse>
    </>
  );
};

export default ContentHeader;
