import * as React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Breadcrumbs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      minHeight: "3rem",
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      alignItems: "center",
    },
    ol: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
      height: "100%",
      width: "100%",
    },
    li: {
      // height: "100%",
      flexGrow: 1,
      textAlign: "center",
      color: "#bdbdbd",
    },
    separator: {},
    active: {
      color: "white",
    },
    done: {
      color: "white",
      // backgroundColor: theme.palette.primary.light,
    },
    clickable: {
      cursor: "pointer",
    },
  })
);

interface StepBarProps {
  mode: string;
  examSectionList: any;
  currentSectionIndex: number;
  setCurrentSectionIndex: (i: number) => void;
}

const StepBar: React.FC<StepBarProps> = ({
  mode,
  examSectionList,
  currentSectionIndex,
  setCurrentSectionIndex,
}) => {
  const classes = useStyles();
  return (
    <Breadcrumbs
      classes={{
        root: classes.root,
        ol: classes.ol,
        li: classes.li,
        separator: classes.separator,
      }}
      separator={<NavigateNextIcon fontSize="large" color="secondary" />}
    >
      {examSectionList &&
        examSectionList.map((section: any, index: number) => {
          const isTestMode = mode === "test";
          const handleClick = () => {
            if (!isTestMode) {
              setCurrentSectionIndex(index);
            }
          };
          return (
            <span
              key={index}
              onClick={handleClick}
              className={`${
                currentSectionIndex === index ? classes.active : ""
              } ${currentSectionIndex > index ? classes.done : ""} ${
                !isTestMode ? classes.clickable : ""
              }`}
            >
              {section.name}
            </span>
          );
        })}
    </Breadcrumbs>
  );
};

export default StepBar;
