import { Grid, makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import parse from "html-react-parser";
import RenderQuestion from "components/Practice/RenderQuestion";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    overflow: "auto",
  },
  passage: {
    backgroundColor: "#e8f4fd",
    padding: theme.spacing(1),
    borderRadius: "4px",
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      overflowY: "scroll",
      height: "calc(100vh - 8.5rem)",
      fontSize: "1.1rem",
    },
  },
  question: {
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      overflowY: "scroll",
      height: "calc(100vh - 8.5rem)",
    },
  },
}));

interface ReadingContentProps {
  practice: any;
  answers: any;
  setAnswers: (data: any) => void;
  errors: any;
}

const ReadingContent: React.FC<ReadingContentProps> = ({
  practice,
  answers,
  setAnswers,
  errors,
}) => {
  console.log("readingContent", practice);
  const { passage, questions } = practice;
  const classes = useStyles();
  const renderQuestion = () => {
    if (questions) {
      if (Array.isArray(questions)) {
        return (
          <>
            {practice.questions.map((question: any) => (
              <Grid item key={question.range}>
                <RenderQuestion
                  question={question.questionBody}
                  type={question.type}
                  setAnswers={setAnswers}
                  answers={answers}
                  errors={errors}
                />
              </Grid>
            ))}
          </>
        );
      } else {
        return (
          <RenderQuestion
            question={questions.questionBody}
            type={questions.type}
            setAnswers={setAnswers}
            answers={answers}
            errors={errors}
          />
        );
      }
    } else {
      return (
        <RenderQuestion
          question={practice.questionBody}
          type={practice.type}
          setAnswers={setAnswers}
          answers={answers}
          errors={errors}
        />
      );
    }
  };
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={6} className={classes.passage}>
        {parse(passage)}
      </Grid>
      <Grid item xs={12} sm={6} className={classes.question}>
        {renderQuestion()}
      </Grid>
    </Grid>
  );
};

export default ReadingContent;
