import * as React from "react";
import {
  Button,
  Collapse,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import AlbumIcon from "@material-ui/icons/Album";
import StopIcon from "@material-ui/icons/Stop";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import MicIcon from "@material-ui/icons/Mic";
import format from "date-fns/format";
import { Storage, API } from "aws-amplify";
import * as mutations from "graphql/mutations";
import { useSnackbar } from "notistack";

interface AudioRecorderProps {
  username: string;
  setRecordList: (record: any) => void;
  questionID: string;
  questionTypeName: string;
}

const AudioRecorder: React.FC<AudioRecorderProps> = ({
  username,
  setRecordList,
  questionID,
  questionTypeName,
}) => {
  const [showRecorder, setShowRecorder] = React.useState(false);
  const [recordState, setRecordState] = React.useState<any>(RecordState.STOP);
  const [audioData, setAudioData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const start = () => {
    setRecordState(RecordState.START);
  };
  const stop = () => {
    setRecordState(RecordState.STOP);
  };

  //audioData contains blob and blobUrl
  const onStop = (data: any) => {
    setAudioData(data);
  };
  const handleOnDelete = () => {
    setAudioData(null);
  };
  const handleOnSave = async () => {
    setLoading(true);
    const file = new File(
      [audioData.blob],
      format(new Date(), "yyyyMMdd-hh:mm:ssaa"),
      {
        lastModified: Date.now(),
        type: audioData.blob.type,
      }
    );
    try {
      const recordKey = (await Storage.put(
        `resource/speakingRecords/${username}/${file.name}`,
        file
      )) as any;
      const input = {
        user: username,
        typeName: "SpeakingRecord",
        type: questionTypeName,
        typeID: questionID,
        url: recordKey.key,
      };
      const speakingRecordData = (await API.graphql({
        query: mutations.createSpeakingRecord,
        variables: { input },
      })) as any;
      setRecordList((pre: any) => [
        ...pre,
        speakingRecordData.data.createSpeakingRecord,
      ]);
      setAudioData(null);
      enqueueSnackbar("Record saved successfully", { variant: "success" });
      setShowRecorder(false);
    } catch (error) {
      enqueueSnackbar("Record failed to save, please try again later", {
        variant: "error",
      });
      console.log("speaking record save error", error);
    }
    setLoading(false);
  };
  return (
    <>
      <Tooltip title={showRecorder ? "Hide Recorder" : "Show Recorder"}>
        <IconButton
          color="primary"
          aria-label="micphone"
          onClick={() => setShowRecorder(!showRecorder)}
        >
          {showRecorder ? <ExpandLessIcon /> : <MicIcon />}
        </IconButton>
      </Tooltip>
      <Collapse in={showRecorder} timeout="auto" unmountOnExit>
        {audioData && <audio controls src={audioData.url}></audio>}
        <AudioReactRecorder
          state={recordState}
          onStop={onStop}
          canvasHeight={50}
          backgroundColor="#9eb5d9"
          foregroundColor="rgb(255,255,255)"
        />
        {loading ? (
          <Button startIcon={<CircularProgress size={20} />}>Saving</Button>
        ) : (
          <>
            {recordState === RecordState.STOP ? (
              <Tooltip title="Record">
                <IconButton onClick={start} key="record">
                  <AlbumIcon style={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Stop">
                <IconButton onClick={stop} key="stop">
                  <StopIcon style={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            )}
            {audioData && (
              <>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={handleOnDelete}
                    color="default"
                    key="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Save">
                  <IconButton onClick={handleOnSave} color="primary" key="save">
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
        )}
      </Collapse>
    </>
  );
};

export default AudioRecorder;
