import Spinner from "components/Spinner";
import * as React from "react";
import { fetchPartOneTopic, fetchPartTwo } from "utilities/awsCRUD";
import TopBanner from "components/Practice/components/TopBanner";
import BottomBanner from "components/Practice/components/BottomBanner";
import RenderSpeakingPart from "./RenderSpeakPart";
import { Button } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import MainWrapper from "layout/MainWrapper";

interface RenderExamSpeakingProps {
  part1IDList?: any[];
  part2ID: string;
  time?: string;
  onSubmit: () => void;
}

const RenderExamSpeaking: React.FC<RenderExamSpeakingProps> = ({
  part1IDList,
  part2ID,
  time,
  onSubmit,
}) => {
  const [part1List, setPart1List] = React.useState<any[]>([]);
  const [part2, setPart2] = React.useState<any>(null);
  const [index, setIndex] = React.useState<number>(0);
  React.useEffect(() => {
    if (part1IDList && part1IDList.length > 0) {
      (async () => {
        const promiseList = part1IDList.map((topic: string) =>
          fetchPartOneTopic(topic)
        );
        const returnedPart1List = await Promise.all(promiseList);
        setPart1List([...returnedPart1List]);
      })();
    }
  }, [part1IDList]);
  React.useEffect(() => {
    if (part2ID) {
      (async () => {
        const returnedPart2 = await fetchPartTwo(part2ID);
        setPart2(returnedPart2);
      })();
    }
  }, [part2ID]);
  const speakingList = React.useMemo(() => {
    if (part2) {
      return [...part1List, part2];
    }
    return [...part1List];
  }, [part1List, part2]);
  const handlePrev = () => {
    setIndex(index - 1);
  };
  const handleNext = () => {
    setIndex(index + 1);
  };
  if (speakingList.length === 0) return <Spinner />;
  return (
    <>
      <TopBanner time={time} title="Speaking" format="ES" onSubmit={onSubmit} />
      <MainWrapper>
        <RenderSpeakingPart data={speakingList[index]} />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "5px",
          }}
        >
          {index !== 0 && (
            <Button
              startIcon={<NavigateBeforeIcon />}
              onClick={handlePrev}
              color="secondary"
              variant="contained"
              style={{ color: "#0d47a1" }}
            >
              Prev
            </Button>
          )}
          <div style={{ flexGrow: 1 }}></div>
          {index !== speakingList.length - 1 && (
            <Button
              endIcon={<NavigateNextIcon />}
              onClick={handleNext}
              color="secondary"
              variant="contained"
              style={{ color: "#0d47a1" }}
            >
              Next
            </Button>
          )}
        </div>
      </MainWrapper>
      <BottomBanner title="Speaking" onSubmit={onSubmit} />
    </>
  );
};

export default RenderExamSpeaking;
