import * as React from "react";
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
  Theme,
  IconButton,
  Typography,
} from "@material-ui/core";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import CreateIcon from "@material-ui/icons/Create";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useAuthValue } from "context/auth.context";

interface ListTitleProps {
  content: any;
  open?: boolean;
  toggleOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    paddingLeft: theme.spacing(4),
  },
  listItemText: {
    // cursor: "pointer",
  },
  section: {
    backgroundColor: theme.palette.secondary.light,
    "&:hover": {
      background: theme.palette.secondary.light,
      cursor: "auto",
    },
  },
}));

const ListTitle: React.FC<ListTitleProps> = ({ content, open, toggleOpen }) => {
  const { user } = useAuthValue();
  const isAdmin = user.groups?.includes("admin");
  const classes = useStyles();
  const { typeName, title } = content;
  const isHeader: boolean = typeName === "header";
  const isFile = typeName === "file";
  const isAssignment = typeName === "assignment";

  return (
    <ListItem
      button={true}
      className={isHeader ? classes.section : classes.listItem}
      onClick={!isHeader ? toggleOpen : null}
    >
      {!isHeader && (
        <ListItemIcon>
          {isFile && <FolderOpenIcon color="primary" />}
          {isAssignment && <CreateIcon color="primary" />}
        </ListItemIcon>
      )}
      <ListItemText
        className={classes.listItemText}
        primary={
          isHeader ? (
            <Typography>
              <b>{title.toUpperCase()}</b>
            </Typography>
          ) : (
            <b>{title}</b>
          )
        }
      />
      {isAdmin && isHeader && open && (
        <IconButton onClick={toggleOpen} color="primary">
          <ExpandLess />
        </IconButton>
      )}
      {isAdmin && isHeader && !open && (
        <IconButton onClick={toggleOpen} color="primary">
          <ExpandMore />
        </IconButton>
      )}
      {!isHeader && open && (
        <IconButton onClick={toggleOpen} color="primary">
          <ExpandLess />
        </IconButton>
      )}
      {!isHeader && !open && (
        <IconButton onClick={toggleOpen} color="primary">
          <ExpandMore />
        </IconButton>
      )}
    </ListItem>
  );
};

export default ListTitle;
