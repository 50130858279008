import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import MultiSelect from "components/MultiSelect";
import config from "customConfig";

interface PartOneTopicCreationDialogProps {
  dialogValue: any;
  setDialogValue: (data: any) => void;
  open: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  loading: boolean;
}

const PartOneTopicCreationDialog: React.FC<PartOneTopicCreationDialogProps> = ({
  open,
  onClose,
  setDialogValue,
  dialogValue,
  onSubmit,
  loading,
}) => {
  const setDialogValueSeason = (season: string[]) => {
    setDialogValue({ ...dialogValue, season: [...season] });
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Part One Topic</DialogTitle>
      <DialogContent>
        <Grid container alignItems="center">
          <Grid item>
            <TextField
              autoFocus
              variant="outlined"
              value={dialogValue.id}
              onChange={(event) =>
                setDialogValue({ ...dialogValue, id: event.target.value })
              }
              label="topic"
              type="text"
            />
          </Grid>
          <Grid item>
            <MultiSelect
              options={config.seasonOptions}
              label="Season"
              value={dialogValue.season}
              setValue={setDialogValueSeason}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          color="primary"
          variant="outlined"
          startIcon={loading ? <CircularProgress size={18} /> : null}
          disabled={loading}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PartOneTopicCreationDialog;
