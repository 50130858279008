import {
  makeStyles,
  Theme,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Avatar,
  CardActions,
  Typography,
  Button,
} from "@material-ui/core";
import DeleteButton from "components/DeleteButton";
import { useAuthValue } from "context/auth.context";
import config from "customConfig";
import * as React from "react";
import parse from "html-react-parser";
import { useSnackbar } from "notistack";
import { deleteCourse } from "utilities/awsCRUD";
import { useHistory } from "react-router-dom";
import UserAvatar from "components/UserAvatar";

interface CourseCardProps {
  course: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    transition: "transform 0.15s ease-in-out",
    "&:hover": { transform: "scale3d(1.01, 1.01, 1)" },
  },
  cardHovered: {
    transform: "scale3d(1.05, 1.05, 1)",
  },
  header: {
    // backgroundColor: theme.palette.primary.main,
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  actions: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
}));

const CourseCard: React.FC<CourseCardProps> = ({ course }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { user } = useAuthValue();
  const isAdmin = user.groups.includes("admin");
  const onDelete = async () => {
    try {
      await deleteCourse(course.id);
      enqueueSnackbar(`successfully deleted ${course.title}`, {
        variant: "success",
      });
    } catch (error) {
      console.log("course deletion error", error);
      enqueueSnackbar("Connection error, please try again later", {
        variant: "error",
      });
    }
  };
  const onView = async () => {
    history.push("/mycourse", { id: course.id });
  };
  return (
    <Card className={classes.root} elevation={3}>
      <CardHeader
        className={classes.header}
        avatar={
          user.username === course.student ? (
            <UserAvatar user={user} />
          ) : (
            <Avatar className={classes.avatar}>
              {course.student[0].toUpperCase()}
            </Avatar>
          )
        }
        title={<Typography variant="h6">{course.title}</Typography>}
      />
      <CardMedia
        className={classes.media}
        image={`${config.awsUrl}resource/img/1on1course.png`}
        title="1on1Course"
      />
      <CardContent>{parse(course.description)}</CardContent>
      <CardActions className={classes.actions}>
        <Button onClick={onView} variant="contained" color="primary">
          Enter
        </Button>
        {isAdmin && <DeleteButton onDelete={onDelete} />}
      </CardActions>
    </Card>
  );
};

export default CourseCard;
