import * as React from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import useLocalImmer from "hooks/useLocalImmer";
import CardLayout from "layout/CardLayout";
import HomeworkForm from "components/Assignment/HomeworkForm";
import { isObjFieldFull } from "utilities/validation";
import useAmplifyMutation from "hooks/useAmplifyMutation";
import * as mutations from "graphql/mutations";

export interface HomeworkEditionViewProps {}

const cleanUpdateHw = (data: any) => {
  if (!data) return null;
  const cleaned = { ...data };
  delete cleaned.createdAt;
  delete cleaned.updatedAt;
  delete cleaned.tableData;
  delete cleaned.status;
  console.log("cleaned", cleaned);
  return cleaned;
};

const HomeworkEditionView: React.FC<HomeworkEditionViewProps> = () => {
  const { state } = useLocation() as any;
  const hwData = state?.hwData;
  const { enqueueSnackbar } = useSnackbar();
  const [hw, updateHw] = useLocalImmer("hwEditData", hwData);
  const { status, execute } = useAmplifyMutation();
  const history = useHistory();
  if (!hwData) return <Redirect to="/homework" />;
  const onPreview = () => {
    if (isObjFieldFull(hwData)) {
      history.push({ pathname: "/homework/preview", state: { hwData: hw } });
    } else {
      enqueueSnackbar("you are missing fields", { variant: "warning" });
    }
  };

  const onUpdate = async () => {
    const input = cleanUpdateHw(hw);
    if (isObjFieldFull(input)) {
      try {
        await execute({
          query: mutations.updateHomework,
          variables: { input },
        });
        enqueueSnackbar(`successfully updated ${input.title}`, {
          variant: "success",
        });
        afterMutation();
      } catch (error) {
        console.log("Homework update error", error);
        enqueueSnackbar("Connection error, please try agian later", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("There are fields missing", { variant: "warning" });
    }
  };
  const onDelete = async () => {
    try {
      await execute({
        query: mutations.deleteHomework,
        variables: { input: { id: hw.id } },
      });
      enqueueSnackbar(`Successfully deleted ${hw.title}`, {
        variant: "success",
      });
      afterMutation();
    } catch (error) {
      console.log("Homework delete error", error);
      enqueueSnackbar("Connection error, please try agian later", {
        variant: "error",
      });
    }
  };
  const afterMutation = () => {
    window.localStorage.removeItem("hwEditData");
    history.push("/homework");
  };
  const onCancel = () => {
    window.localStorage.removeItem("hwEditData");
    history.goBack();
  };
  return (
    <CardLayout
      title="Note Edition"
      mode="edit"
      loading={status === "pending"}
      onPreview={onPreview}
      onUpdate={onUpdate}
      onDelete={onDelete}
      onCancel={onCancel}
    >
      <HomeworkForm hwData={hw} updateHwData={updateHw} />
    </CardLayout>
  );
};

export default HomeworkEditionView;
