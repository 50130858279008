import React from "react";
import { useImmer } from "use-immer";

export default function useLocalState(key, defaultValue) {
  // const stickyValue = window.localStorage.getItem(key);
  // const initialValue =
  //   stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  const [storedValue, setStoredValue] = useImmer(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : defaultValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return defaultValue;
    }
  });

  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(storedValue));
  }, [key, storedValue]);
  return [storedValue, setStoredValue];
}
