import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  DialogActions,
} from "@material-ui/core";
import DeleteButton from "components/DeleteButton";
import { useAuthValue } from "context/auth.context";
import format from "date-fns/format";
import { useSnackbar } from "notistack";
import * as React from "react";
import { deleteEvent } from "utilities/awsCRUD";

interface EventDialogProps {
  open: boolean;
  handleClose: () => void;
  event: any;
  handleDeleteEvent: (event: string) => void;
}

const EventDialog: React.FC<EventDialogProps> = ({
  open,
  handleClose,
  event,
  handleDeleteEvent,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthValue();
  const isAdmin = user.groups?.includes("admin");
  const { id, student, title, start, end } = event;
  const handleDelete = async () => {
    try {
      await deleteEvent(id);
      enqueueSnackbar("successfully deleted class", { variant: "success" });
      handleDeleteEvent(id);
      handleClose();
    } catch (error) {
      enqueueSnackbar("connection error, please try again later", {
        variant: "error",
      });
    }
  };
  if (!event) return null;
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{`${student}-${title}`}</DialogTitle>
      <DialogContent>
        <Typography>Start: {format(start, "hh:mm aa")}</Typography>
        <Typography>End: {format(end, "hh:mm aa")}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {isAdmin && <DeleteButton onDelete={handleDelete} />}
      </DialogActions>
    </Dialog>
  );
};

export default EventDialog;
