import * as React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: "1rem",
      paddingBottom: "1rem",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  })
);
export interface MainWrapperProps {}

const MainWrapper: React.FC<MainWrapperProps> = ({ children }) => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" className={classes.root}>
      {children}
    </Container>
  );
};

export default MainWrapper;
