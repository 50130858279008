import * as React from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { isObjFieldFull } from "utilities/validation";
import useLocalImmer from "hooks/useLocalImmer";
import CardLayout from "layout/CardLayout";
import NoteForm from "components/Assignment/NoteForm";
import useAmplifyMutation from "hooks/useAmplifyMutation";
import * as mutations from "graphql/mutations";

export interface NoteCreationViewProps {}

const initialData = {
  title: "",
  student: "",
  description: "",
  practiceList: [],
} as any;

const NoteCreationView: React.FC<NoteCreationViewProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [noteData, updateNoteData] = useLocalImmer("noteData", initialData);
  const { status, execute } = useAmplifyMutation();
  const onCancel = () => {
    window.localStorage.removeItem("noteData");
    history.goBack();
  };

  const onSubmit = async () => {
    if (isObjFieldFull(noteData)) {
      try {
        await execute({
          query: mutations.createNote,
          variables: { input: noteData },
        });
        enqueueSnackbar("Successfully created Note", { variant: "success" });
        onCancel();
      } catch (error) {
        enqueueSnackbar("Connection error, please try again later", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("you are missing fields", { variant: "warning" });
    }
  };

  const onPreview = () => {
    if (isObjFieldFull(noteData)) {
      history.push({ pathname: "preview", state: { noteData } });
    } else {
      enqueueSnackbar("you are missing fields", { variant: "warning" });
    }
  };
  return (
    <CardLayout
      loading={status === "pending"}
      title="Note Creation"
      mode="create"
      onPreview={onPreview}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <NoteForm noteData={noteData} updateNoteData={updateNoteData} />
    </CardLayout>
  );
};

export default NoteCreationView;
