import * as React from "react";
import DropDown from "components/DropDown";
import RadioSelector from "components/RadioSelector";
import { makeStyles, createStyles, TextField, Theme } from "@material-ui/core";
import config from "customConfig";

export interface WritingControlProps {
  input: any;
  handleInputChange: any;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);
const WritingControl: React.FC<WritingControlProps> = ({
  input,
  handleInputChange,
}) => {
  const classes = useStyles();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    handleInputChange("search")(value);
  };

  const typeOptions =
    input.typeName === "Essay"
      ? config.essayType
      : input.typeName === "Graph"
      ? config.graphType
      : config.letterType;

  return (
    <div>
      <div className={classes.inputContainer}>
        <DropDown
          name="Task"
          options={config.writingTasks}
          value={input.typeName}
          setValue={handleInputChange("typeName")}
        />
        {input.typeName === "Essay" && (
          <RadioSelector
            name="Genre"
            options={config.genre}
            value={input.genre}
            setValue={handleInputChange("genre")}
          />
        )}
        <TextField
          label="Search by ID"
          type="search"
          variant="outlined"
          name="search"
          autoComplete="off"
          value={input.search ?? ""}
          onChange={handleChange}
          className={classes.formControl}
        />
      </div>
      <div className={classes.inputContainer}>
        <DropDown
          name="Book"
          options={config.books}
          value={input.book}
          setValue={handleInputChange("book")}
          noneValue
        />
        <DropDown
          name="Type"
          options={typeOptions}
          value={input.type}
          setValue={handleInputChange("type")}
          noneValue
        />
        {input.typeName === "Essay" && (
          <DropDown
            name="Topic"
            options={config.essayTopic}
            value={input.topic}
            setValue={handleInputChange("topic")}
            noneValue
          />
        )}
        {input.typeName === "Letter" && (
          <DropDown
            name="Aim"
            options={config.letterAim}
            value={input.aim}
            setValue={handleInputChange("aim")}
            noneValue
          />
        )}
      </div>
    </div>
  );
};

export default WritingControl;
