import * as React from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { API } from "aws-amplify";
import * as customQueries from "customQueries";

interface P2ListAutoCompleteProps {
  style?: any;
  setValue: (value: any) => void;
  value: any;
  multiple?: boolean;
}

const P2ListAutoComplete: React.FC<P2ListAutoCompleteProps> = ({
  style,
  setValue,
  value,
  multiple,
}) => {
  const [part2List, setPart2List] = React.useState<any>([]);
  React.useEffect(() => {
    (async () => {
      try {
        const data = (await API.graphql({
          query: customQueries.listP2,
          variables: {
            typeName: "PartTwo",
            limit: 1000,
          },
        })) as any;
        const listData = data.data.listP2.items.map((data: any) => data);
        setPart2List(listData);
      } catch (error) {
        console.log("list part two  error", error);
      }
    })();
  }, []);
  return (
    <Autocomplete
      style={style}
      multiple={multiple}
      options={part2List}
      value={value}
      filterSelectedOptions
      getOptionLabel={(option) => option.prompt}
      onChange={(_event, newValue) => {
        if (Array.isArray(newValue)) {
          setValue([...newValue]);
        } else {
          setValue(newValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Selected Part 2"
          placeholder="Part Two Prompt"
        />
      )}
    />
  );
};

export default P2ListAutoComplete;

P2ListAutoComplete.defaultProps = {
  style: null,
  multiple: false,
};
