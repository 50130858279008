import * as React from "react";
import MainWrapper from "layout/MainWrapper";
import PracticeList from "components/Practice/PracticeList";
import { useHistory, Link } from "react-router-dom";
import CreateIcon from "@material-ui/icons/Create";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useAuthValue } from "context/auth.context";
import customConfig from "customConfig";

export interface PracticeListViewProps {}

const { subjects, editables } = customConfig;

const PracticeListView: React.FC<PracticeListViewProps> = () => {
  const history = useHistory();
  const { isAdmin } = useAuthValue();
  const action = [
    //view practice
    (rowData: any) => {
      const { typeName, id } = rowData;
      const subject = subjects[typeName];
      return {
        icon: () => {
          if (subject === "speaking") {
            return <VisibilityIcon />;
          }
          return (
            <Link to={`/practice/${subject}?typeName=${typeName}&id=${id}`}>
              <VisibilityIcon style={{ color: "black" }} />
            </Link>
          );
        },
        tooltip: "view",
        onClick: (_event: any) => {
          if (subject === "speak") {
            history.push("/practice/speak", { practiceData: rowData });
          } else {
            history.push(`/practice/${subject}?typeName=${typeName}&id=${id}`);
          }
        },
      };
    },
    //edit practice
    (rowData: any) => {
      const { typeName, id } = rowData;
      const isEditable = editables.includes(typeName);
      const subject = subjects[typeName];

      return isAdmin && isEditable
        ? {
            icon: () => <CreateIcon />,
            tooltip: "Edit",
            onClick: (_event: any, rowData: any) => {
              let locationState;
              if (subject === "speak") {
                locationState = { practiceData: rowData };
              } else {
                locationState = { id, typeName };
              }
              history.push(`/practice/${subject}/edit`, locationState);
            },
          }
        : null;
    },
  ];

  return (
    <MainWrapper>
      <PracticeList action={action} />
    </MainWrapper>
  );
};

export default PracticeListView;
