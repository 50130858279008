import * as React from "react";
import { Button, Collapse } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CKeditor from "./CKeditor";

interface PopEditorProps {
  value: string;
  setValue: (data: string) => void;
  title: string;
}

const PopEditor: React.FC<PopEditorProps> = ({ value, setValue, title }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const handleOnChange = (_e: any, editor: any) => {
    setValue(editor.getData());
  };
  return (
    <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setOpen(!open)}
        startIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      >
        {title}
      </Button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CKeditor data={value} handleOnChange={handleOnChange} />
      </Collapse>
    </div>
  );
};

export default PopEditor;
