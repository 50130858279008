import { areTwoEqualArrays, rangeToArray } from ".";

export const blankMatchRange = (text: string, range: string) => {
  if (!text || !range) return false;
  const rangeArray = rangeToArray(range);
  const questionArray = Array.from(text.matchAll(/\[(\d\d?)\]/gim)).map(
    (match: RegExpMatchArray) => parseInt(match[1])
  );
  questionArray.sort((a, b) => a - b);
  if (rangeArray.length === 0 || questionArray.length === 0) return false;
  if (rangeArray.length !== questionArray.length) return false;
  for (var i = 0; i < rangeArray.length; ++i) {
    if (rangeArray[i] !== questionArray[i]) return false;
  }
  return true;
};

export const optionsGreaterThanRange = (options: string[], range: string) => {
  if (!options || !range) return false;
  return options.length >= rangeToArray(range).length;
};

export const validateRange = (range: string) => {
  if (!range) return false;
  const reg = /^\d{1,2}(-\d{1,2})?$/gi;
  if (reg.test(range)) {
    let [begin, end] = range.split("-");
    if (!end) return true;
    if (parseInt(begin) < parseInt(end)) return true;
  }
  return false;
};

export const noEmptyValueInObj = (obj: any) => {
  if (typeof obj !== "object") return false;
  const valuesArray = Object.values(obj);
  if (valuesArray.length === 0) return false;
  return valuesArray.every((value) => !!value);
};

export const noEmptyArray = (...args: any[]) => {
  for (let arr of args) {
    if (arr.length === 0) return false;
  }
  return true;
};

export const validateTestAnswers = (...args: any[]) => {
  const answersArray = args.flat().map((question) => question.answers);
  const answers = {};
  for (let ans of answersArray) {
    Object.assign(answers, ans);
  }
  let answerKeys = Object.keys(answers)
    .map((key) => {
      if (/\d\d?-\d\d?/.test(key)) return rangeToArray(key);
      return key;
    })
    .flat()
    .map((key) => Number(key));
  if (answerKeys.length !== 40) return false;
  const modelAnswerKeys = [...Array(40).keys()].map((num) => num + 1);
  if (!areTwoEqualArrays(answerKeys, modelAnswerKeys)) return false;
  return true;
};

// export const eachPassageHasOverHundredWord = (args: any[]) => {
//   for (let passage of args) {
//     if (passage.split(" ").length < 100) return false;
//   }
//   return true;
// };

export const eachSectionHasTenQuestions = (...args: any[]) => {
  for (let section of args) {
    let sectionQuestions: any[] = [];
    for (let question of section) {
      sectionQuestions = sectionQuestions.concat(rangeToArray(question.range));
    }
    if (sectionQuestions.length !== 10) {
      return false;
    }
  }
  return true;
};

export const validateListeningTest = (
  testInfo: any,
  sectionOne: any,
  sectionTwo: any,
  sectionThree: any,
  sectionFour: any,
  enqueueSnackbar: any
) => {
  if (!noEmptyValueInObj(testInfo)) {
    enqueueSnackbar("missing test info", { variant: "error" });
    return false;
  }
  if (!noEmptyArray(sectionOne, sectionTwo, sectionThree, sectionFour)) {
    enqueueSnackbar("there is empty session", { variant: "error" });
    return false;
  }
  if (
    !validateQuestionList(40, sectionOne, sectionTwo, sectionThree, sectionFour)
  ) {
    enqueueSnackbar("total is not 40 questions", {
      variant: "error",
    });
    return false;
  }
  if (!validateTestAnswers(sectionOne, sectionTwo, sectionThree, sectionFour)) {
    enqueueSnackbar("missing questions or order isn't right", {
      variant: "error",
    });
    return false;
  }
  return true;
};

export const validateReadingTest = (
  testInfo: any,
  enqueueSnackbar: any,
  ...paras: any[]
) => {
  if (!noEmptyValueInObj(testInfo)) {
    enqueueSnackbar("missing test info", { variant: "error" });
    return false;
  }
  if (!noEmptyArray(paras.map((para: any) => para.questions))) {
    enqueueSnackbar("there is empty session", { variant: "error" });
    return false;
  }
  // if (!eachPassageHasOverHundredWord(paras.map((para: any) => para.passage))) {
  //   return false;
  // }
  if (!validateTestAnswers(...paras.map((para: any) => para.questions))) {
    enqueueSnackbar("missing questions or order isn't right", {
      variant: "error",
    });
    return false;
  }
  return true;
};

export const validateReadingTestNew = (readingTest: any) => {
  const { book, test, genre, p1, p2, p3, s1a, s1b, s2a, s2b, s3 } = readingTest;
  // let passageArray;
  let data;
  if (genre === "A") {
    // passageArray = [p1.passage, p2.passage, p3.passage];
    data = { book, test, genre, p1, p2, p3 };
  } else {
    // passageArray = [
    //   s1a.passage,
    //   s1b.passage,
    //   s2a.passage,
    //   s2b.passage,
    //   s3.passage,
    // ];
    data = { book, test, genre, s1a, s1b, s2a, s2b, s3 };
  }
  if (!isObjFieldFull(data))
    throw new Error("You are missing field(s), please check again.");

  // if (!eachPassageHasOverHundredWord(passageArray))
  //   throw new Error("One or more passages are less than 100 words.");
};

export const validateQuestionList = (count: number, ...args: any[]) => {
  if (args.length === 0) return false;
  const questionList: any[] = args.flat();
  if (questionList.length === 0) return false;
  let orderList: any[] = [];
  for (let question of questionList) {
    orderList.push(rangeToArray(question.range));
  }
  orderList = orderList.flat();
  if (count === 6) {
    if (!(orderList.length === 6 || orderList.length === 7)) return false;
  } else {
    if (count !== orderList.length) return false;
  }
  if (
    !orderList.every(
      (num, i) => i === orderList.length - 1 || num === orderList[i + 1] - 1
    )
  )
    return false;
  return true;
};

export const validateWritingData = (writingData: any, enqueueSnackbar: any) => {
  for (const [key, value] of Object.entries(writingData)) {
    if (!value && key !== "sampleAnswer") {
      enqueueSnackbar(`missing ${key}`, { variant: "error" });
      return false;
    }
  }
  if (writingData.task === "Essay") {
    if (!writingData.genre) {
      enqueueSnackbar("missing genre", { variant: "error" });
      return false;
    }
    if (!writingData.topic) {
      enqueueSnackbar("missing topic", { variant: "error" });
      return false;
    }
  }
  if (writingData.task === "Graph") {
    if (!writingData.img) {
      enqueueSnackbar("missing image", { variant: "error" });
      return false;
    }
  }
  if (writingData.task === "Letter") {
    if (!writingData.aim) {
      enqueueSnackbar("missing aim", { variant: "error" });
      return false;
    }
  }
  return true;
};

export const validatePartOneTopic = (part1Topic: any) => {
  if (typeof part1Topic !== "object") return false;
  const { id, season, questions } = part1Topic;
  if (!id || !season || !questions) return false;
  if (questions.length === 0) return false;
  return true;
};

export const validatePartTwo = (partTwo: any) => {
  const { prompt, type, season, subQuestions } = partTwo;
  if (!prompt || !type) return false;
  if (season.length < 1) return false;
  if (subQuestions.some((q: string) => q === "")) return false;
  return true;
};

export const isObjFieldFull = (obj: any, ...except: string[]) => {
  if (typeof obj !== "object" || Array.isArray(obj) || obj === null)
    return false;
  let testObj: any;
  if (except.length > 0) {
    testObj = { ...obj };
    except.forEach((property: string) => delete testObj[property]);
  } else {
    testObj = obj;
  }
  const values = Object.values(testObj) as any;
  for (let i = 0; i < values.length; i++) {
    if (Array.isArray(values[i])) {
      if (values[i].length === 0) {
        return false;
      }
    }
    if (typeof values[i] === "object" && !Array.isArray(values[i])) {
      if (!isObjFieldFull(values[i])) {
        console.log("object test failed", values[i]);
        return false;
      }
    }
    if (!values[i] && values[i] !== 0) return false;
  }
  return true;
};
