import * as React from "react";
import BottomBanner from "../BottomBanner";
import TopBanner from "../TopBanner";
import SpeakingContent from "./SpeakingContent";

interface RenderSpeakingProps {
  practice: any;
  time?: string;
  onSubmit?: (data: any) => Promise<void>;
  onClose?: () => void;
  submittedAnswers?: any;
  title: string;
}

const RenderSpeaking: React.FC<RenderSpeakingProps> = ({
  practice,
  time,
  onSubmit,
  onClose,
  submittedAnswers,
  title,
}) => {
  const [submitted, setSubmitted] = React.useState<any>(submittedAnswers);
  const { typeName } = practice;
  const [index, setIndex] = React.useState<number>(0);
  const practiceList = React.useMemo<any[]>(() => {
    if (practice.typeName === "SpeakingTest") {
      return [...practice.part1, practice.part2];
    }
    return [];
  }, [practice]);
  const nextPage = () => {
    setIndex(index + 1);
  };
  const prevPage = () => {
    setIndex(index - 1);
  };
  const handleSubmit = async () => {
    setSubmitted({ completed: true });
    if (onSubmit) {
      await onSubmit({ completed: true });
    }
  };
  const isExam = title.includes("Exam");
  const lastIndex = practiceList.length - 1;

  return (
    <div
      style={
        isExam
          ? null
          : {
              zIndex: 1200,
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              top: 0,
              left: 0,
              backgroundColor: "rgba(250,250,250,1)",
            }
      }
    >
      <TopBanner
        onClose={onClose}
        onSubmit={handleSubmit}
        time={time}
        errors={submitted}
        title={title}
      />
      <SpeakingContent
        practice={typeName.includes("Test") ? practiceList[index] : practice}
      />
      <BottomBanner
        onSubmit={handleSubmit}
        title={title}
        nextPage={nextPage}
        prevPage={prevPage}
        index={index}
        lastIndex={lastIndex}
        typeName={typeName}
        submitted={!!submitted}
      />
    </div>
  );
};

export default RenderSpeaking;
