import * as React from "react";
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useSnackbar } from "notistack";
import Spinner from "components/Spinner";

export interface ChangePasswordDialogProps {
  toggleCPD: any;
  openCPD: boolean;
}

const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = ({
  toggleCPD,
  openCPD,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit, errors, watch } = useForm({ mode: "onBlur" });
  const onSubmit = async ({
    oldPassword,
    newPasswordConfirm,
  }: {
    oldPassword: string;
    newPasswordConfirm: string;
  }) => {
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPasswordConfirm);
      enqueueSnackbar("Password was changed successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setLoading(false);
    toggleCPD();
  };
  return (
    <Dialog open={openCPD} onClose={toggleCPD}>
      {loading ? <Spinner /> : null}
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            error={!!errors.oldPassword}
            variant="outlined"
            margin="normal"
            autoComplete="off"
            required
            fullWidth
            name="oldPassword"
            label="Current Password"
            type="password"
            inputRef={register({
              minLength: {
                value: 6,
                message: "The password should have at least 6 characters",
              },
            })}
            helperText={errors?.password?.message}
          />
          <TextField
            error={!!errors.newPassword}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="New Password"
            type="password"
            inputRef={register({
              minLength: {
                value: 6,
                message: "The password should have at least 6 characters",
              },
            })}
            helperText={errors?.newPassword?.message}
          />
          <TextField
            error={!!errors.newPasswordConfirm}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="newPasswordConfirm"
            label="Confirm New Password"
            type="password"
            inputRef={register({
              validate: (value) => value === watch("newPassword"),
            })}
            helperText={
              errors?.newPasswordConfirm &&
              "The new password inputs are not the same"
            }
          />
          <Grid container justify="space-between">
            <Grid item>
              <Button onClick={toggleCPD} variant="contained" color="secondary">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordDialog;
