import * as React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { useAuthValue } from 'context/auth.context';
import Spinner from 'components/Spinner';
import { useSnackbar } from 'notistack';
import { createUser } from 'utilities/awsCRUD';

export interface ResetProps {
    initialUser: any;
    classes:any;
}

 
const ResetPasswordForm: React.FC<ResetProps> = ({initialUser, classes}) => {
        const { enqueueSnackbar } = useSnackbar();
        const { setUser} = useAuthValue()
        const [loading, setLoading] = React.useState(false)
        const { register, handleSubmit,  errors , getValues} = useForm({mode:'onBlur'});
        const onSubmit=async ({newPassword}:{newPassword:string}) =>{
              let createdUser = undefined
              setLoading(true)
              try {
                const user = await Auth.completeNewPassword(initialUser, newPassword);
                createdUser = await createUser(user, enqueueSnackbar)
                
              } catch (error) {
                  enqueueSnackbar('connection error, please make check your connection', { variant:'error' })
              }
              setLoading(false)  
              setUser(createdUser) 
        }
        return (
            <Container component="main" maxWidth="xs">
               { loading&&<Spinner/> }
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Please reset your password on your first time sign in. 
                </Typography>
                <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    error={!!errors.newPassword}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="newPassword"
                    label="New Password"
                    type="password"
                    autoComplete="current-password"
                    inputRef={register({ minLength: {value:8, message:"密码不能少于8位"}})}
                    helperText={errors?.newPassword?.message}
                  />
                  <TextField
                    error={!!errors.confirmPassword}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    inputRef={register({
                        validate: value => value === getValues('newPassword') || '您输入的密码不一致'  // <p>error message</p>
                      })}
                      helperText={errors?.confirmPassword?.message}
                  />
                  {/* <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  /> */}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Reset
                  </Button>
                </form>
              </div>
            </Container>
          );
}
 
export default ResetPasswordForm;