import * as React from "react";
import type { RefObject } from "react";
import {
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import VolumeSlider from "components/VolumnSlider";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useHistory } from "react-router-dom";
import PracticeTimer from "components/Practice/PracticeTimer";
import { stringToSeconds, trackEvent } from "utilities";
import ResultDialog from "./ResultDialog";
import useHighlight from "hooks/useHighlight";
import ListAltIcon from "@material-ui/icons/ListAlt";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useAuthValue } from "context/auth.context";

interface TopBannerProps {
  onClose?: () => void;
  onSubmit: () => void;
  time: string;
  errors: any;
  toggleShowScript?: () => void;
  title: string;
  audioPlayer?: RefObject<HTMLAudioElement | null>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "0.5rem 1rem",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    height: "4rem",
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: "1rem",
  },
  timer: {
    cursor: "default",
  },
}));

const TopBanner: React.FC<TopBannerProps> = ({
  time,
  toggleShowScript,
  title,
  errors,
  onSubmit,
  onClose,
  audioPlayer,
}) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useAuthValue();
  const classes = useStyles();
  const history = useHistory();
  const { highlightFabs } = useHighlight();
  const [open, setOpen] = React.useState<boolean>(false);
  const [openResultDialog, setOpenResultDialog] =
    React.useState<boolean>(false);
  const isReadingOrListening =
    title.includes("Reading") || title.includes("Listening");
  const isPractice = title.includes("Practice");
  const isHomework = title.includes("homework");
  const message = `Are you sure you want to quit the ${title}?`;
  const handleCancel = () => {
    if (isPractice) {
      history.goBack();
    }
    if (isHomework) {
      trackEvent(`Quit ${title}`, user);
      history.goBack();
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <Grid
      container
      justify="space-between"
      direction="row"
      alignItems="center"
      className={classes.root}
    >
      <Grid item className={classes.title}>
        <IconButton
          onClick={() => setOpen(true)}
          className={classes.icon}
          color="primary"
        >
          <CancelPresentationIcon />
        </IconButton>
        {!downSm && <Typography>{title}</Typography>}
      </Grid>

      {time && !errors && (
        <Grid item className={classes.timer}>
          <Typography align="center">Timer</Typography>
          <PracticeTimer
            timeLimit={stringToSeconds(time)}
            handleSubmit={onSubmit}
            position="static"
          />
        </Grid>
      )}
      {audioPlayer && (
        <Grid item>
          <VolumeSlider audioPlayer={audioPlayer} />
        </Grid>
      )}
      {(errors || isPractice) && toggleShowScript && (
        <Grid item>
          {downSm ? (
            <IconButton color="primary" onClick={toggleShowScript}>
              <VisibilityIcon />
            </IconButton>
          ) : (
            <Button
              onClick={toggleShowScript}
              startIcon={<VisibilityIcon />}
              variant="outlined"
              color="primary"
            >
              script
            </Button>
          )}
        </Grid>
      )}

      {errors && isReadingOrListening && (
        <Grid item>
          <Button
            onClick={() => setOpenResultDialog(true)}
            variant="outlined"
            startIcon={<ListAltIcon />}
            color="primary"
          >
            Result
          </Button>
        </Grid>
      )}
      {isReadingOrListening && highlightFabs}
      {openResultDialog && (
        <ResultDialog
          open={openResultDialog}
          setOpen={setOpenResultDialog}
          resultList={errors}
        />
      )}
      {open && (
        <ConfirmationDialog
          open={open}
          onCancel={() => setOpen(false)}
          message={message}
          onConfirm={handleCancel}
        />
      )}
    </Grid>
  );
};

export default TopBanner;
