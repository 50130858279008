import * as React from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { API } from "aws-amplify";
import * as customQueries from "customQueries";

interface P1ListAutoCompleteProps {
  style?: any;
  setValue: (value: any) => void;
  value: any;
  multiple?: boolean;
}

const P1ListAutoComplete: React.FC<P1ListAutoCompleteProps> = ({
  style,
  setValue,
  value,
  multiple,
}) => {
  const [topicList, setTopicList] = React.useState<any>([]);
  React.useEffect(() => {
    (async () => {
      try {
        const data = (await API.graphql({
          query: customQueries.listP1topics,
          variables: {
            typeName: "PartOneTopic",
            limit: 1000,
          },
        })) as any;
        const listData = data.data.listP1topics.items.map(
          (data: any) => data.id
        );
        setTopicList(listData);
      } catch (error) {
        console.log("list part one topics error", error);
      }
    })();
  }, []);
  return (
    <Autocomplete
      style={style}
      multiple={multiple}
      id="tags-outlined"
      options={topicList}
      value={value}
      filterSelectedOptions
      onChange={(_event, newValue) => {
        if (Array.isArray(newValue)) {
          setValue([...newValue]);
        } else {
          setValue(newValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Selected Part 1"
          placeholder="Part One Topic"
        />
      )}
    />
  );
};

export default P1ListAutoComplete;

P1ListAutoComplete.defaultProps = {
  style: null,
  multiple: false,
};
