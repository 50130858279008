import * as React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import UserAvatar from "components/UserAvatar";
import UserListItemDetail from "./UserListItemDetail";

export interface UserListItemProps {
  user: any;
}

const UserListItem: React.FC<UserListItemProps> = ({ user }) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemAvatar>
          <UserAvatar user={user} />
        </ListItemAvatar>
        <ListItemText primary={user.username} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      {open && <UserListItemDetail user={user} open={open} />}
    </>
  );
};

export default UserListItem;
