import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import MultiSelect from "components/MultiSelect";
import config from "customConfig";
import { useSnackbar } from "notistack";

interface AddSpeakingQuestionDialogProps {
  isEdit: boolean;
  updateQuestion: (question: any) => void;
  selectedQuestion: any;
  open: boolean;
  onClose: () => void;
  addQuestion: (question: any) => void;
  part: "1" | "3";
}

const AddSpeakingQuestionDialog: React.FC<AddSpeakingQuestionDialogProps> = ({
  isEdit,
  selectedQuestion,
  updateQuestion,
  open,
  onClose,
  addQuestion,
  part,
}) => {
  const [data, setData] = React.useState<any>(selectedQuestion);
  const { enqueueSnackbar } = useSnackbar();
  const { question, type } = data;
  const onSubmit = () => {
    if (question && type.length !== 0) {
      if (!isEdit) {
        addQuestion(data);
      } else {
        updateQuestion(data);
      }
      onClose();
    } else {
      enqueueSnackbar("you are missing fields", { variant: "error" });
    }
  };
  const addType = (type: string[]) => {
    setData((pre: any) => ({ ...pre, type }));
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth={true}>
      <DialogTitle>Add Speaking Question</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          value={question}
          onChange={(event) =>
            setData({
              ...data,
              question: event.target.value,
            })
          }
          label="question"
          type="text"
          variant="outlined"
          fullWidth={true}
        />
        <MultiSelect
          options={part === "1" ? config.partOneType : config.partThreeType}
          label="type"
          value={type}
          setValue={addType}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button type="submit" color="primary" onClick={onSubmit}>
          {isEdit ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSpeakingQuestionDialog;
