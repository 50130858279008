import { Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
// import MyCalendar from "components/Scheduler";
import * as React from "react";
import HomeworkTable from "components/Assignment/HomeworkTable";
import Profile from "./Profile";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingTop: "3rem",
    },
  })
);

export interface UserDashboardProps {}

const UserDashboard: React.FC<UserDashboardProps> = () => {
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.root} spacing={3}>
      {/* <Grid item md={6} spacing={3}> */}
      <Grid item>
        <Profile />
        {/* </Grid> */}
        {/* <Grid item md={6}>
          <MyCalendar height="250px" />
        </Grid> */}
      </Grid>
      <Grid item>
        <HomeworkTable />
      </Grid>
    </Grid>
  );
};

export default UserDashboard;
