/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_cognito_identity_pool_id": "ap-northeast-2:b95140fd-f735-4d2b-b27c-e93651ac8326",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_sQpTjSCEC",
    "aws_user_pools_web_client_id": "7hl5lur0smt480bpna29j3pfrm",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_appsync_graphqlEndpoint": "https://7t2fheqw5jehnf43cb5t4vb26i.appsync-api.ap-northeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "boxwhylmsd5a83bf5767d4a3db3fa3957bd02927c130601-dev",
    "aws_user_files_s3_bucket_region": "ap-northeast-2",
    "aws_content_delivery_bucket": "boxwhylms-manual-hosting-dev",
    "aws_content_delivery_bucket_region": "ap-northeast-2",
    "aws_content_delivery_url": "https://d2gc1ctpaz7mp9.cloudfront.net",
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ]
};


export default awsmobile;
