import { API } from "aws-amplify";
import * as mutations from "graphql/mutations";

export const trackEvent = async (action, user) => {
  if (!user.groups?.includes("admin")) {
    const input = {
      userID: user.id,
      action: action,
      createdAt: new Date().toISOString(),
      type: "Trackings",
    };
    try {
      await API.graphql({
        query: mutations.createTracking,
        variables: { input },
      });
    } catch (error) {
      console.log("tracking error", error);
    }
  }
};
export const rangeToArray = (range) => {
  const [begin, end] = range.split("-");
  const beginNum = parseInt(begin);
  const endNum = parseInt(end);
  if (isNaN(endNum)) return [beginNum];
  const newArray = [];
  for (let i = beginNum; i <= endNum; i++) {
    newArray.push(i);
  }
  return newArray;
};

export const emptyObj = (answers) => {
  if (!answers) return null;
  const input = {};
  Object.keys(answers).forEach((key) => (input[key] = ""));
  return input;
};

export const areTwoEqualArrays = (first, second) => {
  if (first.length !== second.length) {
    return false;
  }
  for (let i = 0; i < first.length; i++) {
    if (!second.includes(first[i])) {
      return false;
    }
  }
  return true;
};

export const getDataFromArray = (array) => {
  if (array) {
    const answers = {};
    array.forEach((question) => {
      Object.assign(answers, emptyObj(question.answers));
    });
    return answers;
  }
  return null;
};

export const getDataFromArrays = (...arrs) => {
  if (arrs.length !== 0) {
    const data = {};
    arrs.forEach((array) => Object.assign(data, getDataFromArray(array)));
    return data;
  }
  return null;
};

export const getAnswersFromArray = (array) => {
  if (array) {
    const answers = {};
    array.forEach((question) => {
      Object.assign(answers, question.answers);
    });
    return answers;
  }
  return null;
};

export const getAnswersFromArrays = (...arrs) => {
  if (arrs.length !== 0) {
    const answers = {};
    arrs.forEach((array) => Object.assign(answers, getAnswersFromArray(array)));
    return answers;
  }
  return null;
};

export const millSecondsToMinutesAndSeconds = (millis) => {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return `${minutes} mins: ${seconds < 10 ? "0" : ""}${seconds} secs`;
};

export const stringToSeconds = (str) => {
  if (str) {
    const mins = Number(str.substr(0, 2));
    const secs = Number(str.substr(2, 4));
    return 60 * mins + secs;
  }
  return null;
};

export const compareAnswerLabel = (a, b) => {
  let first = a.includes("-")
    ? a.split("-")[0]
    : a.toLowerCase().includes("task")
    ? a.charAt(a.length - 1)
    : a;
  let second = b.includes("-")
    ? a.split("-")[0]
    : b.toLowerCase().includes("task")
    ? b.charAt(b.length - 1)
    : b;
  return first - second;
};

export function sortObject(obj, compareFunc) {
  return Object.keys(obj)
    .sort(compareFunc)
    .reduce(function (result, key) {
      result[key] = obj[key];
      return result;
    }, {});
}

export const createPracticeResult = (submittedAnswers, answers) => {
  if (!submittedAnswers || !answers) return null;
  const result = {};
  for (const [label, ans] of Object.entries(answers)) {
    if (Array.isArray(ans)) {
      if (!areTwoEqualArrays(ans, submittedAnswers[label])) {
        result[label] = {
          correct: false,
          ans: ans,
          input: submittedAnswers[label],
        };
      } else {
        result[label] = {
          correct: true,
          ans: ans,
          input: submittedAnswers[label],
        };
      }
    } else if (ans.includes("/")) {
      if (
        !ans
          .toLowerCase()
          .split("/")
          .map((an) => an.trim())
          .includes(submittedAnswers[label].trim().toLowerCase())
      ) {
        result[label] = { correct: false, ans, input: submittedAnswers[label] };
      } else {
        result[label] = { correct: true, ans, input: submittedAnswers[label] };
      }
    } else {
      if (
        ans.trim().toLowerCase() !==
        submittedAnswers[label].trim().toLowerCase()
      ) {
        result[label] = { correct: false, ans, input: submittedAnswers[label] };
      } else {
        result[label] = { correct: true, ans, input: submittedAnswers[label] };
      }
    }
  }
  return result;
};

export const wordCounter = (text) => {
  var wordCount = 0;
  if (text) {
    for (var i = 0; i <= text.length; i++) {
      if (text.charAt(i) === " ") {
        wordCount++;
      }
    }
  }
  return wordCount;
};
