import * as React from "react";
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Button,
  makeStyles,
  // Theme,
} from "@material-ui/core";
import parse from "html-react-parser";
import MainWrapper from "layout/MainWrapper";
import { trackEvent } from "utilities";
import { useAuthValue } from "context/auth.context";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "70vh",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flexGrow: 1,
  },
  actions: {},
  button: {
    display: "block",
    margin: "auto",
  },
}));

interface RenderExamInsProps {
  ins: string;
  next: () => void;
}

const RenderExamIns: React.FC<RenderExamInsProps> = ({ ins, next }) => {
  const classes = useStyles();
  const { user } = useAuthValue();
  const handleStart = () => {
    next();
    trackEvent("Start Exam", user);
  };
  return (
    <MainWrapper>
      <Card className={classes.root}>
        <CardHeader title="Boxwhy IELTS Mock Exam Instruction" />
        <CardContent className={classes.content}>{parse(ins)}</CardContent>
        <CardActions className={classes.actions}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleStart}
            className={classes.button}
          >
            Start
          </Button>
        </CardActions>
      </Card>
    </MainWrapper>
  );
};

export default RenderExamIns;
