import { Grid, TextField, useTheme } from "@material-ui/core";
import DropDown from "components/DropDown";
import config from "customConfig";
import * as React from "react";
import GenreSelector from "../components/GenreSelector";
import EditPassages from "./EditPassages";

interface ReadingTestFormProps {
  readingTest: any;
  updateReadingTest: (readingTest: any) => void;
  isEdit?: boolean;
}

const ReadingTestForm: React.FC<ReadingTestFormProps> = ({
  readingTest,
  updateReadingTest,
  isEdit,
}) => {
  const theme = useTheme();
  const { book, test, genre } = readingTest;
  const onBookChange = (book: string) => {
    updateReadingTest((draft: any) => {
      draft.book = book;
    });
  };
  const onTestChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateReadingTest((draft: any) => {
      draft.test = e.target.value;
    });
  };
  const onGenreChange = (genre: string) => {
    updateReadingTest((draft: any) => {
      draft.genre = genre;
    });
  };
  return (
    <Grid container direction="column">
      <Grid item container justify="space-between">
        <Grid item>
          <DropDown
            name="Book"
            options={config.books}
            value={book}
            setValue={onBookChange}
            isEdit={isEdit}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Test"
            variant="outlined"
            autoComplete="off"
            value={test}
            onChange={onTestChange}
            disabled={isEdit}
            style={{ margin: theme.spacing(1) }}
          />
        </Grid>
        <Grid item>
          <GenreSelector
            genre={genre}
            setGenre={onGenreChange}
            isEdit={isEdit}
          />
        </Grid>
      </Grid>
      <Grid item>
        <EditPassages
          readingTest={readingTest}
          updateReadingTest={updateReadingTest}
        />
      </Grid>
    </Grid>
  );
};

ReadingTestForm.defaultProps = {
  isEdit: false,
};

export default ReadingTestForm;
