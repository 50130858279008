import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  TextField,
  DialogTitle,
} from "@material-ui/core";
import MUITimePicker from "components/MUITimePicker";
import UserList from "components/UserList";
import { useSnackbar } from "notistack";
import * as React from "react";
import { createEvent } from "utilities/awsCRUD";
import { isObjFieldFull } from "utilities/validation";

interface CreateEventDialogProps {
  open: boolean;
  handleClose: () => void;
  data: any;
  addEventToList: (event: any) => void;
}

const CreateEventDialog: React.FC<CreateEventDialogProps> = ({
  open,
  handleClose,
  data,
  addEventToList,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [event, setEvent] = React.useState<any>({
    title: "",
    student: "",
    start: data.start,
    end: data.start.getDate() === data.end.getDate() ? data.end : data.start,
  });
  const handleUserChange = (user: string) => {
    setEvent({ ...event, student: user });
  };
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEvent({ ...event, title: e.target.value });
  };
  const handleSubmit = async () => {
    if (isObjFieldFull(event)) {
      const newEvent = await createEvent(event);
      addEventToList(newEvent);
      handleClose();
      enqueueSnackbar("You are successfully", { variant: "success" });
    } else {
      enqueueSnackbar("You are missing field", { variant: "error" });
    }
  };
  const handleStartChange = (date: Date | null) => {
    setEvent({ ...event, start: date });
  };
  const handleEndChange = (date: Date | null) => {
    setEvent({ ...event, end: date });
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create lesson</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid item>
            <UserList
              handleUserChange={handleUserChange}
              value={event.student}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Title"
              value={event.title}
              onChange={handleTitleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            Date: {`${event.start.getMonth() + 1}/${event.start.getDate()}`}
          </Grid>
          <Grid item>
            <MUITimePicker
              label="start"
              onChange={handleStartChange}
              value={event.start}
            />
          </Grid>
          <Grid item>
            <MUITimePicker
              label="end"
              onChange={handleEndChange}
              value={event.end}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="secondary">
          submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEventDialog;
