import * as React from "react";
import BottomBanner from "../BottomBanner";
import TopBanner from "../TopBanner";
import WritingContent from "./WritingContent";

interface RenderWritingProps {
  practice: any[];
  time?: string;
  onSubmit?: (data: any) => Promise<void>;
  onClose?: () => void;
  submittedAnswers?: any[];
  title: string;
}

const RenderWriting: React.FC<RenderWritingProps> = ({
  practice,
  time,
  onSubmit,
  onClose,
  submittedAnswers,
  title,
}) => {
  const isSingle = practice.length === 1;
  const [index, setIndex] = React.useState<number>(0);
  const [answers, setAnswers] = React.useState<any>(
    isSingle ? "" : { task1: "", task2: "" }
  );
  const [submitted, setSubmitted] = React.useState<any>(submittedAnswers);
  const handleSubmit = async () => {
    setSubmitted(isSingle ? [answers] : [answers.task1, answers.task2]);
    if (onSubmit) {
      await onSubmit({
        text: isSingle ? [answers] : [answers.task1, answers.task2],
      });
    }
  };
  const nextPage = () => {
    setIndex(index + 1);
  };
  const prevPage = () => {
    setIndex(index - 1);
  };
  const isExam = title.includes("Exam");
  const lastIndex = practice.length - 1;

  return (
    <div
      style={
        isExam
          ? null
          : {
              zIndex: 1200,
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(250,250,250,1)",
            }
      }
    >
      <TopBanner
        onClose={onClose}
        onSubmit={handleSubmit}
        time={time}
        errors={submitted}
        title={title}
      />
      <WritingContent
        practice={practice[index]}
        setAnswers={setAnswers}
        answers={answers}
        submittedAnswer={submitted ? submitted[index] : null}
      />
      <BottomBanner
        onSubmit={handleSubmit}
        title={title}
        answers={answers}
        nextPage={nextPage}
        prevPage={prevPage}
        index={index}
        lastIndex={lastIndex}
        typeName="writing"
        submitted={!!submitted}
      />
    </div>
  );
};

export default RenderWriting;
