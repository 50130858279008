import React from "react";
import Routes from "routes";
import NavBar from "components/Navbar";
import { useAuthValue } from "context/auth.context";
import Login from "auth/Login";
import Spinner from "components/Spinner";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function App() {
  const { user } = useAuthValue();
  return (
    <QueryClientProvider client={queryClient}>
      {user === null && <Spinner />}
      {user === undefined && <Login />}
      {user && (
        <>
          <NavBar />
          <Routes />
        </>
      )}
    </QueryClientProvider>
  );
}

export default App;
