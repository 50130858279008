import * as React from "react";
import {
  Link,
  Avatar,
  Button,
  TextField,
  Grid,
  Typography,
  Container,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useAuthValue } from "context/auth.context";
import { useSnackbar } from "notistack";
import Spinner from "components/Spinner";

export interface LoginFormProps {
  setInitialUser: any;
  setFormType: any;
  classes: any;
}

const LoginForm: React.FC<LoginFormProps> = ({
  setInitialUser,
  setFormType,
  classes,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { getUser } = useAuthValue();
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit, errors } = useForm({ mode: "onBlur" });
  const onSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setLoading(true);
    try {
      const user = await Auth.signIn(email, password);
      if (
        user.challengeName &&
        user.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        setInitialUser(user);
        setFormType("changePassword");
      } else {
        getUser && getUser();
      }
    } catch (error) {
      if (error.code === "NotAuthorizedException") {
        enqueueSnackbar(
          "the email or password you entered was incorrect, please enter again",
          {
            variant: "error",
          }
        );
      } else {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    }
    setLoading(false);
  };
  return (
    <Container component="main" maxWidth="xs">
      {loading && <Spinner />}
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            error={!!errors.email}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            inputRef={register({
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: "please enter your email",
              },
            })}
            helperText={errors?.email?.message}
          />

          <TextField
            error={!!errors.password}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            inputRef={register({
              minLength: {
                value: 6,
                message: "password has to be at least 6 characters",
              },
            })}
            helperText={errors?.password?.message}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                onClick={() => setFormType("forgotPassword")}
                variant="body2"
                style={{ cursor: "pointer" }}
              >
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default LoginForm;
