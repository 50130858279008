import { Button } from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import CKeditor from "components/CKeditor";
import * as React from "react";
import { QuestionProps } from ".";
import { blankMatchRange } from "utilities/validation";

const validateFGO = (data: string, range: string, options: string[]) => {
  return blankMatchRange(data, range) && options.length > 2;
};

const AddFGO: React.FC<QuestionProps> = ({ question, onSubmit, range }) => {
  const [ins, setIns] = React.useState<any>(question?.ins ?? "");
  const [options, setOptions] = React.useState<any>(question?.options ?? []);
  const handleEditorChange = (_e: any, editor: any) => {
    setIns(editor.getData());
  };
  const handleChipChange = (chips: string[]) => {
    setOptions(chips);
  };
  const handlePreview = () => {
    onSubmit({ ins, options });
  };
  const showButton = validateFGO(ins, range, options);
  return (
    <>
      <CKeditor data={ins} handleOnChange={handleEditorChange} />
      <ChipInput
        defaultValue={options}
        onChange={(chips: string[]) => handleChipChange(chips)}
      />
      <Button
        variant="outlined"
        onClick={handlePreview}
        color="primary"
        disabled={!showButton}
      >
        {question ? "Edit" : "Add"} Answers
      </Button>
    </>
  );
};

export default AddFGO;
