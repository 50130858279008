import * as React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ProtectedRoute from "routes/utilities/ProtectedRoute";
import SpeakingDetailView from "./SpeakingDetailView";
import SpeakingCreationView from "./SpeakingCreationView";
import SpeakingPreviewView from "./SpeakingPreviewView";

export interface SpeakingProps {}

const Speaking: React.FC<SpeakingProps> = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <SpeakingDetailView />
      </Route>
      <ProtectedRoute exact path={`${path}/preview`}>
        <SpeakingPreviewView />
      </ProtectedRoute>
      <ProtectedRoute exact path={`${path}/create`}>
        <SpeakingCreationView />
      </ProtectedRoute>
      <ProtectedRoute exact path={`${path}/edit`}>
        <SpeakingCreationView />
      </ProtectedRoute>
    </Switch>
  );
};

export default Speaking;
