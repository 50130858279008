import * as React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Avatar,
  // Button,
} from "@material-ui/core";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import QuestionDialog from "./QuestionDialog";
import { useHistory } from "react-router-dom";

// import { validateQuestionList } from "utilities/validation";
// import { getAnswersFromArray } from "utilities";

export interface QuestionListProps {
  questions: any[];
  setQuestions: (question: any) => void;
  sectionID: string;
  listening?: boolean;
  passage?: string;
}

// const countQuestions = (listening: boolean, sectionID: string) => {
//   if (!sectionID) return;
//   if (listening) return 10;
//   const sectionNumber = sectionID.split("-").splice(-1)[0];
//   if (["s3", "p1", "p2"].includes(sectionNumber)) return 13;
//   if (sectionNumber === "p3") return 14;
//   return 6;
// };

const QuestionList: React.FC<QuestionListProps> = ({
  questions,
  setQuestions,
  sectionID,
  listening,
  passage,
}) => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = React.useState<
    number | null
  >(null);
  const [selectedQuestion, setSelectedQuestion] = React.useState<any>(null);

  const addQuestion = (data: any) => {
    setQuestions([...questions, data]);
  };
  const removeQuestion = (question: any) => {
    setQuestions([...questions.filter((q) => q.range !== question.range)]);
  };
  const updateQuestion = (data: any) => {
    questions[selectedQuestionIndex] = data;
    setQuestions([...questions]);
  };
  const handleSave = (data: any) => {
    if (selectedQuestion) {
      updateQuestion(data);
    } else {
      addQuestion(data);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedQuestionIndex(null);
    setSelectedQuestion(null);
  };
  const editQuestion = (question: any, index: number) => {
    setSelectedQuestionIndex(index);
    setSelectedQuestion(question);
    setOpen(true);
  };

  const previewQuestion = (question: any) => {
    const practiceData = { ...question };
    let path;
    if (listening) {
      practiceData["typeName"] = "ListeningQuestion";
      practiceData["sectionID"] = sectionID;
      path = "/practice/listening/preview";
    } else {
      practiceData["typeName"] = "ReadingQuestion";
      practiceData["passage"] = passage;
      path = "/practice/reading/preview";
    }
    history.push({
      pathname: path,
      state: { practiceData },
    });
  };
  // const handleSectionPreview = () => {
  //   let path;
  //   let practiceData: any = { questions };
  //   if (listening) {
  //     path = "/practice/listeningSection";
  //     practiceData["id"] = sectionID;
  //   } else {
  //     path = "/practice/readingPassage";
  //     practiceData["id"] = sectionID;
  //     practiceData["passage"] = passage;
  //   }
  //   practiceData["answers"] = getAnswersFromArray(questions);
  //   history.push({ pathname: path, state: { practiceData } });
  // };
  // const count = countQuestions(listening, sectionID);
  // const showPreview = validateQuestionList(count, questions);
  return (
    <List>
      {questions.length > 0 &&
        questions.map((question: any, index: number) => (
          <ListItem key={question.range}>
            <ListItemAvatar>
              <Avatar>
                <QuestionAnswerIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={question.range} secondary={question.type} />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => removeQuestion(question)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => editQuestion(question, index)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="preview"
                onClick={() => previewQuestion(question)}
              >
                <VisibilityIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      <ListItem>
        {/* {showPreview ? (
          <Button
            onClick={handleSectionPreview}
            variant="outlined"
            color="primary"
          >
            preview {listening ? "section" : "passage"}
          </Button>
        ) : ( */}
        <IconButton aria-label="add question" onClick={() => setOpen(true)}>
          <AddIcon />
        </IconButton>
        {/* )} */}
      </ListItem>

      {open && (
        <QuestionDialog
          handleSave={handleSave}
          open={open}
          handleClose={handleClose}
          question={selectedQuestion}
          listening={listening}
        />
      )}
    </List>
  );
};
QuestionList.defaultProps = {
  listening: true,
};
export default QuestionList;
