import * as React from "react";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import CardLayout from "layout/CardLayout";
import useLocalImmer from "hooks/useLocalImmer";
import NoteForm from "components/Assignment/NoteForm";
import { isObjFieldFull } from "utilities/validation";
import { useSnackbar } from "notistack";
import useAmplifyMutation from "hooks/useAmplifyMutation";
import * as mutations from "graphql/mutations";

export interface NoteEditionViewProps {}

const cleanUpdateNoteData = (noteData: any) => {
  if (!noteData) return null;
  const cleaned = { ...noteData };
  delete cleaned.createdAt;
  delete cleaned.updatedAt;
  delete cleaned.tableData;
  return cleaned;
};

const NoteEditionView: React.FC<NoteEditionViewProps> = () => {
  const { state } = useLocation() as any;
  const noteData = state?.noteData;
  const history = useHistory();
  const { status, execute } = useAmplifyMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [note, updateNote] = useLocalImmer("noteEditData", noteData);
  if (!noteData) return <Redirect to="/note" />;

  const onPreview = () => {
    if (isObjFieldFull(noteData)) {
      history.push({ pathname: "preview", state: { noteData } });
    } else {
      enqueueSnackbar("you are missing fields", { variant: "warning" });
    }
  };
  const afterMutation = () => {
    window.localStorage.removeItem("noteEditData");
    history.push("/note");
  };
  const onCancel = () => {
    window.localStorage.removeItem("noteEditData");
    history.goBack();
  };

  const onDelete = async () => {
    try {
      await execute({
        query: mutations.deleteNote,
        variables: { input: { id: note.id } },
      });
      enqueueSnackbar(`Successfully deleted ${noteData.title}`, {
        variant: "success",
      });
      afterMutation();
    } catch (error) {
      console.log("Note delete error", error);
      enqueueSnackbar("Connection error, please try agian later", {
        variant: "error",
      });
    }
  };
  const onUpdate = async () => {
    if (isObjFieldFull(noteData)) {
      const input = cleanUpdateNoteData(noteData);
      try {
        await execute({ query: mutations.updateNote, variables: { input } });
        enqueueSnackbar(`successfully updated ${note.title}`, {
          variant: "success",
        });
        afterMutation();
      } catch (error) {
        console.log("Note update error", error);
        enqueueSnackbar("Connection error, please try agian later", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("There are fields missing", { variant: "warning" });
    }
  };
  return (
    <CardLayout
      title="Note Edition"
      mode="edit"
      loading={status === "pending"}
      onPreview={onPreview}
      onUpdate={onUpdate}
      onDelete={onDelete}
      onCancel={onCancel}
    >
      <NoteForm noteData={note} updateNoteData={updateNote} />
    </CardLayout>
  );
};

export default NoteEditionView;
