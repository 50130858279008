/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      title
      typeName
      student
      description
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      title
      typeName
      student
      description
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      title
      typeName
      student
      description
      content
      createdAt
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      title
      typeName
      student
      start
      end
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      title
      typeName
      student
      start
      end
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      title
      typeName
      student
      start
      end
      createdAt
      updatedAt
    }
  }
`;
export const createExamModel = /* GraphQL */ `
  mutation CreateExamModel(
    $input: CreateExamModelInput!
    $condition: ModelExamModelConditionInput
  ) {
    createExamModel(input: $input, condition: $condition) {
      id
      name
      typeName
      instructions
      listening {
        id
        typeName
        time
      }
      reading {
        id
        typeName
        time
      }
      writing {
        task1ID
        task2ID
        time
      }
      speaking {
        part1
        part2 {
          id
          prompt
        }
        time
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateExamModel = /* GraphQL */ `
  mutation UpdateExamModel(
    $input: UpdateExamModelInput!
    $condition: ModelExamModelConditionInput
  ) {
    updateExamModel(input: $input, condition: $condition) {
      id
      name
      typeName
      instructions
      listening {
        id
        typeName
        time
      }
      reading {
        id
        typeName
        time
      }
      writing {
        task1ID
        task2ID
        time
      }
      speaking {
        part1
        part2 {
          id
          prompt
        }
        time
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteExamModel = /* GraphQL */ `
  mutation DeleteExamModel(
    $input: DeleteExamModelInput!
    $condition: ModelExamModelConditionInput
  ) {
    deleteExamModel(input: $input, condition: $condition) {
      id
      name
      typeName
      instructions
      listening {
        id
        typeName
        time
      }
      reading {
        id
        typeName
        time
      }
      writing {
        task1ID
        task2ID
        time
      }
      speaking {
        part1
        part2 {
          id
          prompt
        }
        time
      }
      createdAt
      updatedAt
    }
  }
`;
export const createExam = /* GraphQL */ `
  mutation CreateExam(
    $input: CreateExamInput!
    $condition: ModelExamConditionInput
  ) {
    createExam(input: $input, condition: $condition) {
      id
      name
      examModelID
      student
      dueDate
      submittedDate
      submittedAnswers
      result
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateExam = /* GraphQL */ `
  mutation UpdateExam(
    $input: UpdateExamInput!
    $condition: ModelExamConditionInput
  ) {
    updateExam(input: $input, condition: $condition) {
      id
      name
      examModelID
      student
      dueDate
      submittedDate
      submittedAnswers
      result
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteExam = /* GraphQL */ `
  mutation DeleteExam(
    $input: DeleteExamInput!
    $condition: ModelExamConditionInput
  ) {
    deleteExam(input: $input, condition: $condition) {
      id
      name
      examModelID
      student
      dueDate
      submittedDate
      submittedAnswers
      result
      status
      createdAt
      updatedAt
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      title
      description
      student
      practiceList {
        id
        typeName
        name
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      id
      title
      description
      student
      practiceList {
        id
        typeName
        name
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      title
      description
      student
      practiceList {
        id
        typeName
        name
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const createHomework = /* GraphQL */ `
  mutation CreateHomework(
    $input: CreateHomeworkInput!
    $condition: ModelHomeworkConditionInput
  ) {
    createHomework(input: $input, condition: $condition) {
      id
      title
      description
      student
      dueDate
      practiceList {
        id
        typeName
        type
        name
        timeLimit
        startTime
        endTime
        submittedAnswers
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateHomework = /* GraphQL */ `
  mutation UpdateHomework(
    $input: UpdateHomeworkInput!
    $condition: ModelHomeworkConditionInput
  ) {
    updateHomework(input: $input, condition: $condition) {
      id
      title
      description
      student
      dueDate
      practiceList {
        id
        typeName
        type
        name
        timeLimit
        startTime
        endTime
        submittedAnswers
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteHomework = /* GraphQL */ `
  mutation DeleteHomework(
    $input: DeleteHomeworkInput!
    $condition: ModelHomeworkConditionInput
  ) {
    deleteHomework(input: $input, condition: $condition) {
      id
      title
      description
      student
      dueDate
      practiceList {
        id
        typeName
        type
        name
        timeLimit
        startTime
        endTime
        submittedAnswers
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const createSpeakingList = /* GraphQL */ `
  mutation CreateSpeakingList(
    $input: CreateSpeakingListInput!
    $condition: ModelSpeakingListConditionInput
  ) {
    createSpeakingList(input: $input, condition: $condition) {
      id
      typeName
      name
      type
      questions {
        id
        typeName
        question
        partOneTopicID
        partTwoID
        type
        season
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSpeakingList = /* GraphQL */ `
  mutation UpdateSpeakingList(
    $input: UpdateSpeakingListInput!
    $condition: ModelSpeakingListConditionInput
  ) {
    updateSpeakingList(input: $input, condition: $condition) {
      id
      typeName
      name
      type
      questions {
        id
        typeName
        question
        partOneTopicID
        partTwoID
        type
        season
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpeakingList = /* GraphQL */ `
  mutation DeleteSpeakingList(
    $input: DeleteSpeakingListInput!
    $condition: ModelSpeakingListConditionInput
  ) {
    deleteSpeakingList(input: $input, condition: $condition) {
      id
      typeName
      name
      type
      questions {
        id
        typeName
        question
        partOneTopicID
        partTwoID
        type
        season
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBook = /* GraphQL */ `
  mutation CreateBook(
    $input: CreateBookInput!
    $condition: ModelBookConditionInput
  ) {
    createBook(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateBook = /* GraphQL */ `
  mutation UpdateBook(
    $input: UpdateBookInput!
    $condition: ModelBookConditionInput
  ) {
    updateBook(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteBook = /* GraphQL */ `
  mutation DeleteBook(
    $input: DeleteBookInput!
    $condition: ModelBookConditionInput
  ) {
    deleteBook(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const createListeningTest = /* GraphQL */ `
  mutation CreateListeningTest(
    $input: CreateListeningTestInput!
    $condition: ModelListeningTestConditionInput
  ) {
    createListeningTest(input: $input, condition: $condition) {
      id
      typeName
      book
      test
      s1ID
      s1 {
        id
        typeName
        book
        sectionNumber
        types
        questions {
          items {
            id
            typeName
            name
            book
            sectionNumber
            sectionID
            type
            start
            end
            range
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s2ID
      s2 {
        id
        typeName
        book
        sectionNumber
        types
        questions {
          items {
            id
            typeName
            name
            book
            sectionNumber
            sectionID
            type
            start
            end
            range
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s3ID
      s3 {
        id
        typeName
        book
        sectionNumber
        types
        questions {
          items {
            id
            typeName
            name
            book
            sectionNumber
            sectionID
            type
            start
            end
            range
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s4ID
      s4 {
        id
        typeName
        book
        sectionNumber
        types
        questions {
          items {
            id
            typeName
            name
            book
            sectionNumber
            sectionID
            type
            start
            end
            range
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateListeningTest = /* GraphQL */ `
  mutation UpdateListeningTest(
    $input: UpdateListeningTestInput!
    $condition: ModelListeningTestConditionInput
  ) {
    updateListeningTest(input: $input, condition: $condition) {
      id
      typeName
      book
      test
      s1ID
      s1 {
        id
        typeName
        book
        sectionNumber
        types
        questions {
          items {
            id
            typeName
            name
            book
            sectionNumber
            sectionID
            type
            start
            end
            range
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s2ID
      s2 {
        id
        typeName
        book
        sectionNumber
        types
        questions {
          items {
            id
            typeName
            name
            book
            sectionNumber
            sectionID
            type
            start
            end
            range
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s3ID
      s3 {
        id
        typeName
        book
        sectionNumber
        types
        questions {
          items {
            id
            typeName
            name
            book
            sectionNumber
            sectionID
            type
            start
            end
            range
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s4ID
      s4 {
        id
        typeName
        book
        sectionNumber
        types
        questions {
          items {
            id
            typeName
            name
            book
            sectionNumber
            sectionID
            type
            start
            end
            range
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteListeningTest = /* GraphQL */ `
  mutation DeleteListeningTest(
    $input: DeleteListeningTestInput!
    $condition: ModelListeningTestConditionInput
  ) {
    deleteListeningTest(input: $input, condition: $condition) {
      id
      typeName
      book
      test
      s1ID
      s1 {
        id
        typeName
        book
        sectionNumber
        types
        questions {
          items {
            id
            typeName
            name
            book
            sectionNumber
            sectionID
            type
            start
            end
            range
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s2ID
      s2 {
        id
        typeName
        book
        sectionNumber
        types
        questions {
          items {
            id
            typeName
            name
            book
            sectionNumber
            sectionID
            type
            start
            end
            range
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s3ID
      s3 {
        id
        typeName
        book
        sectionNumber
        types
        questions {
          items {
            id
            typeName
            name
            book
            sectionNumber
            sectionID
            type
            start
            end
            range
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s4ID
      s4 {
        id
        typeName
        book
        sectionNumber
        types
        questions {
          items {
            id
            typeName
            name
            book
            sectionNumber
            sectionID
            type
            start
            end
            range
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createListeningSection = /* GraphQL */ `
  mutation CreateListeningSection(
    $input: CreateListeningSectionInput!
    $condition: ModelListeningSectionConditionInput
  ) {
    createListeningSection(input: $input, condition: $condition) {
      id
      typeName
      book
      sectionNumber
      types
      questions {
        items {
          id
          typeName
          name
          book
          sectionNumber
          sectionID
          type
          start
          end
          range
          questionBody
          answers
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateListeningSection = /* GraphQL */ `
  mutation UpdateListeningSection(
    $input: UpdateListeningSectionInput!
    $condition: ModelListeningSectionConditionInput
  ) {
    updateListeningSection(input: $input, condition: $condition) {
      id
      typeName
      book
      sectionNumber
      types
      questions {
        items {
          id
          typeName
          name
          book
          sectionNumber
          sectionID
          type
          start
          end
          range
          questionBody
          answers
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteListeningSection = /* GraphQL */ `
  mutation DeleteListeningSection(
    $input: DeleteListeningSectionInput!
    $condition: ModelListeningSectionConditionInput
  ) {
    deleteListeningSection(input: $input, condition: $condition) {
      id
      typeName
      book
      sectionNumber
      types
      questions {
        items {
          id
          typeName
          name
          book
          sectionNumber
          sectionID
          type
          start
          end
          range
          questionBody
          answers
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createListeningQuestion = /* GraphQL */ `
  mutation CreateListeningQuestion(
    $input: CreateListeningQuestionInput!
    $condition: ModelListeningQuestionConditionInput
  ) {
    createListeningQuestion(input: $input, condition: $condition) {
      id
      typeName
      name
      book
      sectionNumber
      sectionID
      type
      start
      end
      range
      questionBody
      answers
      createdAt
      updatedAt
    }
  }
`;
export const updateListeningQuestion = /* GraphQL */ `
  mutation UpdateListeningQuestion(
    $input: UpdateListeningQuestionInput!
    $condition: ModelListeningQuestionConditionInput
  ) {
    updateListeningQuestion(input: $input, condition: $condition) {
      id
      typeName
      name
      book
      sectionNumber
      sectionID
      type
      start
      end
      range
      questionBody
      answers
      createdAt
      updatedAt
    }
  }
`;
export const deleteListeningQuestion = /* GraphQL */ `
  mutation DeleteListeningQuestion(
    $input: DeleteListeningQuestionInput!
    $condition: ModelListeningQuestionConditionInput
  ) {
    deleteListeningQuestion(input: $input, condition: $condition) {
      id
      typeName
      name
      book
      sectionNumber
      sectionID
      type
      start
      end
      range
      questionBody
      answers
      createdAt
      updatedAt
    }
  }
`;
export const createReadingTestA = /* GraphQL */ `
  mutation CreateReadingTestA(
    $input: CreateReadingTestAInput!
    $condition: ModelReadingTestAConditionInput
  ) {
    createReadingTestA(input: $input, condition: $condition) {
      id
      typeName
      book
      test
      genre
      p1ID
      p1 {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      p2ID
      p2 {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      p3ID
      p3 {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateReadingTestA = /* GraphQL */ `
  mutation UpdateReadingTestA(
    $input: UpdateReadingTestAInput!
    $condition: ModelReadingTestAConditionInput
  ) {
    updateReadingTestA(input: $input, condition: $condition) {
      id
      typeName
      book
      test
      genre
      p1ID
      p1 {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      p2ID
      p2 {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      p3ID
      p3 {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteReadingTestA = /* GraphQL */ `
  mutation DeleteReadingTestA(
    $input: DeleteReadingTestAInput!
    $condition: ModelReadingTestAConditionInput
  ) {
    deleteReadingTestA(input: $input, condition: $condition) {
      id
      typeName
      book
      test
      genre
      p1ID
      p1 {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      p2ID
      p2 {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      p3ID
      p3 {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createReadingTestG = /* GraphQL */ `
  mutation CreateReadingTestG(
    $input: CreateReadingTestGInput!
    $condition: ModelReadingTestGConditionInput
  ) {
    createReadingTestG(input: $input, condition: $condition) {
      id
      typeName
      genre
      book
      test
      s1aID
      s1a {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s1bID
      s1b {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s2aID
      s2a {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s2bID
      s2b {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s3ID
      s3 {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateReadingTestG = /* GraphQL */ `
  mutation UpdateReadingTestG(
    $input: UpdateReadingTestGInput!
    $condition: ModelReadingTestGConditionInput
  ) {
    updateReadingTestG(input: $input, condition: $condition) {
      id
      typeName
      genre
      book
      test
      s1aID
      s1a {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s1bID
      s1b {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s2aID
      s2a {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s2bID
      s2b {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s3ID
      s3 {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteReadingTestG = /* GraphQL */ `
  mutation DeleteReadingTestG(
    $input: DeleteReadingTestGInput!
    $condition: ModelReadingTestGConditionInput
  ) {
    deleteReadingTestG(input: $input, condition: $condition) {
      id
      typeName
      genre
      book
      test
      s1aID
      s1a {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s1bID
      s1b {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s2aID
      s2a {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s2bID
      s2b {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      s3ID
      s3 {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createReadingPassage = /* GraphQL */ `
  mutation CreateReadingPassage(
    $input: CreateReadingPassageInput!
    $condition: ModelReadingPassageConditionInput
  ) {
    createReadingPassage(input: $input, condition: $condition) {
      id
      typeName
      book
      testID
      genre
      types
      passageNumber
      passage
      questions {
        items {
          id
          typeName
          name
          book
          genre
          type
          range
          passageNumber
          passageID
          passage {
            id
            typeName
            book
            testID
            genre
            types
            passageNumber
            passage
            questions {
              nextToken
            }
            createdAt
            updatedAt
          }
          questionBody
          answers
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateReadingPassage = /* GraphQL */ `
  mutation UpdateReadingPassage(
    $input: UpdateReadingPassageInput!
    $condition: ModelReadingPassageConditionInput
  ) {
    updateReadingPassage(input: $input, condition: $condition) {
      id
      typeName
      book
      testID
      genre
      types
      passageNumber
      passage
      questions {
        items {
          id
          typeName
          name
          book
          genre
          type
          range
          passageNumber
          passageID
          passage {
            id
            typeName
            book
            testID
            genre
            types
            passageNumber
            passage
            questions {
              nextToken
            }
            createdAt
            updatedAt
          }
          questionBody
          answers
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteReadingPassage = /* GraphQL */ `
  mutation DeleteReadingPassage(
    $input: DeleteReadingPassageInput!
    $condition: ModelReadingPassageConditionInput
  ) {
    deleteReadingPassage(input: $input, condition: $condition) {
      id
      typeName
      book
      testID
      genre
      types
      passageNumber
      passage
      questions {
        items {
          id
          typeName
          name
          book
          genre
          type
          range
          passageNumber
          passageID
          passage {
            id
            typeName
            book
            testID
            genre
            types
            passageNumber
            passage
            questions {
              nextToken
            }
            createdAt
            updatedAt
          }
          questionBody
          answers
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createReadingQuestion = /* GraphQL */ `
  mutation CreateReadingQuestion(
    $input: CreateReadingQuestionInput!
    $condition: ModelReadingQuestionConditionInput
  ) {
    createReadingQuestion(input: $input, condition: $condition) {
      id
      typeName
      name
      book
      genre
      type
      range
      passageNumber
      passageID
      passage {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      questionBody
      answers
      createdAt
      updatedAt
    }
  }
`;
export const updateReadingQuestion = /* GraphQL */ `
  mutation UpdateReadingQuestion(
    $input: UpdateReadingQuestionInput!
    $condition: ModelReadingQuestionConditionInput
  ) {
    updateReadingQuestion(input: $input, condition: $condition) {
      id
      typeName
      name
      book
      genre
      type
      range
      passageNumber
      passageID
      passage {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      questionBody
      answers
      createdAt
      updatedAt
    }
  }
`;
export const deleteReadingQuestion = /* GraphQL */ `
  mutation DeleteReadingQuestion(
    $input: DeleteReadingQuestionInput!
    $condition: ModelReadingQuestionConditionInput
  ) {
    deleteReadingQuestion(input: $input, condition: $condition) {
      id
      typeName
      name
      book
      genre
      type
      range
      passageNumber
      passageID
      passage {
        id
        typeName
        book
        testID
        genre
        types
        passageNumber
        passage
        questions {
          items {
            id
            typeName
            name
            book
            genre
            type
            range
            passageNumber
            passageID
            passage {
              id
              typeName
              book
              testID
              genre
              types
              passageNumber
              passage
              createdAt
              updatedAt
            }
            questionBody
            answers
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      questionBody
      answers
      createdAt
      updatedAt
    }
  }
`;
export const createEssay = /* GraphQL */ `
  mutation CreateEssay(
    $input: CreateEssayInput!
    $condition: ModelEssayConditionInput
  ) {
    createEssay(input: $input, condition: $condition) {
      id
      typeName
      book
      genre
      type
      topic
      prompt
      sampleAnswer
      createdAt
      updatedAt
    }
  }
`;
export const updateEssay = /* GraphQL */ `
  mutation UpdateEssay(
    $input: UpdateEssayInput!
    $condition: ModelEssayConditionInput
  ) {
    updateEssay(input: $input, condition: $condition) {
      id
      typeName
      book
      genre
      type
      topic
      prompt
      sampleAnswer
      createdAt
      updatedAt
    }
  }
`;
export const deleteEssay = /* GraphQL */ `
  mutation DeleteEssay(
    $input: DeleteEssayInput!
    $condition: ModelEssayConditionInput
  ) {
    deleteEssay(input: $input, condition: $condition) {
      id
      typeName
      book
      genre
      type
      topic
      prompt
      sampleAnswer
      createdAt
      updatedAt
    }
  }
`;
export const createGraph = /* GraphQL */ `
  mutation CreateGraph(
    $input: CreateGraphInput!
    $condition: ModelGraphConditionInput
  ) {
    createGraph(input: $input, condition: $condition) {
      id
      typeName
      book
      type
      prompt
      img
      sampleAnswer
      createdAt
      updatedAt
    }
  }
`;
export const updateGraph = /* GraphQL */ `
  mutation UpdateGraph(
    $input: UpdateGraphInput!
    $condition: ModelGraphConditionInput
  ) {
    updateGraph(input: $input, condition: $condition) {
      id
      typeName
      book
      type
      prompt
      img
      sampleAnswer
      createdAt
      updatedAt
    }
  }
`;
export const deleteGraph = /* GraphQL */ `
  mutation DeleteGraph(
    $input: DeleteGraphInput!
    $condition: ModelGraphConditionInput
  ) {
    deleteGraph(input: $input, condition: $condition) {
      id
      typeName
      book
      type
      prompt
      img
      sampleAnswer
      createdAt
      updatedAt
    }
  }
`;
export const createLetter = /* GraphQL */ `
  mutation CreateLetter(
    $input: CreateLetterInput!
    $condition: ModelLetterConditionInput
  ) {
    createLetter(input: $input, condition: $condition) {
      id
      typeName
      book
      type
      aim
      prompt
      sampleAnswer
      createdAt
      updatedAt
    }
  }
`;
export const updateLetter = /* GraphQL */ `
  mutation UpdateLetter(
    $input: UpdateLetterInput!
    $condition: ModelLetterConditionInput
  ) {
    updateLetter(input: $input, condition: $condition) {
      id
      typeName
      book
      type
      aim
      prompt
      sampleAnswer
      createdAt
      updatedAt
    }
  }
`;
export const deleteLetter = /* GraphQL */ `
  mutation DeleteLetter(
    $input: DeleteLetterInput!
    $condition: ModelLetterConditionInput
  ) {
    deleteLetter(input: $input, condition: $condition) {
      id
      typeName
      book
      type
      aim
      prompt
      sampleAnswer
      createdAt
      updatedAt
    }
  }
`;
export const createPartOneTopic = /* GraphQL */ `
  mutation CreatePartOneTopic(
    $input: CreatePartOneTopicInput!
    $condition: ModelPartOneTopicConditionInput
  ) {
    createPartOneTopic(input: $input, condition: $condition) {
      id
      typeName
      season
      questions {
        items {
          id
          typeName
          question
          partOneTopicID
          type
          season
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePartOneTopic = /* GraphQL */ `
  mutation UpdatePartOneTopic(
    $input: UpdatePartOneTopicInput!
    $condition: ModelPartOneTopicConditionInput
  ) {
    updatePartOneTopic(input: $input, condition: $condition) {
      id
      typeName
      season
      questions {
        items {
          id
          typeName
          question
          partOneTopicID
          type
          season
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePartOneTopic = /* GraphQL */ `
  mutation DeletePartOneTopic(
    $input: DeletePartOneTopicInput!
    $condition: ModelPartOneTopicConditionInput
  ) {
    deletePartOneTopic(input: $input, condition: $condition) {
      id
      typeName
      season
      questions {
        items {
          id
          typeName
          question
          partOneTopicID
          type
          season
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPartOne = /* GraphQL */ `
  mutation CreatePartOne(
    $input: CreatePartOneInput!
    $condition: ModelPartOneConditionInput
  ) {
    createPartOne(input: $input, condition: $condition) {
      id
      typeName
      question
      partOneTopicID
      type
      season
      createdAt
      updatedAt
    }
  }
`;
export const updatePartOne = /* GraphQL */ `
  mutation UpdatePartOne(
    $input: UpdatePartOneInput!
    $condition: ModelPartOneConditionInput
  ) {
    updatePartOne(input: $input, condition: $condition) {
      id
      typeName
      question
      partOneTopicID
      type
      season
      createdAt
      updatedAt
    }
  }
`;
export const deletePartOne = /* GraphQL */ `
  mutation DeletePartOne(
    $input: DeletePartOneInput!
    $condition: ModelPartOneConditionInput
  ) {
    deletePartOne(input: $input, condition: $condition) {
      id
      typeName
      question
      partOneTopicID
      type
      season
      createdAt
      updatedAt
    }
  }
`;
export const createPartTwo = /* GraphQL */ `
  mutation CreatePartTwo(
    $input: CreatePartTwoInput!
    $condition: ModelPartTwoConditionInput
  ) {
    createPartTwo(input: $input, condition: $condition) {
      id
      typeName
      prompt
      subQuestions
      season
      type
      partThreeQuestions {
        items {
          id
          typeName
          question
          partTwoID
          type
          season
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePartTwo = /* GraphQL */ `
  mutation UpdatePartTwo(
    $input: UpdatePartTwoInput!
    $condition: ModelPartTwoConditionInput
  ) {
    updatePartTwo(input: $input, condition: $condition) {
      id
      typeName
      prompt
      subQuestions
      season
      type
      partThreeQuestions {
        items {
          id
          typeName
          question
          partTwoID
          type
          season
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePartTwo = /* GraphQL */ `
  mutation DeletePartTwo(
    $input: DeletePartTwoInput!
    $condition: ModelPartTwoConditionInput
  ) {
    deletePartTwo(input: $input, condition: $condition) {
      id
      typeName
      prompt
      subQuestions
      season
      type
      partThreeQuestions {
        items {
          id
          typeName
          question
          partTwoID
          type
          season
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPartThree = /* GraphQL */ `
  mutation CreatePartThree(
    $input: CreatePartThreeInput!
    $condition: ModelPartThreeConditionInput
  ) {
    createPartThree(input: $input, condition: $condition) {
      id
      typeName
      question
      partTwoID
      type
      season
      createdAt
      updatedAt
    }
  }
`;
export const updatePartThree = /* GraphQL */ `
  mutation UpdatePartThree(
    $input: UpdatePartThreeInput!
    $condition: ModelPartThreeConditionInput
  ) {
    updatePartThree(input: $input, condition: $condition) {
      id
      typeName
      question
      partTwoID
      type
      season
      createdAt
      updatedAt
    }
  }
`;
export const deletePartThree = /* GraphQL */ `
  mutation DeletePartThree(
    $input: DeletePartThreeInput!
    $condition: ModelPartThreeConditionInput
  ) {
    deletePartThree(input: $input, condition: $condition) {
      id
      typeName
      question
      partTwoID
      type
      season
      createdAt
      updatedAt
    }
  }
`;
export const createSpeakingRecord = /* GraphQL */ `
  mutation CreateSpeakingRecord(
    $input: CreateSpeakingRecordInput!
    $condition: ModelSpeakingRecordConditionInput
  ) {
    createSpeakingRecord(input: $input, condition: $condition) {
      id
      user
      typeName
      type
      typeID
      url
      createdAt
      updatedAt
    }
  }
`;
export const updateSpeakingRecord = /* GraphQL */ `
  mutation UpdateSpeakingRecord(
    $input: UpdateSpeakingRecordInput!
    $condition: ModelSpeakingRecordConditionInput
  ) {
    updateSpeakingRecord(input: $input, condition: $condition) {
      id
      user
      typeName
      type
      typeID
      url
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpeakingRecord = /* GraphQL */ `
  mutation DeleteSpeakingRecord(
    $input: DeleteSpeakingRecordInput!
    $condition: ModelSpeakingRecordConditionInput
  ) {
    deleteSpeakingRecord(input: $input, condition: $condition) {
      id
      user
      typeName
      type
      typeID
      url
      createdAt
      updatedAt
    }
  }
`;
export const createCustomTest = /* GraphQL */ `
  mutation CreateCustomTest(
    $input: CreateCustomTestInput!
    $condition: ModelCustomTestConditionInput
  ) {
    createCustomTest(input: $input, condition: $condition) {
      id
      typeName
      questions {
        typeName
        type
        range
        questionBody
        answers
      }
      answers
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomTest = /* GraphQL */ `
  mutation UpdateCustomTest(
    $input: UpdateCustomTestInput!
    $condition: ModelCustomTestConditionInput
  ) {
    updateCustomTest(input: $input, condition: $condition) {
      id
      typeName
      questions {
        typeName
        type
        range
        questionBody
        answers
      }
      answers
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomTest = /* GraphQL */ `
  mutation DeleteCustomTest(
    $input: DeleteCustomTestInput!
    $condition: ModelCustomTestConditionInput
  ) {
    deleteCustomTest(input: $input, condition: $condition) {
      id
      typeName
      questions {
        typeName
        type
        range
        questionBody
        answers
      }
      answers
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      email
      groups
      picture
      targetScores
      note
      examDate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      email
      groups
      picture
      targetScores
      note
      examDate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      email
      groups
      picture
      targetScores
      note
      examDate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTracking = /* GraphQL */ `
  mutation CreateTracking(
    $input: CreateTrackingInput!
    $condition: ModelTrackingConditionInput
  ) {
    createTracking(input: $input, condition: $condition) {
      id
      userID
      type
      user {
        id
        username
        email
        groups
        picture
        targetScores
        note
        examDate
        createdAt
        updatedAt
        owner
      }
      action
      createdAt
      updatedAt
    }
  }
`;
export const updateTracking = /* GraphQL */ `
  mutation UpdateTracking(
    $input: UpdateTrackingInput!
    $condition: ModelTrackingConditionInput
  ) {
    updateTracking(input: $input, condition: $condition) {
      id
      userID
      type
      user {
        id
        username
        email
        groups
        picture
        targetScores
        note
        examDate
        createdAt
        updatedAt
        owner
      }
      action
      createdAt
      updatedAt
    }
  }
`;
export const deleteTracking = /* GraphQL */ `
  mutation DeleteTracking(
    $input: DeleteTrackingInput!
    $condition: ModelTrackingConditionInput
  ) {
    deleteTracking(input: $input, condition: $condition) {
      id
      userID
      type
      user {
        id
        username
        email
        groups
        picture
        targetScores
        note
        examDate
        createdAt
        updatedAt
        owner
      }
      action
      createdAt
      updatedAt
    }
  }
`;
