import * as React from "react";
import { RenderQuestionProps } from ".";
import parse from "html-react-parser";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "10px",
      fontSize: "1.25rem",
      "& .MuiFormLabel-root": {
        fontSize: "inherit",
      },
      "& .MuiTypography-body1": {
        fontSize: "inherit",
      },
      "& .MuiFormControl-root": {
        margin: "5px",
        verticalAlign: "bottom",
      },
      [theme.breakpoints.down("xs")]: {
        padding: 0,
        fontSize: "0.75rem",
      },
    },
    questionStem: {
      display: "flex",
      justifyContent: "space-between",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      [theme.breakpoints.down("xs")]: {
        minWidth: 70,
      },
    },
  })
);

const RenderTFNG: React.FC<RenderQuestionProps> = ({
  question,
  setAnswers,
  answers,
  errors,
}) => {
  const classes = useStyles();
  const options =
    question.type === "tf"
      ? ["TRUE", "FALSE", "NOT GIVEN"]
      : ["YES", "NO", "NOT GIVEN"];
  const handleChange = (e: any) => {
    setAnswers({ ...answers, [e.target.name]: e.target.value });
  };

  return (
    <div className={classes.root}>
      <div>{question && parse(question.ins)}</div>
      {question.type === "tf" ? (
        <table>
          <tbody>
            <tr>
              <td>
                <strong>TRUE</strong>
              </td>
              <td> if the statement agrees with the information</td>
            </tr>
            <tr>
              <td>
                <strong>FALSE</strong>
              </td>
              <td> if the statement contradicts the information</td>
            </tr>
            <tr>
              <td>
                <strong>NOT GIVEN</strong>
              </td>
              <td> if there is no information on this</td>
            </tr>
          </tbody>
        </table>
      ) : (
        <table>
          <tbody>
            <tr>
              <td>
                <strong>YES</strong>
              </td>
              <td> if the statement agrees with the views of the writer</td>
            </tr>
            <tr>
              <td>
                <strong>NO</strong>
              </td>
              <td> if the statement contradicts the views of the writer</td>
            </tr>
            <tr>
              <td>
                <strong>NOT GIVEN</strong>
              </td>
              <td>
                {" "}
                if it is impossible to say waht the writer thinks about this
              </td>
            </tr>
          </tbody>
        </table>
      )}

      {question.stems.map((stem: any) => {
        const { order, text } = stem;
        return (
          <div key={order} className={classes.questionStem}>
            <p>
              <b>{order}.</b> {text}
            </p>
            <FormControl
              className={classes.formControl}
              size="small"
              margin="dense"
              variant="outlined"
              error={errors && !errors[order].correct}
              disabled={errors && true}

              // disabled={true}
            >
              <InputLabel id={`question-${order}`}>Q {order}</InputLabel>
              <Select
                labelId={`question-${order}`}
                name={order}
                value={answers[order] || ""}
                variant="outlined"
                onChange={handleChange}
                label={`Q ${order}`}
              >
                {options.map((option: string) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              {errors && !errors[order].correct && (
                <FormHelperText>{errors[order].ans}</FormHelperText>
              )}
            </FormControl>
          </div>
        );
      })}
    </div>
  );
};

export default RenderTFNG;
