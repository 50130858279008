import * as React from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { QuestionProps } from ".";
import { useForm, useFieldArray } from "react-hook-form";
import { rangeToArray } from "utilities";
import CKeditor from "components/CKeditor";

const generateTFNGtemplate = (range: string) => {
  const template: { ins: string; stems: any[]; type: string } = {
    ins: "",
    stems: [],
    type: "tf",
  };
  rangeToArray(range).forEach((order) => {
    template.stems.push({ order, text: "" });
  });
  return template;
};

const AddTFNG: React.FC<QuestionProps> = ({ question, onSubmit, range }) => {
  const defaultValues = question ? question : generateTFNGtemplate(range);
  const { register, setValue, watch, handleSubmit, control } = useForm({
    defaultValues,
  });
  const { fields } = useFieldArray({
    control,
    name: "stems",
  });
  const handleEditorChange = (_e: any, editor: any) => {
    setValue("ins", editor.getData());
  };
  const handleSelectChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setValue("type", e.target.value);
  };
  React.useEffect(() => {
    register("ins", { required: true });
    setValue("ins", question?.ins);

    register("type", { required: true });
  }, [register, setValue, question]);
  const { ins, type } = watch();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CKeditor data={ins} handleOnChange={handleEditorChange} />
      <FormControl variant="outlined">
        <InputLabel id="type-label">Question Type</InputLabel>
        <Select
          labelId="type-label"
          value={type}
          onChange={handleSelectChange}
          label="Question Type"
        >
          <MenuItem value="tf" key="tf">
            True/False
          </MenuItem>
          <MenuItem value="yn" key="yn">
            Yes/No
          </MenuItem>
        </Select>
      </FormControl>
      {fields &&
        fields.map((stem: any, index: number) => (
          <div key={stem.order}>
            <input
              hidden={true}
              name={`stems[${index}].order`}
              defaultValue={stem.order}
              ref={register()}
            />
            <TextField
              name={`stems[${index}].text`}
              label={`Question ${stem.order}`}
              variant="outlined"
              inputRef={register({ required: true })}
              autoComplete="off"
              defaultValue={stem.text}
              fullWidth
            />
          </div>
        ))}
      <Button type="submit"> {question ? "Edit" : "Add"} Answers</Button>
    </form>
  );
};

export default AddTFNG;
