import {
  makeStyles,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Grid,
  Typography,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useAuthValue } from "context/auth.context";
import MainWrapper from "layout/MainWrapper";
import AddCourseDialog from "components/Course/AddCourseDialog";
import * as React from "react";
import { listCourses } from "utilities/awsCRUD";
import { useSnackbar } from "notistack";
import Spinner from "components/Spinner";
import CourseCard from "components/Course/CourseCard";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "87vh",
  },
}));

interface CourseListViewProps {}

const CourseListView: React.FC<CourseListViewProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [courseList, setCourseList] = React.useState<any>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const { user } = useAuthValue();
  const isAdmin = user.groups?.includes("admin");
  const classes = useStyles();
  React.useEffect(() => {
    (async () => {
      try {
        const data = await listCourses();
        setCourseList(data);
      } catch (error) {
        console.log("list courses error", error);
        enqueueSnackbar("Connect error, please try again later", {
          variant: "error",
        });
      }
    })();
  }, [enqueueSnackbar]);
  return (
    <MainWrapper>
      <Card className={classes.root}>
        <CardHeader
          title="Courses List"
          action={
            isAdmin && (
              <IconButton
                aria-label="create"
                color="primary"
                onClick={() => setOpen(true)}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            )
          }
        />
        <CardContent>
          <Grid container spacing={3}>
            {!courseList ? (
              <Spinner />
            ) : (
              courseList.map((course: any) => (
                <Grid item xs={6} sm={4} key={course.id}>
                  <CourseCard course={course} />
                </Grid>
              ))
            )}
            {courseList && courseList.length === 0 && (
              <Grid item>
                <Typography>
                  You have not registered any IELTS course yet. Please contact
                  your instructor Caocuicui via wechat id:
                  <b>boxwhy-caocuicui</b> for course consulting.
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      {open && (
        <AddCourseDialog open={open} handleClose={() => setOpen(false)} />
      )}
    </MainWrapper>
  );
};

export default CourseListView;
