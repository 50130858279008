import * as React from "react";
import type { RefObject } from "react";
import {
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  IconButton,
} from "@material-ui/core";
import VolumeSlider from "components/VolumnSlider";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useHistory } from "react-router-dom";
import PracticeTimer from "components/Practice/PracticeTimer";
import { stringToSeconds, trackEvent } from "utilities";
import ResultDialog from "../ResultDialog";
import useHighlight from "hooks/useHighlight";
import ListAltIcon from "@material-ui/icons/ListAlt";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useAuthValue } from "context/auth.context";

interface TopBannerProps {
  format: string;
  onSubmit?: () => void;
  time?: string;
  toggleShowScript?: () => void;
  result?: any;
  title: string;
  audioPlayer?: RefObject<HTMLAudioElement | null>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "0.5rem 1rem",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    minHeight: "4rem",
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: "1rem",
  },
  timer: {
    cursor: "default",
  },
}));

const TopBanner: React.FC<TopBannerProps> = ({
  time,
  toggleShowScript,
  title,
  format,
  result,
  onSubmit,
  audioPlayer,
}) => {
  const { user } = useAuthValue();
  const classes = useStyles();
  const history = useHistory();
  const { highlightFabs } = useHighlight();
  const [open, setOpen] = React.useState<boolean>(false);
  const [openResultDialog, setOpenResultDialog] =
    React.useState<boolean>(false);
  const formatName = format.includes("E")
    ? "Exam"
    : format.includes("H")
    ? "Homework"
    : "Practice";
  const message = `Are you sure you want to quit the ${formatName}?`;
  const handleCancel = () => {
    trackEvent(`Quit ${format}`, user);
    history.goBack();
  };
  const readingOrListening = format.includes("L") || format.includes("R");
  return (
    <Grid
      container
      justify="space-between"
      direction="row"
      alignItems="center"
      className={classes.root}
    >
      <Grid item className={classes.title}>
        <IconButton
          onClick={() => setOpen(true)}
          className={classes.icon}
          color="primary"
        >
          <CancelPresentationIcon />
        </IconButton>

        <Typography>{title}</Typography>
      </Grid>

      {time && !result && (
        <Grid item className={classes.timer}>
          <Typography align="center">Timer</Typography>
          <PracticeTimer
            timeLimit={stringToSeconds(time)}
            handleSubmit={onSubmit}
            position="static"
          />
        </Grid>
      )}
      {audioPlayer && (
        <Grid item>
          <VolumeSlider audioPlayer={audioPlayer} />
        </Grid>
      )}
      {(result || format.includes("P")) && toggleShowScript && (
        <Grid item>
          <Button
            onClick={toggleShowScript}
            startIcon={<VisibilityIcon />}
            variant="outlined"
            color="primary"
          >
            script
          </Button>
        </Grid>
      )}

      {result && readingOrListening && (
        <Grid item>
          <Button
            onClick={() => setOpenResultDialog(true)}
            variant="outlined"
            startIcon={<ListAltIcon />}
            color="primary"
          >
            Result
          </Button>
        </Grid>
      )}
      {readingOrListening && highlightFabs}
      {openResultDialog && (
        <ResultDialog
          open={openResultDialog}
          setOpen={setOpenResultDialog}
          resultList={result}
        />
      )}
      {open && (
        <ConfirmationDialog
          open={open}
          onCancel={() => setOpen(false)}
          message={message}
          onConfirm={handleCancel}
        />
      )}
    </Grid>
  );
};

export default TopBanner;
