import * as React from "react";
import { Switch, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import Homework from "./Homework";
import Note from "./Note";
import Practice from "./Practice";
import Exam from "./Exam";
import CourseListView from "./Course/CourseListView";
import MyCourse from "./Course/MyCourse";
import MyCalendar from "./MyCalendar";

export interface RoutesProps {}

const Routes: React.FC<RoutesProps> = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Dashboard />
      </Route>
      <Route path="/practice">
        <Practice />
      </Route>
      <Route path="/note">
        <Note />
      </Route>
      <Route path="/homework">
        <Homework />
      </Route>
      <Route path="/exam">
        <Exam />
      </Route>
      <Route exact path="/course-list">
        <CourseListView />
      </Route>
      <Route exact path="/mycourse">
        <MyCourse />
      </Route>
      <Route exact path="/calendar">
        <MyCalendar />
      </Route>
    </Switch>
  );
};

export default Routes;
